import logowakapi from './../../assets/images/logos/wakapi-logo-sm.svg';
import styles from './landing.module.scss';
import { Link } from "react-router-dom";
import { Button, Footer, Head, PreloadImage, ContactSmall, SubwayLogo2, DollarGeneralLogo2, AmazonLogo2, ThomsonReutersLogo2, BudderflyLogo2 } from "../../components";
import { useEffect, useState } from 'react';


export const LandingStaffing = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            <Head
                title="Wakapi: software outsourcing services in Latin America"
                description="Choose Wakapi for staff augmentation and IT recruitment. Access top nearshore developers, reduce costs, and scale resources efficiently. "
                path="/it-staffing-services-technical-recruitment"
                haveCarousel={false}
            />
            <PreloadImage imagePath={`${process.env.PUBLIC_URL}/images/landing-staffing.webp`} />
            <div className={styles.background}>
                <div className={styles.img__staffing}>
                    <img src={`${process.env.PUBLIC_URL}/images/landing-staffing.webp`} alt="Software outsourcing services in latin america" fetchpriority="high" />
                </div>
                <div className={styles.img__gradient__staffing} />
                <div className={styles.container}>
                    <div className={styles.topBar}>
                        <Link to="/en/" style={{ textDecoration: "none" }}>
                            <div className={styles.logo}>
                                <img src={logowakapi} alt="logo wakapi" width={153} height={48} />
                            </div>
                        </Link>
                        <Link to="/en/clients">
                            <button className={styles.button} style={{color:'black', borderColor:'black'}} aria-label="Success stories">Success stories</button>
                        </Link>
                    </div>
                    <div className={styles.data__staffing}>
                        <h1>Choose Wakapi for Staff Augmentation & IT Recruitment!</h1>
                        <br />
                        <h2>Boost Efficiency and Save Money. </h2> {windowWidth > 930 && (<br/>)}       
                        <p>Get top nearshore developers at a fixed price, eliminating the need to build and manage an in-house team.</p>
                        <br /><br />
                        <h2>Expertise & Talent.</h2> {windowWidth > 930 && (<br/>)}       
                        <p>Outsource specialized software engineers, hand-picked for your projects and stay up-to-date with innovations.</p>
                        <br /><br />
                        <h2>Custom Flexibility, Reduced Overhead.</h2> {windowWidth > 930 && (<br/>)}       
                        <p>Choose from a vast talent pool of programmers to match your needs, while saving on long-term costs.</p>
                        <br /><br />
                        <h2 className={styles.lastTitle}>Agile Scaling Up or Down.</h2> {windowWidth > 930 && (<br/>)}       
                        <p>Seamlessly adjust your resources in response to project demands, scaling swiftly and efficiently.</p>
                        <br />
                        {windowWidth > 930 && (<br/>)}       
                        <Link to='https://meetings.hubspot.com/wakapi/meeting-with-wakapi?utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz--V7abN8cIRcLe7vAfQKMUdfarx3OhKkhpZ8SBYZ4Ks-gE-sO_ubxEZGu4v6ksDbVsDSsc2' target="__blank">
                            <Button primary>Let’s talk</Button>
                        </Link>
                    </div>
                    <div className={styles.logos}>
                        <SubwayLogo2 size={{ width: 150, height: 90 }} />
                        <DollarGeneralLogo2 size={{ width: 208, height: 90 }} />
                        <div className={styles.onlyDesktop}>
                            <AmazonLogo2 size={{ width: 156, height: 90 }} />
                        </div>
                        <ThomsonReutersLogo2 size={{ width: 259, height: 90 }} color={windowWidth > 930 ? 'black' : 'white'} />
                        <div className={styles.onlyDesktop}>
                            <BudderflyLogo2 size={{ width: 186, height: 90 }} color='white' />
                        </div>
                    </div>
                </div>
                <ContactSmall />
            </div>
            <Footer />
        </>
    )
}