import { FormattedMessage } from 'react-intl';
import styles from './servicesList.module.scss';
import { ServiceListItem } from './ServicesListItem';
import { IconCustomSoftwareDevelopment,
    IconProductDesignUserExperience,
    IconConsultancyAndInnovation,
    IconStaffAugmentationAndRecruitment,
    IconAppDevelopment,
    IconQATesting } from '../../../assets/icons';

export const ServicesList = ({small}) =>{
    const lang = localStorage.getItem('lang')

    const items = [
        {
            id: 1,
            title: <FormattedMessage id="services-custom-software-development-title"/>,
            text: <FormattedMessage id="services-custom-software-development-text-1"/>,
            icon: <IconCustomSoftwareDevelopment/>,
            link: `/${lang}/services/software-design-development`
        },
        {
            id: 2,
            title: <FormattedMessage id="services-product-design-user-experience-title"/>,
            text: <FormattedMessage id="services-product-design-user-experience-text-1"/>,
            icon: <IconProductDesignUserExperience/>,
            link: `/${lang}/services/product-design-ux`
            
        },
        {
            id: 3,
            title: <FormattedMessage id="services-consultancy-innovation-title"/>,
            text: <FormattedMessage id="services-consultancy-innovation-text-1"/>,
            icon: <IconConsultancyAndInnovation/>,
            link: `/${lang}/services/consultancy-innovation`
        },
        {
            id: 4,
            title: <FormattedMessage id="services-staff-augmentation-recruitment-title"/>,
            text: <FormattedMessage id="services-staff-augmentation-recruitment-text-1"/>,
            icon: <IconStaffAugmentationAndRecruitment/>,
            link: `/${lang}/services/staff-augmentation-recruitment`
        },
        {
            id: 5,
            title: <FormattedMessage id="services-app-development-title"/>,
            text: <FormattedMessage id="services-app-development-text-1"/>,
            icon: <IconAppDevelopment/>,
            link: `/${lang}/services/app-development`
        },
        {
            id: 6,
            title: <FormattedMessage id="services-qa-testing-title"/>,
            text: <FormattedMessage id="services-qa-testing-text-1"/>,
            icon: <IconQATesting/>,
            link: `/${lang}/services/qa-testing`
        }
    ]

    return(
        <div className={ small ? styles["container--small"] : styles.container}>
            {items.map((item)=>{
                return <ServiceListItem icon={item.icon} title={item.title} text={item.text} small={small} link={item.link} key={item.id}/>
            })}

        </div>
    )
}