export const PaydiantLogo = ({ size: { width = '148', height = '96' } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 148 96" fill="none" xmlns="http://www.w3.org/2000/svg" aria-valuetext="Paydiant">
        <path d="M19.5085 46.8466C18.826 47.6784 18.0419 48.3745 17.1562 48.9342C16.2698 49.4946 15.3141 49.9154 14.2898 50.1953C13.2649 50.4758 12.2218 50.6154 11.158 50.6154H7.99444L6.03972 56.3096H1.87646L8.39264 37.3296H16.1248C17.2598 37.3296 18.22 37.4881 19.0034 37.8036C19.7869 38.1205 20.3903 38.5679 20.8138 39.1459C21.2366 39.7244 21.4768 40.416 21.5344 41.2199C21.5914 42.0245 21.4528 42.9147 21.1174 43.8907L21.0987 43.9446C20.7199 45.0472 20.189 46.0147 19.5078 46.8459L19.5085 46.8466ZM16.8726 43.9725C17.195 43.0329 17.1381 42.3186 16.7023 41.8303C16.2659 41.342 15.5252 41.0985 14.4795 41.0985H11.2622L9.26996 46.9011H12.5688C13.6139 46.9011 14.519 46.6258 15.2856 46.0738C16.0516 45.5225 16.5741 44.8401 16.8538 44.0271L16.8726 43.9725Z" fill="#6F706F"/>
        <path d="M28.8852 56.3089L29.4252 54.7362C28.7279 55.2966 27.971 55.744 27.1532 56.0784C26.3355 56.4128 25.4219 56.5804 24.4125 56.5804C23.7274 56.5804 23.1156 56.4856 22.5769 56.2953C22.0382 56.1057 21.6089 55.8213 21.2884 55.4414C20.9679 55.0615 20.7717 54.5966 20.6999 54.0453C20.6273 53.494 20.7154 52.8563 20.964 52.1336L20.9828 52.079C21.2554 51.2836 21.6361 50.5972 22.123 50.0186C22.6093 49.4407 23.1751 48.9608 23.8194 48.5816C24.463 48.2024 25.1732 47.9173 25.9509 47.7277C26.7278 47.5381 27.5488 47.4427 28.4139 47.4427C29.1526 47.4427 29.7781 47.4972 30.2909 47.6056C30.803 47.7141 31.2899 47.8589 31.7509 48.0394L31.8351 47.7953C32.1329 46.9277 32.0973 46.2686 31.7302 45.816C31.3624 45.3647 31.005 44.8173 29.8396 44.6874C29.051 44.5998 28.1587 44.6816 27.5087 44.8173C26.858 44.953 24.6358 45.7335 23.8828 45.9861L25.4193 42.7796C26.3296 42.4368 27.2555 42.1563 28.1957 41.9394C29.1351 41.7225 30.2177 41.614 31.4434 41.614C33.6959 41.614 35.1469 42.166 35.7957 43.2686C36.4445 44.3712 36.4309 45.9082 35.7543 47.8777L32.8594 56.3102H28.8846L28.8852 56.3089ZM31.029 49.9011C30.7156 49.755 30.353 49.6433 29.9406 49.566C29.5288 49.4888 29.0704 49.4628 28.5667 49.4901C27.6842 49.5375 26.9421 49.7505 26.3393 50.1258C25.7359 50.5024 25.3396 51.0212 25.1512 51.6829L25.1357 51.7381C24.9732 52.3076 25.0548 52.7381 25.3804 53.0284C25.7055 53.3187 26.2008 53.4453 26.8664 53.4096C27.8389 53.357 28.6845 53.0771 29.4058 52.5667C30.1264 52.0576 30.5971 51.4167 30.8166 50.6446L31.0284 49.9005L31.029 49.9011Z" fill="#6F706F"/>
        <path d="M44.7573 57.311C44.2322 58.0519 43.7285 58.6805 43.2455 59.1954C42.7618 59.711 42.2795 60.1305 41.7984 60.4564C41.3173 60.7818 40.8142 61.0039 40.2995 61.161C39.7847 61.3181 39.0343 61.5701 38.4516 61.635C37.7109 61.7175 36.6639 61.5506 36.1207 61.5052C35.3437 61.4402 34.2068 61.0331 33.7898 60.7259L35.3049 57.9766C35.5192 58.1389 35.7652 58.274 36.0443 58.3831C36.3221 58.4915 36.5972 58.5279 36.8672 58.546C37.4163 58.5831 38.2561 58.4454 38.517 58.3233C39.4882 57.8688 40.4581 56.7246 40.7624 56.3636L39.0997 41.7765H44.4466L44.3468 52.0252L51.4665 41.7765H56.3873L44.758 57.311H44.7573Z" fill="#6F706F"/>
        <path d="M63.5023 56.3091L64.2132 54.2396C63.4745 54.9214 62.6735 55.4812 61.8111 55.9208C60.948 56.3604 59.9762 56.5799 58.8949 56.5799C58.0473 56.5799 57.2833 56.4176 56.6022 56.0916C55.9204 55.7662 55.3836 55.2831 54.9913 54.6409C54.5982 53.9994 54.3775 53.213 54.3276 52.2825C54.2777 51.3519 54.4603 50.2805 54.876 49.0695L54.8948 49.0156C55.3105 47.8045 55.8634 46.7337 56.5523 45.8026C57.2406 44.872 57.9975 44.0857 58.8217 43.4441C59.6459 42.8025 60.5103 42.3188 61.4155 41.9934C62.32 41.6681 63.2051 41.5051 64.0701 41.5051C65.1695 41.5051 66.0009 41.7201 66.5655 42.1506C67.1301 42.5811 67.5568 43.0837 67.8462 43.6571L69.8449 37.8694H74.5372L68.1945 56.3098H63.5023V56.3091ZM66.0605 49.0162C66.267 48.4149 66.3609 47.8655 66.3434 47.3642C66.3259 46.8642 66.2204 46.437 66.0268 46.0818C65.8332 45.7266 65.5574 45.4538 65.1993 45.2623C64.8413 45.0714 64.4191 44.9753 63.9329 44.9753C63.4466 44.9753 62.9578 45.0714 62.4689 45.2623C61.9801 45.4532 61.5126 45.7259 61.0659 46.0818C60.6191 46.437 60.2177 46.8591 59.8596 47.3506C59.5022 47.8422 59.2167 48.3974 59.0043 49.0156L58.9855 49.0695C58.779 49.6708 58.6851 50.2208 58.7019 50.7214C58.7194 51.2214 58.8295 51.6487 59.0321 52.0039C59.2348 52.3591 59.5145 52.6318 59.8732 52.8234C60.2313 53.0143 60.6534 53.1097 61.1403 53.1097C61.6272 53.1097 62.1148 53.0143 62.6036 52.8234C63.0924 52.6325 63.5554 52.3597 63.9931 52.0039C64.4308 51.6487 64.8296 51.2214 65.1903 50.7214C65.5515 50.2208 65.8351 49.6701 66.041 49.0695L66.0598 49.0156L66.0605 49.0162Z" fill="#6F706F"/>
        <path d="M72.0601 56.309L77.0495 41.7758H81.159L76.1696 56.309H72.0601ZM77.4904 40.1764L78.7472 36.516H83.0729L81.8162 40.1764H77.4904Z" fill="#6F706F"/>
        <path d="M108.556 42.216C108.974 41.9972 109.411 41.8244 109.87 41.6972C110.328 41.5693 110.818 41.5056 111.341 41.5056C112.909 41.5056 113.957 41.9848 114.484 42.9426C115.01 43.9011 114.986 45.2199 114.409 46.9011L111.178 56.3096H107.069L109.845 48.2225C110.18 47.2485 110.216 46.5141 109.953 46.018C109.691 45.5219 109.163 45.2745 108.37 45.2745C107.577 45.2745 105.595 45.5225 104.966 46.018C104.336 46.5141 103.854 47.2485 103.519 48.2225L100.743 56.3096H96.6335L101.623 41.7764H105.732L105.019 43.8543C105.462 43.6478 108.555 42.216 108.555 42.216H108.556Z" fill="#6F706F"/>
        <path d="M122.718 56.3234C122.142 56.4767 121.512 56.5533 120.827 56.5533C120.196 56.5533 119.647 56.4864 119.18 56.35C118.712 56.2143 118.354 55.9754 118.102 55.6312C117.85 55.2884 117.715 54.8403 117.698 54.289C117.68 53.7377 117.816 53.0377 118.108 52.1877L120.473 45.3006H117.049L118.259 41.7759H121.683L122.959 38.061H127.068L125.793 41.7759H129.199L127.989 45.3006H124.582L122.451 51.5098C122.128 52.4494 122.408 52.9195 123.291 52.9195C124.013 52.9195 124.747 52.7481 125.496 52.4046L124.36 55.7124C123.841 55.9663 123.293 56.1695 122.718 56.3228L122.718 56.3234Z" fill="#6F706F"/>
        <path d="M88.5175 56.2785L89.0575 54.7057C88.3602 55.2661 87.6033 55.7135 86.7855 56.048C85.9678 56.3824 85.0542 56.5499 84.0448 56.5499C83.3598 56.5499 82.7479 56.4551 82.2092 56.2648C81.6705 56.0752 81.2412 55.7908 80.9207 55.4109C80.6002 55.0311 80.404 54.5661 80.3322 54.0148C80.2597 53.4635 80.3477 52.8259 80.5964 52.1031L80.6151 52.0486C80.8877 51.2531 81.2684 50.5668 81.7553 49.9882C82.2416 49.4103 82.8075 48.9304 83.4517 48.5512C84.0953 48.1719 84.8056 47.8869 85.5832 47.6973C86.3602 47.5077 87.1812 47.4122 88.0462 47.4122C88.7849 47.4122 89.4104 47.4667 89.9232 47.5752C90.4354 47.6836 90.9223 47.8284 91.3833 48.009L91.4674 47.7648C91.7653 46.8973 91.7297 46.2382 91.3625 45.7856C90.9948 45.3343 90.6374 44.7869 89.4719 44.657C88.6833 44.5693 87.7911 44.6511 87.141 44.7869C86.4903 44.9226 84.2682 45.7031 83.5152 45.9557L85.0516 42.7492C85.962 42.4063 86.8878 42.1258 87.828 41.9089C88.7675 41.692 89.85 41.5836 91.0757 41.5836C93.3283 41.5836 94.7793 42.1355 95.428 43.2381C96.0768 44.3407 96.0632 45.8778 95.3866 47.8473L92.4917 56.2798H88.5169L88.5175 56.2785ZM90.6613 49.8707C90.3479 49.7245 89.9854 49.6129 89.5729 49.5356C89.1611 49.4583 88.7027 49.4323 88.199 49.4596C87.3165 49.507 86.5745 49.72 85.9717 50.0953C85.3682 50.472 84.972 50.9908 84.7836 51.6525L84.768 51.7077C84.6055 52.2772 84.6871 52.7077 85.0128 52.9979C85.3378 53.2882 85.8331 53.4148 86.4987 53.3791C87.4712 53.3265 88.3168 53.0466 89.0381 52.5363C89.7587 52.0272 90.2295 51.3862 90.449 50.6142L90.6607 49.87L90.6613 49.8707Z" fill="#6F706F"/>
        <path d="M84.6877 35.9536C92.192 31.2458 112.652 21.3756 132.562 30.2393C143.116 34.938 142.766 47.2823 128.616 56.3096C134.666 49.4277 139.911 41.8049 130.846 34.2978C122.299 27.2198 100.868 28.4536 84.6877 35.9536Z" fill="#49A03F"/>
        <path d="M95.9045 28.6303C95.9045 28.6303 112.911 18.9731 131.429 23.4537C149.947 27.9342 144.767 41.181 144.767 41.181C144.767 41.181 146.903 30.0121 130.911 25.9861C114.918 21.9601 102.272 26.5342 95.9052 28.6303H95.9045Z" fill="#49A03F"/>
        </svg>
        
    )
}