export const InCrowdLogo = ({ size: { width = '193', height = '111' } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 193 111" fill="none" xmlns="http://www.w3.org/2000/svg" aria-valuetext="In Crowd">
            <path d="M113.958 50.7077C113.021 50.7077 112.183 50.7249 111.346 50.6927C111.194 50.6863 110.957 50.5126 110.916 50.371C110.038 47.3257 107.854 45.9704 104.823 45.9082C101.714 45.8438 99.2989 47.1906 98.0471 50.0558C96.4169 53.7873 96.4645 57.5897 98.5422 61.1432C100.797 65.0034 106.689 65.5589 109.508 62.3077C110.418 61.2569 110.892 60.0152 111.112 58.6662C111.149 58.441 111.184 58.2159 111.225 57.9607H114.118C113.992 61.1303 112.28 64.6131 108.95 66.0114C105.952 67.2702 102.879 67.2702 99.8891 66.005C97.1369 64.8383 95.4591 62.6315 94.6375 59.8393C93.6581 56.5088 93.6689 53.1461 94.9272 49.8756C96.6417 45.4171 100.722 43.0023 105.649 43.4312C108.615 43.6885 111.11 44.7973 112.775 47.3622C113.413 48.3423 113.804 49.421 113.955 50.7077H113.958Z" fill="#917CBD" />
            <path d="M144.18 50.1264C145.114 50.1264 145.953 50.1007 146.785 50.1479C146.95 50.1564 147.183 50.4266 147.237 50.6175C148.353 54.5592 149.447 58.5074 150.545 62.4534C150.619 62.715 150.707 62.9724 150.917 63.234C151.347 61.4991 151.78 59.7641 152.21 58.0292C152.815 55.5801 153.423 53.1309 154.015 50.6797C154.11 50.2851 154.279 50.0642 154.724 50.1178C154.977 50.1479 155.237 50.1221 155.494 50.1221C157.148 50.1221 157.131 50.1264 157.539 51.7327C158.469 55.3892 159.416 59.0414 160.359 62.6936C160.4 62.8501 160.467 62.9981 160.588 63.3348C160.841 62.4641 161.055 61.75 161.254 61.0337C162.196 57.6195 163.148 54.2097 164.06 50.7891C164.203 50.2529 164.443 50.0792 164.977 50.1135C165.623 50.1543 166.272 50.1243 167.031 50.1243C166.752 51.0121 166.512 51.7992 166.259 52.5841C164.847 56.9933 163.422 61.3983 162.03 65.8118C161.889 66.2579 161.69 66.3973 161.247 66.3715C160.689 66.3394 160.123 66.3286 159.565 66.3737C159.087 66.4123 158.925 66.2043 158.817 65.7839C157.851 62.0181 156.869 58.2565 155.89 54.4928C155.821 54.2311 155.743 53.9695 155.539 53.695C155.222 54.9195 154.897 56.1419 154.588 57.3665C153.877 60.1716 153.168 62.9745 152.469 65.7818C152.366 66.2021 152.182 66.4058 151.708 66.3715C151.127 66.3286 150.541 66.3415 149.957 66.3672C149.551 66.3844 149.345 66.2707 149.209 65.8461C147.583 60.7528 145.929 55.668 144.286 50.5789C144.251 50.4717 144.232 50.3602 144.176 50.1243L144.18 50.1264Z" fill="#917CBD" />
            <path d="M181.532 52.2073V43.9614H184.17V66.3186H181.539V64.2469C181.238 64.6115 181.056 64.8581 180.847 65.079C179.792 66.1899 178.43 66.6446 176.951 66.7325C172.763 66.977 169.755 64.6329 168.962 60.5433C168.471 58.0127 168.655 55.5571 169.915 53.2581C172.447 48.6344 179.059 48.969 181.307 52.055C181.331 52.0872 181.379 52.1044 181.534 52.2073H181.532ZM171.444 58.2529C171.649 59.2458 171.742 60.2259 172.065 61.1266C172.741 63.0267 174.105 64.2062 176.185 64.3756C178.3 64.5472 179.965 63.7365 180.871 61.8021C181.96 59.4795 181.971 57.0776 180.86 54.7572C179.924 52.8056 178.22 52.0272 176.097 52.1451C174.14 52.2523 172.815 53.3118 172.162 55.0896C171.792 56.0954 171.675 57.1913 171.444 58.2529Z" fill="#917CBD" />
            <path d="M126.583 58.1842C126.62 56.6851 126.845 55.2268 127.519 53.8693C128.823 51.2529 131.026 49.9662 133.897 49.7946C139.631 49.4558 142.757 53.5219 142.455 58.9455C142.303 61.6605 141.322 64.0131 138.913 65.5487C134.836 68.1457 127.366 66.8869 126.674 59.501C126.633 59.0635 126.611 58.6238 126.581 58.1842H126.583ZM139.776 58.4759C139.538 57.35 139.426 56.4021 139.13 55.5142C138.461 53.5004 136.678 52.1837 134.71 52.1344C132.579 52.0807 130.849 53.2281 130.014 55.3105C129.184 57.3843 129.201 59.4817 130.127 61.5126C130.94 63.3011 132.365 64.3348 134.384 64.3906C136.429 64.4463 137.969 63.5135 138.818 61.7013C139.307 60.659 139.491 59.4752 139.776 58.4759Z" fill="#917CBD" />
            <path d="M120.08 66.3185H117.455V50.1827H119.928V53.3331C121.295 51.047 123.124 49.6552 125.952 49.7903V52.5289C125.234 52.6361 124.508 52.6812 123.818 52.8634C121.918 53.361 120.791 54.6434 120.385 56.5135C120.199 57.367 120.112 58.2549 120.095 59.1277C120.054 61.2594 120.082 63.3911 120.082 65.525C120.082 65.7738 120.082 66.0225 120.082 66.3185H120.08Z" fill="#917CBD" />
            <path d="M90.4663 65.7411H88.5637C88.5637 65.4666 88.5637 65.2286 88.5637 64.9905C88.5637 62.0739 88.6026 59.1552 88.5421 56.2385C88.5226 55.2756 88.3475 54.287 88.0535 53.3691C87.6816 52.2132 86.7325 51.5977 85.5304 51.4347C82.1814 50.98 79.762 52.9852 79.6799 56.3608C79.6107 59.2109 79.6582 62.061 79.6539 64.9133C79.6539 65.175 79.6539 65.4366 79.6539 65.7368H77.7578V50.253H79.6345V52.6785C79.7577 52.5885 79.8247 52.567 79.8485 52.522C81.5976 49.2086 86.0774 49.2537 88.3043 50.5619C89.5085 51.2696 90.0663 52.4319 90.302 53.7487C90.4144 54.3813 90.4555 55.0333 90.4598 55.6767C90.475 58.8163 90.4663 61.9581 90.4663 65.0978C90.4663 65.3122 90.4663 65.5245 90.4663 65.7433V65.7411Z" fill="#FFBF3D" />
            <path d="M71.1328 44.2417H73.1111V65.7282H71.1328V44.2417Z" fill="#FFBF3D" />
            <path d="M33.7114 47.8876C37.9503 47.8876 41.3866 44.479 41.3866 40.2744C41.3866 36.0697 37.9503 32.6611 33.7114 32.6611C29.4725 32.6611 26.0361 36.0697 26.0361 40.2744C26.0361 44.479 29.4725 47.8876 33.7114 47.8876Z" fill="#917CBD" />
            <path d="M35.869 51.1045H31.5558C27.569 51.1045 24.3086 54.3406 24.3086 58.2931V72.0076C24.3086 74.2101 25.3226 76.1895 26.9074 77.5106C29.0867 78.051 31.3655 78.3384 33.7135 78.3384C36.0615 78.3384 38.3403 78.051 40.5196 77.5106C42.1065 76.1895 43.1184 74.2101 43.1184 72.0076V58.2931C43.1184 54.3385 39.8559 51.1045 35.8712 51.1045H35.869Z" fill="#917CBD" />
            <path d="M49.2778 55.8226C53.5167 55.8226 56.9531 52.4141 56.9531 48.2094C56.9531 44.0048 53.5167 40.5962 49.2778 40.5962C45.0389 40.5962 41.6025 44.0048 41.6025 48.2094C41.6025 52.4141 45.0389 55.8226 49.2778 55.8226Z" fill="#FD8B73" />
            <path d="M17.9282 55.8226C22.1671 55.8226 25.6034 52.4141 25.6034 48.2094C25.6034 44.0048 22.1671 40.5962 17.9282 40.5962C13.6893 40.5962 10.2529 44.0048 10.2529 48.2094C10.2529 52.4141 13.6893 55.8226 17.9282 55.8226Z" fill="#FFBF3D" />
            <path d="M20.0857 58.1816H15.7724C12.5056 58.1816 9.72949 60.3541 8.83008 63.3136C12.5423 70.4314 19.2684 75.7521 27.3329 77.6179V65.3724C27.3329 61.4178 24.0703 58.1838 20.0857 58.1838V58.1816Z" fill="#FFBF3D" />
            <path d="M58.3015 63.8562C57.5945 60.6222 54.6801 58.1816 51.2187 58.1816H46.9054C42.9186 58.1816 39.6582 61.4178 39.6582 65.3702V77.7101C47.7075 75.9837 54.4682 70.826 58.3037 63.8562H58.3015Z" fill="#FD8B73" />
        </svg>
    )
}