import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "../../components/Button/Button";
import styles from "./clients.module.scss";
import { ClientsCommentsSlider } from "./ClientsSlider";

import { useLocation, useNavigate } from "react-router-dom";

export const ClientsComments = ({isHome}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const lang = localStorage.getItem('lang')
  const goToClients = () => {
    const path = location.pathname;
    if (path.includes('/clients')) {
      window.scrollTo(0, 0);
    } else {
      navigate('/' + lang + '/clients')
    }
  }
   {/* Slider de comentarios de clientes */}  
  return (
   
      <div className={isHome ? styles["background--home"] : styles.background}>
        <div className={styles.clientes__container}>
          <div className={styles.clientes__title}>
            <h3>
              <FormattedMessage id="clients-title" />
            </h3>
            <Button secondary onClick={goToClients}>
              <FormattedMessage id="clients-button" />
            </Button>
          </div>
          <div className={styles.clientes__data}>
            <ClientsCommentsSlider />

          </div>
        </div>
      </div>
  );
};
export default ClientsComments;