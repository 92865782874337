import Slider from 'react-slick';
import styles from './slider.module.scss';
import { useRef } from 'react';
import { useEffect } from 'react';

export const SliderComponent = ({resetSlide,
    customPaging,
    appendDots,
    nextArrow,
    prevArrow,
    dots = true,
    infinite = true,
    speed = 1000,
    autoplay = true,
    arrows = true,
    autoplaySpeed = 8000,
    children,
    className,
    afterChange,
    initPlay,
    awaitToPlay,
    slidesToShow=1,
    cssEase='ease',
    manualPlay=false,
    isNext=false,
    draggable=true,
    onSwipe,
    pauseOnHover= false }) => {
    var settings = {
        dots: dots,
        infinite: infinite,
        speed: speed,
        slidesToShow: slidesToShow,
        autoplay: autoplay,
        slidesToScroll: 1,
        autoplaySpeed: autoplaySpeed,
        arrows: arrows,
        cssEase:cssEase,
        draggable: draggable,
        pauseOnHover: pauseOnHover,
        pauseOnFocus: false,
        pauseOnDotsHover: false,
        onSwipe:onSwipe,
        nextArrow: nextArrow || <SampleNextArrow />,
        prevArrow: prevArrow || <SamplePrevArrow />,
        appendDots: appendDots ? appendDots : dots => {
            return (
                <div
                    style={{
                        backgroundColor: "transparent",
                        borderRadius: "10px",
                        bottom: "4px"
                    }}
                >
                    <ul className={styles.dots}> {dots} </ul>
                </div>
            )
        },
        customPaging: customPaging ? customPaging : i => {
            return (
                <div style={{ color: 'transparent' }}>{i} </div>
            )
        }
    };
    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            <div
                className={styles.arrowRight}
                onClick={onClick}
            >
                <svg width="21" height="40" viewBox="0 0 21 40" fill="none">
                    <path
                        d="M1.78516 1.6095L19.7852 20.1095L1.78516 38.6095"
                        stroke="#00D264"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <div
                className={styles.arrowLeft}
                onClick={onClick}
            >
                <svg width="21" height="38" viewBox="0 0 21 38" fill="none">
                    <path
                        d="M19.7852 36.059L1.78515 18.559L19.7852 1.05896"
                        stroke="#00D264"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
        );
    }
    let sliderRef = useRef(null);

    useEffect(() => {
        if(awaitToPlay)
            pause();
    },
    [sliderRef, awaitToPlay])

    useEffect(() => {
        if (initPlay)
            play();
    }, [initPlay])

    
    useEffect(() => {
        if (resetSlide)
            reset();
    }, [resetSlide])

    useEffect(() => {
        if (manualPlay) {
            if (isNext) {
                next();
            } else {
                back();
            }
            pause();
        } else {
            play();
        }
    }, [manualPlay]);

    const handelAfterChange = (index) =>{
        if(afterChange){
            afterChange(index);
        }
    }
    const handleSwipe = (direction) => {
        pause(); // Detener el autoplay
        if (onSwipe) {
            onSwipe(direction); // Notificar al componente padre si es necesario
        }
    };
    const next = () =>{
        sliderRef.slickNext();
    }

    const back = () =>{
        sliderRef.slickPrev();
    }
    const play = () => {
        sliderRef.slickPlay();
    };

    const pause = () => {
        sliderRef.slickPause();
    };
    
    const reset = () => {
        sliderRef.slickGoTo(0);
    };
    return (

        <Slider
        {...settings}
        easing='easing-in'
        className={className}
        afterChange={handelAfterChange}
        ref={slider => (sliderRef = slider)}
        swipe={true}
        swipeToSlide={true}
        onSwipe={handleSwipe} 
    >
          {children}
        </Slider>
    )
}
