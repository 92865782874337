export const PythonIcon = ({ size: { width = "124", height = "136" } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 124 136" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1073_2061)">
                <path d="M55.2313 0.552189C50.6476 0.573487 46.2703 0.964407 42.4188 1.64594C31.0726 3.65044 29.0125 7.84604 29.0125 15.5834V25.8022H55.825V29.2084H29.0125H18.95C11.1576 29.2084 4.33428 33.8922 2.20004 42.8022C-0.261785 53.0152 -0.37098 59.3882 2.20004 70.0522C4.10596 77.99 8.65758 83.6459 16.45 83.6459H25.6688V71.3959C25.6688 62.546 33.3259 54.7397 42.4188 54.7397H69.2C76.655 54.7397 82.6063 48.6015 82.6063 41.1147V15.5834C82.6063 8.3171 76.4763 2.85866 69.2 1.64594C64.594 0.879214 59.8149 0.530891 55.2313 0.552189ZM40.7313 8.77094C43.5008 8.77094 45.7625 11.0696 45.7625 13.8959C45.7625 16.7123 43.5008 18.9897 40.7313 18.9897C37.9518 18.9897 35.7 16.7123 35.7 13.8959C35.7 11.0696 37.9518 8.77094 40.7313 8.77094Z" fill="url(#paint0_linear_1073_2061)" />
                <path d="M85.9502 29.2084V41.1147C85.9502 50.3454 78.1243 58.1147 69.2002 58.1147H42.4189C35.0831 58.1147 29.0127 64.3932 29.0127 71.7397V97.2709C29.0127 104.537 35.3313 108.811 42.4189 110.896C50.9063 113.392 59.0452 113.843 69.2002 110.896C75.9504 108.942 82.6064 105.008 82.6064 97.2709V87.0522H55.8252V83.6459H82.6064H96.0127C103.805 83.6459 106.709 78.2105 109.419 70.0522C112.218 61.6533 112.099 53.5764 109.419 42.8022C107.493 35.0447 103.815 29.2084 96.0127 29.2084H85.9502ZM70.8877 93.8647C73.6672 93.8647 75.9189 96.1421 75.9189 98.9584C75.9189 101.785 73.6672 104.083 70.8877 104.083C68.1181 104.083 65.8564 101.785 65.8564 98.9584C65.8564 96.1421 68.1181 93.8647 70.8877 93.8647Z" fill="url(#paint1_linear_1073_2061)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_1073_2061" x1="0.3125" y1="0.551269" x2="62.1392" y2="53.2362" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5A9FD4" />
                    <stop offset="1" stop-color="#306998" />
                </linearGradient>
                <linearGradient id="paint1_linear_1073_2061" x1="70.2434" y1="98.0993" x2="48.0623" y2="67.017" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFD43B" />
                    <stop offset="1" stop-color="#FFE873" />
                </linearGradient>
                <clipPath id="clip0_1073_2061">
                    <rect width="122.76" height="134.668" fill="white" transform="translate(0.3125 0.55127)" />
                </clipPath>
            </defs>
        </svg>

    )
}