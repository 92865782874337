export const RecoveryPlannerLogo = ({ size: { width = '233', height = '200' } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 233 200" fill="none" xmlns="http://www.w3.org/2000/svg" aria-valuetext="Recovery planner">
        <path d="M8.49237 52.678H17.0171C22.6995 52.678 26.5492 52.8966 28.5598 53.336C30.5725 53.7754 32.2123 54.8944 33.4812 56.697C34.7502 58.4997 35.3847 61.3741 35.3847 65.3225C35.3847 68.9278 34.9367 71.35 34.0407 72.5889C33.1447 73.83 31.3806 74.5738 28.7484 74.8203C31.132 75.4119 32.7353 76.2028 33.5541 77.1953C34.3751 78.1877 34.8852 79.0987 35.0846 79.9282C35.2839 80.7578 35.3847 83.0427 35.3847 86.781V98.9968H24.1978V83.6043C24.1978 81.1243 24.0027 79.5896 23.6104 78.998C23.2182 78.4064 22.1936 78.1106 20.5345 78.1106V98.9946H8.49023V52.678H8.49237ZM20.5367 60.6025V70.902C21.8914 70.902 22.8409 70.7155 23.3832 70.3447C23.9277 69.9738 24.1978 68.7671 24.1978 66.7264V64.18C24.1978 62.7117 23.9363 61.7471 23.4111 61.2905C22.8859 60.8318 21.9278 60.6046 20.5367 60.6046V60.6025Z" fill="black"/>
        <path d="M37.9976 52.678H58.0821V61.9465H50.044V70.7305H57.5677V79.5424H50.044V89.7262H58.8838V98.9946H37.9997V52.678H37.9976Z" fill="black"/>
        <path d="M88.4146 72.848H76.3703V64.7971C76.3703 62.4564 76.2417 60.9966 75.9845 60.4158C75.7272 59.8349 75.1592 59.5455 74.2825 59.5455C73.2901 59.5455 72.662 59.8992 72.3941 60.6044C72.1262 61.3096 71.9933 62.8358 71.9933 65.1807V86.695C71.9933 88.9457 72.1262 90.414 72.3941 91.0999C72.662 91.7858 73.2622 92.1288 74.1968 92.1288C75.1313 92.1288 75.6801 91.7858 75.9566 91.0977C76.2331 90.4097 76.3703 88.7935 76.3703 86.2513V80.4296H88.4146V82.2344C88.4146 87.0294 88.0759 90.429 87.3986 92.4331C86.7212 94.4395 85.2251 96.1971 82.908 97.7061C80.5908 99.2152 77.7335 99.9697 74.3404 99.9697C70.9472 99.9697 67.9035 99.3309 65.6142 98.0534C63.325 96.7759 61.8095 95.0075 61.0657 92.7461C60.3219 90.4868 59.949 87.0873 59.949 82.5473V69.0155C59.949 65.678 60.0626 63.1744 60.2919 61.5047C60.5213 59.8349 61.2029 58.2294 62.3368 56.6839C63.4707 55.1385 65.0441 53.9231 67.0568 53.0357C69.0695 52.1483 71.3802 51.7046 73.9953 51.7046C77.5428 51.7046 80.4708 52.3905 82.7793 53.7623C85.0857 55.1342 86.6033 56.8447 87.3278 58.8917C88.0523 60.9388 88.4146 64.124 88.4146 68.4474V72.848Z" fill="black"/>
        <path d="M118.207 79.7996C118.207 84.4531 118.098 87.7476 117.877 89.6832C117.658 91.6188 116.97 93.3872 115.817 94.9905C114.664 96.5917 113.103 97.8221 111.14 98.6816C109.174 99.539 106.887 99.9698 104.274 99.9698C101.661 99.9698 99.5672 99.5647 97.593 98.7545C95.6188 97.9442 94.0305 96.7289 92.8301 95.1063C91.6276 93.4858 90.9139 91.7217 90.6845 89.814C90.4552 87.9063 90.3416 84.5688 90.3416 79.8017V71.8772C90.3416 67.2237 90.4509 63.9291 90.6716 61.9935C90.8903 60.058 91.5784 58.2896 92.7316 56.6862C93.8848 55.0851 95.4452 53.8547 97.4087 52.9951C99.3721 52.1378 101.661 51.7069 104.274 51.7069C106.754 51.7069 108.981 52.112 110.956 52.9223C112.93 53.7325 114.518 54.95 115.718 56.5705C116.921 58.191 117.635 59.9572 117.864 61.8628C118.093 63.7705 118.207 67.1079 118.207 71.8751V79.7996ZM106.163 64.5786C106.163 62.4244 106.043 61.0461 105.805 60.4438C105.567 59.8436 105.076 59.5435 104.332 59.5435C103.702 59.5435 103.222 59.7857 102.887 60.2723C102.553 60.7589 102.386 62.195 102.386 64.5786V86.2065C102.386 88.8966 102.495 90.5556 102.716 91.1837C102.935 91.8138 103.445 92.1268 104.246 92.1268C105.048 92.1268 105.59 91.7645 105.82 91.04C106.049 90.3155 106.163 88.59 106.163 85.8614V64.5765V64.5786Z" fill="black"/>
        <path d="M149.286 52.678L143.155 98.9968H124.861L117.901 52.678H130.603C132.08 65.4361 133.132 76.2329 133.765 85.0641C134.386 76.1385 135.046 68.214 135.745 61.2927L136.581 52.6801H149.284L149.286 52.678Z" fill="black"/>
        <path d="M149.266 52.678H169.351V61.9465H161.313V70.7305H168.836V79.5424H161.313V89.7262H170.153V98.9946H149.269V52.678H149.266Z" fill="black"/>
        <path d="M171.561 52.678H180.085C185.768 52.678 189.618 52.8966 191.628 53.336C193.641 53.7754 195.281 54.8944 196.55 56.697C197.819 58.4997 198.453 61.3741 198.453 65.3225C198.453 68.9278 198.005 71.35 197.109 72.5889C196.213 73.83 194.449 74.5738 191.817 74.8203C194.2 75.4119 195.804 76.2028 196.622 77.1953C197.443 78.1877 197.954 79.0987 198.153 79.9282C198.352 80.7578 198.453 83.0427 198.453 86.781V98.9968H187.266V83.6043C187.266 81.1243 187.071 79.5896 186.679 78.998C186.287 78.4064 185.262 78.1106 183.603 78.1106V98.9946H171.559V52.678H171.561ZM183.605 60.6025V70.902C184.96 70.902 185.909 70.7155 186.452 70.3447C186.996 69.9738 187.266 68.7671 187.266 66.7264V64.18C187.266 62.7117 187.005 61.7471 186.479 61.2905C185.954 60.8318 184.996 60.6046 183.605 60.6046V60.6025Z" fill="black"/>
        <path d="M226.728 52.678L217.946 82.2303V98.9946H206.789V82.2303L198.32 52.678H209.385C211.115 61.7192 212.09 67.8025 212.311 70.9298C212.977 65.9891 214.096 59.9037 215.665 52.678H226.73H226.728Z" fill="black"/>
        <path d="M8.49219 102.622H20.6222C23.9018 102.622 26.4247 102.879 28.1888 103.393C29.9529 103.908 31.2797 104.651 32.165 105.625C33.0524 106.598 33.6525 107.775 33.9676 109.157C34.2827 110.54 34.4392 112.681 34.4392 115.579V119.613C34.4392 122.569 34.1348 124.725 33.5239 126.078C32.913 127.433 31.792 128.472 30.1629 129.197C28.5317 129.921 26.4011 130.283 23.7689 130.283H20.5365V148.936H8.49219V102.617V102.622ZM20.5365 110.546V122.333C20.8795 122.352 21.1752 122.361 21.4239 122.361C22.5299 122.361 23.2973 122.089 23.726 121.547C24.1547 121.002 24.3691 119.872 24.3691 118.156V114.351C24.3691 112.769 24.1204 111.738 23.6253 111.262C23.1301 110.786 22.0991 110.546 20.5343 110.546H20.5365Z" fill="black"/>
        <path d="M48.8326 102.621V139.669H56.157V148.938H36.7883V102.619H48.8326V102.621Z" fill="black"/>
        <path d="M80.2414 102.621L87.1284 148.94H74.8184L74.171 140.615H69.8626L69.1381 148.94H56.6865L62.8298 102.621H80.2393H80.2414ZM73.8581 132.403C73.2493 127.156 72.6363 120.672 72.0232 112.949C70.7972 121.818 70.0255 128.303 69.7125 132.403H73.8581Z" fill="black"/>
        <path d="M114.953 102.621V148.94H104.396L98.1309 127.885V148.94H88.0608V102.621H98.1309L104.883 123.478V102.621H114.953Z" fill="black"/>
        <path d="M145.569 102.621V148.94H135.012L128.746 127.885V148.94H118.676V102.621H128.746L135.498 123.478V102.621H145.569Z" fill="black"/>
        <path d="M149.29 102.621H169.374V111.89H161.336V120.674H168.86V129.486H161.336V139.669H170.176V148.938H149.292V102.619L149.29 102.621Z" fill="black"/>
        <path d="M172.523 102.621H181.048C186.73 102.621 190.58 102.84 192.591 103.279C194.603 103.719 196.243 104.838 197.512 106.64C198.781 108.443 199.415 111.317 199.415 115.266C199.415 118.871 198.967 121.293 198.071 122.532C197.175 123.773 195.411 124.517 192.779 124.763C195.163 125.355 196.766 126.146 197.585 127.138C198.406 128.131 198.916 129.042 199.115 129.871C199.315 130.701 199.415 132.986 199.415 136.724V148.94H188.229V133.548C188.229 131.067 188.033 129.533 187.641 128.941C187.249 128.35 186.224 128.054 184.565 128.054V148.938H172.521V102.619L172.523 102.621ZM184.567 110.546V120.845C185.922 120.845 186.872 120.659 187.414 120.288C187.958 119.917 188.229 118.71 188.229 116.67V114.123C188.229 112.655 187.967 111.69 187.442 111.234C186.917 110.775 185.959 110.548 184.567 110.548V110.546Z" fill="black"/>
        <path d="M211.554 136.056V141.685H209.351C208.71 141.685 208.311 141.745 208.153 141.865C207.994 141.985 207.915 142.251 207.915 142.66C207.915 143.123 208.011 143.419 208.204 143.543C208.397 143.668 208.815 143.73 209.456 143.73H215.344C215.959 143.73 216.362 143.668 216.549 143.543C216.737 143.419 216.832 143.138 216.832 142.701C216.832 142.264 216.737 142.008 216.549 141.878C216.36 141.749 215.918 141.685 215.222 141.685H213.629V136.056H214.122C215.434 136.056 216.364 136.215 216.913 136.53C217.462 136.847 217.942 137.546 218.356 138.628C218.769 139.711 218.975 141.046 218.975 142.632C218.975 144.218 218.799 145.642 218.45 146.711C218.1 147.781 217.616 148.49 216.997 148.838C216.377 149.185 215.447 149.359 214.206 149.359H210.502C209.589 149.359 208.903 149.305 208.446 149.198C207.99 149.091 207.55 148.771 207.128 148.242C206.706 147.712 206.373 146.977 206.129 146.036C205.887 145.095 205.765 144.015 205.765 142.793C205.765 141.134 205.953 139.766 206.328 138.688C206.704 137.61 207.171 136.901 207.732 136.562C208.292 136.223 209.164 136.054 210.347 136.054H211.552L211.554 136.056Z" fill="#D3112F"/>
        <path d="M213.456 121.695C214.729 121.695 215.631 121.746 216.161 121.849C216.69 121.952 217.175 122.273 217.614 122.811C218.051 123.352 218.39 124.08 218.624 124.998C218.859 125.915 218.975 126.985 218.975 128.207C218.975 129.428 218.864 130.406 218.643 131.33C218.422 132.251 218.088 132.995 217.644 133.557C217.2 134.118 216.718 134.453 216.195 134.56C215.674 134.667 214.759 134.721 213.456 134.721H211.286C210.013 134.721 209.111 134.669 208.581 134.566C208.052 134.463 207.567 134.142 207.128 133.604C206.691 133.064 206.352 132.335 206.118 131.417C205.883 130.5 205.767 129.43 205.767 128.209C205.767 126.987 205.878 126.009 206.099 125.086C206.322 124.164 206.654 123.42 207.098 122.859C207.542 122.297 208.024 121.963 208.547 121.855C209.07 121.748 209.983 121.695 211.286 121.695H213.456ZM209.291 127.323C208.701 127.323 208.324 127.379 208.159 127.491C207.994 127.602 207.912 127.831 207.912 128.179C207.912 128.472 207.979 128.697 208.112 128.854C208.245 129.01 208.637 129.087 209.291 129.087H215.209C215.944 129.087 216.399 129.036 216.572 128.933C216.744 128.83 216.832 128.592 216.832 128.217C216.832 127.842 216.733 127.589 216.534 127.482C216.334 127.375 215.863 127.321 215.117 127.321H209.293L209.291 127.323Z" fill="#D3112F"/>
        <path d="M206.035 102.744H218.709V107.666L210.152 107.672L218.709 109.631V113.121L210.347 115.187L218.709 115.194V120.115H206.035V112.831C206.798 112.615 207.696 112.392 208.729 112.163L211.953 111.363L206.035 110.068V102.744Z" fill="#D3112F"/>
        </svg>
        
        

    )
}