export const PwaIcon = ({ size: { width = "134", height = "54" } , color= "#3D3D3D" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 134 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1073_2044)">
                <path d="M98.1074 44.3851L101.946 34.6345H113.03L107.769 19.8433L114.348 3.13324L133.191 53.3854H119.295L116.075 44.3851H98.1074Z" fill={color} />
                <path d="M86.2515 50.6371L106.423 0.384949L93.0501 0.386018L79.2515 32.8601L69.4387 0.386018H59.1603L48.6252 32.8601L41.1948 18.0614L34.4707 38.8692L41.2981 50.6371H54.4585L63.9794 21.5149L73.0559 50.6371H86.2515Z" fill="#5A0FC8" />
                <path d="M13.0063 33.3879H21.2432C23.7385 33.3879 25.96 33.1085 27.9087 32.5488L30.0385 25.9575L35.9922 7.53298C35.5394 6.81166 35.0216 6.12781 34.4388 5.48462C31.382 2.08567 26.9093 0.387268 21.0216 0.387268H0.3125V50.6394H13.0063V33.3879ZM23.909 11.9475C25.1034 13.1547 25.7 14.7707 25.7 16.7934C25.7 18.8332 25.1747 20.4503 24.1253 21.6457C22.9735 22.9738 20.8543 23.6384 17.7667 23.6384H13.0063V10.1378H17.8018C20.6796 10.1378 22.7157 10.7404 23.909 11.9475Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_1073_2044">
                    <rect width="133" height="53" fill="white" transform="translate(0.3125 0.385376)" />
                </clipPath>
            </defs>
        </svg>



    )
}