export const NavisLogo = ({ size: { width = '143', height = '96' } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 143 97" fill="none" xmlns="http://www.w3.org/2000/svg" aria-valuetext="Navis">
            <path d="M3.50977 35.2442H10.9554V38.8878C13.5806 36.0363 16.7943 34.6558 20.37 34.6558C24.4662 34.6558 27.5893 36.1268 29.1962 38.5257C30.5088 40.5625 30.6672 42.984 30.6672 46.1071V59.3917H23.2215V47.714C23.2215 42.8935 22.2031 40.6304 17.9711 40.6304C13.6712 40.6304 10.9554 43.1877 10.9554 47.4876V59.3917H3.50977V35.2442Z" fill="#001C38" />
            <path d="M60.9708 55.5443V44.0024C60.9708 40.7209 60.6766 38.5257 58.5719 36.9868C56.3087 35.3121 52.9593 34.4294 48.5689 34.4294C39.5164 34.4294 34.8544 37.6431 34.8544 43.2556H42.6621C42.73 40.4041 44.4953 39.2499 48.1389 39.2499C51.873 39.2499 53.6835 40.1325 53.6835 41.943C53.6835 44.5682 50.402 44.4324 44.1105 44.9303C36.4386 45.5187 32.7271 47.2613 32.7271 52.2402C32.7271 57.0606 36.597 60.2743 43.4542 60.2743C47.6184 60.2743 50.832 59.2559 53.5251 56.7664L53.8872 59.3916H62.125V58.8711C61.1744 58.2375 60.9708 57.1512 60.9708 55.5443ZM53.6609 47.8045C54.1814 52.0365 50.6736 55.1822 45.1289 55.1822C42.6395 55.1822 41.1232 53.9375 41.1232 52.127C41.1232 49.8639 43.3184 49.5697 47.5505 49.0718C50.0852 48.755 52.0541 48.6192 53.6609 47.8045Z" fill="#001C38" />
            <path d="M61.3999 35.2443H69.5698L76.1554 51.0182L83.3748 35.2443H91.4768L79.7312 59.4144H72.6476L61.3999 35.2443Z" fill="#001C38" />
            <path d="M101.051 35.2443H93.605V59.4144H101.051V35.2443Z" fill="#76BC21" />
            <path d="M112.796 51.4481C113.022 53.9375 115.127 54.888 119.585 54.888C122.867 54.888 124.768 53.8017 124.768 52.4891C124.768 51.0181 123.455 50.7465 120.604 50.5202L113.814 49.9318C108.768 49.5018 105.712 46.5824 105.712 42.554C105.712 37.3036 110.239 34.3842 118.861 34.3842C127.257 34.3842 131.919 37.5299 131.784 42.4182H124.338C123.976 40.2909 122.075 39.2725 118.499 39.2725C115.285 39.2725 113.679 40.1551 113.679 41.4677C113.679 42.8482 115.512 43.4366 119.291 43.7308L124.542 44.0929C129.792 44.5229 132.576 47.0802 132.576 51.3123C132.576 57.0153 127.982 60.1384 119.065 60.1384C110.08 60.1384 105.35 57.1511 105.056 51.3802H112.796V51.4481Z" fill="#001C38" />
            <path d="M85.5031 19.1082C81.1126 19.1082 76.7448 20.7829 74.2554 25.1054H78.4195C80.6826 22.186 83.8963 21.5976 86.6799 21.5976C90.1877 21.5976 94.3519 23.1365 97.3392 30.7858C97.3165 24.2228 92.4961 19.1082 85.5031 19.1082Z" fill="#001C38" />
            <path d="M80.0257 26.5538H72.5801V32.8227H80.0257V26.5538Z" fill="#76BC21" />
            <path d="M135.563 37.0774H135.925C136.219 37.0774 136.581 37.0774 136.581 36.6474C136.581 36.2853 136.219 36.2853 135.925 36.2853H135.563V37.0774ZM134.544 35.6969H136.219C136.807 35.6969 137.532 35.6969 137.532 36.5795C137.532 36.9416 137.396 37.3037 136.649 37.3716C137.305 37.3716 137.373 37.6658 137.441 38.0279C137.577 38.7521 137.577 38.7521 137.667 38.9784H136.581C136.513 38.7521 136.513 38.5484 136.445 38.2542C136.377 37.8921 136.377 37.7337 135.925 37.7337H135.495V38.9784H134.476V35.6969H134.544ZM133.39 37.3037C133.39 38.6163 134.567 39.7026 136.015 39.7026C137.464 39.7026 138.708 38.6163 138.708 37.3037C138.708 35.9232 137.464 34.9048 136.015 34.9048C134.635 34.8822 133.39 35.9006 133.39 37.3037ZM139.297 37.3037C139.297 38.9105 137.826 40.2231 136.083 40.2231C134.25 40.2231 132.87 38.9105 132.87 37.3037C132.87 35.629 134.341 34.3843 136.083 34.3843C137.826 34.3843 139.297 35.6064 139.297 37.3037Z" fill="#001C38" />
        </svg>

    )
}