import { useIntl } from "react-intl"
import { AppapachoLogo, Contact, DamsuLogo, Footer, Head, TopBar, UnaxUnaLogo } from "../../components"
import { Introduction, WhyChoose } from "../../modules"
import styles from './technologies.module.scss';
import parse from "html-react-parser";
import { HtmlIcon } from "../../assets/icons/technologies";
import { ScaleIcon, WorldIcon, CheckIcon, ArrowIcon } from "../../assets/icons";
import { KeyFeatures } from "../../modules/Technologies/KeyFeatures/KeyFeatures";
import { DevelopmentProcess } from "../../modules/Technologies/DevelopmentProcess/DevelopmentProcess";
import { Expertise } from "../../modules/Technologies/Expertise/Expertise";
import { TechnologiesClient } from "../../modules/Technologies/Clients/Clients";
import { Suspense, useEffect } from "react";
import ReactGA from 'react-ga';
import { CircularProgress } from "@mui/material";

export const HtmlPage = () => {
    const { formatMessage } = useIntl();
    const renderLoader = () => <div className="spinner"><CircularProgress color="inherit" /></div>; 

    useEffect(() => {
        ReactGA.pageview('/technologies/html');
        window.scrollTo(0, 0);    
      }, [])
    return (
        <>
            {/* Encabezados y meta descriptions */}
            <Head
                title="Html | Wakapi"
                description="Build structured, accessible web content with HTML. Learn how it’s essential for creating engaging, user-friendly websites. "
                path="/technologies/html"
            />

            {/* Barra de navegación */}
            <TopBar />
            <main className={styles.container}>
                <Introduction
                    title={parse(formatMessage({ id: "technologies.Html-title" }))}
                    description={parse(formatMessage({ id: "technologies.Html-description" }))}
                    icon={<div className={styles.icon__html}> <HtmlIcon size={{ width: "149", height: "148" }} /></div>}
                />
                {/* WhyChoose modulo */}
                <WhyChoose
                    list={
                        [
                            {
                                icon: <WorldIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.Html-why-choose-1-title" }),
                                description: formatMessage({ id: "technologies.Html-why-choose-1-text" }),
                            },
                            {
                                icon: <CheckIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.Html-why-choose-2-title" }),
                                description: formatMessage({ id: "technologies.Html-why-choose-2-text" }),
                            },
                            {
                                icon: <ArrowIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.Html-why-choose-3-title" }),
                                description: formatMessage({ id: "technologies.Html-why-choose-3-text" }),
                            },
                            {
                                icon: <ScaleIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.Html-why-choose-4-title" }),
                                description: formatMessage({ id: "technologies.Html-why-choose-4-text" }),
                            },
                        ]
                    }
                    title={parse(formatMessage({ id: "technologies.Html-why-choose-title" }))}
                />
                <div className={styles.keyFeatures__background}>
                    <div className={styles.keyFeatures__container}>
                        <KeyFeatures
                            list={
                                [
                                    {
                                        title: formatMessage({ id: "technologies.Html-key-aspects-1-title" }),
                                        text: formatMessage({ id: "technologies.Html-key-aspects-1-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Html-key-aspects-2-title" }),
                                        text: formatMessage({ id: "technologies.Html-key-aspects-2-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Html-key-aspects-3-title" }),
                                        text: formatMessage({ id: "technologies.Html-key-aspects-3-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Html-key-aspects-4-title" }),
                                        text: formatMessage({ id: "technologies.Html-key-aspects-4-text" }),
                                    },
                                ]
                            }
                        />
                    </div>
                    <div className={styles.expertise__container}>
                        <Expertise
                            icon={<HtmlIcon size={{ width: "125", height: "125" }} />}
                            title={formatMessage({ id: "technologies.Html-expertise-title" })}
                            text={formatMessage({ id: "technologies.Html-expertise-text" })}
                            list={
                                [
                                    {
                                        title: formatMessage({ id: "technologies.Html-expertise-1-title" }),
                                        text: formatMessage({ id: "technologies.Html-expertise-1-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Html-expertise-2-title" }),
                                        text: formatMessage({ id: "technologies.Html-expertise-2-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Html-expertise-3-title" }),
                                        text: formatMessage({ id: "technologies.Html-expertise-3-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Html-expertise-4-title" }),
                                        text: formatMessage({ id: "technologies.Html-expertise-4-text" }),
                                    },
                                ]
                            }
                        />
                    </div>
                </div>
                <div className={styles.process__background}>
                    <div className={styles.process__container}>
                        <DevelopmentProcess
                            title={formatMessage({ id: "technologies.development-process" })}
                            list={
                                [
                                    {
                                        title: formatMessage({ id: "technologies.Html-development-process-1-title" }),
                                        text: formatMessage({ id: "technologies.Html-development-process-1-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Html-development-process-2-title" }),
                                        text: formatMessage({ id: "technologies.Html-development-process-2-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Html-development-process-3-title" }),
                                        text: formatMessage({ id: "technologies.Html-development-process-3-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Html-development-process-4-title" }),
                                        text: formatMessage({ id: "technologies.Html-development-process-4-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Html-development-process-5-title" }),
                                        text: formatMessage({ id: "technologies.Html-development-process-5-text" }),
                                    },
                                ]
                            }
                        />
                    </div>
                </div>
                <TechnologiesClient
                    title={formatMessage({ id: "technologies.Html-satisfied" })}
                    list={[
                        <UnaxUnaLogo
                            size={
                                {
                                    width: '98',
                                    height: '78'
                                }}
                        />,
                        <AppapachoLogo
                            size={
                                {
                                    width: '203',
                                    height: '78'
                                }}
                        />,
                        <DamsuLogo
                            size={{
                                width: '137',
                                height: '78'
                            }}
                        />,
                    ]}
                />
                <section className="contact__container">
                    <Suspense fallback={renderLoader()}>
                        <Contact />
                    </Suspense>
                </section>
                <Footer />
            </main>
        </>
    )
}