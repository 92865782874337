import styles from './dollar-general.module.scss';
import { FormattedMessage } from "react-intl";

//Imagenes
import img1 from './../../../../assets/images/clients/slider/dollar-general/dollar-general-improve-service-level-agreements-performance-and-efficiency-1.webp';
import img2 from './../../../../assets/images/clients/slider/dollar-general/dollar-general-improve-service-level-agreements-performance-and-efficiency-2.webp';
import img3 from './../../../../assets/images/clients/slider/dollar-general/dollar-general-improve-service-level-agreements-performance-and-efficiency-3.webp'
import img4 from './../../../../assets/images/clients/slider/dollar-general/dollar-general-improve-service-level-agreements-performance-and-efficiency-4.webp';
import img5 from './../../../../assets/images/clients/slider/dollar-general/dollar-general-improve-service-level-agreements-performance-and-efficiency-5.webp';
import { Inforgraphic } from '../Infographic';
import { PreloadImage } from '../../../../components';


export const DollarGeneralInfographic = () => {
    return (
        <Inforgraphic >
            <PreloadImage imagePath={img1}/>
            <PreloadImage imagePath={img2}/>
            <PreloadImage imagePath={img3}/>
            <div className={styles.block1}>

                <div className={styles.title__container}>
                    <FormattedMessage id="clients.infographic-dollar-general-title" />
                    <svg
                        width="211"
                        height="73"
                        viewBox="0 0 211 73"
                        fill="none"
                        className={styles.arrowSvg__desktop}
                    >
                        <path
                            d="M0.818359 37.345H209.818M173.447 0.539062L209.818 36.5391L173.447 72.5391"
                            stroke="black"
                        />
                    </svg>
                    <svg
                        width="49"
                        height="141"
                        viewBox="0 0 49 141"
                        fill="none"
                        className={styles.arrowSvg__mobile}
                    >
                        <path
                            d="M24.0478 0.539061L24.0477 139.539M48.5264 115.349L24.5838 139.539L0.641194 115.349"
                            stroke="black"
                        />
                    </svg>
                </div>
                <div className={styles.block1__inner}>
                    <div className={styles.text}>
                        <FormattedMessage id="clients.infographic-dollar-general-1" />
                    </div>
                    <img src={img1} alt='Dollar General-enhacing database performance and maintainability' width={338} height={298} />
                </div>

            </div>
            <div className={styles.block2}>
                <img src={img2} alt='Dollar General-enhacing database performance and maintainability' width={488} height={325} />
                <div className={styles.text}>
                    <FormattedMessage id="clients.infographic-dollar-general-2" />
                </div>
            </div>
            <div className={styles.block3}>
                <div className={styles.text}>
                    <FormattedMessage id="clients.infographic-dollar-general-3" />
                </div>
                <img src={img3} alt='Dollar General-enhacing database performance and maintainability' width={471} height={335} />
                <div className={styles.text}>
                    <FormattedMessage id="clients.infographic-dollar-general-4" />
                </div>
            </div>
            <div className={styles.block4}>
                <div className={styles.block4__inner}>
                    <div className={styles.text}>
                        <FormattedMessage id="clients.infographic-dollar-general-5" />
                    </div>
                    <img src={img4} alt='Dollar General-enhacing database performance and maintainability' width={380} height={223} />
                    <div className={styles.text}>
                        <FormattedMessage id="clients.infographic-dollar-general-6" />
                    </div>
                </div>
            </div>
            <div className={styles.block5}>
                <div className={styles.text}>
                    <FormattedMessage id="clients.infographic-dollar-general-7" />
                </div>
                <img src={img5} alt='Dollar General-enhacing database performance and maintainability' width={394} height={310} />
            </div>
        </Inforgraphic>
    );
}