import React, { useState } from "react";
import { useEffect } from "react";
import ReactCardFlip from "react-card-flip";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./team.module.scss";
import parse from "html-react-parser";

export const TeamCardFlipper = () => {
  const { formatMessage } = useIntl();

  const [isFlipped1, setIsFlipped1] = useState(false);
  const [isFlipped2, setIsFlipped2] = useState(false);
  const [isFlipped3, setIsFlipped3] = useState(false);
  const [isFlipped4, setIsFlipped4] = useState(false);

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  const [width, setWidth] = useState("25%");
  const [height, setHeight] = useState("470px");
  useEffect(() => {
    if (dimensions.width > 1280) {
      setWidth("25%");
      setHeight("470px");
    }
    if (dimensions.width > 769) {
      setHeight("470px");
    }
    if (dimensions.width > 640) {
      setWidth("50%");
      setHeight("620px");
    } else {
      setHeight("620px");
      setWidth("100%");
    }
  }, [dimensions]);




  const flip = (num) => {
    switch (num) {
      case 1: {
        if (isFlipped1) {
          setIsFlipped1(false);
        } else {
          setIsFlipped1(true);
          setIsFlipped2(false);
          setIsFlipped3(false);
          setIsFlipped4(false);
        }

        break;
      }
      case 2: {
        if (isFlipped2) {
          setIsFlipped2(false);
        } else {
          setIsFlipped1(false);
          setIsFlipped2(true);
          setIsFlipped3(false);
          setIsFlipped4(false);
        }
        break;
      }
      case 3: {
        if (isFlipped3) {
          setIsFlipped3(false);

        } else {
          setIsFlipped1(false);
          setIsFlipped2(false);
          setIsFlipped3(true);
          setIsFlipped4(false);
        }
        break;
      }
      case 4: {
        if (isFlipped4) {
          setIsFlipped4(false);
        } else {

          setIsFlipped1(false);
          setIsFlipped2(false);
          setIsFlipped3(false);
          setIsFlipped4(true);
        }
        break;
      }
      default:
        break;
    }
  };
  return (
    <div className={styles.cards__container}>
      <ReactCardFlip
        isFlipped={isFlipped1}
        cardStyles={{ front: { width: "100%" }, back: { width: "100%" } }}
        containerStyle={{ width, height }}
      >
        <div
          className={styles.card__buildYourCareer}
          onMouseEnter={() => flip(1)}
          onClick={() => flip(1)}
        >
          <div className={styles.card__title}>
            <FormattedMessage id="team.card.title-1" />
          </div>
          <div className={styles.touch} />
          <div className={styles.touchAnimation} />
        </div>
        <div className={styles.card__buildYourCareer}

          onMouseLeave={() => flip(1)}
          onClick={() => flip(1)}>
          <div className={styles['card__title--open']}>
            <FormattedMessage id="team.card.title-1" />
          </div>
          <div className={styles.card__text}>
            {parse(formatMessage({ id: "team.card.text-1" }))}
          </div>
        </div>
      </ReactCardFlip>

      <ReactCardFlip
        isFlipped={isFlipped2}
        cardStyles={{ front: { width: "100%" }, back: { width: "100%" } }}
        containerStyle={{ width, height }}
      >
        <div
          className={styles.card__loveWhatYouDo}
          onMouseEnter={() => flip(2)}
          onClick={() => flip(2)}
        >
          <div className={styles.card__title}>
            <FormattedMessage id="team.card.title-2" />
          </div>
          <div className={styles.touch} />
          <div className={styles.touchAnimation} />
        </div>
        <div className={styles.card__loveWhatYouDo}
          onMouseLeave={() => flip(2)}
          onClick={() => flip(2)}>
          <div className={styles['card__title--open']}>
            <FormattedMessage id="team.card.title-2" />
          </div>
          <div className={styles.card__text}>
            {parse(formatMessage({ id: "team.card.text-2" }))}
          </div>
        </div>
      </ReactCardFlip>

      <ReactCardFlip
        isFlipped={isFlipped3}
        cardStyles={{ front: { width: "100%" }, back: { width: "100%" } }}
        containerStyle={{ width, height }}
      >
        <div
          className={styles.card__driveYourFuture}
          onMouseEnter={() => flip(3)}
          onClick={() => flip(3)}
        >
          <div className={styles.card__title}>
            <FormattedMessage id="team.card.title-3" />
          </div>
          <div className={styles.touch} />
          <div className={styles.touchAnimation} />
        </div>
        <div className={styles.card__driveYourFuture}
          onMouseLeave={() => flip(3)}
          onClick={() => flip(3)}>
          <div className={styles.card__text}>
            <div className={styles['card__title--open']}>
              <FormattedMessage id="team.card.title-3" />
            </div>
            {parse(formatMessage({ id: "team.card.text-3" }))}
          </div>
        </div>
      </ReactCardFlip>

      <ReactCardFlip
        isFlipped={isFlipped4}
        cardStyles={{ front: { width: "100%" }, back: { width: "100%" } }}
        containerStyle={{ width, height }}
      >
        <div
          className={styles.card__makeADifference}
          onMouseEnter={() => flip(4)}
          onClick={() => flip(4)}
        >
          <div className={styles.card__title}>
            <FormattedMessage id="team.card.title-4" />
          </div>
          <div className={styles.touch} />
          <div className={styles.touchAnimation} />
        </div>
        <div className={styles.card__makeADifference}

          onMouseLeave={() => flip(4)}
          onClick={() => flip(4)}>
          <div className={styles['card__title--open']}>
            <FormattedMessage id="team.card.title-4" />
          </div>
          <div className={styles.card__text}>
            {parse(formatMessage({ id: "team.card.text-4" }))}
          </div>
        </div>
      </ReactCardFlip>
    </div>
  );
};
export default TeamCardFlipper;