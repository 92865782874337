import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import styles from './topBar.module.scss';

export const ClientsButton = ({ path, lang }) => {
    return (
        <Link to={`/${lang}/clients`} style={{ textDecoration: "none" }} className={
            path.includes("/clients")
              ? styles["menu__item--selected"]
              : styles.menu__item
          }>
            <FormattedMessage id="topBar.clients" />
          </Link>
    )
}