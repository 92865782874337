import { useIntl } from "react-intl"
import { Contact, Footer, Head, HowlyLogo, TangoeLogo, TopBar, ZippyYumLogo } from "../../components"
import { Introduction, WhyChoose } from "../../modules"
import styles from './technologies.module.scss';
import parse from "html-react-parser";
import { SqlIcon } from "../../assets/icons/technologies";
import { ScaleIcon, CodeIcon, WorldIcon, BookIcon } from "../../assets/icons";
import { KeyFeatures } from "../../modules/Technologies/KeyFeatures/KeyFeatures";
import { DevelopmentProcess } from "../../modules/Technologies/DevelopmentProcess/DevelopmentProcess";
import { Expertise } from "../../modules/Technologies/Expertise/Expertise";
import { TechnologiesClient } from "../../modules/Technologies/Clients/Clients";
import { Suspense, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import ReactGA from 'react-ga';


export const SqlPage = () => {
    const { formatMessage } = useIntl();
    const renderLoader = () => <div className="spinner"><CircularProgress color="inherit" /></div>;

    useEffect(() => {
        ReactGA.pageview('/technologies/sql');
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            {/* Encabezados y meta descriptions */}
            <Head
                title="SQL | Wakapi"
                description="Optimize data management with SQL. Learn how Structured Query Language streamlines data retrieval and analysis. "
                path="/technologies/sql"
            />

            {/* Barra de navegación */}
            <TopBar />
            <main className={styles.container}>
                <Introduction
                    title={parse(formatMessage({ id: "technologies.Sql-title" }))}
                    description={parse(formatMessage({ id: "technologies.Sql-description" }))}
                    icon={<div className={styles.icon__sql}><SqlIcon size={{ width: "66", height: "88" }} /></div>}
                />
                {/* WhyChoose modulo */}
                <WhyChoose
                    list={
                        [
                            {
                                icon: <CodeIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.Sql-why-choose-1-title" }),
                                description: formatMessage({ id: "technologies.Sql-why-choose-1-text" }),
                            },
                            {
                                icon: <WorldIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.Sql-why-choose-2-title" }),
                                description: formatMessage({ id: "technologies.Sql-why-choose-2-text" }),
                            },
                            {
                                icon: <ScaleIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.Sql-why-choose-3-title" }),
                                description: formatMessage({ id: "technologies.Sql-why-choose-3-text" }),
                            },
                            {
                                icon: <BookIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.Sql-why-choose-4-title" }),
                                description: formatMessage({ id: "technologies.Sql-why-choose-4-text" }),
                            },
                        ]
                    }
                    title={parse(formatMessage({ id: "technologies.Sql-why-choose-title" }))}
                />
                <div className={styles.keyFeatures__background}>
                    <div className={styles.keyFeatures__container}>
                        <KeyFeatures
                            list={
                                [
                                    {
                                        title: formatMessage({ id: "technologies.Sql-key-aspects-1-title" }),
                                        text: formatMessage({ id: "technologies.Sql-key-aspects-1-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Sql-key-aspects-2-title" }),
                                        text: formatMessage({ id: "technologies.Sql-key-aspects-2-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Sql-key-aspects-3-title" }),
                                        text: formatMessage({ id: "technologies.Sql-key-aspects-3-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Sql-key-aspects-4-title" }),
                                        text: formatMessage({ id: "technologies.Sql-key-aspects-4-text" }),
                                    },
                                ]
                            }
                        />
                    </div>
                    <div className={styles.expertise__container}>
                        <Expertise
                            icon={<SqlIcon size={{ width: "125", height: "125" }} />}
                            title={formatMessage({ id: "technologies.Sql-expertise-title" })}
                            text={formatMessage({ id: "technologies.Sql-expertise-text" })}
                            list={
                                [
                                    {
                                        title: formatMessage({ id: "technologies.Sql-expertise-1-title" }),
                                        text: formatMessage({ id: "technologies.Sql-expertise-1-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Sql-expertise-2-title" }),
                                        text: formatMessage({ id: "technologies.Sql-expertise-2-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Sql-expertise-3-title" }),
                                        text: formatMessage({ id: "technologies.Sql-expertise-3-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Sql-expertise-4-title" }),
                                        text: formatMessage({ id: "technologies.Sql-expertise-4-text" }),
                                    },
                                ]
                            }
                        />
                    </div>
                </div>
                <div className={styles.process__background}>
                    <div className={styles.process__container}>
                        <DevelopmentProcess
                            title={formatMessage({ id: "technologies.development-process" })}
                            list={
                                [
                                    {
                                        title: formatMessage({ id: "technologies.Sql-development-process-1-title" }),
                                        text: formatMessage({ id: "technologies.Sql-development-process-1-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Sql-development-process-2-title" }),
                                        text: formatMessage({ id: "technologies.Sql-development-process-2-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Sql-development-process-3-title" }),
                                        text: formatMessage({ id: "technologies.Sql-development-process-3-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Sql-development-process-4-title" }),
                                        text: formatMessage({ id: "technologies.Sql-development-process-4-text" }),
                                    },
                                ]
                            }
                        />
                    </div>
                </div>
                <TechnologiesClient
                    title={formatMessage({ id: "technologies.Sql-satisfied" })}
                    list={[
                        <HowlyLogo
                            size={{
                                width: '120',
                                height: '78'
                            }}
                        />,
                        <TangoeLogo
                            size={{
                                width: '141',
                                height: '78'
                            }}
                        />,
                        <ZippyYumLogo
                        size={{
                            width: '141',
                            height: '78'
                        }}
                        />
                    ]}
                />
                 <section className="contact__container">
                    <Suspense fallback={renderLoader()}>
                        <Contact />
                    </Suspense>
                </section>
                <Footer />
            </main>
        </>
    )
}