export const MachineLearningIcon = ({ size: { width = "123", height = "110" } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 123 110" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M29.715 40.5417C29.0436 45.6204 24.6969 49.5397 19.4347 49.5397C13.7074 49.5397 9.06445 44.8968 9.06445 39.1695C9.06445 33.4421 13.7074 28.7992 19.4347 28.7992C24.7898 28.7992 29.1968 32.8581 29.7471 38.0673H68.0119C68.5403 34.741 71.4214 32.1984 74.8963 32.1984C78.7463 32.1984 81.8674 35.3195 81.8674 39.1695C81.8674 43.0195 78.7463 46.1405 74.8963 46.1405C71.516 46.1405 68.6977 43.7346 68.0603 40.5417H29.715Z" fill="#358AF5" />
            <path fillRule="evenodd" clipRule="evenodd" d="M54.4498 14.528C53.7787 21.042 48.2739 26.1226 41.5825 26.1226C34.4381 26.1226 28.6465 20.3309 28.6465 13.1865C28.6465 6.04215 34.4381 0.250488 41.5825 0.250488C48.3452 0.250488 53.8958 5.43977 54.4696 12.0536H62.5792C63.1436 6.85923 67.5438 2.81628 72.8883 2.81628C78.2328 2.81628 82.633 6.85923 83.1974 12.0536H89.1409C89.6821 8.7424 92.5561 6.21545 96.0204 6.21545C99.8704 6.21545 102.991 9.33649 102.991 13.1865C102.991 17.0365 99.8704 20.1575 96.0204 20.1575C92.6292 20.1575 89.8037 17.7361 89.1783 14.528H83.1726C82.5148 19.6217 78.1611 23.5568 72.8883 23.5568C67.6156 23.5568 63.2619 19.6217 62.604 14.528H54.4498Z" fill="#358AF5" />
            <path fillRule="evenodd" clipRule="evenodd" d="M91.9609 71.7414C99.1053 71.7414 104.897 65.9497 104.897 58.8054C104.897 51.661 99.1053 45.8693 91.9609 45.8693C85.1954 45.8693 79.643 51.063 79.0731 57.6808H66.9319C66.3988 54.6019 63.7144 52.2603 60.4831 52.2603C57.2518 52.2603 54.5674 54.6019 54.0342 57.6808H47.7936C47.233 52.4824 42.8309 48.4351 37.4836 48.4351C31.7562 48.4351 27.1133 53.078 27.1133 58.8053C27.1133 64.5327 31.7562 69.1756 37.4836 69.1756C42.7534 69.1756 47.1052 65.2448 47.7668 60.1552H54.0773C54.6995 63.1223 57.3311 65.3504 60.4831 65.3504C63.6351 65.3504 66.2667 63.1223 66.8888 60.1552H79.0945C79.7695 66.6652 85.2724 71.7414 91.9609 71.7414Z" fill="#358AF5" />
            <path d="M113.962 85.1205H120.05V87.3966H113.962V85.1205ZM114.179 90.3229H121.061V92.6712H111.271V80.0266H120.826V82.3748H114.179V90.3229Z" fill="#358AF5" />
            <path d="M91.4434 92.6712V80.0266H93.8639L101.324 89.1307H100.15V80.0266H103.04V92.6712H100.638L93.1594 83.5671H94.3336V92.6712H91.4434Z" fill="#358AF5" />
            <path d="M80.2773 92.6712V80.0266H83.2037V92.6712H80.2773Z" fill="#358AF5" />
            <path d="M69.1208 80.0266H72.0472V92.6712H69.1208V80.0266ZM63.3765 92.6712H60.4502V80.0266H63.3765V92.6712ZM69.3376 87.4869H63.1598V85.0122H69.3376V87.4869Z" fill="#358AF5" />
            <path d="M48.0151 92.888C47.0397 92.888 46.1305 92.7314 45.2875 92.4183C44.4566 92.0931 43.734 91.6355 43.1198 91.0454C42.5057 90.4554 42.024 89.7629 41.6747 88.9681C41.3375 88.1733 41.1689 87.3002 41.1689 86.3489C41.1689 85.3975 41.3375 84.5244 41.6747 83.7296C42.024 82.9348 42.5057 82.2423 43.1198 81.6523C43.746 81.0622 44.4746 80.6106 45.3056 80.2975C46.1365 79.9723 47.0457 79.8098 48.0332 79.8098C49.1291 79.8098 50.1166 80.0024 50.9957 80.3878C51.8868 80.7611 52.6334 81.3151 53.2356 82.0497L51.3569 83.7838C50.9234 83.29 50.4417 82.9227 49.9118 82.6819C49.382 82.429 48.8039 82.3026 48.1777 82.3026C47.5876 82.3026 47.0457 82.3989 46.552 82.5916C46.0582 82.7843 45.6307 83.0612 45.2694 83.4225C44.9082 83.7838 44.6252 84.2113 44.4204 84.705C44.2277 85.1988 44.1314 85.7467 44.1314 86.3489C44.1314 86.951 44.2277 87.4989 44.4204 87.9927C44.6252 88.4864 44.9082 88.9139 45.2694 89.2752C45.6307 89.6365 46.0582 89.9134 46.552 90.1061C47.0457 90.2988 47.5876 90.3951 48.1777 90.3951C48.8039 90.3951 49.382 90.2747 49.9118 90.0339C50.4417 89.781 50.9234 89.4016 51.3569 88.8959L53.2356 90.63C52.6334 91.3646 51.8868 91.9246 50.9957 92.3099C50.1166 92.6953 49.123 92.888 48.0151 92.888Z" fill="#358AF5" />
            <path d="M21.4102 92.6712L27.0461 80.0266H29.9363L35.5903 92.6712H32.5194L27.8951 81.5078H29.0512L24.4088 92.6712H21.4102ZM24.2281 89.9616L25.0049 87.7398H31.5078L32.3026 89.9616H24.2281Z" fill="#358AF5" />
            <path d="M0.581055 92.6712V80.0266H3.00161L8.38462 88.9501H7.10209L12.3948 80.0266H14.7973L14.8334 92.6712H12.0877L12.0696 84.2354H12.5754L8.3485 91.3345H7.02984L2.71258 84.2354H3.32675V92.6712H0.581055Z" fill="#358AF5" />
            <path d="M117.121 109.52C116.145 109.52 115.248 109.364 114.429 109.051C113.61 108.726 112.9 108.274 112.298 107.696C111.695 107.106 111.226 106.419 110.889 105.637C110.551 104.854 110.383 103.993 110.383 103.054C110.383 102.114 110.551 101.253 110.889 100.47C111.226 99.6876 111.695 99.0072 112.298 98.4292C112.912 97.8391 113.628 97.3875 114.447 97.0744C115.266 96.7492 116.169 96.5867 117.157 96.5867C118.156 96.5867 119.072 96.7492 119.902 97.0744C120.733 97.3995 121.438 97.8873 122.016 98.5376L120.896 99.6575C120.378 99.1517 119.812 98.7844 119.198 98.5556C118.596 98.3148 117.94 98.1943 117.229 98.1943C116.506 98.1943 115.832 98.3148 115.206 98.5556C114.592 98.7965 114.056 99.1337 113.598 99.5672C113.153 100.001 112.803 100.519 112.55 101.121C112.31 101.711 112.189 102.355 112.189 103.054C112.189 103.74 112.31 104.384 112.55 104.986C112.803 105.576 113.153 106.094 113.598 106.54C114.056 106.973 114.592 107.311 115.206 107.551C115.82 107.792 116.488 107.913 117.211 107.913C117.885 107.913 118.53 107.81 119.144 107.606C119.77 107.389 120.348 107.034 120.878 106.54L121.908 107.913C121.281 108.443 120.547 108.846 119.704 109.123C118.873 109.388 118.012 109.52 117.121 109.52ZM120.173 107.678V102.981H121.908V107.913L120.173 107.678Z" fill="#358AF5" />
            <path d="M92.96 109.376V96.7312H94.4412L102.805 107.118H102.028V96.7312H103.834V109.376H102.353L93.9896 98.9892H94.7663V109.376H92.96Z" fill="#358AF5" />
            <path d="M83.5742 109.376V96.7312H85.3806V109.376H83.5742Z" fill="#358AF5" />
            <path d="M65.1221 109.376V96.7312H66.6033L74.9669 107.118H74.1901V96.7312H75.9965V109.376H74.5153L66.1517 98.9892H66.9285V109.376H65.1221Z" fill="#358AF5" />
            <path d="M48.2041 109.376V96.7312H53.1355C54.2434 96.7312 55.1888 96.9058 55.9715 97.2551C56.7543 97.6043 57.3564 98.1101 57.7779 98.7724C58.1994 99.4348 58.4102 100.224 58.4102 101.139C58.4102 102.054 58.1994 102.843 57.7779 103.505C57.3564 104.155 56.7543 104.655 55.9715 105.004C55.1888 105.354 54.2434 105.528 53.1355 105.528H49.1976L50.0105 104.697V109.376H48.2041ZM56.658 109.376L53.4426 104.788H55.3754L58.6269 109.376H56.658ZM50.0105 104.878L49.1976 103.993H53.0813C54.2374 103.993 55.1105 103.746 55.7006 103.252C56.3027 102.746 56.6038 102.042 56.6038 101.139C56.6038 100.236 56.3027 99.5371 55.7006 99.0434C55.1105 98.5496 54.2374 98.3028 53.0813 98.3028H49.1976L50.0105 97.3996V104.878Z" fill="#358AF5" />
            <path d="M29.2656 109.376L34.9919 96.7312H36.7802L42.5245 109.376H40.6278L35.5157 97.7428H36.2383L31.1262 109.376H29.2656ZM31.7042 106.215L32.192 104.77H39.3091L39.833 106.215H31.7042Z" fill="#358AF5" />
            <path d="M16.929 102.186H23.4319V103.722H16.929V102.186ZM17.0915 107.804H24.4616V109.376H15.2852V96.7312H24.2087V98.3028H17.0915V107.804Z" fill="#358AF5" />
            <path d="M0.766602 109.376V96.7312H2.57298V107.804H9.41917V109.376H0.766602Z" fill="#358AF5" />
        </svg>

    )
}