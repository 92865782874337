import React, { useEffect, lazy, Suspense } from "react";

import { Head, TopBar, Footer, Button } from "../../components";
import styles from "./services.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ReactGA from 'react-ga';
import { CircularProgress } from "@mui/material";
import titleImage from './../../assets/images/services/wakapi-what-we-do-services.webp';
import { ServicesList, ServicesDescription } from "../../modules";


const Contact = lazy(() => import('../../components/Contact/Contact'));
const ServicesTechnology = lazy(() => import('../../modules/Services/ServicesTechnology'));

const Services = () => {
  const renderLoader = () => <div className="spinner"><CircularProgress color="inherit" /></div>;
  const { item } = useParams();
  const { formatMessage } = useIntl();

  const [serviceSelected, setServiceSelected] = useState(undefined);

  useEffect(() => {
    ReactGA.pageview('/services');
  }, []);

  useEffect(() => {
    if (item) {
      switch (item) {
        case 'software-design-development':
          setServiceSelected({
            title: "Software Design & Development Services | Wakapi",
            description: "We are experts in Software Development & Design services that cover the entire life cycle of your projects.",
            path: "/services/software-design-development"
          });
          break;
        case 'staff-augmentation-recruitment':
          setServiceSelected({
            title: "Staff Augmentation & Recruitment | Wakapi",
            description: "We will find the right experts, helping you maximize your budget and solve your needs at overlapping business hours.",
            path: "/services/staff-augmentation-recruitment"
          });
          break;
        case 'product-design-ux':
          setServiceSelected({
            title: "Product Design & UX Services | Wakapi",
            description: "Enhance your digital presence with our Product Design & UX services. We create customized and unique software solutions.",
            path: "/services/product-design-ux"
          });
          break;
        case 'app-development':
          setServiceSelected({
            title: "App Software Development Services| Wakapi",
            description: "We help you define a well-scoped App with perfect features, ensuring quality, design, reliability, and usability.",
            path: "/services/app-development"
          });
          break;
        case 'consultancy-innovation':
          setServiceSelected({
            title: "Consultancy & Innovation Services | Wakapi",
            description: "Wakapi's consultancy services can help you apply DevOps, Digital Transformation, AI, BlockChain, and more!",
             path: "/services/consultancy-innovation"
          });
          break;
        case 'qa-testing':
          setServiceSelected({
            title: "QA Services & Testing | Wakapi",
            description: "Improve the quality of your software with our comprehensive QA services. Our tests ensure maximum efficiency.",
            path: "/services/qa-testing"
          });
          break;
        default:
          break;
      }
     
    }
  }, [item])



  return (
    <main>
      <Head
        title={serviceSelected ? serviceSelected.title : "Our Services | Wakapi"}
        description={serviceSelected ? serviceSelected.description : "Get to know our Software Development services. We offer reliable, customized, and ready-to-use solutions."}
        path={serviceSelected ? serviceSelected.path : "/services"}
        haveCarousel={false}
      />
      <TopBar />
      <section className={styles.gradient__background}>
        <div className={styles.container}>
          <div className={styles.title__container}>
            <h1>
              <FormattedMessage id="services-section" />
            </h1>
            <h2>
              <FormattedMessage id="services-title" />
            </h2>
            <p>
              <FormattedMessage id="services-subtitle" />
            </p>
            <a href={"mailto:hello@wakapi.com"}>
              <Button secondary>
                <FormattedMessage id="services-button" />
              </Button>
            </a>
          </div>
          <img src={titleImage} alt={formatMessage({ id: "services-title" })} width={510} height={355} className={styles.principal__image} />
        </div>
        <ServicesList />
      </section>
      <ServicesDescription />
      {!serviceSelected && <div style={{height:2}}></div>}
      <section>
        <Suspense fallback={renderLoader()}>
          <ServicesTechnology />
        </Suspense>
      </section>
      <section className="contact__container">
        <Suspense fallback={renderLoader()}>
          <Contact />
        </Suspense>
      </section>
      <Footer />
    </main>
  );
};

export default Services;
