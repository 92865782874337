import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import { Chevron } from "../../assets/icons/generic"
import styles from './topBar.module.scss'
import React, { useEffect, useState } from "react"

export const ListTechnologies = React.forwardRef(({ lang, path, mobile, toggle }, ref) => {
  return (
    <div className={styles.technologies__content} style={{ position: mobile ? 'relative' : 'absolute' }}>
      <div className={styles.technologies__background}>
        <div className={styles.item__arrow} style={{ fontWeight: path.includes("/technologies") ? 800 : 400 }} onClick={toggle}>
          <div className={styles.technologies__title}>
            <Chevron direction={"left"} size={{ width: 18, height: 18 }} />
            <FormattedMessage id="topBar.technologies" />
          </div>
        </div>
        <div className={styles.technologies__col}>
          <Link to={`/${lang}/technologies/ai`} style={{ textDecoration: "none" }} className={path.includes("/ai") ? styles["menu__item--selected"] : styles.menu__item}>
            AI Development
          </Link>
          <Link to={`/${lang}/technologies/net`} style={{ textDecoration: "none" }} className={path.includes("/net") ? styles["menu__item--selected"] : styles.menu__item}>
            .Net
          </Link>
          <Link to={`/${lang}/technologies/angular`} style={{ textDecoration: "none" }} className={path.includes("/angular") ? styles["menu__item--selected"] : styles.menu__item}>
            Angular JS
          </Link>
          <Link to={`/${lang}/technologies/c-c-sharp-c-plus-plus`} style={{ textDecoration: "none" }} className={path.includes("/c-c-sharp-c-plus-plus") ? styles["menu__item--selected"] : styles.menu__item}>
            C / C# / C++
          </Link>
          <Link to={`/${lang}/technologies/html`} style={{ textDecoration: "none" }} className={path.includes("/html") ? styles["menu__item--selected"] : styles.menu__item}>
            HTML
          </Link>
          <Link to={`/${lang}/technologies/iot`} style={{ textDecoration: "none" }} className={path.includes("/iot") ? styles["menu__item--selected"] : styles.menu__item}>
            IoT
          </Link>

        </div>
        <div className={styles.technologies__col}>
          <Link to={`/${lang}/technologies/java`} style={{ textDecoration: "none" }} className={path.includes("/java") ? styles["menu__item--selected"] : styles.menu__item}>
            Java
          </Link>
          <Link to={`/${lang}/technologies/machine-learning`} style={{ textDecoration: "none" }} className={path.includes("/machine-learning") ? styles["menu__item--selected"] : styles.menu__item}>
            Machine Learning
          </Link>
          <Link to={`/${lang}/technologies/php`} style={{ textDecoration: "none" }} className={path.includes("/php") ? styles["menu__item--selected"] : styles.menu__item}>
            PHP
          </Link>
          <Link to={`/${lang}/technologies/progressive-web-app`} style={{ textDecoration: "none" }} className={path.includes("/progressive-web-app") ? styles["menu__item--selected"] : styles.menu__item}>
            Progressive Web App
          </Link>
          <Link to={`/${lang}/technologies/python`} style={{ textDecoration: "none" }} className={path.includes("/python") ? styles["menu__item--selected"] : styles.menu__item}>
            Python
          </Link>
          <Link to={`/${lang}/technologies/react-native`} style={{ textDecoration: "none" }} className={path.includes("/react-native") ? styles["menu__item--selected"] : styles.menu__item}>
            React Native
          </Link>
        </div>
        <div className={styles.technologies__col}>
          <Link to={`/${lang}/technologies/flutter`} style={{ textDecoration: "none" }} className={path.includes("/flutter") ? styles["menu__item--selected"] : styles.menu__item}>
            Flutter
          </Link>
          <Link to={`/${lang}/technologies/react-node`} style={{ textDecoration: "none" }} className={path.includes("/react-node") ? styles["menu__item--selected"] : styles.menu__item}>
            React Node
          </Link>
          <Link to={`/${lang}/technologies/react-js`} style={{ textDecoration: "none" }} className={path.includes("/react-js") ? styles["menu__item--selected"] : styles.menu__item}>
            ReactJS
          </Link>
          <Link to={`/${lang}/technologies/saas`} style={{ textDecoration: "none" }} className={path.includes("/saas") ? styles["menu__item--selected"] : styles.menu__item}>
            SaaS
          </Link>
          <Link to={`/${lang}/technologies/sql`} style={{ textDecoration: "none" }} className={path.includes("/sql") ? styles["menu__item--selected"] : styles.menu__item}>
            SQL
          </Link>
          <Link to={`/${lang}/technologies/web-app`} style={{ textDecoration: "none" }} className={path.includes("/web-app") ? styles["menu__item--selected"] : styles.menu__item}>
            WebApp
          </Link>
          <Link to={`/${lang}/technologies/ruby`} style={{ textDecoration: "none" }} className={path.includes("/ruby") ? styles["menu__item--selected"] : styles.menu__item}>
            Ruby
          </Link>
        </div>
      </div>
    </div>
  )
});
export const TechnologyButton = ({ path, lang, setIsExtended, isExtended, toggle }) => {

  //Ancho de ventana
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    //Seteo de ancho de ventana y cambios
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles["menu__item--technologies"]}>
      <div className={styles.item__arrow} style={{ fontWeight: path.includes("/technologies") ? 800 : 400 }} onClick={toggle}>
        <FormattedMessage id="topBar.technologies" />
        {width > 1280 && (
          <Chevron direction={"down"} size={{ width: 18, height: 18 }} />
        )}

      </div>
      {(width > 1280 || isExtended) && (
        <ListTechnologies lang={lang} path={path} toggle={toggle} />
      )}

    </div>

  )
}