
import React, { useEffect } from "react";
import { Head, Footer, TopBar } from "../../components"
import ReactGA from 'react-ga';
import { FormattedMessage } from "react-intl";
import styles from './contact.module.scss';
import slogan from './../../assets/images/logos/WAK-inspiringGeekRevolution.svg';
import logo from './../../assets/images/logos/wakapi-logo-white.svg';

const ContactConfirm = () => {
    useEffect(() => {
        ReactGA.pageview('/contact-confirm');
        window.scrollTo(0, 0);
    }, [])

    return (
        <div>
            <Head
                title="Wakapi - Contact"
                description="Wakapi delivers Software Development Services & Solutions to skyrocket your software engineering power. Get to know us."
                path="/contact-confirm"
                noRobots={true}
            />
            <TopBar />
            <div className={styles.background}>
                <div className={styles.container}>
                    <div className={styles.title}>
                        <FormattedMessage id="contact-form-message-success" />
                    </div>
                    <div className={styles.slogan__container}>
                        <img src={slogan} className={styles.slogan} alt='Inspiring geek revolution' />
                        <img src={logo} className={styles.logo} alt='Wakapi' />
                    </div>
                </div>

            </div>

            <Footer />
        </div>
    );
};
export default ContactConfirm;
