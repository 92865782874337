import React from "react";
import styles from './button.module.scss'
import classnames from 'classnames'
export const Button = ({ children, primary, topBar, secondary, disabled, id, type, onClick, secondaryDark }) => {
  const buttonClass = classnames({
    primary,
    secondary,
    topBar,
    secondaryDark
  })
  return <button className={styles[buttonClass]} onClick={onClick} type={type} disabled={disabled} id={id} >{children}</button>
}
export default Button