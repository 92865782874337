export const CuidoBienLogo = ({ size: { width = '136', height = '96' } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 136 96" fill="none" xmlns="http://www.w3.org/2000/svg" aria-valuetext="Cuido bien">
            <g clipPath="url(#clip0_843_666)">
                <path d="M10.455 60.33C7.57503 60.33 5.42503 59.5 4.01503 57.85C2.60503 56.2 1.90503 53.94 1.90503 51.08C1.90503 49.88 2.04503 48.78 2.32503 47.77C2.60503 46.76 3.04503 45.89 3.64503 45.15C4.24503 44.41 4.99503 43.84 5.91503 43.43C6.82503 43.02 7.90503 42.81 9.15503 42.81C11.145 42.81 12.675 43.29 13.745 44.25C14.815 45.21 15.345 46.67 15.345 48.61H11.375C11.375 47.67 11.195 46.94 10.835 46.41C10.475 45.88 9.89503 45.62 9.09503 45.62C8.55503 45.62 8.09503 45.75 7.72503 46.01C7.34503 46.27 7.05503 46.61 6.82503 47.05C6.60503 47.48 6.44503 47.98 6.35503 48.54C6.26503 49.1 6.21503 49.69 6.21503 50.3C6.21503 51.28 6.31503 52.21 6.51503 53.08C6.71503 53.95 7.04503 54.71 7.50503 55.37C7.96503 56.03 8.55503 56.55 9.28503 56.93C10.015 57.32 10.915 57.51 11.995 57.51C12.975 57.51 13.925 57.37 14.845 57.09V59.73C13.505 60.13 12.055 60.33 10.485 60.33H10.455Z" fill="#00E1B9" />
                <path d="M27.965 56.88H27.895C27.445 57.86 26.865 58.69 26.135 59.34C25.405 60 24.415 60.32 23.145 60.32C21.525 60.32 20.335 59.82 19.555 58.81C18.775 57.8 18.395 56.49 18.395 54.87V43.2H22.295V52.91C22.295 53.59 22.315 54.19 22.345 54.7C22.385 55.22 22.465 55.65 22.595 56C22.725 56.35 22.915 56.62 23.175 56.79C23.435 56.96 23.785 57.05 24.235 57.05C24.965 57.05 25.585 56.73 26.095 56.1C26.615 55.47 27.025 54.68 27.325 53.74C27.515 53.13 27.655 52.5 27.745 51.86C27.835 51.21 27.885 50.59 27.885 49.98V43.19H31.785V59.93H27.955V56.87L27.965 56.88Z" fill="#00E1B9" />
                <path d="M36.0251 35.67H40.2451V39.43H36.0251V35.67ZM36.2051 43.2H40.1051V59.94H36.2051V43.2Z" fill="#00E1B9" />
                <path d="M53.785 57.76H53.675C53.225 58.63 52.655 59.27 51.955 59.69C51.255 60.11 50.455 60.32 49.565 60.32C48.365 60.32 47.375 60.08 46.595 59.6C45.805 59.12 45.185 58.48 44.715 57.68C44.245 56.88 43.915 55.96 43.715 54.9C43.515 53.84 43.415 52.75 43.415 51.63C43.415 50.43 43.535 49.3 43.785 48.22C44.035 47.14 44.405 46.2 44.915 45.41C45.415 44.61 46.075 43.98 46.865 43.51C47.665 43.04 48.625 42.81 49.745 42.81C50.635 42.81 51.395 42.97 52.015 43.3C52.635 43.63 53.195 44.19 53.685 44.99H53.795V35.67H57.695V59.94H53.795V57.76H53.785ZM52.325 56.7C52.735 56.16 53.045 55.53 53.255 54.8C53.445 54.26 53.575 53.7 53.655 53.13C53.735 52.56 53.775 52 53.775 51.46C53.775 50.92 53.735 50.32 53.665 49.65C53.595 48.98 53.455 48.35 53.245 47.77C53.035 47.16 52.725 46.65 52.315 46.24C51.905 45.83 51.375 45.62 50.715 45.62C50.055 45.62 49.575 45.81 49.185 46.2C48.795 46.59 48.495 47.09 48.285 47.69C48.075 48.3 47.925 48.97 47.845 49.71C47.765 50.45 47.725 51.18 47.725 51.91C47.725 52.59 47.765 53.26 47.845 53.93C47.925 54.6 48.075 55.2 48.305 55.72C48.525 56.25 48.825 56.68 49.205 57C49.585 57.33 50.065 57.49 50.645 57.49C51.345 57.49 51.905 57.22 52.315 56.68L52.325 56.7Z" fill="#00E1B9" />
                <path d="M64.995 59.67C64.045 59.24 63.265 58.63 62.635 57.84C62.015 57.05 61.565 56.13 61.275 55.06C60.995 53.99 60.855 52.83 60.855 51.56C60.855 50.29 60.995 49.16 61.275 48.1C61.555 47.03 62.005 46.11 62.615 45.32C63.225 44.53 64.005 43.92 64.955 43.47C65.905 43.02 67.065 42.8 68.415 42.8C69.765 42.8 70.895 43.02 71.845 43.47C72.795 43.92 73.575 44.53 74.205 45.32C74.825 46.11 75.275 47.03 75.555 48.1C75.835 49.17 75.975 50.32 75.975 51.56C75.975 52.8 75.835 53.99 75.555 55.06C75.275 56.13 74.825 57.05 74.205 57.82C73.585 58.59 72.795 59.2 71.845 59.65C70.895 60.1 69.755 60.32 68.415 60.32C67.075 60.32 65.935 60.1 64.985 59.67H64.995ZM70.035 57C70.455 56.66 70.785 56.21 71.025 55.66C71.255 55.11 71.425 54.48 71.515 53.76C71.605 53.05 71.655 52.31 71.655 51.56C71.655 50.81 71.605 50.04 71.515 49.33C71.425 48.61 71.255 47.98 71.025 47.43C70.795 46.88 70.465 46.44 70.035 46.11C69.615 45.78 69.075 45.62 68.415 45.62C67.755 45.62 67.195 45.78 66.775 46.11C66.365 46.44 66.035 46.88 65.795 47.43C65.545 47.98 65.385 48.61 65.305 49.33C65.225 50.04 65.185 50.79 65.185 51.56C65.185 52.33 65.235 53.08 65.325 53.79C65.415 54.5 65.585 55.14 65.815 55.69C66.045 56.24 66.375 56.68 66.795 57.01C67.215 57.34 67.755 57.5 68.415 57.5C69.075 57.5 69.615 57.33 70.035 56.99V57Z" fill="#00E1B9" />
                <path d="M82.775 35.67V45.27H82.845C83.855 43.63 85.305 42.81 87.205 42.81C88.305 42.81 89.245 43.04 90.015 43.51C90.785 43.98 91.415 44.61 91.895 45.39C92.375 46.18 92.725 47.09 92.955 48.13C93.175 49.17 93.285 50.26 93.285 51.38C93.285 52.65 93.145 53.82 92.865 54.92C92.585 56.01 92.135 56.95 91.525 57.75C90.915 58.55 90.145 59.17 89.205 59.63C88.265 60.09 87.125 60.32 85.795 60.32C84.575 60.32 83.535 60.14 82.665 59.79C81.795 59.44 81.085 58.93 80.525 58.28C79.965 57.62 79.545 56.84 79.275 55.94C79.005 55.04 78.875 54.04 78.875 52.93V35.67H82.775ZM87.365 56.98C87.775 56.63 88.095 56.17 88.335 55.61C88.565 55.05 88.735 54.41 88.825 53.71C88.915 53.01 88.965 52.3 88.965 51.6C88.965 50.9 88.925 50.15 88.845 49.44C88.765 48.72 88.615 48.08 88.405 47.51C88.195 46.94 87.905 46.47 87.525 46.12C87.145 45.77 86.685 45.59 86.115 45.59C85.435 45.59 84.875 45.81 84.445 46.26C84.015 46.71 83.665 47.24 83.405 47.88C83.145 48.54 82.975 49.23 82.895 49.96C82.815 50.69 82.775 51.37 82.775 52C82.775 52.63 82.815 53.28 82.895 53.93C82.975 54.59 83.135 55.18 83.355 55.71C83.575 56.24 83.885 56.67 84.275 57.01C84.665 57.35 85.175 57.52 85.805 57.52C86.435 57.52 86.955 57.34 87.365 56.99V56.98Z" fill="#FDD305" />
                <path d="M96.425 35.67H100.645V39.43H96.425V35.67ZM96.5951 43.2H100.495V59.94H96.5951V43.2Z" fill="#FDD305" />
                <path d="M114.735 60.2C113.995 60.28 113.265 60.32 112.535 60.32C111.005 60.32 109.695 60.1 108.595 59.65C107.495 59.2 106.595 58.57 105.885 57.73C105.185 56.9 104.665 55.9 104.325 54.74C103.985 53.58 103.815 52.28 103.815 50.85C103.815 49.7 103.955 48.63 104.235 47.65C104.515 46.67 104.945 45.82 105.535 45.1C106.125 44.38 106.865 43.82 107.765 43.41C108.665 43 109.735 42.79 110.985 42.79C112.045 42.79 112.965 42.96 113.765 43.3C114.565 43.64 115.225 44.11 115.765 44.71C116.305 45.31 116.705 46.02 116.975 46.84C117.245 47.66 117.375 48.56 117.375 49.55V50.71H107.845V51.13C107.845 52 107.975 52.82 108.235 53.59C108.495 54.36 108.865 55.04 109.345 55.63C109.825 56.22 110.425 56.68 111.135 57.02C111.845 57.36 112.665 57.53 113.575 57.53C114.095 57.53 114.615 57.49 115.155 57.41C115.695 57.33 116.255 57.19 116.845 57.01V59.72C116.165 59.95 115.455 60.11 114.715 60.19L114.735 60.2ZM112.775 46.13C112.305 45.58 111.675 45.3 110.875 45.3C109.985 45.3 109.295 45.59 108.815 46.18C108.335 46.77 108.045 47.47 107.955 48.29H113.475C113.475 47.4 113.245 46.68 112.775 46.13Z" fill="#FDD305" />
                <path d="M130.195 50.06C130.195 48.72 130.085 47.73 129.865 47.07C129.645 46.41 129.125 46.09 128.295 46.09C127.545 46.09 126.915 46.39 126.415 46.99C125.915 47.59 125.515 48.31 125.235 49.15C125.025 49.76 124.865 50.41 124.765 51.1C124.655 51.79 124.605 52.48 124.605 53.16V59.95H120.705V43.2H124.535V46.3H124.605C124.815 45.83 125.055 45.39 125.325 44.96C125.595 44.54 125.925 44.17 126.305 43.85C126.695 43.53 127.135 43.28 127.645 43.09C128.145 42.9 128.745 42.81 129.425 42.81C130.975 42.81 132.135 43.3 132.925 44.27C133.715 45.24 134.105 46.56 134.105 48.23V59.94H130.205V50.06H130.195Z" fill="#FDD305" />
            </g>
            <defs>
                <clipPath id="clip0_843_666">
                    <rect width="132.19" height="24.66" fill="white" transform="translate(1.90503 35.67)" />
                </clipPath>
            </defs>
        </svg>

    )
}