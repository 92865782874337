import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./clients.module.scss";
import parse from "html-react-parser";
import kablitz from "./../../assets/images/clients/quotes/torsten-kablitz.webp";
import labrador from "./../../assets/images/clients/quotes/mitch-labrador.webp";
import chambouleyron from "./../../assets/images/clients/quotes/julian-chambouleyron.webp";
import maxfield from './../../assets/images/clients/quotes/derek-maxfield.webp';
import dandy from './../../assets/images/clients/quotes/michael-dandy.webp';
import velez from './../../assets/images/clients/quotes/miguelm-velez.webp';
import doyle from './../../assets/images/clients/quotes/sean-doyle.webp';
import Slider from "react-slick";

export const ClientsCommentsSlider = () => {

  const { formatMessage } = useIntl();
  const clientsComment = [
    {
      id: 0,
      title: "clients.reviews-1-title",
      text: "clients.reviews-1-text",
      author: "clients.reviews-1-author",
      img: kablitz,
    },
    {
      id: 1,
      title: "clients.reviews-2-title",
      text: "clients.reviews-2-text",
      author: "clients.reviews-2-author",
      img: labrador,
    },
    {
      id: 2,
      title: "clients.reviews-3-title",
      text: "clients.reviews-3-text",
      author: "clients.reviews-3-author",
      img: chambouleyron,
    },
    {
      id: 3,
      title: "clients.reviews-4-title",
      text: "clients.reviews-4-text",
      author: "clients.reviews-4-author",
      img: maxfield,
    },
    {
      id: 4,
      title: "clients.reviews-5-title",
      text: "clients.reviews-5-text",
      author: "clients.reviews-5-author",
      img: dandy,
    },
    {
      id: 5,
      title: "clients.reviews-6-title",
      text: "clients.reviews-6-text",
      author: "clients.reviews-6-author",
      img: velez,
    },
    {
      id: 6,
      title: "clients.reviews-7-title",
      text: "clients.reviews-7-text",
      author: "clients.reviews-7-author",
      img: doyle,
    },
  ];
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    autoplaySpeed: 9000,
    arrows: false,
    pauseOnHover: false,
    pauseOnFocus: false,
    pauseOnDotsHover: false,
  };

  return (
    <Slider {...settings}  className="clients-slider">
      {clientsComment.map((comment) => {
        return (
          <div className={styles.clientes__slider} key={comment.id}>
            <h4>
              <FormattedMessage id={comment.title} />
            </h4>
            <div className={styles.clientes__comment}>
              <i>
                <FormattedMessage id={comment.text} />
              </i>
            </div>
            <div className={styles.clientes__author}>
              <div className={styles.clientes__img}>
                <img src={comment.img} alt={comment.author} width={40} height={40} />
              </div>
              {parse(formatMessage({ id: comment.author }))}
            </div>
          </div>
        );
      })}
    </Slider>
  );
};
