import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import styles from './topBar.module.scss';

export const AboutUsButton = ({ path, lang }) => {
    return (
        <Link to={`/${lang}/about-us`} style={{ textDecoration: "none" }} className={
            path.includes("/about-us")
                ? styles["menu__item--selected"]
                : styles.menu__item
        }>
            <FormattedMessage id="topBar.about" />
        </Link>
    )
}