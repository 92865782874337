export const QuincyLogo = ({ size: { width = '110', height = '96' } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 110 96" fill="none" xmlns="http://www.w3.org/2000/svg" aria-valuetext="Quincy">
            <path d="M92.3743 55.4008C92.6516 55.4222 92.9289 55.4435 93.2063 55.4648C93.4836 55.5075 93.6969 55.5288 93.8463 55.5288C94.1876 55.5288 94.4863 55.4115 94.7423 55.1768C94.9983 54.9422 95.2756 54.4835 95.5743 53.8008C95.8943 53.1395 96.2996 52.1582 96.7903 50.8568L89.7183 34.1528H92.6943L98.3263 48.0088L103.414 34.1528H106.198L98.0703 55.2408C97.8783 55.7315 97.5903 56.1902 97.2063 56.6168C96.8436 57.0648 96.3743 57.4168 95.7983 57.6728C95.2223 57.9288 94.5289 58.0568 93.7183 58.0568C93.5263 58.0568 93.3236 58.0462 93.1103 58.0248C92.9183 58.0035 92.6729 57.9608 92.3743 57.8968V55.4008Z" fill="#F26522" />
            <path d="M81.5608 51.177C80.3022 51.177 79.1502 50.953 78.1048 50.505C77.0808 50.0357 76.1848 49.3957 75.4168 48.585C74.6702 47.7743 74.0835 46.8463 73.6568 45.801C73.2515 44.7557 73.0488 43.6463 73.0488 42.473C73.0488 40.8943 73.4008 39.4543 74.1048 38.153C74.8088 36.8517 75.8008 35.817 77.0808 35.049C78.3608 34.2597 79.8435 33.865 81.5288 33.865C83.1715 33.865 84.6008 34.2383 85.8168 34.985C87.0542 35.7103 87.9715 36.6917 88.5688 37.929L85.8168 38.793C85.3902 38.0037 84.7928 37.3957 84.0248 36.969C83.2568 36.521 82.4035 36.297 81.4648 36.297C80.4408 36.297 79.5022 36.5637 78.6488 37.097C77.8168 37.6303 77.1555 38.3663 76.6648 39.305C76.1742 40.2223 75.9288 41.2783 75.9288 42.473C75.9288 43.6463 76.1742 44.713 76.6648 45.673C77.1768 46.6117 77.8488 47.3583 78.6808 47.913C79.5342 48.4677 80.4728 48.745 81.4968 48.745C82.1582 48.745 82.7875 48.6277 83.3848 48.393C84.0035 48.1583 84.5368 47.849 84.9848 47.465C85.4542 47.0597 85.7742 46.6223 85.9448 46.153L88.6968 46.985C88.3555 47.7957 87.8328 48.521 87.1288 49.161C86.4462 49.7797 85.6248 50.2703 84.6648 50.633C83.7262 50.9957 82.6915 51.177 81.5608 51.177Z" fill="#F26522" />
            <path d="M69.79 50.857H66.9739V41.513C66.9739 39.7423 66.6966 38.4517 66.1419 37.641C65.6086 36.809 64.7873 36.393 63.6779 36.393C62.9099 36.393 62.1419 36.585 61.3739 36.969C60.6273 37.353 59.9553 37.8757 59.3579 38.537C58.7819 39.177 58.3659 39.9237 58.1099 40.777V50.857H55.2939V34.153H57.8539V37.737C58.3019 36.9477 58.8779 36.265 59.5819 35.689C60.2859 35.113 61.0859 34.665 61.9819 34.345C62.8779 34.025 63.8166 33.865 64.7979 33.865C65.7579 33.865 66.5579 34.0463 67.1979 34.409C67.8593 34.7503 68.3713 35.241 68.7339 35.881C69.1179 36.4997 69.3846 37.2357 69.5339 38.089C69.7046 38.9423 69.79 39.8703 69.79 40.873V50.857Z" fill="#F26522" />
            <path d="M47.9189 50.8568V34.1528H50.7349V50.8568H47.9189ZM47.9189 31.0168V27.4968H50.7349V31.0168H47.9189Z" fill="#F26522" />
            <path d="M28.54 44.1688V34.1528H31.356V43.6248C31.356 45.3315 31.6547 46.6115 32.252 47.4648C32.8494 48.3182 33.7347 48.7448 34.908 48.7448C35.676 48.7448 36.412 48.5848 37.116 48.2648C37.8414 47.9235 38.492 47.4435 39.068 46.8248C39.644 46.2062 40.092 45.4702 40.412 44.6168V34.1528H43.228V47.6568C43.228 47.9982 43.292 48.2542 43.42 48.4248C43.5694 48.5742 43.804 48.6595 44.124 48.6808V50.8568C43.804 50.8995 43.548 50.9208 43.356 50.9208C43.1854 50.9422 43.0147 50.9528 42.844 50.9528C42.3107 50.9528 41.852 50.7928 41.468 50.4728C41.084 50.1315 40.8814 49.7155 40.86 49.2248L40.796 47.2728C40.0707 48.5102 39.0894 49.4702 37.852 50.1528C36.636 50.8355 35.3027 51.1768 33.852 51.1768C32.1027 51.1768 30.78 50.5902 29.884 49.4168C28.988 48.2222 28.54 46.4728 28.54 44.1688Z" fill="#F26522" />
            <path d="M14.4893 51.0171C12.8893 51.0171 11.4386 50.6971 10.1373 50.0571C8.83594 49.3957 7.70527 48.5211 6.74527 47.4331C5.8066 46.3451 5.08127 45.1184 4.56927 43.7531C4.05727 42.3664 3.80127 40.9477 3.80127 39.4971C3.80127 37.9824 4.06794 36.5424 4.60127 35.1771C5.15594 33.7904 5.91327 32.5637 6.87327 31.4971C7.8546 30.4091 8.99594 29.5557 10.2973 28.937C11.5986 28.2971 13.0173 27.9771 14.5533 27.9771C16.1533 27.9771 17.6039 28.3077 18.9053 28.9691C20.2066 29.6304 21.3266 30.5157 22.2653 31.6251C23.2039 32.7344 23.9293 33.9717 24.4413 35.3371C24.9533 36.7024 25.2093 38.0997 25.2093 39.5291C25.2093 41.0437 24.9426 42.4944 24.4093 43.8811C23.8759 45.2464 23.1186 46.4731 22.1373 47.5611C21.1773 48.6277 20.0466 49.4704 18.7453 50.0891C17.4439 50.7077 16.0253 51.0171 14.4893 51.0171ZM14.5213 48.4251C15.7373 48.4251 16.8253 48.1691 17.7853 47.6571C18.7453 47.1451 19.5559 46.4624 20.2173 45.6091C20.8999 44.7557 21.4119 43.7957 21.7533 42.7291C22.1159 41.6624 22.2973 40.5851 22.2973 39.4971C22.2973 38.3451 22.1053 37.2357 21.7213 36.1691C21.3586 35.0811 20.8253 34.1211 20.1213 33.2891C19.4386 32.4571 18.6173 31.7957 17.6573 31.3051C16.7186 30.8144 15.6733 30.5691 14.5213 30.5691C13.3053 30.5691 12.2173 30.8251 11.2573 31.3371C10.2973 31.8491 9.47594 32.5317 8.79327 33.3851C8.13194 34.2384 7.61994 35.1984 7.25727 36.2651C6.8946 37.3104 6.71327 38.3877 6.71327 39.4971C6.71327 40.6491 6.8946 41.7691 7.25727 42.8571C7.64127 43.9237 8.1746 44.8731 8.85727 45.7051C9.53994 46.5371 10.3613 47.1984 11.3213 47.6891C12.2813 48.1797 13.3479 48.4251 14.5213 48.4251ZM15.8013 43.4331H18.6173L24.9853 50.8571H22.1693L15.8013 43.4331Z" fill="#F26522" />
            <path d="M76.3535 57.7278V68.4129H74.9991V63.5519H69.1147V68.4129H67.7603V57.7278H69.1147V62.348H74.9991V57.7278H76.3535Z" fill="#F26522" />
            <path d="M54.853 62.995C54.853 62.3429 54.9634 61.7058 55.1841 61.0837C55.4149 60.4516 55.746 59.8798 56.1774 59.3681C56.6188 58.8464 57.1556 58.435 57.7877 58.134C58.4198 57.823 59.1371 57.6675 59.9398 57.6675C60.8929 57.6675 61.7106 57.8882 62.3928 58.3297C63.0751 58.7611 63.5818 59.3179 63.9128 60.0002L62.8443 60.6924C62.6336 60.2409 62.3627 59.8848 62.0317 59.6239C61.7106 59.353 61.3594 59.1624 60.9782 59.052C60.607 58.9417 60.2408 58.8865 59.8796 58.8865C59.2876 58.8865 58.7609 59.0069 58.2994 59.2477C57.8479 59.4885 57.4666 59.8095 57.1556 60.2108C56.8446 60.6122 56.6088 61.0637 56.4483 61.5653C56.2978 62.0569 56.2225 62.5536 56.2225 63.0552C56.2225 63.607 56.3128 64.1388 56.4934 64.6505C56.6841 65.1621 56.9449 65.6136 57.276 66.0049C57.6071 66.3962 57.9984 66.7072 58.4499 66.938C58.9013 67.1687 59.393 67.2841 59.9247 67.2841C60.2959 67.2841 60.6772 67.2189 61.0685 67.0885C61.4598 66.9581 61.826 66.7574 62.1671 66.4865C62.5082 66.2056 62.7791 65.8444 62.9798 65.4029L64.1085 66.02C63.8878 66.5517 63.5466 67.0032 63.0851 67.3744C62.6236 67.7456 62.1069 68.0266 61.535 68.2172C60.9732 68.4078 60.4163 68.5031 59.8645 68.5031C59.1321 68.5031 58.4599 68.3476 57.8479 68.0366C57.2359 67.7155 56.7041 67.2942 56.2526 66.7724C55.8112 66.2407 55.465 65.6487 55.2142 64.9966C54.9734 64.3344 54.853 63.6672 54.853 62.995Z" fill="#F26522" />
            <path d="M51.9365 67.209V68.4129H44.6826V57.7278H51.801V58.9317H46.0371V62.3931H51.0486V63.5218H46.0371V67.209H51.9365Z" fill="#F26522" />
            <path d="M41.1872 58.9317H37.5001V68.4129H36.1456V58.9317H32.4585V57.7278H41.1872V58.9317Z" fill="#F26522" />
            <path d="M92.3743 55.4008C92.6516 55.4222 92.9289 55.4435 93.2063 55.4648C93.4836 55.5075 93.6969 55.5288 93.8463 55.5288C94.1876 55.5288 94.4863 55.4115 94.7423 55.1768C94.9983 54.9422 95.2756 54.4835 95.5743 53.8008C95.8943 53.1395 96.2996 52.1582 96.7903 50.8568L89.7183 34.1528H92.6943L98.3263 48.0088L103.414 34.1528H106.198L98.0703 55.2408C97.8783 55.7315 97.5903 56.1902 97.2063 56.6168C96.8436 57.0648 96.3743 57.4168 95.7983 57.6728C95.2223 57.9288 94.5289 58.0568 93.7183 58.0568C93.5263 58.0568 93.3236 58.0462 93.1103 58.0248C92.9183 58.0035 92.6729 57.9608 92.3743 57.8968V55.4008Z" fill="#F26522" />
            <path d="M81.5608 51.177C80.3022 51.177 79.1502 50.953 78.1048 50.505C77.0808 50.0357 76.1848 49.3957 75.4168 48.585C74.6702 47.7743 74.0835 46.8463 73.6568 45.801C73.2515 44.7557 73.0488 43.6463 73.0488 42.473C73.0488 40.8943 73.4008 39.4543 74.1048 38.153C74.8088 36.8517 75.8008 35.817 77.0808 35.049C78.3608 34.2597 79.8435 33.865 81.5288 33.865C83.1715 33.865 84.6008 34.2383 85.8168 34.985C87.0542 35.7103 87.9715 36.6917 88.5688 37.929L85.8168 38.793C85.3902 38.0037 84.7928 37.3957 84.0248 36.969C83.2568 36.521 82.4035 36.297 81.4648 36.297C80.4408 36.297 79.5022 36.5637 78.6488 37.097C77.8168 37.6303 77.1555 38.3663 76.6648 39.305C76.1742 40.2223 75.9288 41.2783 75.9288 42.473C75.9288 43.6463 76.1742 44.713 76.6648 45.673C77.1768 46.6117 77.8488 47.3583 78.6808 47.913C79.5342 48.4677 80.4728 48.745 81.4968 48.745C82.1582 48.745 82.7875 48.6277 83.3848 48.393C84.0035 48.1583 84.5368 47.849 84.9848 47.465C85.4542 47.0597 85.7742 46.6223 85.9448 46.153L88.6968 46.985C88.3555 47.7957 87.8328 48.521 87.1288 49.161C86.4462 49.7797 85.6248 50.2703 84.6648 50.633C83.7262 50.9957 82.6915 51.177 81.5608 51.177Z" fill="#F26522" />
            <path d="M69.79 50.857H66.9739V41.513C66.9739 39.7423 66.6966 38.4517 66.1419 37.641C65.6086 36.809 64.7873 36.393 63.6779 36.393C62.9099 36.393 62.1419 36.585 61.3739 36.969C60.6273 37.353 59.9553 37.8757 59.3579 38.537C58.7819 39.177 58.3659 39.9237 58.1099 40.777V50.857H55.2939V34.153H57.8539V37.737C58.3019 36.9477 58.8779 36.265 59.5819 35.689C60.2859 35.113 61.0859 34.665 61.9819 34.345C62.8779 34.025 63.8166 33.865 64.7979 33.865C65.7579 33.865 66.5579 34.0463 67.1979 34.409C67.8593 34.7503 68.3713 35.241 68.7339 35.881C69.1179 36.4997 69.3846 37.2357 69.5339 38.089C69.7046 38.9423 69.79 39.8703 69.79 40.873V50.857Z" fill="#F26522" />
            <path d="M47.9189 50.8568V34.1528H50.7349V50.8568H47.9189ZM47.9189 31.0168V27.4968H50.7349V31.0168H47.9189Z" fill="#F26522" />
            <path d="M28.54 44.1688V34.1528H31.356V43.6248C31.356 45.3315 31.6547 46.6115 32.252 47.4648C32.8494 48.3182 33.7347 48.7448 34.908 48.7448C35.676 48.7448 36.412 48.5848 37.116 48.2648C37.8414 47.9235 38.492 47.4435 39.068 46.8248C39.644 46.2062 40.092 45.4702 40.412 44.6168V34.1528H43.228V47.6568C43.228 47.9982 43.292 48.2542 43.42 48.4248C43.5694 48.5742 43.804 48.6595 44.124 48.6808V50.8568C43.804 50.8995 43.548 50.9208 43.356 50.9208C43.1854 50.9422 43.0147 50.9528 42.844 50.9528C42.3107 50.9528 41.852 50.7928 41.468 50.4728C41.084 50.1315 40.8814 49.7155 40.86 49.2248L40.796 47.2728C40.0707 48.5102 39.0894 49.4702 37.852 50.1528C36.636 50.8355 35.3027 51.1768 33.852 51.1768C32.1027 51.1768 30.78 50.5902 29.884 49.4168C28.988 48.2222 28.54 46.4728 28.54 44.1688Z" fill="#F26522" />
            <path d="M14.4893 51.0171C12.8893 51.0171 11.4386 50.6971 10.1373 50.0571C8.83594 49.3957 7.70527 48.5211 6.74527 47.4331C5.8066 46.3451 5.08127 45.1184 4.56927 43.7531C4.05727 42.3664 3.80127 40.9477 3.80127 39.4971C3.80127 37.9824 4.06794 36.5424 4.60127 35.1771C5.15594 33.7904 5.91327 32.5637 6.87327 31.4971C7.8546 30.4091 8.99594 29.5557 10.2973 28.937C11.5986 28.2971 13.0173 27.9771 14.5533 27.9771C16.1533 27.9771 17.6039 28.3077 18.9053 28.9691C20.2066 29.6304 21.3266 30.5157 22.2653 31.6251C23.2039 32.7344 23.9293 33.9717 24.4413 35.3371C24.9533 36.7024 25.2093 38.0997 25.2093 39.5291C25.2093 41.0437 24.9426 42.4944 24.4093 43.8811C23.8759 45.2464 23.1186 46.4731 22.1373 47.5611C21.1773 48.6277 20.0466 49.4704 18.7453 50.0891C17.4439 50.7077 16.0253 51.0171 14.4893 51.0171ZM14.5213 48.4251C15.7373 48.4251 16.8253 48.1691 17.7853 47.6571C18.7453 47.1451 19.5559 46.4624 20.2173 45.6091C20.8999 44.7557 21.4119 43.7957 21.7533 42.7291C22.1159 41.6624 22.2973 40.5851 22.2973 39.4971C22.2973 38.3451 22.1053 37.2357 21.7213 36.1691C21.3586 35.0811 20.8253 34.1211 20.1213 33.2891C19.4386 32.4571 18.6173 31.7957 17.6573 31.3051C16.7186 30.8144 15.6733 30.5691 14.5213 30.5691C13.3053 30.5691 12.2173 30.8251 11.2573 31.3371C10.2973 31.8491 9.47594 32.5317 8.79327 33.3851C8.13194 34.2384 7.61994 35.1984 7.25727 36.2651C6.8946 37.3104 6.71327 38.3877 6.71327 39.4971C6.71327 40.6491 6.8946 41.7691 7.25727 42.8571C7.64127 43.9237 8.1746 44.8731 8.85727 45.7051C9.53994 46.5371 10.3613 47.1984 11.3213 47.6891C12.2813 48.1797 13.3479 48.4251 14.5213 48.4251ZM15.8013 43.4331H18.6173L24.9853 50.8571H22.1693L15.8013 43.4331Z" fill="#F26522" />
            <path d="M76.3535 57.7278V68.4129H74.9991V63.5519H69.1147V68.4129H67.7603V57.7278H69.1147V62.348H74.9991V57.7278H76.3535Z" fill="#F26522" />
            <path d="M54.853 62.995C54.853 62.3429 54.9634 61.7058 55.1841 61.0837C55.4149 60.4516 55.746 59.8798 56.1774 59.3681C56.6188 58.8464 57.1556 58.435 57.7877 58.134C58.4198 57.823 59.1371 57.6675 59.9398 57.6675C60.8929 57.6675 61.7106 57.8882 62.3928 58.3297C63.0751 58.7611 63.5818 59.3179 63.9128 60.0002L62.8443 60.6924C62.6336 60.2409 62.3627 59.8848 62.0317 59.6239C61.7106 59.353 61.3594 59.1624 60.9782 59.052C60.607 58.9417 60.2408 58.8865 59.8796 58.8865C59.2876 58.8865 58.7609 59.0069 58.2994 59.2477C57.8479 59.4885 57.4666 59.8095 57.1556 60.2108C56.8446 60.6122 56.6088 61.0637 56.4483 61.5653C56.2978 62.0569 56.2225 62.5536 56.2225 63.0552C56.2225 63.607 56.3128 64.1388 56.4934 64.6505C56.6841 65.1621 56.9449 65.6136 57.276 66.0049C57.6071 66.3962 57.9984 66.7072 58.4499 66.938C58.9013 67.1687 59.393 67.2841 59.9247 67.2841C60.2959 67.2841 60.6772 67.2189 61.0685 67.0885C61.4598 66.9581 61.826 66.7574 62.1671 66.4865C62.5082 66.2056 62.7791 65.8444 62.9798 65.4029L64.1085 66.02C63.8878 66.5517 63.5466 67.0032 63.0851 67.3744C62.6236 67.7456 62.1069 68.0266 61.535 68.2172C60.9732 68.4078 60.4163 68.5031 59.8645 68.5031C59.1321 68.5031 58.4599 68.3476 57.8479 68.0366C57.2359 67.7155 56.7041 67.2942 56.2526 66.7724C55.8112 66.2407 55.465 65.6487 55.2142 64.9966C54.9734 64.3344 54.853 63.6672 54.853 62.995Z" fill="#F26522" />
            <path d="M51.9365 67.209V68.4129H44.6826V57.7278H51.801V58.9317H46.0371V62.3931H51.0486V63.5218H46.0371V67.209H51.9365Z" fill="#F26522" />
            <path d="M41.1872 58.9317H37.5001V68.4129H36.1456V58.9317H32.4585V57.7278H41.1872V58.9317Z" fill="#F26522" />
        </svg>

    )
}