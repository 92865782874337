import React, { useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { FormattedMessage } from "react-intl";
import styles from "./timeLine.module.scss";
import history1 from "../../assets/images/story/outsourcing-wakapi-success-story-1.webp";
import history2 from "../../assets/images/story/outsourcing-wakapi-success-story-2.webp";
import history3 from "../../assets/images/story/outsourcing-wakapi-success-story-3.webp";
import history4 from "../../assets/images/story/outsourcing-wakapi-success-story-4.webp";
import history5 from "../../assets/images/story/outsourcing-wakapi-success-story-5.webp";
import history6 from "../../assets/images/story/outsourcing-wakapi-success-story-6.webp";
import history7 from "../../assets/images/story/outsourcing-wakapi-success-story-7.webp";
import history8 from "../../assets/images/story/outsourcing-services-argentina-wakapi.webp";
import history9 from "../../assets/images/story/awa-ventures-startups-wakapi.webp";
import history10 from "../../assets/images/story/wakapi-success-story-8.webp";
import history11 from "../../assets/images/story/wakapi-success-story-9.webp";

export const TimeLine = () => {
  const historyArray = [
    {
      id: 1,
      img: history1,
      year: 2006,
      alt: 'Wakapi Software Outsourcing Story 1',
      text: "aboutUs.history-2006-1",
    },
    {
      id: 2,
      img: history2,
      year: 2006,
      alt: 'Wakapi Software Outsourcing Story 2',
      text: "aboutUs.history-2006-2",
    },
    {
      id: 3,
      img: history3,
      year: 2007,
      alt: 'Wakapi Software Outsourcing Story 3',
      text: "aboutUs.history-2007",
    },
    {
      id: 4,
      img: history4,
      year: 2008,
      alt: 'Wakapi Software Outsourcing Story 4',
      text: "aboutUs.history-2008",
    },
    {
      id: 5,
      img: history5,
      year: 2009,
      alt: 'Wakapi Software Outsourcing Story 5',
      text: "aboutUs.history-2009",
    },
    {
      id: 6,
      img: history6,
      year: 2010,
      alt: 'Wakapi Software Outsourcing Story 6',
      text: "aboutUs.history-2010",
    },
    {
      id: 7,
      img: history7,
      year: 2015,
      alt: 'Wakapi Software Outsourcing Story 7',
      text: "aboutUs.history-2015",
    },
    {
      id: 8,
      img: history8,
      year: 2018,
      alt: 'Wakapi is Outsourcing Services from Argentina',
      text: "aboutUs.history-2018",
    },
    {
      id: 9,
      img: history9,
      year: 2020,
      alt: 'Wakapi has a company builder named Awa Ventures',
      text: "aboutUs.history-2020",
    },
    {
      id: 10,
      img: history10,
      year: 2021,
      alt: 'Wakapi Software Outsourcing Story 8',
      text: "aboutUs.history-2021",
    },
    {
      id: 11,
      img: history11,
      year: 2022,
      alt: 'Wakapi Software Outsourcing Story 9',
      text: "aboutUs.history-2022",
    },
  ];
  const [initScroll, setInitScroll] = useState(false);
  return (
    <div className={styles.history__container}>
      <h2>
        <FormattedMessage id="aboutUs.history-title" />
      </h2>
      <div className={styles.line__container}>
        <ScrollContainer className={styles.line} onScroll={() => setInitScroll(true)}>
          {historyArray.map((item) => {
            return (
              <div className={styles.item} key={item.id}>
                <img src={item.img} alt={item.alt} width={211} height={183}/>
                <div
                  className={
                    item.id === 11
                      ? styles["line__draw--last"]
                      : styles.line__draw
                  }
                />
                <span className={styles.circle} />
                <div className={styles.item__text}>
                  <span className={styles.year}>{item.year}</span>
                  <p>
                    <FormattedMessage id={item.text} />
                  </p>
                </div>
              </div>
            );
          })}
        </ScrollContainer>

      </div>

      <div className={styles.arrow}>
        {!initScroll && (
          <svg width="76" height="57" viewBox="0 0 76 57" fill="none">
            <path
              d="M0.147583 27.6143L73.8298 27.6143"
              stroke="#1F1D1C"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M51.2808 1.53369L74.1721 28.12L50.7071 55.366"
              stroke="#1F1D1C"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
          </svg>
        )}
      </div>


    </div>
  );
};
