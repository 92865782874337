import React from "react";
import styles from "./clients-logos.module.scss";

import { FormattedMessage } from "react-intl";
import { AmazonLogo2, AppapachoLogo, ArsysLogo, BudderflyLogo2, CompressusLogo, CuidoBienLogo, DamsuLogo, DigicelLogo, DollarGeneralLogo2, EarnedCardLogo, ErthLogo2, GisLogo, HowlyLogo, IpcLogo, NavisLogo, NetstepLogo, PaydiantLogo, PinPointLogo2, QuincyLogo, RecoveryPlannerLogo, SubwayLogo2, TangoeLogo, ThomsonReutersLogo2, UnaxUnaLogo, VestaCareLogo, WelfiLogo2 } from "../../../components";


export const ClientsIcons = () => {
  return (
    <>
      <div className={styles.logos__container}>
        <h2><FormattedMessage id="clients.section" /></h2>
        <h3><FormattedMessage id="clients.logos-title" /></h3>
        <div className={styles.logos__rowGroup}>
          <div className={styles.logos__row}>
            <AmazonLogo2
              size={{
                width: '205',
                height: '82'
              }}
            />
            <WelfiLogo2
              size={{
                width: '185',
                height: '82'
              }}
            />
            <VestaCareLogo
              size={{
                width: '188',
                height: '82'
              }}
            />
            <CompressusLogo
              size={{
                width: '159',
                height: '82'
              }}
            />
            <div className={styles.appapacho}>
            <AppapachoLogo
              size={{
                width: '248',
                height: '70'
              }}
            />
            </div>
          </div>

          <div className={styles.logos__row}>
            <SubwayLogo2
              size={{
                width: '185',
                height: '82'
              }}
            />
            <TangoeLogo
              size={{
                width: '157',
                height: '82'
              }}
            />
            <RecoveryPlannerLogo
              size={{
                width: '189',
                height: '82'
              }}
            />
            <ErthLogo2
              size={{
                width: '137',
                height: '82'
              }}
            />
            <UnaxUnaLogo
              size={{
                width: '129',
                height: '82'
              }}
            />
          </div>

          <div className={styles.logos__row}>
            <ThomsonReutersLogo2
              size={{
                width: '230',
                height: '82'
              }}
            />
            <BudderflyLogo2
              size={{
                width: '192',
                height: '82'
              }}
            />
            <PinPointLogo2
              size={{
                width: '201',
                height: '82'
              }}
            />
            <DamsuLogo
              size={{
                width: '200',
                height: '82'
              }}
            />
            <NetstepLogo
              size={{
                width: '130',
                height: '82'
              }}
            />
          </div>

          <div className={styles.logos__row}>
            <DigicelLogo
              size={{
                width: '186',
                height: '82'
              }}
            />
            <PaydiantLogo
              size={{
                width: '172',
                height: '82'
              }}
            />
            <EarnedCardLogo
              size={{
                width: '202',
                height: '82'
              }}
            />
            <CuidoBienLogo
              size={{
                width: '190',
                height: '82'
              }}
            />
            <GisLogo
              size={{
                width: '93',
                height: '82'
              }}
            />
          </div>

          <div className={styles.logos__row}>
            <IpcLogo
              size={{
                width: '144',
                height: '82'
              }}
            />
            <NavisLogo
              size={{
                width: '159',
                height: '82'
              }}
            />
            <ArsysLogo
              size={{
                width: '133',
                height: '82'
              }}
            />
            <QuincyLogo
              size={{
                width: '190',
                height: '82'
              }}
            />
            <HowlyLogo
              size={{
                width: '175',
                height: '82'
              }}
            />
          </div>


        </div>
        <div className={styles.logos__row}>
          <DollarGeneralLogo2
            size={{
              width: '250',
              height: '82'
            }}
          />

        </div>
      </div>
    </>
  );
};
