export const SaasIcon = ({ size: { width = "151", height = "86" } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 151 86" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M110.582 70.1382C104.735 70.1382 99.7566 66.8589 98.1367 61.8807L103.273 59.4706C104.695 62.908 107.659 64.8044 110.582 64.8044C113.585 64.8044 115.363 63.4216 115.363 61.6832C115.363 60.1423 114.296 59.3126 112.558 58.7595L106.552 56.863C101.535 55.2826 99.164 52.4379 99.164 48.5265C99.164 43.2717 103.391 39.7554 109.753 39.7554C115.402 39.7554 119.827 42.8766 121.408 47.2227L116.272 49.6328C115.284 46.8276 112.913 45.0892 109.753 45.0892C107.066 45.0892 105.288 46.472 105.288 48.2894C105.288 49.6723 106.315 50.6995 108.212 51.2922L114.059 53.1096C118.998 54.6505 121.487 57.2976 121.487 61.3276C121.487 66.6219 117.141 70.1382 110.582 70.1382Z" fill="#1CA8FF" />
            <path d="M86.7236 47.6178C92.2154 47.6178 95.9688 50.8181 95.9688 55.4407V69.6641H90.4375V67.6886C88.8176 69.3876 86.8421 70.1382 84.353 70.1382C79.4538 70.1382 76.3721 67.3726 76.3721 63.7772C76.3721 60.5769 78.9007 58.4829 83.2862 57.5742L90.0424 56.1519V55.4407C90.0424 53.8208 88.699 52.754 86.7236 52.754C84.6296 52.754 82.8911 53.9788 82.259 55.9148L77.1623 53.4652C78.4266 49.9094 82.1405 47.6178 86.7236 47.6178ZM85.2617 65.7922C87.9484 65.7922 90.0424 63.8562 90.0424 61.4461V60.4189L84.353 61.6832C83.1282 61.9598 82.496 62.7104 82.496 63.6982C82.496 65.002 83.5628 65.7922 85.2617 65.7922Z" fill="#1CA8FF" />
            <path d="M65.1562 47.6178C70.648 47.6178 74.4014 50.8181 74.4014 55.4407V69.6641H68.8701V67.6886C67.2502 69.3876 65.2747 70.1382 62.7856 70.1382C57.8864 70.1382 54.8047 67.3726 54.8047 63.7772C54.8047 60.5769 57.3333 58.4829 61.7189 57.5742L68.475 56.1519V55.4407C68.475 53.8208 67.1317 52.754 65.1562 52.754C63.0622 52.754 61.3238 53.9788 60.6916 55.9148L55.5949 53.4652C56.8592 49.9094 60.5731 47.6178 65.1562 47.6178ZM63.6943 65.7922C66.381 65.7922 68.475 63.8562 68.475 61.4461V60.4189L62.7856 61.6832C61.5608 61.9598 60.9287 62.7104 60.9287 63.6982C60.9287 65.002 61.9954 65.7922 63.6943 65.7922Z" fill="#1CA8FF" />
            <path d="M41.9045 70.1381C36.0571 70.1381 31.0789 66.8589 29.459 61.8807L34.5952 59.4706C36.0176 62.9079 38.9808 64.8044 41.9045 64.8044C44.9072 64.8044 46.6851 63.4215 46.6851 61.6831C46.6851 60.1422 45.6184 59.3125 43.88 58.7594L37.8745 56.8629C32.8568 55.2826 30.4862 52.4379 30.4862 48.5264C30.4862 43.2717 34.7138 39.7553 41.0748 39.7553C46.7247 39.7553 51.1497 42.8766 52.7301 47.2226L47.5939 49.6327C46.6061 46.8275 44.2356 45.0891 41.0748 45.0891C38.3881 45.0891 36.6102 46.4719 36.6102 48.2894C36.6102 49.6722 37.6375 50.6995 39.5339 51.2921L45.3813 53.1095C50.32 54.6504 52.8091 57.2975 52.8091 61.3275C52.8091 66.6218 48.4631 70.1381 41.9045 70.1381Z" fill="#1CA8FF" />
            <path fillRule="evenodd" clipRule="evenodd" d="M109.225 30.6402L106.835 26.1953C100.799 14.9716 88.8783 7.32677 75.1404 7.32677C61.4025 7.32677 49.4813 14.9716 43.4457 26.1953L41.0555 30.6402L36.0803 29.7929C34.7035 29.5584 33.2885 29.4351 31.843 29.4351C18.1258 29.4351 7.09618 40.4576 7.09618 53.9395C7.09618 67.4688 17.3165 77.7 30.4758 78.406L31.1836 78.4439H110.833V78.2955L117.996 78.4376C118.201 78.4417 118.325 78.4439 118.438 78.4439C132.155 78.4439 143.185 67.4214 143.185 53.9395C143.185 40.4576 132.155 29.4351 118.438 29.4351C116.992 29.4351 115.577 29.5584 114.2 29.7929L109.225 30.6402ZM117.857 85.4605V85.4679H30.0995V85.4198C13.3637 84.522 0.0722656 71.3512 0.0722656 53.9395C0.0722656 36.5278 14.2974 22.4112 31.843 22.4112C33.6892 22.4112 35.4986 22.5688 37.2596 22.8687C44.4797 9.44223 58.7339 0.302856 75.1404 0.302856C91.5469 0.302856 105.801 9.44223 113.021 22.8687C114.782 22.5688 116.592 22.4112 118.438 22.4112C135.983 22.4112 150.208 36.5278 150.208 53.9395C150.208 71.1162 136.365 85.0861 119.146 85.4602C118.911 85.4653 118.675 85.4679 118.438 85.4679C118.244 85.4679 118.05 85.4643 117.857 85.4605Z" fill="#1CA8FF" />
        </svg>

    )
}