import { useIntl } from "react-intl"
import { Contact, DigicelLogo, Footer, Head, HowlyLogo, ShootheLogo, TopBar } from "../../components"
import { Introduction, WhyChoose } from "../../modules"
import styles from './technologies.module.scss';
import parse from "html-react-parser";
import { ReactNodeIcon } from "../../assets/icons/technologies";
import { SettingsIcon, ScaleIcon, DobleArrowIcon, CheckIcon } from "../../assets/icons";
import { KeyFeatures } from "../../modules/Technologies/KeyFeatures/KeyFeatures";
import { DevelopmentProcess } from "../../modules/Technologies/DevelopmentProcess/DevelopmentProcess";
import { Expertise } from "../../modules/Technologies/Expertise/Expertise";
import { TechnologiesClient } from "../../modules/Technologies/Clients/Clients";
import { Suspense, useEffect } from "react";
import ReactGA from 'react-ga';
import { CircularProgress } from "@mui/material";

export const ReactNodePage = () => {
    const { formatMessage } = useIntl();
    const renderLoader = () => <div className="spinner"><CircularProgress color="inherit" /></div>;

    useEffect(() => {
        ReactGA.pageview('/technologies/react-node');
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            {/* Encabezados y meta descriptions */}
            <Head
                title="React Node | Wakapi"
                description="Combine React and Node.js for modern web apps. Enhance performance and scalability for your web development projects. "
                path="/technologies/react-node"
            />

            {/* Barra de navegación */}
            <TopBar />
            <main className={styles.container}>
                <Introduction
                    title={parse(formatMessage({ id: "technologies.ReactNode-title" }))}
                    description={parse(formatMessage({ id: "technologies.ReactNode-description" }))}
                    icon={<div className={styles.icon__reactNode}><ReactNodeIcon size={{ width: "166", height: "105" }} color="#747474"/></div>}
                />
                {/* WhyChoose modulo */}
                <WhyChoose
                    list={
                        [
                            {
                                icon: <DobleArrowIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.ReactNode-why-choose-1-title" }),
                                description: formatMessage({ id: "technologies.ReactNode-why-choose-1-text" }),
                            },
                            {
                                icon: <CheckIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.ReactNode-why-choose-2-title" }),
                                description: formatMessage({ id: "technologies.ReactNode-why-choose-2-text" }),
                            },
                            {
                                icon: <ScaleIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.ReactNode-why-choose-3-title" }),
                                description: formatMessage({ id: "technologies.ReactNode-why-choose-3-text" }),
                            },
                            {
                                icon: <SettingsIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.ReactNode-why-choose-4-title" }),
                                description: formatMessage({ id: "technologies.ReactNode-why-choose-4-text" }),
                            },
                        ]
                    }
                    title={parse(formatMessage({ id: "technologies.ReactNode-why-choose-title" }))}
                />
                <div className={styles.keyFeatures__background}>
                    <div className={styles.keyFeatures__container}>
                        <KeyFeatures
                            list={
                                [
                                    {
                                        title: formatMessage({ id: "technologies.ReactNode-key-aspects-1-title" }),
                                        text: formatMessage({ id: "technologies.ReactNode-key-aspects-1-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.ReactNode-key-aspects-2-title" }),
                                        text: formatMessage({ id: "technologies.ReactNode-key-aspects-2-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.ReactNode-key-aspects-3-title" }),
                                        text: formatMessage({ id: "technologies.ReactNode-key-aspects-3-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.ReactNode-key-aspects-4-title" }),
                                        text: formatMessage({ id: "technologies.ReactNode-key-aspects-4-text" }),
                                    },
                                ]
                            }
                        />
                    </div>
                    <div className={styles.expertise__container}>
                        <Expertise
                            icon={<ReactNodeIcon size={{ width: "198", height: "105" }} color="#747474" />}
                            title={formatMessage({ id: "technologies.ReactNode-expertise-title" })}
                            text={formatMessage({ id: "technologies.ReactNode-expertise-text" })}
                            list={
                                [
                                    {
                                        title: formatMessage({ id: "technologies.ReactNode-expertise-1-title" }),
                                        text: formatMessage({ id: "technologies.ReactNode-expertise-1-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.ReactNode-expertise-2-title" }),
                                        text: formatMessage({ id: "technologies.ReactNode-expertise-2-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.ReactNode-expertise-3-title" }),
                                        text: formatMessage({ id: "technologies.ReactNode-expertise-3-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.ReactNode-expertise-4-title" }),
                                        text: formatMessage({ id: "technologies.ReactNode-expertise-4-text" }),
                                    },
                                ]
                            }
                        />
                    </div>
                </div>
                <div className={styles.process__background}>
                    <div className={styles.process__container}>
                        <DevelopmentProcess
                            title={formatMessage({ id: "technologies.development-process" })}
                            list={
                                [
                                    {
                                        title: formatMessage({ id: "technologies.ReactNode-development-process-1-title" }),
                                        text: formatMessage({ id: "technologies.ReactNode-development-process-1-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.ReactNode-development-process-2-title" }),
                                        text: formatMessage({ id: "technologies.ReactNode-development-process-2-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.ReactNode-development-process-3-title" }),
                                        text: formatMessage({ id: "technologies.ReactNode-development-process-3-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.ReactNode-development-process-4-title" }),
                                        text: formatMessage({ id: "technologies.ReactNode-development-process-4-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.ReactNode-development-process-5-title" }),
                                        text: formatMessage({ id: "technologies.ReactNode-development-process-5-text" }),
                                    },
                                ]
                            }
                        />
                    </div>
                </div>
                <TechnologiesClient
                    title={formatMessage({ id: "technologies.ReactNode-satisfied" })}
                    list={[
                        <HowlyLogo
                            size={{
                                width: '120',
                                height: '78'
                            }}
                        />,
                        <ShootheLogo
                        size={
                            {
                                width: '91',
                                height: '78'
                            }}
                    />,
                    <DigicelLogo
                    size={{
                        width: '99',
                        height: '78'
                    }}
                />,
                    ]}
                />
                 <section className="contact__container">
                    <Suspense fallback={renderLoader()}>
                        <Contact />
                    </Suspense>
                </section>
                <Footer />
            </main>
        </>
    )
}