export const LightSpeedLogo = ({ size: { width = '173', height = '84' } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 173 84" fill="none" xmlns="http://www.w3.org/2000/svg" aria-valuetext="Lightspeed">
            <path d="M17.2613 21.3643L18.9888 24.3529C19.3342 24.9712 19.3342 25.727 18.9888 26.3453L8.69332 44.1054L13.5646 52.4873C14.3247 53.7927 15.7412 54.6171 17.2613 54.6171C18.7815 54.6171 20.1979 53.7927 20.958 52.4873L25.8293 44.1054L24.5511 41.8381L19.0233 51.3537C18.6778 51.972 17.9868 52.3499 17.2959 52.3499C16.5704 52.3499 15.9139 51.972 15.5685 51.3537L11.319 44.1054L20.8889 27.6163L22.6163 30.605C22.9618 31.2233 22.9618 31.9791 22.6163 32.5974L15.9485 44.1054L17.2613 46.3726L24.5165 33.8684L29.2842 42.0786C30.0097 43.3496 30.0097 44.8955 29.2842 46.1665L24.4474 54.5141C23.7219 55.7851 21.4071 58.6363 17.2613 58.6363C13.1155 58.6363 10.8353 55.7851 10.0753 54.5141L5.23847 46.1665C4.51296 44.8955 4.51296 43.3496 5.23847 42.0786L17.2613 21.3643Z" fill="#ED5253" />
            <path d="M39.752 30.2622H43.1032V49.946H39.752V30.2622Z" fill="black" />
            <path d="M47.5943 33.8688C48.6819 33.8688 49.5635 32.9921 49.5635 31.9107C49.5635 30.8293 48.6819 29.9526 47.5943 29.9526C46.5067 29.9526 45.625 30.8293 45.625 31.9107C45.625 32.9921 46.5067 33.8688 47.5943 33.8688Z" fill="black" />
            <path d="M45.9014 36.2734H49.2871V49.9456H45.9014V36.2734Z" fill="black" />
            <path d="M58.1669 35.9302C54.332 35.9302 51.2227 38.6096 51.2227 42.8693C51.2227 47.129 53.9174 49.7397 58.2014 49.7397C59.8943 49.7397 61.6908 50.5985 61.6908 52.4879C61.6908 54.3773 60.1707 55.4422 58.2014 55.4422C56.2322 55.4422 54.6084 54.2742 54.6084 52.4879H51.2227C51.2227 56.1636 54.1938 58.6026 58.2014 58.6026C62.1745 58.6026 65.0766 56.2666 65.0766 52.4879C65.0766 50.7359 64.5238 49.087 62.2436 47.9534C64.4893 46.9229 65.1457 44.5526 65.1457 42.835C65.1457 38.6096 62.0018 35.9302 58.1669 35.9302ZM58.2014 46.9572C56.2322 46.9572 54.6084 45.274 54.6084 42.8693C54.6084 40.499 56.2322 38.7471 58.2014 38.7471C60.1707 38.7471 61.7599 40.5334 61.7599 42.8693C61.7599 45.2396 60.1362 46.9572 58.2014 46.9572Z" fill="black" />
            <path d="M74.9569 35.9295C73.4022 35.9295 71.9166 36.4104 70.6729 37.9563V30.2271H67.2871V49.9108H70.6729V42.9717C70.6729 40.9449 72.0548 39.2617 74.0586 39.2617C75.8551 39.2617 77.168 40.3266 77.168 42.7999V49.9108H80.5537V42.5595C80.5883 38.5403 78.8608 35.9295 74.9569 35.9295Z" fill="black" />
            <path d="M88.8108 47.6096C88.3963 47.6096 88.0508 47.4722 87.8435 47.1973C87.6362 46.9225 87.5325 46.5103 87.5325 45.892V39.1589H90.2619L90.5728 36.239H87.5671V32.4946L84.1468 32.8725V36.2734H81.5557V39.1933H84.1468V45.995C84.1468 47.4035 84.4923 48.4684 85.1487 49.1898C85.8051 49.9112 86.807 50.289 88.0853 50.289C88.6726 50.289 89.2254 50.2203 89.8127 50.0486C90.4001 49.8768 90.9183 49.6363 91.3674 49.3272L90.1928 47.163C89.7436 47.4722 89.26 47.6096 88.8108 47.6096Z" fill="black" />
            <path d="M102.733 42.5945C101.352 41.7357 99.7277 41.6326 98.1385 41.5296C97.2057 41.4609 95.7892 41.2548 95.7892 40.0524C95.7892 39.1936 96.6875 38.7127 98.3113 38.7127C99.6241 38.7127 100.764 39.0219 101.732 39.915L103.632 37.7165C102.042 36.3424 100.419 35.9302 98.2422 35.9302C95.7201 35.9302 92.4035 37.0294 92.4035 40.1898C92.4035 41.4952 93.0944 42.6975 94.2345 43.3846C95.5128 44.1747 97.1712 44.2777 98.5876 44.4495C99.555 44.5526 101.144 44.793 100.868 46.1328C100.695 47.0603 99.555 47.3351 98.7604 47.3694C97.8967 47.4038 97.033 47.3351 96.1693 47.129C95.271 46.8885 94.5455 46.4763 93.7509 45.961L92.0234 48.1252C92.1271 48.1939 92.2307 48.2969 92.2307 48.2969C94.4418 50.1176 97.5166 50.7016 100.315 50.0833C102.423 49.6023 104.219 48.0908 104.219 45.8236C104.288 44.5526 103.874 43.2815 102.733 42.5945Z" fill="black" />
            <path d="M113.892 35.9302C112.338 35.9302 110.507 36.5829 109.436 38.0944L109.332 36.2393H106.05V55.9231L109.436 55.5452V48.3313C110.403 49.8084 112.51 50.2894 113.961 50.2894C118.349 50.2894 120.906 47.0259 120.906 43.0754C120.906 39.0562 118.107 35.9302 113.892 35.9302ZM113.616 47.3694C111.301 47.3694 109.746 45.274 109.746 43.1441C109.746 41.0143 111.198 38.7471 113.616 38.7471C116.069 38.7471 117.485 41.0486 117.485 43.1441C117.52 45.274 115.931 47.3694 113.616 47.3694Z" fill="black" />
            <path d="M125.328 44.3121C125.709 45.9266 127.194 47.3351 129.612 47.3351C130.856 47.3351 132.515 46.7167 133.309 45.9266L135.486 48.0565C134.035 49.5336 131.651 50.255 129.578 50.255C125.294 50.255 122.185 47.4381 122.185 43.0754C122.185 38.9532 125.398 35.9302 129.336 35.9302C133.482 35.9302 136.937 38.7471 136.591 44.3121H125.328ZM133.309 41.7357C132.929 40.1211 131.305 38.7127 129.336 38.7127C127.505 38.7127 125.812 39.9494 125.328 41.7357H133.309Z" fill="black" />
            <path d="M141.013 44.3121C141.393 45.9266 142.879 47.3351 145.297 47.3351C146.541 47.3351 148.199 46.7167 148.994 45.9266L151.17 48.0565C149.719 49.5336 147.335 50.255 145.263 50.255C140.978 50.255 137.869 47.4381 137.869 43.0754C137.869 38.9532 141.082 35.9302 145.021 35.9302C149.166 35.9302 152.621 38.7471 152.276 44.3121H141.013ZM149.028 41.7357C148.648 40.1211 147.024 38.7127 145.055 38.7127C143.224 38.7127 141.531 39.9494 141.048 41.7357H149.028Z" fill="black" />
            <path d="M160.464 50.2895C162.018 50.2895 163.849 49.6368 164.92 48.1253L165.024 49.946H168.306V30.2622H164.92V37.8884C163.953 36.4112 161.811 35.9647 160.36 35.9647C155.972 35.9647 153.45 39.1938 153.45 43.1786C153.45 47.1635 156.249 50.2895 160.464 50.2895ZM160.705 38.8502C163.02 38.8502 164.575 40.9457 164.575 43.0756C164.575 45.2054 163.124 47.4726 160.705 47.4726C158.252 47.4726 156.836 45.171 156.836 43.0756C156.836 40.9457 158.391 38.8502 160.705 38.8502Z" fill="black" />
        </svg>

    )
}