import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./benefits.module.scss";
import Slider from "react-slick";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
export const Benefits = () => {
  const benefitsArray = [
    {
      id: 0,
      bg: "#E5E5E5",
      color: "#1F1D1C",
      title: "aboutUs.benefits-1-title",
      text: "aboutUs.benefits-1-text",
    },
    {
      id: 1,
      bg: "#1F1D1C",
      color: "#FFFFFF",
      title: "aboutUs.benefits-2-title",
      text: "aboutUs.benefits-2-text",
    },
    {
      id: 2,
      bg: "#D2D2D2",
      color: "#1F1D1C",
      title: "aboutUs.benefits-3-title",
      text: "aboutUs.benefits-3-text",
    },
    {
      id: 3,
      bg: "#E5E5E5",
      color: "#1F1D1C",
      title: "aboutUs.benefits-4-title",
      text: "aboutUs.benefits-4-text",
    },
    {
      id: 4,
      bg: "#1F1D1C",
      color: "#FFFFFF",
      title: "aboutUs.benefits-5-title",
      text: "aboutUs.benefits-5-text",
    },
    {
      id: 5,
      bg: "#D2D2D2",
      color: "#1F1D1C",
      title: "aboutUs.benefits-6-title",
      text: "aboutUs.benefits-6-text",
    },
    {
      id: 6,
      bg: "#0020C8",
      color: "#FFFFFF",
      title: "aboutUs.benefits-7-title",
      text: "aboutUs.benefits-7-text",
    },
    {
      id: 7,
      bg: "#E5E5E5",
      color: "#1F1D1C",
      title: "aboutUs.benefits-8-title",
      text: "aboutUs.benefits-8-text",
    },
    {
      id: 8,
      bg: "#1F1D1C",
      color: "#FFFFFF",
      title: "aboutUs.benefits-9-title",
      text: "aboutUs.benefits-9-text",
    },
  ];

  const [width,setWidth] = useState(window.innerWidth);

  useEffect(()=>{
    setWidth(window.innerWidth)
  },[])

  const [selected, setSelected] = useState(0);

  const [containerRef, isVisible] = useIntersectionObserver();

  const whiteDots = () => {
    if (selected === 1 || selected === 4 || selected === 6 || selected === 8){

      return "#FFFFFF";      
    }
     
    return "#1F1D1C";
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    autoplaySpeed: 9000,
    arrows: false,
    pauseOnHover: false,
    pauseOnFocus: false,
    pauseOnDotsHover: false,
    vertical: width <=930 ? false : true,
    customPaging: i => {
      return (
        <div style={{
          backgroundColor: i === selected ? whiteDots() : 'transparent',
          width: 10,height:10,
          borderRadius:10,
          color:'transparent',
          borderWidth:1,
          borderColor: whiteDots(),
          borderStyle: 'solid'
        }}>{i} </div>
      )
    }
  };

  return (
    <div className={styles.benefits__container}  ref={containerRef}>
      <div className={styles.benefits__titleContainer}>
        <h3>
          <FormattedMessage id="aboutUs.benefits-title" />
        </h3>
        <div className={styles.arrow}>
          <svg width="150" height="74" viewBox="0 0 149 74" fill="none">
            <path
              d="M0 35.6182L146.821 35.6182"
              stroke="#1F1D1C"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M114.934 1L147.304 36.2894L114.123 72.4545"
              stroke="#1F1D1C"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
          </svg>
        </div>
      </div>
      <div className={styles.benefit__carousel}>
         <Slider {...settings}  className="benefits-slider" afterChange={(index) => setSelected(index)} >
          {benefitsArray.map((benefit) => {
            return (
              <div>
              <div
                className={styles.benefit__slider}
                key={benefit.id}
                style={{ backgroundColor: benefit.bg }}
              >
                <div
                  className={styles.benefits__text}
                  style={{ color: benefit.color }}
                >
                  <span className={styles.item__title}>
                    <FormattedMessage id={benefit.title} />
                  </span>
                  <br />
                  <br />
                  <FormattedMessage id={benefit.text} />
                </div>
              </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};
export default Benefits;