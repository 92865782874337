export const CompressusLogo = ({ size: { width = '166', height = '96' } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 166 96" fill="none" xmlns="http://www.w3.org/2000/svg" aria-valuetext="Compressus">
            <path d="M38.7033 43.8223C38.3003 44.7637 37.7446 45.5899 37.0321 46.301C36.3211 47.012 35.4733 47.572 34.4887 47.9822C33.5042 48.391 32.4304 48.5968 31.2659 48.5968C30.1014 48.5968 29.0305 48.3924 28.0546 47.9822C27.0772 47.572 26.2337 47.0192 25.5212 46.3225C24.8102 45.6259 24.256 44.8069 23.8616 43.8655C23.4658 42.9241 23.2686 41.9151 23.2686 40.837V40.7938C23.2686 39.7157 23.4701 38.7067 23.8717 37.7653C24.2733 36.8239 24.8303 35.9977 25.5428 35.2866C26.2539 34.5756 27.1017 34.0156 28.0862 33.6054C29.0708 33.1966 30.1446 32.9908 31.3091 32.9908C32.4735 32.9908 33.543 33.1952 34.5204 33.6054C35.4977 34.0156 36.3412 34.5684 37.0537 35.265C37.7648 35.9617 38.3175 36.7807 38.7134 37.7221C39.1078 38.6635 39.3064 39.6725 39.3064 40.7506V40.7938C39.3064 41.8719 39.1049 42.8809 38.7033 43.8223ZM35.836 40.7938C35.836 40.1475 35.7252 39.5358 35.5021 38.9614C35.2789 38.3871 34.9637 37.8833 34.5535 37.4529C34.1433 37.0226 33.6596 36.68 33.0982 36.4295C32.5383 36.1776 31.9266 36.0524 31.2659 36.0524C30.6052 36.0524 29.9762 36.1747 29.4234 36.4194C28.8707 36.6641 28.3957 37.001 28.0013 37.4328C27.6055 37.8646 27.2974 38.3641 27.0743 38.9312C26.8512 39.4998 26.7404 40.1058 26.7404 40.7521V40.7952C26.7404 41.4415 26.8512 42.0533 27.0743 42.6276C27.2974 43.2019 27.6127 43.7057 28.0229 44.1361C28.4331 44.5679 28.9139 44.9091 29.4666 45.1595C30.0194 45.4114 30.634 45.5366 31.3091 45.5366C31.9841 45.5366 32.5815 45.4143 33.1414 45.1696C33.7014 44.9249 34.1792 44.5881 34.5751 44.1562C34.9695 43.7244 35.2789 43.2264 35.5021 42.6578C35.7252 42.0907 35.836 41.4833 35.836 40.837V40.7938Z" fill="#4D75B8" />
            <path d="M54.5469 48.3388V38.4875L50.3006 44.9332H50.2143L46.0112 38.5523V48.3388H42.7351V33.2495H46.3135L50.2805 39.6304L54.246 33.2495H57.8244V48.3388H54.5483H54.5469Z" fill="#4D75B8" />
            <path d="M73.3857 40.815C73.069 41.4757 72.6343 42.0299 72.0816 42.4747C71.5289 42.9209 70.8811 43.2548 70.1413 43.4765C69.4014 43.6996 68.6069 43.8104 67.7591 43.8104H65.2372V48.3373H61.918V33.2495H68.0829C68.9883 33.2495 69.7973 33.3762 70.5083 33.6266C71.2194 33.8785 71.824 34.2341 72.3191 34.6932C72.8143 35.1524 73.1957 35.7023 73.462 36.3428C73.7283 36.9819 73.8607 37.6901 73.8607 38.4659V38.5091C73.8607 39.3857 73.7024 40.1543 73.3871 40.815H73.3857ZM70.4968 38.5307C70.4968 37.7836 70.2564 37.2151 69.7742 36.8279C69.292 36.4392 68.6357 36.2464 67.8023 36.2464H65.2372V40.8596H67.867C68.7004 40.8596 69.3467 40.6409 69.8073 40.2018C70.2665 39.7643 70.4968 39.2216 70.4968 38.5739V38.5307Z" fill="#4D75B8" />
            <path d="M85.9531 48.3388L82.7202 43.511H80.112V48.3388H76.7927V33.2495H83.6903C85.4867 33.2495 86.8844 33.6885 87.8833 34.5637C88.8823 35.4403 89.3818 36.6623 89.3818 38.2284V38.2716C89.3818 39.4936 89.0838 40.4883 88.4879 41.2569C87.892 42.0256 87.1118 42.5898 86.1489 42.9497L89.8352 48.3388H85.9545H85.9531ZM86.0179 38.4011C86.0179 37.6829 85.7876 37.1431 85.3284 36.7847C84.8678 36.4263 84.2287 36.2464 83.4097 36.2464H80.112V40.579H83.4744C84.2935 40.579 84.9225 40.3846 85.3601 39.9974C85.7976 39.6088 86.0179 39.0921 86.0179 38.4458V38.4026V38.4011Z" fill="#4D75B8" />
            <path d="M92.8508 48.3388V33.2495H104.211V36.2032H96.1269V39.2633H103.24V42.217H96.1269V45.3851H104.319V48.3388H92.8508Z" fill="#4D75B8" />
            <path d="M118.268 45.8702C117.996 46.4518 117.619 46.9412 117.132 47.3355C116.647 47.7314 116.067 48.0322 115.395 48.2409C114.721 48.4496 113.975 48.5533 113.156 48.5533C112.006 48.5533 110.879 48.3561 109.772 47.9603C108.665 47.5659 107.659 46.9584 106.754 46.1394L108.716 43.7903C109.405 44.3502 110.113 44.7964 110.839 45.1275C111.564 45.4586 112.359 45.6227 113.221 45.6227C113.911 45.6227 114.446 45.4974 114.828 45.2455C115.208 44.9936 115.399 44.6453 115.399 44.2005V44.1573C115.399 43.9414 115.359 43.7514 115.28 43.5859C115.2 43.4204 115.049 43.2664 114.826 43.1224C114.603 42.9785 114.294 42.8345 113.896 42.692C113.5 42.5481 112.985 42.3984 112.35 42.2401C111.586 42.0529 110.895 41.8442 110.275 41.6154C109.654 41.385 109.129 41.1015 108.697 40.7632C108.265 40.4264 107.93 40.0061 107.692 39.5023C107.455 39 107.335 38.3738 107.335 37.6268V37.5836C107.335 36.8941 107.464 36.2723 107.721 35.7196C107.979 35.1668 108.339 34.6889 108.802 34.2859C109.266 33.8843 109.82 33.5748 110.465 33.3589C111.108 33.143 111.819 33.0351 112.595 33.0351C113.702 33.0351 114.718 33.2006 115.645 33.5302C116.572 33.8613 117.423 34.3348 118.2 34.9524L116.476 37.4526C115.801 36.9934 115.14 36.6307 114.492 36.3644C113.846 36.0981 113.198 35.9657 112.552 35.9657C111.906 35.9657 111.42 36.0924 111.097 36.3428C110.773 36.5947 110.612 36.9071 110.612 37.2799V37.3231C110.612 37.5677 110.659 37.7793 110.753 37.9593C110.846 38.1392 111.016 38.3004 111.261 38.4443C111.505 38.5883 111.841 38.725 112.265 38.8546C112.69 38.9841 113.227 39.1353 113.876 39.3065C114.639 39.5081 115.32 39.7341 115.919 39.9859C116.516 40.2378 117.021 40.5387 117.431 40.8913C117.842 41.244 118.151 41.6571 118.361 42.1307C118.57 42.6042 118.675 43.1728 118.675 43.8335V43.8767C118.675 44.6237 118.54 45.2887 118.268 45.8702Z" fill="#4D75B8" />
            <path d="M132.537 45.8702C132.265 46.4518 131.888 46.9412 131.401 47.3355C130.916 47.7314 130.336 48.0322 129.664 48.2409C128.99 48.4496 128.244 48.5533 127.425 48.5533C126.275 48.5533 125.148 48.3561 124.041 47.9603C122.934 47.5659 121.928 46.9584 121.023 46.1394L122.985 43.7903C123.674 44.3502 124.383 44.7964 125.108 45.1275C125.833 45.4586 126.628 45.6227 127.49 45.6227C128.18 45.6227 128.715 45.4974 129.097 45.2455C129.477 44.9936 129.668 44.6453 129.668 44.2005V44.1573C129.668 43.9414 129.628 43.7514 129.549 43.5859C129.469 43.4204 129.318 43.2664 129.095 43.1224C128.872 42.9785 128.563 42.8345 128.165 42.692C127.769 42.5481 127.254 42.3984 126.619 42.2401C125.855 42.0529 125.164 41.8442 124.544 41.6154C123.923 41.385 123.398 41.1015 122.966 40.7632C122.534 40.4264 122.199 40.0061 121.961 39.5023C121.724 39 121.604 38.3738 121.604 37.6268V37.5836C121.604 36.8941 121.733 36.2723 121.99 35.7196C122.248 35.1668 122.608 34.6889 123.071 34.2859C123.535 33.8843 124.089 33.5748 124.734 33.3589C125.377 33.143 126.088 33.0351 126.864 33.0351C127.971 33.0351 128.987 33.2006 129.914 33.5302C130.841 33.8613 131.692 34.3348 132.469 34.9524L130.745 37.4526C130.07 36.9934 129.409 36.6307 128.761 36.3644C128.115 36.0981 127.467 35.9657 126.821 35.9657C126.175 35.9657 125.689 36.0924 125.366 36.3428C125.042 36.5947 124.881 36.9071 124.881 37.2799V37.3231C124.881 37.5677 124.928 37.7793 125.022 37.9593C125.115 38.1392 125.285 38.3004 125.53 38.4443C125.774 38.5883 126.11 38.725 126.534 38.8546C126.959 38.9841 127.496 39.1353 128.145 39.3065C128.908 39.5081 129.589 39.7341 130.188 39.9859C130.785 40.2378 131.29 40.5387 131.7 40.8913C132.111 41.244 132.42 41.6571 132.63 42.1307C132.839 42.6042 132.944 43.1728 132.944 43.8335V43.8767C132.944 44.6237 132.809 45.2887 132.537 45.8702Z" fill="#4D75B8" />
            <path d="M148.756 44.7604C148.446 45.6082 148.004 46.3164 147.43 46.8836C146.856 47.4521 146.159 47.8753 145.339 48.156C144.52 48.4367 143.607 48.5763 142.601 48.5763C140.574 48.5763 138.979 48.0164 137.815 46.8951C136.65 45.7738 136.069 44.0997 136.069 41.873V33.2495H139.388V41.7852C139.388 43.0216 139.676 43.9515 140.25 44.5762C140.825 45.2009 141.622 45.5132 142.643 45.5132C143.663 45.5132 144.461 45.211 145.035 44.6079C145.609 44.0047 145.897 43.0994 145.897 41.8917V33.2495H149.216V41.7636C149.216 42.9137 149.062 43.9126 148.753 44.7604H148.756Z" fill="#4D75B8" />
            <path d="M163.577 45.8702C163.305 46.4518 162.928 46.9412 162.442 47.3355C161.957 47.7314 161.376 48.0322 160.704 48.2409C160.031 48.4496 159.285 48.5533 158.466 48.5533C157.316 48.5533 156.189 48.3561 155.082 47.9603C153.975 47.5659 152.969 46.9584 152.063 46.1394L154.025 43.7903C154.715 44.3502 155.423 44.7964 156.149 45.1275C156.874 45.4586 157.669 45.6227 158.531 45.6227C159.22 45.6227 159.756 45.4974 160.137 45.2455C160.517 44.9936 160.709 44.6453 160.709 44.2005V44.1573C160.709 43.9414 160.668 43.7514 160.589 43.5859C160.51 43.4204 160.359 43.2664 160.136 43.1224C159.913 42.9785 159.603 42.8345 159.206 42.692C158.81 42.5481 158.295 42.3984 157.66 42.2401C156.896 42.0529 156.205 41.8442 155.584 41.6154C154.964 41.385 154.438 41.1015 154.007 40.7632C153.575 40.4264 153.239 40.0061 153.002 39.5023C152.764 39 152.645 38.3738 152.645 37.6268V37.5836C152.645 36.8941 152.773 36.2723 153.031 35.7196C153.288 35.1668 153.648 34.6889 154.112 34.2859C154.575 33.8843 155.129 33.5748 155.774 33.3589C156.418 33.143 157.129 33.0351 157.905 33.0351C159.011 33.0351 160.028 33.2006 160.955 33.5302C161.882 33.8613 162.732 34.3348 163.51 34.9524L161.785 37.4526C161.11 36.9934 160.449 36.6307 159.802 36.3644C159.155 36.0981 158.508 35.9657 157.861 35.9657C157.215 35.9657 156.73 36.0924 156.406 36.3428C156.082 36.5947 155.921 36.9071 155.921 37.2799V37.3231C155.921 37.5677 155.969 37.7793 156.062 37.9593C156.156 38.1392 156.326 38.3004 156.57 38.4443C156.815 38.5883 157.15 38.725 157.575 38.8546C158 38.9841 158.536 39.1353 159.186 39.3065C159.949 39.5081 160.629 39.7341 161.228 39.9859C161.826 40.2378 162.331 40.5387 162.741 40.8913C163.151 41.244 163.461 41.6571 163.671 42.1307C163.88 42.6042 163.985 43.1728 163.985 43.8335V43.8767C163.985 44.6237 163.849 45.2887 163.577 45.8702Z" fill="#4D75B8" />
            <path d="M34.4083 63.9393V55.9952L30.984 61.1929H30.9149L27.5251 56.0485V63.9393H24.8823V51.772H27.7683L30.9667 56.9179L34.165 51.772H37.051V63.9393H34.4097H34.4083Z" fill="#222121" />
            <path d="M40.353 63.9393V51.772H49.5134V54.1528H42.9958V56.6214H48.7318V59.0021H42.9958V61.5571H49.6012V63.9379H40.3545L40.353 63.9393Z" fill="#222121" />
            <path d="M63.1229 60.2544C62.8105 60.9957 62.3672 61.6391 61.7929 62.1832C61.22 62.7288 60.5348 63.1563 59.7417 63.47C58.9472 63.7824 58.0763 63.9393 57.1263 63.9393H52.3806V51.772H57.1263C58.0763 51.772 58.9486 51.926 59.7417 52.2326C60.5348 52.5392 61.2186 52.9653 61.7929 53.5108C62.3658 54.0563 62.8091 54.6954 63.1229 55.431C63.4352 56.1665 63.5921 56.9639 63.5921 57.8204V57.8549C63.5921 58.7128 63.4352 59.5117 63.1229 60.2544ZM60.7939 57.8549C60.7939 57.3223 60.7047 56.8301 60.5248 56.3781C60.3448 55.9261 60.0958 55.5375 59.7777 55.2136C59.4582 54.8898 59.0738 54.6379 58.6219 54.4579C58.1699 54.278 57.6719 54.1888 57.1263 54.1888H55.0579V61.524H57.1263C57.6704 61.524 58.1699 61.4376 58.6219 61.2634C59.0738 61.0893 59.4596 60.8403 59.7777 60.5164C60.0958 60.1925 60.3448 59.8096 60.5248 59.3692C60.7047 58.9287 60.7939 58.4364 60.7939 57.8924V57.8578V57.8549Z" fill="#222121" />
            <path d="M90.1608 63.0225C89.8239 63.2888 89.4569 63.5176 89.0567 63.7091C88.6566 63.9005 88.219 64.0459 87.744 64.1438C87.269 64.2416 86.7364 64.292 86.1448 64.292C85.2524 64.292 84.4233 64.1294 83.659 63.8055C82.8946 63.4816 82.234 63.0383 81.6769 62.4755C81.1213 61.9141 80.6866 61.2506 80.3728 60.4848C80.059 59.719 79.9036 58.9029 79.9036 58.0335V57.9989C79.9036 57.1295 80.0605 56.3163 80.3728 55.5563C80.6852 54.7977 81.1199 54.1313 81.6769 53.5569C82.2325 52.9826 82.899 52.5321 83.6762 52.201C84.4521 51.8714 85.31 51.7059 86.2485 51.7059C86.8156 51.7059 87.3352 51.7519 87.8045 51.8455C88.2737 51.9391 88.6998 52.0657 89.0827 52.2284C89.4655 52.391 89.8182 52.5882 90.1435 52.82C90.4674 53.0517 90.7696 53.3065 91.0474 53.5843L89.3446 55.5491C88.8696 55.1201 88.386 54.7848 87.8937 54.5415C87.4014 54.2982 86.8472 54.1759 86.2341 54.1759C85.7245 54.1759 85.2524 54.2752 84.8177 54.471C84.383 54.6682 84.0087 54.9402 83.6964 55.2885C83.384 55.6369 83.1408 56.0385 82.9666 56.4962C82.7924 56.9539 82.7061 57.4433 82.7061 57.9644V57.9989C82.7061 58.52 82.7924 59.0137 82.9666 59.4758C83.1408 59.9393 83.3812 60.3452 83.6878 60.6921C83.9943 61.0404 84.3657 61.3153 84.8004 61.5183C85.2351 61.7212 85.713 61.822 86.2341 61.822C86.9293 61.822 87.518 61.6939 87.9988 61.4391C88.4795 61.1844 88.9574 60.8375 89.4324 60.3956L91.1352 62.1171C90.8229 62.4539 90.4976 62.7547 90.1622 63.021L90.1608 63.0225Z" fill="#222121" />
            <path d="M102.884 61.328C102.629 61.9138 102.275 62.4263 101.823 62.8667C101.371 63.3072 100.838 63.6555 100.224 63.9103C99.6091 64.1651 98.9369 64.2932 98.2071 64.2932C97.4773 64.2932 96.8224 64.1679 96.2078 63.9189C95.5931 63.6699 95.0634 63.3273 94.6172 62.8941C94.171 62.4594 93.8198 61.9498 93.565 61.364C93.3102 60.7796 93.1821 60.1506 93.1821 59.4784V59.4438C93.1821 58.7716 93.3088 58.1426 93.565 57.5582C93.8198 56.9738 94.1739 56.4599 94.6258 56.0195C95.0778 55.579 95.6104 55.2321 96.225 54.9759C96.8397 54.7211 97.5119 54.593 98.2416 54.593C98.9714 54.593 99.6263 54.7182 100.241 54.9673C100.856 55.2163 101.385 55.5589 101.832 55.9921C102.278 56.4268 102.628 56.9364 102.884 57.5222C103.138 58.108 103.267 58.7356 103.267 59.4078V59.4424C103.267 60.1146 103.138 60.7436 102.884 61.328ZM100.658 59.4424C100.658 59.094 100.601 58.7673 100.484 58.4607C100.368 58.1541 100.201 57.8821 99.9804 57.6431C99.7602 57.4056 99.4997 57.2171 99.1988 57.0789C98.898 56.9392 98.5669 56.8702 98.2085 56.8702C97.8256 56.8702 97.4874 56.9364 97.1923 57.0702C96.8972 57.2041 96.6453 57.3855 96.4366 57.6172C96.2279 57.849 96.0681 58.1181 95.9587 58.4262C95.8479 58.7327 95.7932 59.0609 95.7932 59.4078V59.4424C95.7932 59.7907 95.8508 60.1175 95.9674 60.424C96.0825 60.7306 96.2509 61.0041 96.4712 61.2416C96.6914 61.4791 96.949 61.6677 97.2441 61.8059C97.5392 61.9441 97.8731 62.0146 98.2431 62.0146C98.613 62.0146 98.9642 61.9484 99.2593 61.8145C99.5544 61.6821 99.8063 61.4993 100.015 61.2675C100.224 61.0358 100.382 60.7666 100.493 60.4586C100.602 60.152 100.658 59.8238 100.658 59.4769V59.4424Z" fill="#222121" />
            <path d="M111.713 64.083V58.8983C111.713 58.2736 111.573 57.8029 111.296 57.4848C111.018 57.1667 110.623 57.0083 110.114 57.0083C109.604 57.0083 109.201 57.1681 108.906 57.4848C108.611 57.8029 108.463 58.2736 108.463 58.8983V64.083H105.82V54.7657H108.463V56.0972C108.614 55.8986 108.778 55.7086 108.958 55.5286C109.138 55.3473 109.338 55.1875 109.558 55.0479C109.778 54.9082 110.022 54.7974 110.288 54.7154C110.554 54.6333 110.855 54.593 111.192 54.593C112.2 54.593 112.98 54.8996 113.53 55.5142C114.079 56.1289 114.356 56.9738 114.356 58.0519V64.083H111.713Z" fill="#222121" />
            <path d="M123.185 64.083V58.8983C123.185 58.2736 123.045 57.8029 122.767 57.4848C122.49 57.1667 122.095 57.0083 121.586 57.0083C121.076 57.0083 120.673 57.1681 120.378 57.4848C120.083 57.8029 119.935 58.2736 119.935 58.8983V64.083H117.292V54.7657H119.935V56.0972C120.086 55.8986 120.25 55.7086 120.43 55.5286C120.61 55.3473 120.81 55.1875 121.03 55.0479C121.25 54.9082 121.494 54.7974 121.76 54.7154C122.026 54.6333 122.327 54.593 122.664 54.593C123.671 54.593 124.452 54.8996 125.001 55.5142C125.551 56.1289 125.828 56.9738 125.828 58.0519V64.083H123.185Z" fill="#222121" />
            <path d="M137.447 60.0151C137.441 60.1087 137.432 60.2181 137.421 60.3448H130.938C131.064 60.9479 131.329 61.4027 131.728 61.7093C132.128 62.0159 132.623 62.1699 133.215 62.1699C133.655 62.1699 134.05 62.0951 134.397 61.9439C134.744 61.7942 135.103 61.5495 135.475 61.2142L136.988 62.5528C136.547 63.0983 136.015 63.523 135.388 63.831C134.762 64.139 134.027 64.2916 133.18 64.2916C132.485 64.2916 131.839 64.175 131.243 63.9433C130.646 63.7115 130.13 63.3819 129.696 62.953C129.261 62.524 128.923 62.0173 128.679 61.4315C128.436 60.8471 128.314 60.1951 128.314 59.4768V59.4423C128.314 58.7816 128.427 58.1554 128.652 57.5653C128.878 56.9737 129.193 56.4584 129.599 56.0179C130.005 55.5775 130.489 55.2306 131.05 54.9743C131.611 54.7196 132.235 54.5915 132.918 54.5915C133.694 54.5915 134.366 54.7311 134.935 55.0089C135.502 55.2867 135.974 55.6609 136.351 56.1302C136.728 56.5994 137.006 57.1378 137.186 57.7466C137.366 58.3555 137.455 58.9888 137.455 59.6495V59.6841C137.455 59.8122 137.453 59.9216 137.447 60.0137V60.0151ZM134.257 57.2601C133.92 56.8945 133.475 56.7131 132.918 56.7131C132.361 56.7131 131.914 56.8931 131.571 57.2515C131.229 57.6113 131.005 58.0921 130.902 58.6938H134.882C134.801 58.1022 134.592 57.6243 134.256 57.2601H134.257Z" fill="#222121" />
            <path d="M146.721 63.8137C146.141 64.1318 145.388 64.2916 144.461 64.2916C143.766 64.2916 143.119 64.1635 142.523 63.9087C141.926 63.654 141.414 63.3085 140.985 62.8752C140.556 62.4405 140.22 61.931 139.977 61.3451C139.734 60.7607 139.612 60.1375 139.612 59.4768V59.4423C139.612 58.7816 139.732 58.1554 139.977 57.5653C140.22 56.9737 140.557 56.4584 140.985 56.0179C141.414 55.5775 141.926 55.2306 142.523 54.9743C143.121 54.7196 143.777 54.5915 144.497 54.5915C145.378 54.5915 146.108 54.7311 146.688 55.0089C147.266 55.2867 147.777 55.6696 148.218 56.1561L146.601 57.8949C146.3 57.5825 145.99 57.3335 145.671 57.1478C145.353 56.9622 144.956 56.87 144.481 56.87C144.144 56.87 143.841 56.9363 143.568 57.0701C143.296 57.204 143.059 57.3853 142.856 57.6171C142.653 57.8488 142.496 58.118 142.387 58.426C142.276 58.7326 142.221 59.0608 142.221 59.4077V59.4423C142.221 59.8021 142.276 60.1375 142.387 60.4498C142.496 60.7622 142.656 61.0357 142.865 61.2674C143.073 61.4992 143.322 61.682 143.612 61.8144C143.901 61.9483 144.226 62.0145 144.585 62.0145C145.025 62.0145 145.411 61.9252 145.741 61.7453C146.07 61.5654 146.398 61.325 146.722 61.0242L148.27 62.5888C147.818 63.0868 147.302 63.4956 146.722 63.8137H146.721Z" fill="#222121" />
            <path d="M154.943 64.0919C154.606 64.1897 154.219 64.2401 153.778 64.2401C153.372 64.2401 153.005 64.1969 152.674 64.1091C152.344 64.0228 152.059 63.8688 151.822 63.6485C151.584 63.4283 151.399 63.1419 151.266 62.7878C151.132 62.4337 151.066 61.986 151.066 61.4405V57.0258H149.954V54.766H151.066V52.3852H153.709V54.766H155.9V57.0258H153.709V61.0058C153.709 61.6089 153.993 61.9097 154.561 61.9097C155.025 61.9097 155.459 61.8003 155.865 61.5801V63.7004C155.587 63.863 155.279 63.9926 154.944 64.0919H154.943Z" fill="#222121" />
            <path d="M75.6817 64.0832L73.8047 61.1799L71.9277 64.0832H69.1641L72.4315 59.3548L69.285 54.7659H72.1177L73.8565 57.4778L75.6126 54.7659H78.3763L75.2297 59.3029L78.5144 64.0832H75.6817Z" fill="#4F75B6" />
            <path d="M21.0909 50.0634C20.4618 50.5629 19.7738 50.9904 19.0253 51.3474C18.2768 51.7058 17.4592 51.9764 16.5697 52.1606C15.6801 52.3449 14.6841 52.437 13.5786 52.437C11.9089 52.437 10.3587 52.1333 8.92788 51.5259C7.49712 50.9199 6.26067 50.0893 5.22142 49.0386C4.18073 47.9878 3.36746 46.7456 2.78307 45.3148C2.19723 43.8841 1.90503 42.3554 1.90503 40.7303V40.6656C1.90503 39.0405 2.19723 37.5161 2.78307 36.0969C3.3689 34.6776 4.18073 33.4311 5.22142 32.3573C6.26211 31.2835 7.50863 30.4386 8.96099 29.8211C10.4134 29.2036 12.0183 28.8941 13.7744 28.8941C14.8366 28.8941 15.8068 28.9805 16.6848 29.1546C17.5629 29.3288 18.3589 29.5663 19.0742 29.87C19.7896 30.1737 20.4503 30.5422 21.0577 30.9755C21.6637 31.4087 22.228 31.8866 22.749 32.4063L19.5622 36.0811C18.6727 35.2793 17.7687 34.6503 16.8475 34.1954C15.9263 33.7406 14.8913 33.5132 13.7413 33.5132C12.7869 33.5132 11.9046 33.6974 11.0913 34.0659C10.278 34.4344 9.5785 34.9439 8.9941 35.5945C8.40826 36.2451 7.95341 36.998 7.6281 37.8544C7.3028 38.7108 7.14014 39.6263 7.14014 40.6022V40.667C7.14014 41.6429 7.3028 42.5641 7.6281 43.4306C7.95341 44.2986 8.4025 45.0572 8.97826 45.7063C9.55259 46.357 10.2464 46.8723 11.0596 47.2508C11.8729 47.6308 12.7668 47.8194 13.7427 47.8194C15.0439 47.8194 16.1436 47.5805 17.0432 47.104C17.9429 46.6276 18.8367 45.977 19.7263 45.1522L22.9131 48.3721C22.3273 49.0011 21.7213 49.564 21.0923 50.0634H21.0909Z" fill="#4F75B6" />
            <path d="M71.6514 67.1059L69.7744 64.2026L67.8974 67.1059H65.1338L68.4012 62.3775L65.2547 57.7886H68.0874L69.8262 60.5005L71.5823 57.7886H74.346L71.1994 62.3256L74.4842 67.1059H71.6514Z" fill="#222121" />
        </svg>


    )
}