export const EarnedCardLogo = ({ size: { width = '125', height = '96' } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 125 96" fill="none" xmlns="http://www.w3.org/2000/svg" aria-valuetext="Earned Card">
            <path d="M25.297 54.2563C26.0676 53.5067 26.9806 52.9281 28.036 52.5096C29.0877 52.0947 30.2308 51.8873 31.4615 51.8873C32.6922 51.8873 33.7659 52.102 34.8103 52.5278C35.8548 52.9535 36.7897 53.6049 37.626 54.4747L39.6601 52.5096C38.6595 51.3887 37.4434 50.5408 36.0155 49.9695C34.5876 49.3982 33.0282 49.1107 31.3373 49.1107C29.6465 49.1107 28.1309 49.3873 26.7249 49.9368C25.3153 50.4863 24.0919 51.2687 23.0474 52.273C22.003 53.2811 21.1922 54.4601 20.6079 55.8102C20.0236 57.1603 19.7314 58.645 19.7314 60.2681C19.7314 61.8911 20.0236 63.3758 20.6079 64.7259C21.1922 66.076 21.9993 67.2551 23.0328 68.2631C24.0663 69.2711 25.2861 70.0499 26.6921 70.5994C28.0981 71.1489 29.6392 71.4254 31.3081 71.4254C32.9771 71.4254 34.5876 71.1343 36.0155 70.5521C37.4434 69.9698 38.6595 69.1183 39.6601 67.9974L37.626 66.0323C36.7897 66.9275 35.8548 67.5862 34.8103 68.012C33.7659 68.4378 32.652 68.6525 31.4615 68.6525C30.271 68.6525 29.0877 68.445 28.036 68.0302C26.9842 67.6153 26.0712 67.0331 25.297 66.2834C24.5265 65.5338 23.9275 64.6459 23.5003 63.6196C23.073 62.5898 22.8575 61.4726 22.8575 60.2681C22.8575 59.0635 23.0693 57.9463 23.5003 56.9165C23.9275 55.8866 24.5265 54.9987 25.297 54.2527V54.2563Z" fill="#181818" />
            <path d="M50.291 49.3618L40.3723 71.1816H43.5933L46 65.7267H57.6424L60.049 71.1816H63.3321L53.3842 49.3618H50.2874H50.291ZM47.1029 63.2339L51.8248 52.5278L56.5468 63.2339H47.1029Z" fill="#181818" />
            <path d="M82.2637 71.1816L77.067 63.8744C77.2715 63.8016 77.4724 63.7215 77.6659 63.6378C79.0208 63.0338 80.0653 62.1677 80.7957 61.0359C81.526 59.9042 81.8912 58.5468 81.8912 56.9674C81.8912 55.3881 81.526 54.0271 80.7957 52.8844C80.0653 51.7417 79.0208 50.8684 77.6659 50.2643C76.3111 49.6602 74.6713 49.3618 72.7541 49.3618H64.2122V71.1816H67.3419V64.5403H72.7541C73.2361 64.5403 73.6999 64.5221 74.1455 64.4821L78.8565 71.178H82.2674L82.2637 71.1816ZM72.6591 61.8947H67.3419V52.0765H72.6591C74.6604 52.0765 76.1796 52.5023 77.2094 53.3538C78.2429 54.2054 78.7579 55.4099 78.7579 56.9711C78.7579 58.5322 78.2429 59.7404 77.2094 60.6029C76.1759 61.4653 74.6604 61.8984 72.6591 61.8984V61.8947Z" fill="#181818" />
            <path d="M103.883 46.5218V24.702H112.232C114.346 24.702 116.209 25.1605 117.823 26.0739C119.437 26.9873 120.69 28.2683 121.588 29.9095C122.483 31.5507 122.932 33.4539 122.932 35.6155C122.932 37.7772 122.483 39.6804 121.588 41.3216C120.69 42.9628 119.437 44.2401 117.823 45.1572C116.209 46.0706 114.346 46.5291 112.232 46.5291H103.883V46.5218ZM106.714 43.8107H112.064C113.707 43.8107 115.124 43.4686 116.322 42.7809C117.52 42.0931 118.451 41.1324 119.108 39.8987C119.769 38.6614 120.098 37.2349 120.098 35.6119C120.098 33.9889 119.769 32.5369 119.108 31.3105C118.447 30.0842 117.52 29.1271 116.322 28.443C115.124 27.7588 113.703 27.4131 112.064 27.4131H106.714V43.8107Z" fill="#319F4D" />
            <path d="M85.2583 71.3089V49.4891H93.6067C95.7211 49.4891 97.5836 49.9476 99.1978 50.861C100.812 51.7744 102.065 53.0554 102.963 54.6966C103.858 56.3378 104.307 58.2411 104.307 60.4027C104.307 62.5643 103.858 64.4675 102.963 66.1087C102.065 67.7499 100.812 69.0272 99.1978 69.9443C97.5836 70.8577 95.7211 71.3162 93.6067 71.3162H85.2583V71.3089ZM88.0886 68.5978H93.4387C95.082 68.5978 96.499 68.2558 97.6968 67.568C98.8947 66.8802 99.8259 65.9195 100.483 64.6858C101.144 63.4486 101.473 62.022 101.473 60.399C101.473 58.776 101.144 57.324 100.483 56.0976C99.8223 54.8713 98.8947 53.9142 97.6968 53.2301C96.499 52.5459 95.0784 52.2002 93.4387 52.2002H88.0886V68.5978Z" fill="#181818" />
            <path d="M32.1299 24.5746L22.2112 46.3944H25.4322L27.8388 40.9394H39.4812L41.8879 46.3944H45.171L35.2267 24.5746H32.1299ZM28.9381 38.4467L33.66 27.7405L38.382 38.4467H28.9381Z" fill="#319F4D" />
            <path d="M59.4062 38.8506C60.761 38.2465 61.8055 37.3804 62.5359 36.2487C63.2663 35.1169 63.6315 33.7596 63.6315 32.1802C63.6315 30.6008 63.2663 29.2398 62.5359 28.0972C61.8055 26.9545 60.761 26.0811 59.4062 25.477C58.0513 24.873 56.4116 24.5746 54.4943 24.5746H45.9524V46.3944H49.0821V39.7531H54.4943C54.9764 39.7531 55.4402 39.7349 55.8857 39.6949L60.5967 46.3907H64.0076L58.8109 39.0835C59.0154 39.0107 59.2163 38.9307 59.4098 38.847L59.4062 38.8506ZM54.403 37.1075H49.0858V27.2857H54.403C56.4043 27.2857 57.9235 27.7114 58.9533 28.563C59.9868 29.4145 60.5018 30.619 60.5018 32.1802C60.5018 33.7414 59.9868 34.9495 58.9533 35.812C57.9198 36.6744 56.4043 37.1075 54.403 37.1075Z" fill="#319F4D" />
            <path d="M81.9314 40.8339L68.7917 24.5746H66.228V46.3944H69.3541V30.135L82.4938 46.3944H85.0611V24.5746H81.9314V40.8339Z" fill="#319F4D" />
            <path d="M89.794 43.6833V36.6381H100.775V33.9888H89.794V27.2857H102.119V24.5746H86.668V46.3944H102.558V43.6833H89.794Z" fill="#319F4D" />
            <path d="M5.13829 36.6998H20.5714V34.0506H5.19672C5.90154 30.2368 9.25403 27.3474 13.2858 27.3474H20.3048V24.6363H12.8768C6.82914 24.6363 1.92822 29.5236 1.92822 35.5462C1.92822 41.5725 6.82914 46.4562 12.8768 46.4562H20.7431V43.7451H13.2858C9.13352 43.7451 5.70434 40.681 5.14194 36.6998H5.13829Z" fill="#319F4D" />
        </svg>


    )
}