import logowakapi from './../../assets/images/logos/wakapi-logo-sm.svg';
import styles from './landing.module.scss';
import { Link } from "react-router-dom";
import { Button,Footer, Head, PreloadImage, ContactSmall, SubwayLogo2, DollarGeneralLogo2, AmazonLogo2, ThomsonReutersLogo2, BudderflyLogo2 } from "../../components";
import { useEffect, useState } from 'react';

export const LandingOutsourcingServices = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            <Head
                title="Wakapi: software outsourcing services in Latin America"
                description="Looking for software outsourcing? Wakapi is the top choice in Latin America, providing expert development outsourcing services at affordable prices since 2006. "
                path="/software-outsourcing-services-in-latin-america"
                haveCarousel={false}
            />
            <PreloadImage imagePath={`${process.env.PUBLIC_URL}/images/landing-software-outsourcing-services-in-latin-america.webp`} />
            <div className={styles.background}>
                <div className={styles.img}>
                    <img src={`${process.env.PUBLIC_URL}/images/landing-software-outsourcing-services-in-latin-america.webp`} alt="Software outsourcing services in latin america" fetchpriority="high" />
                </div>
                <div className={styles.img__gradient} />
                <div className={styles.container}>
                    <div className={styles.topBar}>
                        <Link to="/en/" style={{ textDecoration: "none" }}>
                            <div className={styles.logo}>
                                <img src={logowakapi} alt="logo wakapi" width={153} height={48} />
                            </div>
                        </Link>
                        <Link to="/en/clients">
                            <button className={styles.button} aria-label="Success stories">Success stories</button>
                        </Link>
                    </div>
                    <div className={styles.data}>
                        <h1>Software outsourcing done right!</h1>
                        <br/>
                        <h2>Get the best development team in no time!</h2><br/>
                        <p>Wakapi will staff the tech team of your dreams in a month or less, choosing the best nearshore coders and the perfect tech stack. Choose us to outsource your company's software development and overcome every technological capacity challenge.</p>
                        <br/>
                        {windowWidth > 930 && (<br/>)}                        
                        <h2>Maximize your budget with risk-free software solutions!</h2><br/>
                        <p>Enhance your company’s technological potential with cutting-edge code and competitive rates. Our top-notch experts deliver software solutions to revolutionize your industry and increase your profitability while saving costs.</p>
                        <br/>
                        {windowWidth > 930 && (<br/>)}       
                        <h2>Cultural alignment and top Latin American engineers in your time zone!</h2><br/>
                        <p>Benefit from overlapping business hours and experts who will understand your goals and make them their own. <br />We have ready-to-go teams of engineers in South America's top cities. </p>
                        <br/>
                        {windowWidth > 930 && (<br/>)}       
                        <h2 className={styles.lastTitle}>Gain flexibility and adapt quickly! </h2><br/>
                        <p>Your in-house teams can continue with their day-to-day business while our coding experts help you hit the market at the right time. We provide software teams you can scale up or down as needed. </p>
                        {windowWidth > 426 && (<br/>)}
                        <Link to='https://meetings.hubspot.com/wakapi/meeting-with-wakapi?utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz--V7abN8cIRcLe7vAfQKMUdfarx3OhKkhpZ8SBYZ4Ks-gE-sO_ubxEZGu4v6ksDbVsDSsc2' target="__blank">
                            <Button primary>Schedule a meeting</Button>
                        </Link>
                    </div>
                    <div className={styles.logos}>
                        <SubwayLogo2 size={{width:150, height:90}}/>
                        <DollarGeneralLogo2  size={{width:208, height:90}}/>
                        <div className={styles.onlyDesktop}>
                            <AmazonLogo2 size={{width:156, height:90}} color={ windowWidth > 930 ? '#221F1F' : 'white'}/> 
                        </div>
                        <ThomsonReutersLogo2  size={{width: 259, height:90}} color={ windowWidth > 930 ? 'black' : 'white'}/>
                        <div className={styles.onlyDesktop}>
                            <BudderflyLogo2 size={{width: 186, height:90}} color={'white'}/>
                        </div>
                    </div>
                </div>
                <ContactSmall/>
            </div>
            <Footer />
        </>
    )
}

                        