import { useIntl } from "react-intl";
import styles from './keyFeatures.module.scss';

export const KeyFeatures = ({list, allwidth}) =>{
    const { formatMessage } = useIntl();
    return(
        
        <div className={allwidth ? styles.keyFeatures__containerAllWidth :  styles.keyFeatures__container}>
            <h2>{formatMessage({ id: "technologies.key-features-title" })}</h2>
            <div className={ styles.keyFeatures__list }>
                {list.map((item,index)=>{
                    return(
                        <div key={index} className={styles.keyFeatures__item}>
                            <h4>{item.title}</h4>
                            <p>{item.text}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}