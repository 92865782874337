import React from 'react'
import styles from './footer.module.scss';
import { Link } from 'react-router-dom';
export const Footer = () => {
  const lang = localStorage.getItem('lang')
  return (
    <footer>
        <div className={styles.container__row}>
            <Link to={`/${lang}/privacy-policy`}>
                Privacy Policy
            </Link>
            <Link to={`/${lang}/cookie-policy`}>
                Cookie Policy
            </Link>
            <Link to={`/${lang}/disclaimer`}>
                 Disclaimer
            </Link>
            <a href={"mailto:hello@wakapi.com"}>Client Support </a>
        </div>
    </footer>
  )
}
export default Footer;