import React from "react";
import styles from "./pinPoint-infographic.module.scss";
import img1 from "./../../../../assets/images/clients/slider/pinPoint/staff-augmentation-success-pinpoint1.webp";
import img3 from "./../../../../assets/images/clients/slider/pinPoint/staff-augmentation-success-pinpoint3.webp";
import img2 from "./../../../../assets/images/clients/slider/pinPoint/staff-augmentation-success-pinpoint2.webp";
import screen1 from "./../../../../assets/images/clients/slider/pinPoint/screen-augmentation-success-pinpoint1.webp";
import screen2 from "./../../../../assets/images/clients/slider/pinPoint/screen-augmentation-success-pinpoint2.webp";
import screen3 from "./../../../../assets/images/clients/slider/pinPoint/screen-augmentation-success-pinpoint3.webp";
import screen4 from "./../../../../assets/images/clients/slider/pinPoint/screen-augmentation-success-pinpoint4.webp";
import { FormattedMessage } from "react-intl";
import { Inforgraphic } from "../Infographic";
import { PreloadImage } from "../../../../components";
export const PinPointInfographic = () => {

  return (
    <Inforgraphic height={550}>
      <PreloadImage imagePath={screen1} />
      <PreloadImage imagePath={screen2} />
      <PreloadImage imagePath={screen3} />
      <PreloadImage imagePath={img1} />
      <div className={styles.block1}>
        <div className={styles.title__container}>
          <FormattedMessage id="clients.infographic-pinPoint-title" />
          <svg
            width="211"
            height="73"
            viewBox="0 0 211 73"
            fill="none"
            className={styles.arrowSvg__desktop}
          >
            <path
              d="M0.818359 37.345H209.818M173.447 0.539062L209.818 36.5391L173.447 72.5391"
              stroke="black"
            />
          </svg>
          <svg
            width="49"
            height="141"
            viewBox="0 0 49 141"
            fill="none"
            className={styles.arrowSvg__mobile}
          >
            <path
              d="M24.0478 0.539061L24.0477 139.539M48.5264 115.349L24.5838 139.539L0.641194 115.349"
              stroke="black"
            />
          </svg>
        </div>
        <div className={styles.text1}>
          <img src={screen1} alt="Staff Augmentation in Latam Success Story PinPoint 4" className={styles.img} width={364} height={203} loading="lazy" />
          <p>
            <FormattedMessage id="clients.infographic-pinPoint-1" />
          </p>
        </div>
      </div>
      <div className={styles.block2}>
        <p>
          <FormattedMessage id="clients.infographic-pinPoint-2" />
        </p>
        <div className={styles.text3}>
          <img src={screen2} alt="Staff Augmentation in Latam Success Story PinPoint 5" className={styles.img} width={401} height={219} loading="lazy" />
          <p>
            <FormattedMessage id="clients.infographic-pinPoint-3" />
          </p>
        </div>
        <div className={styles.text4}>
          <img src={screen3} alt="Staff Augmentation in Latam Success Story PinPoint 6" className={styles.img} width={318} height={227} loading="lazy" />
          <p>
            <FormattedMessage id="clients.infographic-pinPoint-4" />
          </p>
        </div>
      </div>
      <div className={styles.block3}>
        <div className={styles.text3}>
          <img src={screen2} alt="Staff Augmentation in Latam Success Story PinPoint 5" className={styles.img} width={401} height={219} loading="lazy" />
          <p>
            <FormattedMessage id="clients.infographic-pinPoint-3" />
          </p>
        </div>
      </div>
      <img src={img1} alt="Staff Augmentation in Latam Success Story PinPoint 1" className={styles.img1} width={454} height={552} loading="lazy" />
      <div className={styles.block5}>
        <div className={styles.block4}>
          <FormattedMessage id="clients.infographic-pinPoint-5" />
          <img src={screen4} alt="Staff Augmentation in Latam Success Story PinPoint 6" width={342} height={218} loading="lazy" />
        </div>
        <div className={styles.text5}>
          <img src={img2} alt="Staff Augmentation in Latam Success Story PinPoint 2" width={449} height={300} loading="lazy" />
          <p><FormattedMessage id="clients.infographic-pinPoint-6" /></p>

        </div>
        <div className={styles.text6}>
          <FormattedMessage id="clients.infographic-pinPoint-7" />
        </div>
      </div>
      <div className={styles.block6}>
        <img src={img3} alt="Staff Augmentation in Latam Success Story PinPoint 3" width={681} height={552} loading="lazy" />
        <div className={styles.text6}>
          <FormattedMessage id="clients.infographic-pinPoint-7" />
        </div>
      </div>
    </Inforgraphic>
  );
};
export default PinPointInfographic;
