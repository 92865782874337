
import styles from './presentation.module.scss';
import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { BannerImageAnimated, BannerTextAnimated, PreloadImage, SliderComponent, Button } from '../../components';

//Imagenes banner
import banner2Background from '../../assets/images/bannerPresentacion/banner_software_development_outsourcing_wakapi_background.webp'
import banner2Front from '../../assets/images/bannerPresentacion/banner_software_development_outsourcing_wakapi.webp'
import banner3Background from '../../assets/images/bannerPresentacion/banner_software-outsourcing_latin_america_wakapi_background.webp'
import banner3Front from '../../assets/images/bannerPresentacion/banner_software-outsourcing_latin_america_wakapi.webp'
import coheteAnimation from "../../assets/animation/banner_software_outsourcing_services_latin_america_wakapi.mp4";
import cohetePoster from '../../assets/animation/banner_software_outsourcing_services_latin_america_wakapi.webp';


export const Presentacion = () => {
  const { formatMessage } = useIntl();
  const [isLoad, setIsLoad] = useState(false);
  useEffect(() => {
    setIsLoad(true);
  }, [])

  const [isVisible, setIsVisible] = useState(0);
  const change = (index) => {
    setIsVisible(index)
  }

  return (
    <section className={styles.background__white}>
      <div className={styles.presentacion__container}>

        {/* Precarga de primer imagen */}
        <PreloadImage imagePath={`${process.env.PUBLIC_URL}/images/banner_development_outsourcing_background.webp`} />
        <PreloadImage imagePath={`${process.env.PUBLIC_URL}/images/banner_development_outsourcing.webp`} />


      {/* Slider */}
        <SliderComponent afterChange={(index) => change(index)} className='presentation-slider' autoplay={isLoad}>

          {/* Banner Gabi y Fer */}
          <div className={styles.banner} id={0}>
            <BannerImageAnimated
              imgFront={`${process.env.PUBLIC_URL}/images/banner_development_outsourcing.webp`}
              altFront='Development Outsourcing Wakapi'
              frontStatic={true}
              isVisible={0 === isVisible}
              highPriority
            />
            <div className={styles.banner__gradient}>
              <BannerTextAnimated
                isPrincipal
                isVisible={0 === isVisible}
                title1={parse(formatMessage({ id: "home.banner2-title1" }))}
                title2={parse(formatMessage({ id: "home.banner2-title2" }))}
                titleMobile={parse(formatMessage({ id: "home.banner2-title" }))}
                text={parse(formatMessage({ id: "home.banner2-text" }))}
                button={
                  <Link to='https://meetings.hubspot.com/wakapi/meeting-with-wakapi?utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz--V7abN8cIRcLe7vAfQKMUdfarx3OhKkhpZ8SBYZ4Ks-gE-sO_ubxEZGu4v6ksDbVsDSsc2' target="__blank">
                    <Button primary>
                      {formatMessage({ id: "home.banner2-button" })}
                    </Button>
                  </Link>}
              />
            </div>
          </div>

          {/* Banner Nico */}
          <div className={styles.banner} id={1}>
            <BannerImageAnimated
              imgBackground={banner2Background}
              altBackground='Software Development Outsourcing Wakapi'
              imgFront={banner2Front}
              altFront='Software Development Outsourcing Wakapi'
              isVisible={1 === isVisible}
            />
            <div className={styles.banner__gradient}>
              <BannerTextAnimated
                isVisible={1 === isVisible}
                title1={parse(formatMessage({ id: "home.banner1-title1" }))}
                title2={parse(formatMessage({ id: "home.banner1-title2" }))}
                title3={parse(formatMessage({ id: "home.banner1-title3" }))}
                titleMobile={parse(formatMessage({ id: "home.banner1-title" }))}
                text={parse(formatMessage({ id: "home.banner1-text" }))}
                button={
                  <Link to='https://meetings.hubspot.com/wakapi/meeting-with-wakapi?utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz--V7abN8cIRcLe7vAfQKMUdfarx3OhKkhpZ8SBYZ4Ks-gE-sO_ubxEZGu4v6ksDbVsDSsc2' target="__blank">
                    <Button primary>
                      {formatMessage({ id: "home.banner1-button" })}
                    </Button>
                  </Link>}
              />
            </div>
          </div>

          {/* Banner Oso */}
          <div className={styles.banner} id={0}>
            <BannerImageAnimated
              imgBackground={banner3Background}
              altBackground='Software Outsourcing Latin America Wakapi'
              imgFront={banner3Front}
              altFront='Software Outsourcing Latin America Wakapi'
              isVisible={2 === isVisible}
            />
            <div className={styles.banner__gradient}>
              <BannerTextAnimated
                isVisible={2 === isVisible}
                title1={parse(formatMessage({ id: "home.banner3-title1" }))}
                title2={parse(formatMessage({ id: "home.banner3-title2" }))}
                title3={parse(formatMessage({ id: "home.banner3-title3" }))}
                titleMobile={parse(formatMessage({ id: "home.banner3-title" }))}
                text={parse(formatMessage({ id: "home.banner3-text" }))}
                button={
                  <Link to='https://meetings.hubspot.com/wakapi/meeting-with-wakapi?utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz--V7abN8cIRcLe7vAfQKMUdfarx3OhKkhpZ8SBYZ4Ks-gE-sO_ubxEZGu4v6ksDbVsDSsc2' target="__blank">
                    <Button primary>
                      {formatMessage({ id: "home.banner3-button" })}
                    </Button>
                  </Link>
                }
              />
            </div>
          </div>

          {/* Banner Skyrocket */}
          <div className={styles.banner} id={3}>
            <div className={styles["banner__img--ilustration"]}>
              <video autoPlay={true} alt='Software Outsourcing Services Latin America Wakapi' className={styles.cohete} loop muted controls={false} playsInline={true} disablePictureInPicture disableRemotePlayback poster={cohetePoster}>
                <source src={coheteAnimation} type="video/mp4" />
              </video>
            </div>
            <div className={styles["banner__gradient--ilustration"]} >
              <BannerTextAnimated
                isVisible={3 === isVisible}
                title1={parse(formatMessage({ id: "home.banner4-title1" }))}
                title2={parse(formatMessage({ id: "home.banner4-title2" }))}
                text={parse(formatMessage({ id: "home.banner4-text" }))}
                titleMobile={parse(formatMessage({ id: "home.banner4-title" }))}
                button={
                  <Link to='https://meetings.hubspot.com/wakapi/meeting-with-wakapi?utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz--V7abN8cIRcLe7vAfQKMUdfarx3OhKkhpZ8SBYZ4Ks-gE-sO_ubxEZGu4v6ksDbVsDSsc2' target="__blank">
                    <Button primary>
                      {formatMessage({ id: "home.banner4-button" })}
                    </Button>
                  </Link>
                }
              />
            </div>
          </div>
        </SliderComponent>
        
      </div>
    </section>
  );
};

