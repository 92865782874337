import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './team.module.scss'
import Button from '../../components/Button/Button';
import { Link } from 'react-router-dom';
import teamGif from './../../assets/animation/wakapi-staff-augmentation-latam.gif';

export const Team = () => {
  const lang = localStorage.getItem('lang')
  return (
    <div className={styles.background__gray2}>
      <div className={styles.team__container}>
        <h2>
          <FormattedMessage id="home.team-section" />
        </h2>
        <h3>
          <FormattedMessage id="home.team-title" />
        </h3>
        <p>
          <FormattedMessage id="home.team-subtitle" />
        </p>
        <div className={styles.button}>
        <Link to={`/${lang}/team/jobs`}>
          
          <Button primary type='button' >
            <FormattedMessage id="home.team-button" />
          </Button>
        </Link>
        </div>
        <div className={styles.teamImage__container}>
          <img src={teamGif} alt='Wakapi software development team' className={styles.teamImage} width={900} height={404} loading="lazy"/>
        </div>
      </div>
    </div>
  )
}
export default Team;