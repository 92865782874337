import React, { useEffect, useState } from "react";
import { Head, TopBar, Footer } from "../../components";
import ReactGA from 'react-ga';

export const PrivacyPolicy = () => {

  const [isLoad, setIsLoad] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview('/privacyPolicy');
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    script.onload = () => {
      setIsLoad(true);
    };
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <Head
        title="Privacy Policy | Wakapi"
        description="Learn more about our privacy policy information."
        path="/privacy-policy"
      />
      <TopBar />
      <div className="policy__container" style={{ minHeight: '100vh' }}>
        <div className="policy__title"><h1>Privacy policy</h1></div>
        <div className="policy__text">
        {isLoad && (
            <div className="policy__paragraph">
              When you visit or log in to our website, cookies and similar technologies may be used by our online data partners or vendors to associate these activities with other personal information they or others have about you, including by association with your email or online profiles. We (or service providers on our behalf) may then send communications and marketing to these emails or profiles. You may opt out of receiving this advertising by visiting&nbsp;
              <a href="https://app.retention.com/optout">this page</a>
            </div>
          )}
          <div
            name="termly-embed"
            data-id="b40b9ee8-370b-4934-b918-b7de4b128937"
            data-type="iframe"
          ></div>
         
        </div>

      </div>
      <Footer />
    </>

  );
};
export default PrivacyPolicy;

