
import ReactPlayer from 'react-player';
import videoJourney from './../../assets/images/aboutUs/portadaVideos/wakapi-our-journey-in-software-services-and-solutions.webp'
import videoCustomMade from './../../assets/images/aboutUs/portadaVideos/wakapi-custom-made-outsourcing-services.webp';
import videoUx from './../../assets/images/aboutUs/portadaVideos/wakapi-keys-to-a-flawless-ux-experience.webp'
import videoQA from './../../assets/images/aboutUs/portadaVideos/wakapi-equals-risk-free-software-solutions.webp'
import videoSoftwareEngineer from './../../assets/images/aboutUs/portadaVideos/wakapi-coding-wizard.webp'
import videoProjectPartner from './../../assets/images/aboutUs/portadaVideos/wakapi-approach-to-career-coaching.webp'
import videoReleaseCoordinator from './../../assets/images/aboutUs/portadaVideos/wakapi-why-having-a-release-coordinator-matters.webp'
import videoStaffAugmentation from './../../assets/images/aboutUs/portadaVideos/wakapi-staff-augmentation-and-recruitment.webp'
import styles from './aboutUsVideo.module.scss';
import videoProjectManager from './../../assets/images/aboutUs/portadaVideos/wakapi-project-manager.webp'
import React, { useEffect, useRef, useState } from 'react';


export const AboutUsVideos = () => {
    const videosList = [
        {
            id: 0,
            portada: videoCustomMade,
            videos: {
                es: 'https://youtu.be/giMyqJ0FW1I',
                en: 'https://youtu.be/Jd7adZbg8vs',
            },
            alt: 'Wakapi: custom-made outsourcing services'
        },
        {
            id: 1,
            portada: videoUx,
            videos: {
                es: 'https://youtu.be/FcBUQ2gAcTg',
                en: 'https://youtu.be/jrXIv-drSUw',
            },
            alt: 'Keys to a flawless Ux Experience'
        },
        {
            id: 2,
            portada: videoQA,
            alt: 'Wakapi equals Risk-free Software Solutions',
            videos: {
                es: 'https://youtu.be/JGGyjMEG8y0',
                en: 'https://youtu.be/FjkkepYa2Ds',              
                
            },
        },
        {
            id: 3,
            portada: videoJourney,
            videos: {
                es: 'https://youtu.be/tkA0HkG_9rI',
                en: 'https://youtu.be/L2l4p30c0_4',
            },
            alt: 'Our journey in Wakapi'
        },
        {
            id: 4,
            portada: videoSoftwareEngineer,
            videos: {
                es: 'https://youtu.be/qaX_uhOosW0',
                en: 'https://youtu.be/d_mWbfytrJc',
            },
            alt: 'Meet Victor: Wakapi´s Coding Wizard'
        },
        {
            id: 5,
            portada: videoProjectPartner,
            videos: {
                es: 'https://youtu.be/FER6obBmXik',
                en: 'https://youtu.be/zTJ50UOd1xs',
            },
            alt: 'Discover Wakapi´s approach to Career Coaching'
        },
        {
            id: 6,
            portada: videoReleaseCoordinator,
            videos: {
                es: 'https://youtu.be/YKGthohraJI',
                en: 'https://www.youtube.com/watch?v=dmWvUIwGR-Y',
            },
            alt: 'Why having a Release Coordinator matters'
        },
        {
            id: 7,
            portada: videoProjectManager,
            videos: {
                es: 'https://youtu.be/Eytr57RY5K0',
                en: 'https://youtu.be/y8GWi8ppNmA',
            },
            alt: 'A Wakapi Project Manager!'
        },
        {
            id: 8,
            portada: videoStaffAugmentation,
            videos: {
                es: 'https://youtu.be/BlfcVo7UZK0',
                en: 'https://youtu.be/UIos9iLAfco',
            },
            alt: 'Staff augmentation & recruitment at Wakapi'
        },
    ]

    const [videoSelected, setVideoSelected] = useState(videosList[0]);
    const [play, setPlay] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [startPosition, setStartPosition] = useState(0);

    const handleTouchStart = (event) => {
        setIsDragging(true);
        setStartPosition(event.touches[0].clientX);
    };

    const handleTouchMove = (event) =>{
        if (!isDragging) return;
        const currentPosition = event.touches[0].clientX;
        const diff = currentPosition - startPosition;

        if (diff > 50) { // Arrastra hacia la derecha
            setIsDragging(false);
            handleScrollLeft();
        } else if (diff < -50) { // Arrastra hacia la izquierda
            setIsDragging(false);
            
            handleScrollRight();
        }
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        if (videoSelected.id !== 0) {
            setPlay(true)
        }

    }, [videoSelected]);

    const videoListRef = useRef(null);
    const handleScrollRight = () => {
        if (videoListRef.current) {
            videoListRef.current.scrollBy({ left: 145, behavior: 'smooth' });
        }
    };

    const handleScrollLeft = () => {
        if (videoListRef.current) {
            videoListRef.current.scrollBy({ left: -145, behavior: 'smooth' });
        }
    };
    return (
        <div className={styles.video__container}>
            <div className={styles.video}>
                <ReactPlayer url={ localStorage.getItem("lang") === 'en' ? videoSelected.videos.en : videoSelected.videos.es }
                    width='100%'
                    height='100%'
                    className='video'
                    pip={false}
                    playing={play}
                    config={{
                        youtube: {
                            playerVars: {
                                showinfo: 0,
                                modestbranding: 1,
                                cc_load_policy:1,
                                controls: 0,
                                rel: 0,
                                fs: 0,
                                iv_load_policy: 3,
                                disablekb: 1,
                                color: 'white',
                            },
                            embedOptions: {
                                controls: 0,
                            }
                        }
                    }
                    }
                />
            </div>
            <div className={styles.video__list}>
                <div
                    className={styles.arrow}
                    onClick={handleScrollLeft}
                >
                    <svg width="13" height="24" viewBox="0 0 21 38" fill="none">
                        <path
                            d="M19.7852 36.059L1.78515 18.559L19.7852 1.05896"
                            stroke="#00D264"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <div className={styles["video__list--container"]}>

                    <div className={styles.list} ref={videoListRef}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    >
                        {videosList.map((video) => {
                            return (
                                <div className={styles.video__item} key={video.id} onClick={() => setVideoSelected(video)}>
                                    {video.id === videoSelected.id && (
                                        <div className={styles.video__active}>
                                        </div>
                                    )}
                                    <img src={video.portada} width={145} height={80} alt={video.alt} />
                                </div>
                            )
                        })}
                    </div>

                </div>
                <div
                    className={styles.arrow}
                    onClick={handleScrollRight}
                >
                    <svg width="13" height="24" viewBox="0 0 21 40" fill="none">
                        <path
                            d="M1.78516 1.6095L19.7852 20.1095L1.78516 38.6095"
                            stroke="#00D264"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </div>
        </div>
    )
}