import React, { useEffect, Suspense, lazy } from "react";
import logoawa from "../../assets/images/logos/awa-logo-white.svg";
import styles from "./nosotros.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import ReactGA from 'react-ga';
import { CircularProgress } from "@mui/material";
import { Head, TopBar, Footer } from "../../components";
import parse from "html-react-parser";
import { AboutUsVideos,TimeLine } from "../../modules";


const Contact = lazy(() => import('../../components/Contact/Contact'));
const Founders = lazy(() => import('../../modules/Founders/Founders'));
const Team = lazy(() => import('../../modules/Team/Team'));
const Benefits = lazy(() => import('../../modules/Benefits/Benefits'));


const Nosotros = () => {
  const renderLoader = () => <div className="spinner"><CircularProgress color="inherit" /></div>;

  const { formatMessage } = useIntl();
  useEffect(() => {
    ReactGA.pageview('/about-us');
    window.scrollTo(0, 0);
  }, [])

  return (
    <main>
      <Head
        title="About Us | Wakapi"
        description="Wakapi wants to contribute to the generation of a technological community. Discover more about the vision of our Founders."
        path="/about-us"
        haveCarousel={true}
      />
      <TopBar />
      <section className={styles.presentation__container}>
        {/* <iframe src={video} title="Wakapi" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
        <div className={styles.presentation}>
          <div className={styles.presentation__text}>
            <h1><FormattedMessage id="aboutUs.presentation-title" /></h1>
            <h2><FormattedMessage id="aboutUs.presentation-subtitle" /></h2>
            <div>
              {parse(formatMessage({ id: "aboutUs.presentation-text" }))}
            </div>
          </div>
              <AboutUsVideos/>
        </div>
      </section>

      <section>
        <TimeLine />
      </section>
      <section className="team">
        <Suspense fallback={renderLoader()}>
          <Team />
        </Suspense>
      </section>
      <section className={styles.benefits__container}>
        <Suspense fallback={renderLoader()}>
          <Benefits />
        </Suspense>
      </section>
      <section className={styles.founders__container}>
        <Suspense fallback={renderLoader()}>
          <Founders />
        </Suspense>
      </section>
      <section className={styles.awa__container}>
        <div className={styles.awa}>
          <div className={styles.awa__logo}>
            <img src={logoawa} alt="Awa Ventures" width={205} height={102} loading="lazy" />
          </div>
          <p><FormattedMessage id="awa-text" /> <br /> <a href="https://www.awa-ventures.com/" target="_blank" rel="noreferrer" >www.awa-ventures.com</a></p>
        </div>
      </section>
      <section className="contact__container">
        <Suspense fallback={renderLoader()}>
          <Contact />
        </Suspense>
      </section>
      <Footer />
    </main>
  );
};

export default Nosotros;
