export const ReactIcon = ({ size: { width = "114", height = "102" } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 114 102" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1073_2139)">
                <path d="M56.9854 61.052C62.594 61.052 67.1407 56.5003 67.1407 50.8854C67.1407 45.2705 62.594 40.7188 56.9854 40.7188C51.3768 40.7188 46.8301 45.2705 46.8301 50.8854C46.8301 56.5003 51.3768 61.052 56.9854 61.052Z" fill="#61DAFB" />
                <path fillRule="evenodd" clipRule="evenodd" d="M8.43547 44.6183C5.98424 46.8336 4.97041 48.9555 4.97041 50.8854C4.97041 52.8152 5.98424 54.9372 8.43547 57.1524C10.8864 59.3674 14.5721 61.4758 19.3373 63.2973C28.8507 66.9337 42.1606 69.2349 56.9854 69.2349C71.8101 69.2349 85.12 66.9337 94.6334 63.2973C99.3986 61.4758 103.084 59.3674 105.535 57.1524C107.987 54.9372 109 52.8152 109 50.8854C109 48.9555 107.987 46.8336 105.535 44.6183C103.084 42.4033 99.3986 40.2949 94.6334 38.4734C85.12 34.837 71.8101 32.5358 56.9854 32.5358C42.1606 32.5358 28.8507 34.837 19.3373 38.4734C14.5721 40.2949 10.8864 42.4033 8.43547 44.6183ZM17.5703 33.8403C27.7791 29.9381 41.7151 27.5765 56.9854 27.5765C72.2556 27.5765 86.1917 29.9381 96.4005 33.8403C101.496 35.7882 105.791 38.1681 108.855 40.9371C111.918 43.7058 113.954 47.0634 113.954 50.8854C113.954 54.7074 111.918 58.065 108.855 60.8337C105.791 63.6026 101.496 65.9825 96.4005 67.9304C86.1917 71.8327 72.2556 74.1943 56.9854 74.1943C41.7151 74.1943 27.7791 71.8327 17.5703 67.9304C12.4744 65.9825 8.17994 63.6026 5.11602 60.8337C2.05238 58.065 0.0166016 54.7074 0.0166016 50.8854C0.0166016 47.0634 2.05238 43.7058 5.11602 40.9371C8.17994 38.1681 12.4744 35.7882 17.5703 33.8403Z" fill="#61DAFB" />
                <path fillRule="evenodd" clipRule="evenodd" d="M38.1323 5.65944C34.9903 4.64186 32.6478 4.82387 30.9784 5.78878C29.309 6.75369 27.9802 8.69366 27.2895 11.9265C26.5989 15.159 26.6178 19.4086 27.4248 24.4508C29.0357 34.517 33.7 47.2072 41.1124 60.0602C48.5248 72.9131 57.1704 83.3021 65.0728 89.7319C69.0311 92.9526 72.6979 95.0938 75.8394 96.1113C78.9814 97.1289 81.3239 96.9469 82.9933 95.982C84.6628 95.0171 85.9915 93.0771 86.6822 89.8443C87.3728 86.6118 87.3539 82.3621 86.5469 77.32C84.936 67.2538 80.2717 54.5636 72.8593 41.7106C65.4469 28.8576 56.8013 18.4686 48.8989 12.0388C44.9406 8.81816 41.2739 6.67691 38.1323 5.65944ZM52.0233 8.19026C60.5034 15.0901 69.5143 25.9917 77.1494 39.2309C84.7846 52.4701 89.7097 65.7334 91.4384 76.5355C92.3013 81.9275 92.3897 86.8407 91.5264 90.8816C90.6631 94.9221 88.7765 98.3659 85.4702 100.277C82.164 102.188 78.2416 102.102 74.3147 100.83C70.3874 99.558 66.1814 97.0247 61.9484 93.5805C53.4683 86.6807 44.4574 75.779 36.8223 62.5398C29.1872 49.3006 24.2621 36.0374 22.5333 25.2353C21.6704 19.8433 21.582 14.93 22.4453 10.8892C23.3086 6.84867 25.1952 3.40487 28.5015 1.49386C31.8077 -0.417139 35.7301 -0.330935 39.6571 0.940873C43.5843 2.21279 47.7903 4.74611 52.0233 8.19026Z" fill="#61DAFB" />
                <path fillRule="evenodd" clipRule="evenodd" d="M86.6821 11.9265C85.9914 8.69366 84.6627 6.75369 82.9933 5.78878C81.3239 4.82387 78.9814 4.64187 75.8394 5.65944C72.6978 6.67691 69.0311 8.81816 65.0728 12.0388C57.1704 18.4686 48.5248 28.8576 41.1124 41.7106C33.7 54.5636 29.0357 67.2538 27.4247 77.32C26.6178 82.3621 26.5989 86.6118 27.2895 89.8443C27.9802 93.0771 29.3089 95.0171 30.9783 95.982C32.6478 96.9469 34.9903 97.1289 38.1322 96.1113C41.2738 95.0939 44.9406 92.9526 48.8989 89.7319C56.8013 83.3021 65.4469 72.9131 72.8593 60.0602C80.2717 47.2072 84.936 34.517 86.5469 24.4508C87.3538 19.4086 87.3728 15.159 86.6821 11.9265ZM91.4383 25.2353C89.7096 36.0374 84.7845 49.3006 77.1494 62.5398C69.5143 75.779 60.5033 86.6807 52.0233 93.5805C47.7903 97.0247 43.5843 99.558 39.657 100.83C35.7301 102.102 31.8077 102.188 28.5014 100.277C25.1952 98.3659 23.3085 94.9221 22.4453 90.8816C21.5819 86.8407 21.6704 81.9275 22.5333 76.5355C24.262 65.7334 29.1871 52.4701 36.8223 39.2309C44.4574 25.9917 53.4683 15.0901 61.9484 8.19027C66.1814 4.74611 70.3874 2.2128 74.3146 0.940876C78.2415 -0.330932 82.164 -0.417136 85.4702 1.49387C88.7765 3.40487 90.6631 6.84867 91.5264 10.8892C92.3897 14.93 92.3013 19.8433 91.4383 25.2353Z" fill="#61DAFB" />
            </g>
            <defs>
                <clipPath id="clip0_1073_2139">
                    <rect width="113.938" height="101.485" fill="white" transform="translate(0.0166016 0.1427)" />
                </clipPath>
            </defs>
        </svg>

    )
}