export const CIcon = ({ size: { width = "114", height = "126" } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 114 126" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1073_2072)">
                <path fillRule="evenodd" clipRule="evenodd" d="M53.4467 1.23753C55.4659 0.103603 57.9519 0.103603 59.968 1.23753C69.9447 6.84169 99.9669 23.7107 109.947 29.3149C111.966 30.4458 113.209 32.5411 113.209 34.8089C113.209 46.0173 113.209 79.7524 113.209 90.9607C113.209 93.2285 111.966 95.3238 109.947 96.4577C99.9699 102.062 69.9477 118.931 59.968 124.535C57.9489 125.669 55.4629 125.669 53.4467 124.535C43.47 118.931 13.4478 102.062 3.46813 96.4577C1.45198 95.3238 0.208984 93.2315 0.208984 90.9637C0.208984 79.7553 0.208984 46.0202 0.208984 34.8119C0.208984 32.5441 1.45198 30.4488 3.47111 29.3149C13.4448 23.7107 43.473 6.84169 53.4467 1.23753Z" fill="#004482" />
                <path fillRule="evenodd" clipRule="evenodd" d="M1.11298 93.8504C0.321984 92.7909 0.208984 91.785 0.208984 90.4308C0.208984 79.2879 0.208984 45.7463 0.208984 34.6005C0.208984 32.3445 1.44901 30.2612 3.46219 29.1362C13.4032 23.5648 43.3243 6.79395 53.2653 1.21955C55.2785 0.0915716 58.0054 0.115381 60.0186 1.24336C69.9596 6.81478 99.7796 23.4427 109.721 29.0142C110.523 29.4666 111.136 30.0112 111.683 30.7285L1.11298 93.8504Z" fill="#659AD2" />
                <path fillRule="evenodd" clipRule="evenodd" d="M56.7086 21.2195C79.6862 21.2195 98.3401 39.8892 98.3401 62.8862C98.3401 85.8832 79.6862 104.553 56.7086 104.553C33.731 104.553 15.0771 85.8832 15.0771 62.8862C15.0771 39.8892 33.731 21.2195 56.7086 21.2195ZM56.7086 42.0529C68.1959 42.0529 77.5244 51.3862 77.5244 62.8862C77.5244 74.3832 68.1959 83.7195 56.7086 83.7195C45.2213 83.7195 35.8929 74.3832 35.8929 62.8862C35.8929 51.3862 45.2183 42.0529 56.7086 42.0529Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M111.678 30.7582C113.212 32.1957 113.182 34.3624 113.182 36.0678C113.182 47.3624 113.087 79.4874 113.209 90.782C113.221 91.9606 112.832 93.2016 112.249 94.1362L55.499 62.8862L111.678 30.7582Z" fill="#00599C" />
            </g>
            <defs>
                <clipPath id="clip0_1073_2072">
                    <rect width="113" height="125" fill="white" transform="translate(0.208984 0.385376)" />
                </clipPath>
            </defs>
        </svg>

    )
}