import { useEffect, useRef, useState } from 'react';
import styles from './infographics.module.scss';
import ScrollContainer from 'react-indiana-drag-scroll';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
export const Inforgraphic = ({ children, height=450 }) => {

    //Hooks
    const [isDragging, setIsDragging] = useState(false);    
    const animationRef = useRef(null);
   
    const [containerRef, isVisible] = useIntersectionObserver();

    //Ancho de ventana
    const [width, setWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        //Seteo de ancho de ventana y cambios
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {       
        initAnimation();    
    }, [isDragging, isVisible, width]);

    useEffect(() => {     
        // Detiene la animacion si el elemento no esta visible  
        if(!isVisible)
            clearTimeout(animationRef.current);
    }, [isVisible]);

    
    const time = () =>{
        //Seteo de tiempo de transicion segun ancho de ventana
        if(width > 1280){
            return 40000
        }
        if(width > 769){
            return 45000
        }
      
        return 50000
    }

    const initAnimation = () => {
        const scrollContainer = containerRef.current;
        if (scrollContainer.scrollLeft !== 0 && isDragging) {
            return;
        }
        if (scrollContainer && isVisible) {
            const scrollAmount = scrollContainer.scrollWidth / 2; // Desplazamiento hacia la mitad del contenedor duplicado
            smoothScroll(scrollContainer, scrollAmount, time());
        }

        return () => {
            clearTimeout(animationRef.current); // Limpiar la animación en el desmontaje
        };
    }


    const smoothScroll = (element, to, duration) => {
        const start = element.scrollLeft;
        const change = to - start;
        const increment = 10; // Intervalo de tiempo en ms entre cada incremento
        const stepAmount = change / (duration / increment);
        let currentTime = 0;

        const animateScroll = () => {
            if (!isDragging && isVisible) {
                currentTime += increment;
                element.scrollLeft = start + currentTime * stepAmount / increment;
                if (currentTime < duration ) {
                    animationRef.current = setTimeout(animateScroll, increment);
                } else {
                    //Agrego 10 para evitar diferencia de decimales
                    if (element.scrollLeft+10 >= element.scrollWidth / 2) {
                        element.scrollLeft = 0;
                    }
                    smoothScroll(element, element.scrollWidth / 2, time()); // Re-trigger the scroll
                }
            }
        };
        animateScroll();
    };

    const handleDragStart = () => {
        if(!isDragging)
            setIsDragging(true);
        clearTimeout(animationRef.current); // Detener la animación en caso de que esté en progreso
    };


    return (
        <div className={styles.container}>
            <div className={styles.infographic__container} onDrag={handleDragStart} onDragStart={handleDragStart} onMouseDown={handleDragStart} onClick={handleDragStart} onTouchStart={handleDragStart}>
                <ScrollContainer className={styles.infographic__desktop} vertical={false} style={{ height: height }} innerRef={containerRef}>
                    {children}
                    {children}
                </ScrollContainer>
                <div className={styles.infographic__mobile}>
                    {children}
                </div>
            </div>

        </div>
    )
}