export const HowlyLogo = ({ size: { width = '147', height = '96' } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 147 96" fill="none" xmlns="http://www.w3.org/2000/svg" aria-valuetext="Howly">
            <g clipPath="url(#clip0_702_35)">
                <path d="M66.2891 45.219V34.1393H69.6415V59.7616H66.2891V47.968M53.5417 45.2152V59.7578H50.2083V34.1355H53.5417V45.2152Z" fill="#010101" />
                <path d="M75.2869 50.1059C75.2869 48.2504 75.6501 46.5781 76.3728 45.093C77.0955 43.6116 78.102 42.4662 79.396 41.6606C80.6862 40.855 82.1581 40.4503 83.8154 40.4503C86.3732 40.4503 88.4429 41.3437 90.0245 43.1305C91.6061 44.9173 92.3931 47.2959 92.3931 50.2625V50.4916C92.3931 52.3356 92.0412 53.9926 91.3412 55.4587C90.6412 56.9248 89.6385 58.0664 88.3369 58.8834C87.0353 59.7005 85.537 60.109 83.8494 60.109C81.303 60.109 79.2371 59.2156 77.6593 57.4288C76.0777 55.642 75.2906 53.2748 75.2906 50.3312V50.1021L75.2869 50.1059ZM78.5182 50.4916C78.5182 52.5952 79.0025 54.2828 79.9673 55.5542C80.9322 56.8294 82.2262 57.4631 83.8494 57.4631C85.4727 57.4631 86.778 56.8179 87.7391 55.5274C88.7002 54.237 89.1807 52.4311 89.1807 50.1059C89.1807 48.0251 88.6926 46.3414 87.7126 45.051C86.7326 43.7605 85.4348 43.1153 83.8154 43.1153C82.1959 43.1153 80.9511 43.7529 79.9787 45.0242C79.0063 46.2994 78.5182 48.1206 78.5182 50.4916Z" fill="#010101" />
                <path d="M113.041 55.2946L116.647 40.6222H119.859L114.395 59.7654H111.792L107.225 45.2076L102.779 59.7654H100.176L94.7163 40.6222H97.9098L101.614 54.9968L105.984 40.6222H108.572L113.041 55.2946Z" fill="#010101" />
                <path d="M126.621 59.7616H123.408V32.8488H126.621V59.7616Z" fill="#010101" />
                <path d="M137.908 55.012L142.285 40.6183H145.725L138.172 62.6861C137.003 65.8398 135.145 67.4166 132.599 67.4166L131.99 67.3631L130.79 67.134V64.5073L131.657 64.576C132.747 64.576 133.594 64.3546 134.2 63.9117C134.809 63.4688 135.308 62.6556 135.702 61.4758L136.413 59.5478L129.708 40.6145H133.216L137.904 55.0082L137.908 55.012Z" fill="#010101" />
                <path d="M83.8381 37.0447C84.9875 37.0447 85.9192 36.1046 85.9192 34.9448C85.9192 33.7851 84.9875 32.845 83.8381 32.845C82.6888 32.845 81.7571 33.7851 81.7571 34.9448C81.7571 36.1046 82.6888 37.0447 83.8381 37.0447Z" fill="#010101" />
                <path d="M52.2023 51.2591L50.2915 49.0294C50.6207 48.7392 58.5363 41.9814 73.0279 44.6807L72.4982 47.5785C59.4633 45.1541 52.2704 51.198 52.2023 51.2591Z" fill="#010101" />
                <path d="M33.3339 60.51L20.4163 50.3428L29.2096 47.4335L33.3339 60.51Z" fill="#A6A5A5" />
                <path d="M38.0408 25.6253L33.4776 31.8943L15.5049 36.7432L25.7172 28.9965L25.7209 28.9927L32.4635 24.0408L37.6283 23.5406L38.0408 25.6253Z" fill="#C5C5C4" />
                <path d="M25.7219 28.9927L25.7173 28.9972V28.9927H25.7219Z" fill="#C5C5C4" />
                <path d="M25.7172 28.9965L15.5049 36.7431L8.43315 44.4478L0.623535 41.3705L14.9639 31.3522L19.3984 28.084L25.7172 28.9965Z" fill="#575756" />
                <path d="M38.8241 32.6083L33.1372 37.7854L29.2096 47.4334L20.4163 50.3427L33.4777 31.8943L38.109 30.8138C38.3511 31.4094 38.8279 32.6083 38.8279 32.6083H38.8241Z" fill="#858585" />
                <path d="M33.4776 31.8943L20.4162 50.3427L15.5049 36.7431L33.4776 31.8943Z" fill="#A6A5A5" />
                <path d="M33.3338 60.5138L19.6329 71.3377L3.34399 55.3901L20.42 50.3428L33.3338 60.5138Z" fill="#858585" />
                <path d="M20.4162 50.3427L3.34399 55.3901L8.43311 44.4478L15.5049 36.7432L20.4162 50.3427Z" fill="#575756" />
                <path d="M33.3339 60.51L19.6331 71.3377L20.4163 50.3428L33.3339 60.51Z" fill="#575756" />
                <path d="M15.5049 36.7432L8.43315 44.4478L0.623535 41.3705L15.5049 36.7432Z" fill="#3C3C3B" />
                <path d="M38.8241 32.6083L33.1372 37.7854L33.4777 31.8943L38.109 30.8138C38.3474 31.4133 38.8279 32.6083 38.8279 32.6083H38.8241Z" fill="#575756" />
                <path d="M36.149 21.3376L37.6284 23.5406L32.4258 24.0713L36.149 21.3376Z" fill="#3C3C3B" />
            </g>
            <defs>
                <clipPath id="clip0_702_35">
                    <rect width="145" height="50" fill="white" transform="translate(0.623535 21.3376)" />
                </clipPath>
            </defs>
        </svg>

    )
}