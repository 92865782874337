import { FormattedMessage } from "react-intl";
import Button from "../../../components/Button/Button";
import { ServicesList } from "../ServicesList/ServicesList";
import styles from './servicesHome.module.scss';

export const ServicesHome =() =>{
    return (
        <section className={styles.services__background} >
          <div className={styles.servicios__container}>
            
            {/* Titulo Home */}
            <div className={styles.titleServices}>
              <h2>
                <FormattedMessage id="services-section" />
              </h2>
              <h3>
                <FormattedMessage id="services-title" />
              </h3>
              <p>
                <FormattedMessage id="services-subtitle" />
              </p>
              <a href={"mailto:hello@wakapi.com"}>
                <Button secondary>
                  <FormattedMessage id="services-button" />
                </Button>
              </a>
            </div>

            {/* Listado de servicios */}
            <ServicesList small />
          </div>
        </section>
      );
}