import styles from './developmentProcess.module.scss';

export const DevelopmentProcess = ({ title, list }) => {
    return (
        <div className={styles.process}>
            <h2>{title}</h2>
            <div className={styles.process__list}>
                {list.map((item, index) => {
                    return (
                        <div key={index} className={styles.process__item}>
                            <div className={styles.process__number}>
                                { index + 1 }
                            </div>
                            <div className={styles.process__text}>
                                <h4>{item.title}</h4>
                                <p>{item.text}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}