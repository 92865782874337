export const Chevron = ({ size: { width = "18", height = '18' }, color = "#221F1F", direction = 'right' }) => {

    const directionPath = {
        down: <path d="M3.75 8L8.41429 11.998C8.75133 12.2869 9.24867 12.2869 9.58571 11.998L14.25 8" stroke={color} strokeWidth="1.6" strokeLinecap="round" />,
        left: <path d="M10 3.75L6.00204 8.41429C5.71315 8.75133 5.71315 9.24867 6.00204 9.58571L10 14.25" stroke={color} strokeWidth="1.6" strokeLinecap="round"/>,
        right: <path d="M8 14.25L11.998 9.58571C12.2869 9.24867 12.2869 8.75133 11.998 8.41429L8 3.75" stroke={color} strokeWidth="1.6" strokeLinecap="round"/>,
        top: <path d="M14.25 10L9.58571 6.00204C9.24867 5.71315 8.75133 5.71315 8.41429 6.00204L3.75 10" stroke={color} strokeWidth="1.6" strokeLinecap="round"/>

    }
    return (
        <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            {directionPath[direction]}
        </svg>

    )
}