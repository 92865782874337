import styles from './awaBanner.module.scss';
import { SliderComponent } from '../../components/Slider/Slider';
import bannerAwa from './../../assets/images/bannerAwa/banner-awa-ventures-making-a-difference.webp'
import bannerAwa2 from './../../assets/images/bannerAwa/banner-awa-ventures-transforming-ideas.webp'
import bannerAwa3 from './../../assets/images/bannerAwa/banner-awa-ventures-collaboration-for-success.webp'
import bannerAwa4 from './../../assets/images/bannerAwa/banner-awa-ventures-fueling-innovation.webp'
import bannerAwa5 from './../../assets/images/bannerAwa/banner-awa-ventures-an-impactful-journey.webp'
import { FormattedMessage } from 'react-intl';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

const AwaLogo = ({ color }) => {
    return (
        <svg width="197" height="22" viewBox="0 0 197 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.awa__logo}>
            <path d="M8.49001 3.73C5.04001 3.73 1.67001 5.04 0.85001 8.42L5.23001 9.7C5.41001 8.28 6.56001 7.63 8.50001 7.63C10.44 7.63 11.35 8.46 11.35 9.82V10.86H6.41001C2.58001 10.86 0.0100098 12.43 0.0100098 15.66C0.0100098 18.89 2.41001 21.16 6.65001 21.16C8.53001 21.16 10.62 19.92 11.35 18.87V20.74H15.84V9.82C15.84 5.61 12.64 3.73 8.50001 3.73H8.49001ZM11.34 13.72C11.34 15.61 9.91001 17.21 8.03001 17.4C7.76001 17.43 7.49001 17.44 7.20001 17.44C5.04001 17.44 4.35001 16.5 4.35001 15.63C4.35001 14.9 4.68001 13.72 6.69001 13.72H11.34Z" fill={color} />
            <path d="M52.29 3.73C48.84 3.73 45.47 5.04 44.65 8.42L49.03 9.7C49.21 8.28 50.36 7.63 52.3 7.63C54.24 7.63 55.15 8.46 55.15 9.82V10.86H50.21C46.38 10.86 43.81 12.43 43.81 15.66C43.81 18.89 45.96 21.16 50.21 21.16C52.09 21.16 54.42 19.92 55.15 18.87V20.74H59.64V9.82C59.64 5.61 56.44 3.73 52.3 3.73H52.29ZM55.14 13.72C55.14 15.61 53.71 17.21 51.83 17.4C51.56 17.43 51.29 17.44 51 17.44C48.84 17.44 48.15 16.5 48.15 15.63C48.15 14.9 48.48 13.72 50.49 13.72H55.14Z" fill={color} />
            <path d="M43.5 4.15L37.66 20.74H33.69L30.18 10.44L26.67 20.74H22.7L16.86 4.15H21.8L24.69 14.28L28.27 4.15H32.1L35.68 14.28L38.57 4.15H43.51H43.5Z" fill={color} />
            <path d="M179.45 13.02C179.63 10.59 178.99 8.39 177.64 6.82C176.94 6 176.06 5.36 175.03 4.92C173.95 4.46 172.72 4.22 171.39 4.22C170.18 4.22 169.05 4.44 168.01 4.87C167.03 5.28 166.16 5.87 165.43 6.63C163.96 8.16 163.14 10.3 163.14 12.66C163.14 15.02 163.97 17.14 165.47 18.65C166.22 19.41 167.12 20 168.14 20.4C169.22 20.83 170.41 21.05 171.69 21.05C173.45 21.05 175.07 20.62 176.36 19.81C177.21 19.27 177.93 18.55 178.46 17.7L175.81 16.87C174.88 18.09 173.73 18.87 171.3 18.82C167.56 18.72 166.01 15.21 166.01 13.35V13.03H179.44L179.45 13.02ZM165.98 11.17L166.13 10.49C166.41 9.33 167.02 8.31 167.89 7.53C168.82 6.7 170.01 6.28 171.43 6.28C172.85 6.28 174.03 6.64 174.9 7.34C175.86 8.11 176.44 9.24 176.6 10.62L176.62 11.18H165.98V11.17Z" fill={color} />
            <path d="M122.57 19.3C123.68 20.45 125.35 21.06 127.4 21.06C128.24 21.06 129.27 21.01 130.56 20.62L130.21 18.3C129.42 18.53 128.69 18.64 127.97 18.64C126.75 18.64 125.71 18.29 124.96 17.63C124.13 16.89 123.7 15.83 123.7 14.49V6.7H130.33V4.69H123.7V0L120.9 0.11V4.69H117.03V6.7H120.9V14.49C120.9 16.51 121.48 18.17 122.57 19.3Z" fill={color} />
            <path d="M148.77 20.61V4.69H145.97V15.63L145.72 15.93C144.26 17.7 142.43 18.71 140.72 18.71C139.12 18.71 137.79 18.17 136.88 17.14C135.99 16.14 135.54 14.75 135.54 13V4.69H132.74V13.31C132.74 15.64 133.4 17.61 134.65 18.99C135.88 20.34 137.6 21.06 139.65 21.06C141.31 21.06 142.8 20.52 144.21 19.4L145.96 18.01V20.61H148.77Z" fill={color} />
            <path d="M161.97 7.01V4.43C160.13 4.61 158.64 5.28 157.39 6.47L155.56 8.21V4.68H152.76V20.6H155.56V10.32L155.83 10.01C157.37 8.28 159.32 7.32 161.96 7.01H161.97Z" fill={color} />
            <path d="M107.35 6.58C108.98 6.58 110.26 7.08 111.17 8.08C112.07 9.05 112.52 10.43 112.52 12.17V20.6H115.32V11.86C115.32 9.55 114.67 7.61 113.43 6.26C112.21 4.93 110.47 4.22 108.4 4.22C107 4.22 105.77 4.56 104.64 5.25C104.21 5.51 103.82 5.84 103.48 6.23L102.09 7.81V4.68H99.29V20.6H102.09V9.47L102.35 9.17C103.75 7.52 105.57 6.58 107.34 6.58H107.35Z" fill={color} />
            <path d="M189.3 21.06C191.43 21.06 193.21 20.61 194.44 19.76C195.67 18.92 196.29 17.7 196.29 16.15C196.29 14.88 195.88 13.92 195.03 13.23C194.28 12.62 193.22 12.2 191.77 11.95L188.52 11.42C187.5 11.24 186.72 10.9 186.18 10.39C185.65 9.89 185.38 9.26 185.38 8.57C185.38 7.74 185.83 7.01 186.61 6.55C187.27 6.17 188.16 5.97 189.27 5.97C192.06 5.97 193.13 7.12 193.53 8.13H196C195.66 6.79 194.92 5.79 193.8 5.15C192.68 4.51 191.15 4.19 189.27 4.19C187.23 4.19 185.52 4.66 184.33 5.56C183.21 6.4 182.61 7.62 182.61 9.06C182.61 10.29 183.04 11.27 183.9 11.99C184.65 12.62 185.74 13.07 187.05 13.3L190.96 13.98C192.68 14.29 193.71 15.27 193.71 16.6C193.71 17.38 193.29 19.2 189.41 19.2C188.06 19.2 187.01 18.96 186.21 18.46C185.48 18.01 184.96 17.35 184.66 16.5L182.17 16.48C182.42 17.63 182.93 18.58 183.68 19.3C184.9 20.47 186.79 21.06 189.3 21.06Z" fill={color} />
            <path d="M75.53 4.69L70.62 18.67L65.53 4.69H62.41L68.12 20.61H72.93L78.64 4.69H75.53Z" fill={color} />
            <path d="M95.88 13.02C96.06 10.59 95.41 8.39 94.07 6.82C93.37 6 92.49 5.37 91.46 4.92C90.38 4.46 89.15 4.22 87.82 4.22C86.61 4.22 85.48 4.44 84.44 4.87C83.46 5.28 82.59 5.87 81.86 6.63C80.39 8.16 79.58 10.3 79.58 12.66C79.58 15.02 80.41 17.14 81.91 18.65C82.66 19.41 83.56 20 84.58 20.4C85.66 20.83 86.85 21.05 88.13 21.05C89.9 21.05 91.51 20.62 92.8 19.81C93.65 19.27 94.37 18.55 94.9 17.7L92.25 16.87C91.44 17.93 90.31 18.87 87.75 18.82C84.01 18.72 82.46 15.21 82.46 13.35V13.03H95.89L95.88 13.02ZM82.41 11.17L82.56 10.49C82.84 9.33 83.45 8.31 84.32 7.53C85.25 6.7 86.44 6.28 87.86 6.28C89.28 6.28 90.46 6.64 91.33 7.34C92.29 8.11 92.87 9.24 93.03 10.62L93.05 11.18H82.41V11.17Z" fill={color} />
        </svg>
    )
}

const sliders = [
    {
        id: 1,
        title: <FormattedMessage id="home.awaBanner1-title" />,
        text: <FormattedMessage id="home.awaBanner1-text" />,
        logoColor: '#FF7262',
        img: bannerAwa,
        altImg: 'Making a Difference, Empowering Change'
    },
    {
        id: 2,
        title: <FormattedMessage id="home.awaBanner2-title" />,
        text: <FormattedMessage id="home.awaBanner2-text" />,
        logoColor: '#216BB4',
        img: bannerAwa2,
        altImg: 'Collaboration for Success'
    },
    {
        id: 3,
        title: <FormattedMessage id="home.awaBanner3-title" />,
        text: <FormattedMessage id="home.awaBanner3-text" />,
        logoColor: '#739FF9',
        img: bannerAwa3,
        altImg: 'Transforming Ideas into Reality'
    },
    {
        id: 4,
        title: <FormattedMessage id="home.awaBanner4-title" />,
        text: <FormattedMessage id="home.awaBanner4-text" />,
        logoColor: '#FF7262',
        img: bannerAwa4,
        altImg: 'Fueling Innovation, Driving Growth'
    },
    {
        id: 5,
        title: <FormattedMessage id="home.awaBanner5-title" />,
        text: <FormattedMessage id="home.awaBanner5-text" />,
        logoColor: '#216BB4',
        img: bannerAwa5,
        altImg: 'An Impactful Journey'
    }
]
export const AwaBanner = () => {

    //Hooks   
    const [containerRef, isVisible] = useIntersectionObserver();    

    return (
        <div className={styles.container} ref={containerRef}>
            <SliderComponent className='awa-slider' autoplay={false}>
                {sliders.map((slider) => {
                    return (
                        <div key={slider.id}>
                            <div className={styles.slider__container}>
                                <div className={styles.data__container}>
                                    <div className={styles.data}>
                                        <h3>
                                            {slider.title}
                                        </h3>
                                        <p>
                                            {slider.text}
                                        </p>
                                        <a
                                            href="https://www.awa-ventures.com/"
                                            style={{ textDecoration: "none" }}
                                            target="__blank"
                                        >
                                            <AwaLogo color={slider.logoColor} />
                                        </a>
                                    </div>
                                </div>
                                <img src={slider.img} width={920} height={495} className={styles.slider__img} alt={slider.altImg} />
                            </div>
                        </div>
                    )
                })}
            </SliderComponent>
        </div>
    )
}