export const ReactNativeIcon = ({ size: { width = "109", height = "96" } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 109 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1073_2146)">
                <path fillRule="evenodd" clipRule="evenodd" d="M8.87251 41.9909C6.567 44.0745 5.61343 46.0703 5.61343 47.8854C5.61343 49.7005 6.567 51.6964 8.87251 53.7799C11.1778 55.8633 14.6444 57.8463 19.1263 59.5595C28.0742 62.9798 40.5929 65.1442 54.5364 65.1442C68.4799 65.1442 80.9986 62.9798 89.9465 59.5595C94.4285 57.8463 97.895 55.8633 100.2 53.7799C102.506 51.6964 103.459 49.7005 103.459 47.8854C103.459 46.0703 102.506 44.0745 100.2 41.9909C97.895 39.9076 94.4285 37.9245 89.9465 36.2113C80.9986 32.791 68.4799 30.6266 54.5364 30.6266C40.5929 30.6266 28.0742 32.791 19.1263 36.2113C14.6444 37.9245 11.1778 39.9076 8.87251 41.9909ZM17.4643 31.8536C27.0663 28.1833 40.1739 25.9621 54.5364 25.9621C68.8989 25.9621 82.0066 28.1833 91.6085 31.8536C96.4015 33.6857 100.441 35.9241 103.322 38.5285C106.204 41.1326 108.119 44.2906 108.119 47.8854C108.119 51.4802 106.204 54.6382 103.322 57.2424C100.441 59.8467 96.4015 62.0851 91.6085 63.9172C82.0066 67.5875 68.8989 69.8087 54.5364 69.8087C40.1739 69.8087 27.0663 67.5875 17.4643 63.9172C12.6714 62.0851 8.63218 59.8467 5.75039 57.2424C2.86886 54.6382 0.954102 51.4802 0.954102 47.8854C0.954102 44.2906 2.86886 41.1326 5.75039 38.5285C8.63218 35.9241 12.6714 33.6857 17.4643 31.8536Z" fill="#FF6C00" />
                <path fillRule="evenodd" clipRule="evenodd" d="M36.8033 5.34788C33.8481 4.39079 31.6448 4.56197 30.0747 5.46953C28.5045 6.37708 27.2548 8.20173 26.6051 11.2424C25.9555 14.2827 25.9733 18.2797 26.7323 23.0222C28.2475 32.49 32.6345 44.4258 39.6063 56.5148C46.578 68.6037 54.7097 78.3752 62.1424 84.4227C65.8654 87.452 69.3142 89.4659 72.269 90.4229C75.2242 91.38 77.4275 91.2088 78.9976 90.3013C80.5678 89.3937 81.8175 87.5691 82.4672 84.5284C83.1168 81.4881 83.099 77.491 82.34 72.7486C80.8248 63.2808 76.4378 51.3449 69.466 39.256C62.4943 27.1671 54.3626 17.3956 46.9299 11.348C43.2069 8.31883 39.7581 6.30486 36.8033 5.34788ZM49.8686 7.72826C57.8446 14.2179 66.3199 24.4715 73.5011 36.9237C80.6824 49.3759 85.3147 61.8508 86.9407 72.0107C87.7523 77.0822 87.8355 81.7034 87.0235 85.504C86.2115 89.3044 84.437 92.5435 81.3273 94.3409C78.2176 96.1383 74.5283 96.0572 70.8349 94.861C67.141 93.6647 63.1851 91.282 59.2037 88.0425C51.2277 81.5529 42.7524 71.2993 35.5712 58.8471C28.3899 46.3948 23.7576 33.92 22.1316 23.7601C21.32 18.6886 21.2368 14.0674 22.0488 10.2667C22.8608 6.46642 24.6353 3.22733 27.745 1.42992C30.8547 -0.367484 34.544 -0.286405 38.2374 0.909801C41.9313 2.10611 45.8872 4.48883 49.8686 7.72826Z" fill="#FF6C00" />
                <path fillRule="evenodd" clipRule="evenodd" d="M82.4679 11.2424C81.8182 8.20175 80.5685 6.3771 78.9983 5.46955C77.4282 4.56199 75.2249 4.39081 72.2697 5.3479C69.3149 6.30488 65.8661 8.31885 62.1431 11.3481C54.7104 17.3957 46.5787 27.1671 39.607 39.256C32.6352 51.345 28.2482 63.2808 26.733 72.7486C25.974 77.4911 25.9562 81.4881 26.6058 84.5284C27.2555 87.5691 28.5052 89.3937 30.0754 90.3013C31.6455 91.2088 33.8488 91.38 36.804 90.4229C39.7588 89.4659 43.2076 87.452 46.9306 84.4228C54.3633 78.3752 62.495 68.6037 69.4667 56.5148C76.4385 44.4259 80.8255 32.49 82.3407 23.0222C83.0997 18.2798 83.1175 14.2827 82.4679 11.2424ZM86.9414 23.7601C85.3154 33.9201 80.6831 46.3949 73.5018 58.8471C66.3206 71.2993 57.8453 81.5529 49.8693 88.0426C45.8879 91.282 41.932 93.6647 38.2381 94.861C34.5447 96.0572 30.8554 96.1383 27.7457 94.3409C24.636 92.5435 22.8615 89.3044 22.0495 85.5041C21.2375 81.7034 21.3207 77.0822 22.1323 72.0107C23.7583 61.8508 28.3906 49.376 35.5719 36.9238C42.7531 24.4716 51.2284 14.2179 59.2044 7.72828C63.1858 4.48886 67.1417 2.10613 70.8356 0.909822C74.529 -0.286384 78.2183 -0.367463 81.328 1.42994C84.4377 3.22735 86.2122 6.46644 87.0242 10.2668C87.8362 14.0674 87.753 18.6886 86.9414 23.7601Z" fill="#FF6C00" />
                <path d="M62.341 57.3216H59.0036L50.2111 43.4502C49.9886 43.0993 49.8045 42.7351 49.6591 42.3578H49.5821C49.6505 42.7615 49.6848 43.6257 49.6848 44.9505V57.3216H46.7324V38.4491H50.288L58.7854 51.9915C59.1448 52.5531 59.3759 52.9392 59.4786 53.1497H59.5299C59.4444 52.6496 59.4015 51.803 59.4015 50.6096V38.4491H62.341V57.3216Z" fill="#FF6C00" />
            </g>
            <defs>
                <clipPath id="clip0_1073_2146">
                    <rect width="107.165" height="95.4526" fill="white" transform="translate(0.954102 0.159058)" />
                </clipPath>
            </defs>
        </svg>


    )
}