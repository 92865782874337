import { useEffect } from 'react';
import styles from './bannerTextAnimated.module.scss';
export const BannerTextAnimated = ({ isPrincipal, isVisible, title1, title2, title3, titleMobile, text, button }) => {

    useEffect(()=>{

        //Funcion para evitar la copia del h1 en una misma pagina SEO
        document.addEventListener("DOMContentLoaded", function() {
            // Encuentra todos los clones del slider que tienen la clase 'slick-cloned'
            const clonedSlides = document.querySelectorAll(".slick-cloned h1");
          
            clonedSlides.forEach(clone => {
              // Cambia el h1 del clone por un div
              const div = document.createElement("div");
              div.innerHTML = clone.innerHTML; 
              clone.replaceWith(div);  
            });
          });
    },[])
    return (
        <div className={styles.banner__content}>
            {isPrincipal ?
                <h1>
                    <span className={isVisible ? styles["banner__title--animation1"] : styles.banner__title}>
                        {title1}
                    </span>
                    <span className={isVisible ? styles["banner__title--animation2"] : styles.banner__title}>
                        {title2}
                    </span>
                    {title3 && (
                        <span className={isVisible ? styles["banner__title--animation3"] : styles.banner__title}>
                            {title3}
                        </span>
                    )}
                </h1>
                :
                <>
                    <span className={isVisible ? styles["banner__title--animation1"] : styles.banner__title}>
                        {title1}
                    </span>
                    <span className={isVisible ? styles["banner__title--animation2"] : styles.banner__title}>
                        {title2}
                    </span>
                    {title3 && (
                        <span className={isVisible ? styles["banner__title--animation3"] : styles.banner__title}>
                            {title3}
                        </span>
                    )}
                </>
            }

            <div className={isVisible ? styles["banner__titleMobile--animation"] : styles.banner__titleMobile}>
                {titleMobile}
            </div>
            <div className={isVisible ? styles["banner__text--animation"] : styles.banner__text}>
                {text}
            </div>
            <div className={isVisible ? styles["button--animation"] : styles.button}>
                {button}
            </div>
        </div>
    )
}