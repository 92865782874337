export const ModularIcon = ({ size: { width = "56", height = '56' }, color = "#0ACF83" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.9037 25.1919L9.96191 30.3545L10.0403 40.8153L19.0605 46.1137L28.0023 40.9511L27.9239 30.4902L18.9037 25.1919Z" stroke={color} strokeWidth="5" strokeMiterlimit="10" />
            <path d="M27.7986 9.62305L18.8568 14.7856L18.9352 25.2465L27.9554 30.5448L36.8972 25.3823L36.8188 14.9214L27.7986 9.62305Z" stroke={color} strokeWidth="5" strokeMiterlimit="10" />
            <path d="M36.9393 25.4551L27.9975 30.6176L28.0759 41.0785L37.0961 46.3769L46.0379 41.2143L45.9595 30.7534L36.9393 25.4551Z" stroke={color} strokeWidth="5" strokeMiterlimit="10" />

        </svg>

    )
}