import { FormattedMessage, useIntl } from "react-intl"
import { BudderflyLogo2, Contact, Footer, Head, LightSpeedLogo, NavigateLogo, TangoeLogo, TopBar } from "../../components"
import { Introduction, WhyChoose } from "../../modules"
import styles from './technologies.module.scss';
import parse from "html-react-parser";
import { PhpIcon } from "../../assets/icons/technologies";
import { ScaleIcon, CommentIcon, DobleArrowIcon, CheckIcon } from "../../assets/icons";
import { KeyFeatures } from "../../modules/Technologies/KeyFeatures/KeyFeatures";
import { DevelopmentProcess } from "../../modules/Technologies/DevelopmentProcess/DevelopmentProcess";
import { Expertise } from "../../modules/Technologies/Expertise/Expertise";
import { TechnologiesClient } from "../../modules/Technologies/Clients/Clients";
import { Suspense, useEffect } from "react";
import ReactGA from 'react-ga';
import { CircularProgress } from "@mui/material";

export const PhpPage = () => {
    const { formatMessage } = useIntl();
    const renderLoader = () => <div className="spinner"><CircularProgress color="inherit" /></div>;

    useEffect(() => {
        ReactGA.pageview('/technologies/php');
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            {/* Encabezados y meta descriptions */}
            <Head
                title="PHP | Wakapi"
                description="Enhance web development with PHP. Discover its flexibility and ease of integration for dynamic and functional websites. "
                path="/technologies/php"
            />

            {/* Barra de navegación */}
            <TopBar />
            <main className={styles.container}>
                <Introduction
                    title={parse(formatMessage({ id: "technologies.Php-title" }))}
                    description={parse(formatMessage({ id: "technologies.Php-description" }))}
                    icon={<div className={styles.icon__php}><PhpIcon size={{ width: "167", height: "92" }} /></div>}
                />
                {/* WhyChoose modulo */}
                <WhyChoose
                    list={
                        [
                            {
                                icon: <DobleArrowIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.Php-why-choose-1-title" }),
                                description: formatMessage({ id: "technologies.Php-why-choose-1-text" }),
                            },
                            {
                                icon: <CheckIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.Php-why-choose-2-title" }),
                                description: formatMessage({ id: "technologies.Php-why-choose-2-text" }),
                            },
                            {
                                icon: <ScaleIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.Php-why-choose-3-title" }),
                                description: formatMessage({ id: "technologies.Php-why-choose-3-text" }),
                            },
                            {
                                icon: <CommentIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.Php-why-choose-4-title" }),
                                description: formatMessage({ id: "technologies.Php-why-choose-4-text" }),
                            },
                        ]
                    }
                    title={parse(formatMessage({ id: "technologies.Php-why-choose-title" }))}
                />
                <div className={styles.keyFeatures__background}>
                    <div className={styles.keyFeatures__container}>
                        <KeyFeatures
                            list={
                                [
                                    {
                                        title: formatMessage({ id: "technologies.Php-key-aspects-1-title" }),
                                        text: formatMessage({ id: "technologies.Php-key-aspects-1-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Php-key-aspects-2-title" }),
                                        text: formatMessage({ id: "technologies.Php-key-aspects-2-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Php-key-aspects-3-title" }),
                                        text: formatMessage({ id: "technologies.Php-key-aspects-3-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Php-key-aspects-4-title" }),
                                        text: formatMessage({ id: "technologies.Php-key-aspects-4-text" }),
                                    },
                                ]
                            }
                        />
                    </div>
                    <div className={styles.expertise__container}>
                        <Expertise
                            icon={<PhpIcon size={{ width: "125", height: "125" }} />}
                            title={formatMessage({ id: "technologies.Php-expertise-title" })}
                            text={formatMessage({ id: "technologies.Php-expertise-text" })}
                            list={
                                [
                                    {
                                        title: formatMessage({ id: "technologies.Php-expertise-1-title" }),
                                        text: formatMessage({ id: "technologies.Php-expertise-1-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Php-expertise-2-title" }),
                                        text: formatMessage({ id: "technologies.Php-expertise-2-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Php-expertise-3-title" }),
                                        text: formatMessage({ id: "technologies.Php-expertise-3-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Php-expertise-4-title" }),
                                        text: formatMessage({ id: "technologies.Php-expertise-4-text" }),
                                    },
                                ]
                            }
                        />
                    </div>
                </div>
                <div className={styles.process__background}>
                    <div className={styles.process__container}>
                        <DevelopmentProcess
                            title={formatMessage({ id: "technologies.development-process" })}
                            list={
                                [
                                    {
                                        title: formatMessage({ id: "technologies.Php-development-process-1-title" }),
                                        text: formatMessage({ id: "technologies.Php-development-process-1-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Php-development-process-2-title" }),
                                        text: formatMessage({ id: "technologies.Php-development-process-2-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Php-development-process-3-title" }),
                                        text: formatMessage({ id: "technologies.Php-development-process-3-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Php-development-process-4-title" }),
                                        text: formatMessage({ id: "technologies.Php-development-process-4-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Php-development-process-5-title" }),
                                        text: formatMessage({ id: "technologies.Php-development-process-5-text" }),
                                    },
                                ]
                            }
                        />
                    </div>
                </div>
                <TechnologiesClient
                    title={formatMessage({ id: "technologies.Php-satisfied" })}
                    list={[
                        <BudderflyLogo2
                        size={
                            {
                                width: '185',
                                height: '90'
                            }}
                    />,
                        <TangoeLogo
                            size={
                                {
                                    width: '94',
                                    height: '70'
                                }}
                        />,
                        <NavigateLogo
                            size={
                                {
                                    width: '178',
                                    height: '90'
                                }}
                        />,
                        <LightSpeedLogo
                            size={
                                {
                                    width: '173',
                                    height: '84'
                                }}
                        />
                    ]}
                />
                 <section className="contact__container">
                    <Suspense fallback={renderLoader()}>
                        <Contact />
                    </Suspense>
                </section>
                <Footer />
            </main>
        </>
    )
}