import { useEffect, useRef, useState } from 'react';

const useIntersectionObserver = (options={
    root: null,
    rootMargin: '300px',
    threshold: 1.0,
  }) => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
      // Verificacion de que el usuario llego a visualizar el componente para que comience a desplazarse
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [options]);

  return [containerRef, isVisible];
};

export default useIntersectionObserver;