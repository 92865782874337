import { FormattedMessage, useIntl } from 'react-intl';
import styles from './introductionTechnologies.module.scss';
import { Link } from 'react-router-dom';
import { Button, PreloadImage } from '../../../components';
import { useState } from 'react';

export const Introduction = ({title,description, icon}) => {
    const { formatMessage } = useIntl();
    const [isImgLoad, setIsLoad] = useState(false);
    <PreloadImage imagePath={`${process.env.PUBLIC_URL}/images/technologies_img.webp`}/>
    return (
        <div className={styles.container} >
            <div className={styles.data}>
                <div className={styles.text}>
                    <h1><FormattedMessage id="technologies.title" /></h1>
                    <h2>{title}</h2>
                    <p>{description}</p>
                    <Link to='https://meetings.hubspot.com/wakapi/meeting-with-wakapi?utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz--V7abN8cIRcLe7vAfQKMUdfarx3OhKkhpZ8SBYZ4Ks-gE-sO_ubxEZGu4v6ksDbVsDSsc2' target="__blank">
                        <Button secondary>
                            {formatMessage({ id: "technologies.button" })}
                        </Button>
                    </Link>
                </div>
                <div className={styles.img}>
                    <div className={styles.img__gradient}>
                        {isImgLoad && icon}
                    </div>
                    <img src={`${process.env.PUBLIC_URL}/images/technologies_img.webp`} width={614} height={473} onLoad={()=>setIsLoad(true)}/>
                </div>
            </div>
        </div>
    )
}