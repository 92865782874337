import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./amazon-infographic.module.scss";
import img1 from "./../../../../assets/images/clients/slider/amazon/outsourcing-qa-testing-services-coding1.webp";
import img2 from "./../../../../assets/images/clients/slider/amazon/outsourcing-qa-testing-services-coding2.webp";
import img3 from "./../../../../assets/images/clients/slider/amazon/outsourcing-qa-testing-services-coding3.webp";
import img4 from "./../../../../assets/images/clients/slider/amazon/outsourcing-qa-testing-services-coding4.webp";
import { Inforgraphic } from "../Infographic";
import { PreloadImage } from "../../../../components";

export const AmazonInfographic = () => {

  return (
    <Inforgraphic height={550}>
      <PreloadImage imagePath={img1}/>
      <PreloadImage imagePath={img2}/>
      <PreloadImage imagePath={img3}/>
      <div className={styles.block1}>
        <div className={styles.title__container}>
          <FormattedMessage id="clients.infographic-amazon-title" />
          <svg
            width="211"
            height="73"
            viewBox="0 0 211 73"
            fill="none"
            className={styles.arrowSvg__desktop}
          >
            <path
              d="M0.818359 37.345H209.818M173.447 0.539062L209.818 36.5391L173.447 72.5391"
              stroke="black"
            />
          </svg>
          <svg
            width="49"
            height="141"
            viewBox="0 0 49 141"
            fill="none"
            className={styles.arrowSvg__mobile}
          >
            <path
              d="M24.0478 0.539061L24.0477 139.539M48.5264 115.349L24.5838 139.539L0.641194 115.349"
              stroke="black"
            />
          </svg>
        </div>
        <img src={img1} alt="Outsourcing QA Testing Services Coding 1" className={styles.img} width={737} height={866} loading="lazy" />
        <div className={styles.text1}>
          <FormattedMessage id="clients.infographic-amazon-1" />
        </div>
      </div>
      <div className={styles.block2}>
        <img src={img2} alt="Outsourcing QA Testing Services Coding 2" className={styles.img} width={831} height={834} loading="lazy" />
        <div className={styles.text2}>
          <FormattedMessage id="clients.infographic-amazon-2" />
        </div>
      </div>
      <div className={styles.block3}>
        <div className={styles.text3}>
          <FormattedMessage id="clients.infographic-amazon-3" />
        </div>
        <img src={img3} alt="Outsourcing QA Testing Services Coding 3" className={styles.img} width={878} height={782} loading="lazy" />
      </div>
      <div className={styles.block4}>
        <div className={styles.yellow__block}>
          <div className={styles.text3}>
            <FormattedMessage id="clients.infographic-amazon-4" />
          </div>
        </div>
      </div>
      <div className={styles.block5}>
        <img src={img4} alt="Outsourcing QA Testing Services Coding 4" className={styles.img} width={727} height={551} loading="lazy" />
      </div>
    </Inforgraphic>
  );
};
export default AmazonInfographic;
