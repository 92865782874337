import React from 'react'
import slogan from './../../assets/images/logos/WAK-inspiringGeekRevolution.svg';
import logo from './../../assets/images/logos/wakapi-logo-white.svg';
import styles from './slogan.module.scss';

export const Slogan = () =>{
    return(
<div className={styles.container__slogan}>
          <img src={slogan} className={styles.slogan} alt='Inspiring geek revolution' width={860} height={98}/>
          <img src={logo} className={styles.logo} alt='Wakapi' width={130} height={40}/>
      </div>
    )
}

export default Slogan;