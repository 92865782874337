import { useIntl } from 'react-intl';
import styles from './expertise.module.scss';
export const Expertise = ({ icon, title, text, list }) => {
    const { formatMessage } = useIntl();
    return (
        <div className={styles.expertise}>
            {icon}
            <h2>{title}</h2>
            <p>
                {text}
                <br />
                <br />
                <b style={{fontSize:18}}>{formatMessage({ id: "technologies.what-we-bring-to-the-table" })}</b>
            </p>
            <div className={styles.expertise__list}>
                {list.map((item,index)=>{
                    return(
                        <div className={styles.expertise__item} key={index}>
                            <b>{item.title}</b>
                            <p>{item.text}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}