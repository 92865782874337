

export const getJobs = async () => {
    const response = await fetch('https://api.teamtailor.com/v1/jobs',{ 
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token token=tQWhUjMlhg3tGSFQG2Bew_noQZZZp007kkLHm9-2',
        'X-Api-Version': '20210218'
      }});
    const jobs = await response.json(); 
    return(jobs.data)
  }

