import styles from "./home.module.scss";
import React, { useEffect, lazy, Suspense } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import ReactGA from 'react-ga';
import { AwaBanner, Certifications, Presentacion, ServicesHome, ClientsSlider } from "../../modules";
import { Head, TopBar, Footer } from "../../components";
import { CaseStudiesSummaryHome } from "../../modules/Clients/Case-studies/CaseStudiesSummaryHome";


// Declaracion de modulos lazy
const Contact = lazy(() => import('../../components/Contact/Contact'));
const ClientsComments = lazy(() => import('../../modules/Clients/ClientsComments'));
const Team = lazy(() => import('../../modules/Team/Team'));


const Home = () => {
  // Loader para lazy components
  const renderLoader = () => <div className="spinner"><CircularProgress color="inherit" /></div>;

  useEffect(() => {
    // Componente de seguimiento google analitycs
    ReactGA.pageview('/wakapiHome');
    // Mostrar el inicio de la pagina al cargar
    window.scrollTo(0, 0);
  }, [])


  return (
    <>
      {/* Encabezados y meta descriptions */}
      <Head
        title="Wakapi - Software Development Services & Nearshore Outsourcing"
        description="Wakapi delivers Software Development Services & Solutions to skyrocket your software engineering power. Get to know us."
        path="/"
        haveCarousel={true}
      />

      {/* Barra de navegación */}
      <TopBar />

      {/* Contenido de pagina */}
      <main>

        <Presentacion />

        <ServicesHome/>

        <section className={styles.background__white}>
          <Suspense fallback={renderLoader()}>
            {/* <ClientsIcons /> */}
            <ClientsSlider/>
            <CaseStudiesSummaryHome/>
            <ClientsComments isHome={true}/>
          </Suspense>
        </section>

        <Suspense fallback={renderLoader()}>
          <Certifications />
        </Suspense>

        <Suspense fallback={renderLoader()}>
          <AwaBanner />
        </Suspense>

        <section className="team">
          <Suspense fallback={renderLoader()}>
            <Team />
          </Suspense>
        </section>

        <section  className="contact__container">
          <Suspense fallback={renderLoader()}>
            <Contact />
          </Suspense>
        </section>

      </main>

    {/* Pie de pagina */}
      <Footer />
    </>
  );
};

export default Home;
