export const IotIcon = ({ size: { width = "125", height = "137" } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 125 137" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M47.1504 78.5133V62.7089H50.3291V78.5133H47.1504Z" fill="#42ADB2" />
            <path d="M57.9662 78.7679C54.6438 78.7679 51.9778 76.2222 51.9778 72.7219C51.9778 69.2216 54.6438 66.6759 57.9662 66.6759C61.2885 66.6759 63.9545 69.2216 63.9545 72.7219C63.9545 76.2222 61.2885 78.7679 57.9662 78.7679ZM57.9662 75.904C59.6478 75.904 60.7758 74.5887 60.7758 72.7219C60.7758 70.8763 59.6478 69.5398 57.9662 69.5398C56.2845 69.5398 55.1566 70.8763 55.1566 72.7219C55.1566 74.5887 56.2845 75.904 57.9662 75.904Z" fill="#42ADB2" />
            <path d="M68.5488 78.5133V65.5728H64.6728V62.7089H75.5626V65.5728H71.7276V78.5133H68.5488Z" fill="#42ADB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M77.3896 49.1751H41.6184C38.5395 49.1751 36.0436 51.7006 36.0436 54.816V89.1314C36.0436 92.2468 38.5395 94.7723 41.6184 94.7723H77.3896C80.4685 94.7723 82.9644 92.2468 82.9644 89.1314V54.816C82.9644 51.7006 80.4685 49.1751 77.3896 49.1751ZM41.6184 45.4145C36.487 45.4145 32.3271 49.6237 32.3271 54.816V89.1314C32.3271 94.3237 36.487 98.5329 41.6184 98.5329H77.3896C82.521 98.5329 86.6809 94.3237 86.6809 89.1314V54.816C86.6809 49.6237 82.521 45.4145 77.3896 45.4145H41.6184Z" fill="#42ADB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M79.731 19.4272C78.1598 19.4272 76.8861 20.6562 76.8861 22.1723V46.6498H73.0928V22.1723C73.0928 18.6347 76.0648 15.767 79.731 15.767H96.5637V19.4272H79.731Z" fill="#42ADB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M49.622 18.2376L49.622 46.6498L45.916 46.6498L45.916 18.2376L49.622 18.2376Z" fill="#42ADB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.2942 33.0613V58.7558C11.2942 60.2763 12.5276 61.5088 14.0491 61.5088H33.5626V65.1795H14.0491C10.499 65.1795 7.62109 62.3035 7.62109 58.7558V33.0613H11.2942Z" fill="#42ADB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M110.152 66.4148H85.4453V62.7089H110.152V66.4148Z" fill="#42ADB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M102.473 87.4974H85.4453V83.7091H102.473C106.032 83.7091 108.916 86.6772 108.916 90.3386V112.121H105.235V90.3386C105.235 88.7695 103.998 87.4974 102.473 87.4974Z" fill="#42ADB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M64.4453 120.768L64.4453 97.2975L68.1512 97.2975L68.1512 120.768L64.4453 120.768Z" fill="#42ADB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M42.1268 114.197V97.2975H45.9151V114.197C45.9151 117.826 42.947 120.768 39.2856 120.768H17.5029V117.013H39.2856C40.8548 117.013 42.1268 115.752 42.1268 114.197Z" fill="#42ADB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M48.3864 14.5967C51.1513 14.5967 53.3927 12.3553 53.3927 9.59042C53.3927 6.82554 51.1513 4.58416 48.3864 4.58416C45.6216 4.58416 43.3802 6.82554 43.3802 9.59042C43.3802 12.3553 45.6216 14.5967 48.3864 14.5967ZM48.3864 18.2376C53.1621 18.2376 57.0336 14.3661 57.0336 9.59042C57.0336 4.81471 53.1621 0.943237 48.3864 0.943237C43.6107 0.943237 39.7393 4.81471 39.7393 9.59042C39.7393 14.3661 43.6107 18.2376 48.3864 18.2376Z" fill="#42ADB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M105.211 22.9838C107.976 22.9838 110.217 20.5823 110.217 17.6199C110.217 14.6576 107.976 12.2561 105.211 12.2561C102.446 12.2561 100.204 14.6576 100.204 17.6199C100.204 20.5823 102.446 22.9838 105.211 22.9838ZM105.211 26.8848C109.986 26.8848 113.858 22.7368 113.858 17.6199C113.858 12.5031 109.986 8.3551 105.211 8.3551C100.435 8.3551 96.5635 12.5031 96.5635 17.6199C96.5635 22.7368 100.435 26.8848 105.211 26.8848Z" fill="#42ADB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M116.328 68.9504C119.093 68.9504 121.334 66.709 121.334 63.9441C121.334 61.1792 119.093 58.9379 116.328 58.9379C113.563 58.9379 111.322 61.1792 111.322 63.9441C111.322 66.709 113.563 68.9504 116.328 68.9504ZM116.328 72.5913C121.104 72.5913 124.975 68.7198 124.975 63.9441C124.975 59.1684 121.104 55.2969 116.328 55.2969C111.552 55.2969 107.681 59.1684 107.681 63.9441C107.681 68.7198 111.552 72.5913 116.328 72.5913Z" fill="#42ADB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M107.064 124.279C110.026 124.279 112.428 121.878 112.428 118.915C112.428 115.953 110.026 113.552 107.064 113.552C104.101 113.552 101.7 115.953 101.7 118.915C101.7 121.878 104.101 124.279 107.064 124.279ZM107.064 128.18C112.18 128.18 116.329 124.032 116.329 118.915C116.329 113.799 112.18 109.651 107.064 109.651C101.947 109.651 97.7988 113.799 97.7988 118.915C97.7988 124.032 101.947 128.18 107.064 128.18Z" fill="#42ADB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M66.298 133.187C69.2604 133.187 71.6619 130.945 71.6619 128.18C71.6619 125.416 69.2604 123.174 66.298 123.174C63.3357 123.174 60.9342 125.416 60.9342 128.18C60.9342 130.945 63.3357 133.187 66.298 133.187ZM66.298 136.828C71.4149 136.828 75.5629 132.956 75.5629 128.18C75.5629 123.405 71.4149 119.533 66.298 119.533C61.1812 119.533 57.0332 123.405 57.0332 128.18C57.0332 132.956 61.1812 136.828 66.298 136.828Z" fill="#42ADB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.5622 124.279C15.3271 124.279 17.5685 121.878 17.5685 118.915C17.5685 115.953 15.3271 113.552 12.5622 113.552C9.79734 113.552 7.55596 115.953 7.55596 118.915C7.55596 121.878 9.79734 124.279 12.5622 124.279ZM12.5622 128.18C17.3379 128.18 21.2094 124.032 21.2094 118.915C21.2094 113.799 17.3379 109.651 12.5622 109.651C7.78652 109.651 3.91504 113.799 3.91504 118.915C3.91504 124.032 7.78652 128.18 12.5622 128.18Z" fill="#42ADB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.85617 31.8911C11.6211 31.8911 13.8624 29.6497 13.8624 26.8848C13.8624 24.1199 11.6211 21.8785 8.85617 21.8785C6.09128 21.8785 3.8499 24.1199 3.8499 26.8848C3.8499 29.6497 6.09128 31.8911 8.85617 31.8911ZM8.85617 35.532C13.6319 35.532 17.5034 31.6605 17.5034 26.8848C17.5034 22.1091 13.6319 18.2376 8.85617 18.2376C4.08046 18.2376 0.208984 22.1091 0.208984 26.8848C0.208984 31.6605 4.08046 35.532 8.85617 35.532Z" fill="#42ADB2" />
        </svg>

    )
}