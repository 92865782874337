export const WebAppIcon = ({ size: { width = "143", height = "35" } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 143 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M132.108 7.67084C137.653 7.67084 142.018 12.143 142.018 17.8675C142.018 23.5561 137.725 28.0641 132.072 28.0641C129.782 28.0641 127.814 27.3128 126.491 26.0963V34.7903H121.124V8.10017H126.133V10.1395C127.492 8.56528 129.603 7.67084 132.108 7.67084ZM131.428 23.2341C134.362 23.2341 136.473 20.9801 136.473 17.8675C136.473 14.7548 134.362 12.5008 131.428 12.5008C128.53 12.5008 126.491 14.719 126.491 17.8675C126.491 21.0159 128.53 23.2341 131.428 23.2341Z" fill="#E69C19" />
            <path d="M108.7 7.67084C114.245 7.67084 118.61 12.143 118.61 17.8675C118.61 23.5561 114.317 28.0641 108.664 28.0641C106.374 28.0641 104.406 27.3128 103.082 26.0963V34.7903H97.7158V8.10017H102.725V10.1395C104.084 8.56528 106.195 7.67084 108.7 7.67084ZM108.02 23.2341C110.954 23.2341 113.064 20.9801 113.064 17.8675C113.064 14.7548 110.954 12.5008 108.02 12.5008C105.122 12.5008 103.082 14.719 103.082 17.8675C103.082 21.0159 105.122 23.2341 108.02 23.2341Z" fill="#E69C19" />
            <path d="M86.4841 7.67084C91.4572 7.67084 94.8561 10.5688 94.8561 14.7548V27.6348H89.8472V25.8459C88.3803 27.3843 86.5914 28.0641 84.3374 28.0641C79.901 28.0641 77.1104 25.5597 77.1104 22.3039C77.1104 19.4059 79.4001 17.5097 83.3714 16.6868L89.4894 15.3988V14.7548C89.4894 13.2879 88.273 12.3219 86.4841 12.3219C84.5879 12.3219 83.0137 13.431 82.4412 15.1841L77.8259 12.9659C78.9708 9.74594 82.3339 7.67084 86.4841 7.67084ZM85.1603 24.1286C87.5932 24.1286 89.4894 22.3754 89.4894 20.193V19.2628L84.3374 20.4077C83.2283 20.6581 82.6559 21.3379 82.6559 22.2323C82.6559 23.413 83.6219 24.1286 85.1603 24.1286Z" fill="#E69C19" />
            <path d="M65.7581 7.67089C71.3037 7.67089 75.6685 12.1431 75.6685 17.8675C75.6685 23.5562 71.411 28.0641 65.7224 28.0641C63.3253 28.0641 61.1786 27.277 59.7833 25.7744V27.6348H54.7744V0.980469H60.1411V9.74599C61.4648 8.42222 63.4326 7.67089 65.7581 7.67089ZM65.0784 23.2342C68.0121 23.2342 70.123 21.0159 70.123 17.8675C70.123 14.7549 68.0121 12.5009 65.0784 12.5009C62.1804 12.5009 60.1411 14.7191 60.1411 17.8675C60.1411 21.0517 62.1804 23.2342 65.0784 23.2342Z" fill="#0ACF83" />
            <path d="M52.6231 17.295C52.6231 18.0821 52.5515 18.8335 52.3727 19.3701H38.634C38.956 21.8388 40.7091 23.413 43.3567 23.413C45.2529 23.413 46.6482 22.5901 47.3996 21.2663L51.6929 23.3057C50.5122 26.1679 47.2922 28.0641 43.2851 28.0641C37.0956 28.0641 33.0527 23.4846 33.0527 17.8317C33.0527 11.9999 37.3461 7.67084 42.9274 7.67084C48.4013 7.67084 52.6231 11.8926 52.6231 17.295ZM38.7414 15.4346H46.9345C46.7198 13.3595 45.1456 11.9642 42.9274 11.9642C40.8165 11.9642 39.278 13.1806 38.7414 15.4346Z" fill="#0ACF83" />
            <path d="M27.1188 8.10022H32.7001L25.9739 27.6348H21.2513L16.7791 14.6117L12.2711 27.6348H7.54846L0.822266 8.10022H6.3678L9.98134 19.7637L13.7738 8.10022H19.7486L23.5411 19.7637L27.1188 8.10022Z" fill="#0ACF83" />
        </svg>


    )
}