import React, { useEffect } from "react";
import ReactGA from 'react-ga';
import { Head, TopBar, Footer } from "../../components";

export const Disclaimer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview('/disclaimer');
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <>
      <Head
        title="Disclaimer | Wakapi"
        description="Learn more about our website disclaimer."
        path="/disclaimer"
      />
      <TopBar />
      <div className="policy__container" style={{ minHeight: '100vh' }}>
        <div className="policy__text">
        <div className="policy__title"><h1>Disclaimer</h1></div>    
          <div
            name="termly-embed"
            data-id="0b659849-8a50-47be-a848-1537aefbe59e"
            data-type="iframe"
          ></div>
        </div>

      </div>
      <Footer />
    </>

  );
};
export default Disclaimer;