export const FlutterIcon = ({ size: { width = "108", height = "131" } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 108 131" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.9583 83.8215L7.0498 65.8853L65.2043 7.64087H101.021" fill="#42A5F5" />
            <path d="M101.021 61.3852H65.2044L51.7891 74.8213L69.6976 92.7575" fill="#42A5F5" />
            <path d="M51.7891 110.694L65.2044 124.13H101.021L69.6976 92.7575" fill="#0D47A1" />
            <path d="M51.8026 74.8577L33.9199 92.7681L51.8026 110.678L69.6852 92.7681L51.8026 74.8577Z" fill="#42A5F5" />
            <path d="M69.6854 92.768L51.8027 110.678L54.299 113.179L72.1817 95.2682L69.6854 92.768Z" fill="url(#paint0_linear_1073_2052)" />
            <path d="M51.7891 110.694L78.363 101.501L69.6976 92.7575" fill="url(#paint1_linear_1073_2052)" />
            <defs>
                <linearGradient id="paint0_linear_1073_2052" x1="51.8027" y1="110.678" x2="301.82" y2="360.309" gradientUnits="userSpaceOnUse">
                    <stop offset="0.2" stopOpacity="0.15" />
                    <stop offset="0.85" stopColor="#616161" stopOpacity="0.01" />
                </linearGradient>
                <linearGradient id="paint1_linear_1073_2052" x1="51.7891" y1="92.7575" x2="78.363" y2="92.7575" gradientUnits="userSpaceOnUse">
                    <stop offset="0.2" stopOpacity="0.55" />
                    <stop offset="0.85" stopColor="#616161" stopOpacity="0.01" />
                </linearGradient>
            </defs>
        </svg>


    )
}