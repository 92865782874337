export const RubyIcon = ({ size: { width = "95", height = "94" } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 95 94" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1073_1979)">
                <path fillRule="evenodd" clipRule="evenodd" d="M73.3107 61.8922L19.624 93.7711L89.1382 89.054L94.4921 18.9599L73.3107 61.8922Z" fill="url(#paint0_linear_1073_1979)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M89.2522 89.006L83.2774 47.7681L67.0029 69.2579L89.2522 89.006Z" fill="url(#paint1_linear_1073_1979)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M89.3326 89.006L45.5608 85.5699L19.8564 93.6813L89.3326 89.006Z" fill="url(#paint2_linear_1073_1979)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M19.9184 93.6903L30.8532 57.8676L6.79102 63.0127L19.9184 93.6903Z" fill="url(#paint3_linear_1073_1979)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M66.999 69.3766L56.9375 29.9659L28.1436 56.9564L66.999 69.3766Z" fill="url(#paint4_linear_1073_1979)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M92.2098 30.5211L64.9915 8.29089L57.4121 32.7945L92.2098 30.5211Z" fill="url(#paint5_linear_1073_1979)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M79.4808 0.365049L63.4725 9.21159L53.373 0.246399L79.4808 0.365049Z" fill="url(#paint6_linear_1073_1979)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0.459961 75.0291L7.16605 62.7987L1.74138 48.2285L0.459961 75.0291Z" fill="url(#paint7_linear_1073_1979)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M1.38086 47.7681L6.83876 63.2495L30.5545 57.9288L57.6304 32.7655L65.2715 8.49497L53.2399 -0.000366211L32.7846 7.65493C26.3401 13.6491 13.8344 25.5094 13.3835 25.7324C12.9374 25.9602 5.12545 40.725 1.38086 47.7681Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M20.5451 19.9565C34.5126 6.1077 52.5189 -2.07439 59.4291 4.89747C66.335 11.8693 59.0114 28.8126 45.044 42.6566C31.0765 56.5007 13.2932 65.1337 6.38782 58.1618C-0.522355 51.1947 6.57766 33.8006 20.5451 19.9565Z" fill="url(#paint8_linear_1073_1979)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M19.9189 93.6761L30.7683 57.7394L66.7999 69.3149C53.7721 81.5311 39.2826 91.8584 19.9189 93.6761Z" fill="url(#paint9_linear_1073_1979)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M57.6777 32.6948L66.9277 69.3339C77.8103 57.8913 87.5775 45.5897 92.361 30.374L57.6777 32.6948Z" fill="url(#paint10_linear_1073_1979)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M92.2665 30.5591C95.9684 19.387 96.8226 3.35977 79.3664 0.384033L65.043 8.29561L92.2665 30.5591Z" fill="url(#paint11_linear_1073_1979)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0.459961 74.8677C0.972529 93.3064 14.2756 93.5812 19.9423 93.743L6.85282 63.1736L0.459961 74.8677Z" fill="#9E1209" />
                <path fillRule="evenodd" clipRule="evenodd" d="M57.7295 32.7518C66.0919 37.8917 82.9455 48.2142 83.2872 48.4041C83.8182 48.7031 90.5533 37.0469 92.0815 30.4595L57.7295 32.7518Z" fill="url(#paint12_radial_1073_1979)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M30.7539 57.7394L45.2577 85.7218C53.8337 81.0708 60.5493 75.404 66.7001 69.3339L30.7539 57.7394Z" fill="url(#paint13_radial_1073_1979)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M6.79623 63.2116L4.74121 87.6819C8.61869 92.9784 13.9532 93.4388 19.5487 93.0259C15.5004 82.9502 7.41321 62.8034 6.79623 63.2116Z" fill="url(#paint14_linear_1073_1979)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M64.958 8.35731L93.771 12.4009C92.2333 5.88465 87.511 1.67969 79.4618 0.365051L64.958 8.35731Z" fill="url(#paint15_linear_1073_1979)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_1073_1979" x1="83.075" y1="102.299" x2="63.238" y2="67.2757" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FB7655" />
                    <stop offset="0.41" stop-color="#E42B1E" />
                    <stop offset="0.99" stop-color="#990000" />
                    <stop offset="1" stop-color="#990000" />
                </linearGradient>
                <linearGradient id="paint1_linear_1073_1979" x1="92.9569" y1="72.878" x2="67.3914" y2="55.722" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#871101" />
                    <stop offset="0.99" stop-color="#911209" />
                    <stop offset="1" stop-color="#911209" />
                </linearGradient>
                <linearGradient id="paint2_linear_1073_1979" x1="72.5016" y1="103.36" x2="46.937" y2="86.205" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#871101" />
                    <stop offset="0.99" stop-color="#911209" />
                    <stop offset="1" stop-color="#911209" />
                </linearGradient>
                <linearGradient id="paint3_linear_1073_1979" x1="18.8251" y1="60.4592" x2="22.7883" y2="86.2161" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="0.23" stop-color="#E57252" />
                    <stop offset="0.46" stop-color="#DE3B20" />
                    <stop offset="0.99" stop-color="#A60003" />
                    <stop offset="1" stop-color="#A60003" />
                </linearGradient>
                <linearGradient id="paint4_linear_1073_1979" x1="46.0847" y1="36.4087" x2="47.545" y2="62.6953" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="0.23" stop-color="#E4714E" />
                    <stop offset="0.56" stop-color="#BE1A0D" />
                    <stop offset="0.99" stop-color="#A80D00" />
                    <stop offset="1" stop-color="#A80D00" />
                </linearGradient>
                <linearGradient id="paint5_linear_1073_1979" x1="70.2752" y1="12.1119" x2="74.6468" y2="30.9512" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="0.18" stop-color="#E46342" />
                    <stop offset="0.4" stop-color="#C82410" />
                    <stop offset="0.99" stop-color="#A80D00" />
                    <stop offset="1" stop-color="#A80D00" />
                </linearGradient>
                <linearGradient id="paint6_linear_1073_1979" x1="56.9259" y1="5.4772" x2="75.764" y2="-3.94185" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="0.54" stop-color="#C81F11" />
                    <stop offset="0.99" stop-color="#BF0905" />
                    <stop offset="1" stop-color="#BF0905" />
                </linearGradient>
                <linearGradient id="paint7_linear_1073_1979" x1="2.31247" y1="53.8927" x2="3.86294" y2="69.4159" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="0.31" stop-color="#DE4024" />
                    <stop offset="0.99" stop-color="#BF190B" />
                    <stop offset="1" stop-color="#BF190B" />
                </linearGradient>
                <linearGradient id="paint8_linear_1073_1979" x1="-8.34646" y1="73.6122" x2="64.5382" y2="-1.33369" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#BD0012" />
                    <stop offset="0.07" stop-color="white" />
                    <stop offset="0.17" stop-color="white" />
                    <stop offset="0.27" stop-color="#C82F1C" />
                    <stop offset="0.33" stop-color="#820C01" />
                    <stop offset="0.46" stop-color="#A31601" />
                    <stop offset="0.72" stop-color="#B31301" />
                    <stop offset="0.99" stop-color="#E82609" />
                    <stop offset="1" stop-color="#E82609" />
                </linearGradient>
                <linearGradient id="paint9_linear_1073_1979" x1="47.4812" y1="81.1719" x2="25.5276" y2="75.7536" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8C0C01" />
                    <stop offset="0.54" stop-color="#990C00" />
                    <stop offset="0.99" stop-color="#A80D0E" />
                    <stop offset="1" stop-color="#A80D0E" />
                </linearGradient>
                <linearGradient id="paint10_linear_1073_1979" x1="85.1883" y1="54.8228" x2="65.6855" y2="37.343" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#7E110B" />
                    <stop offset="0.99" stop-color="#9E0C00" />
                    <stop offset="1" stop-color="#9E0C00" />
                </linearGradient>
                <linearGradient id="paint11_linear_1073_1979" x1="92.3536" y1="22.7505" x2="82.6389" y2="12.3647" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#79130D" />
                    <stop offset="0.99" stop-color="#9E120B" />
                    <stop offset="1" stop-color="#9E120B" />
                </linearGradient>
                <radialGradient id="paint12_radial_1073_1979" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(68.7224 37.6773) scale(23.8997 23.8997)">
                    <stop stop-color="#A80D00" />
                    <stop offset="0.99" stop-color="#7E0E08" />
                    <stop offset="1" stop-color="#7E0E08" />
                </radialGradient>
                <radialGradient id="paint13_radial_1073_1979" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(35.6244 69.173) scale(31.7715 31.7715)">
                    <stop stop-color="#A30C00" />
                    <stop offset="0.99" stop-color="#800E08" />
                    <stop offset="1" stop-color="#800E08" />
                </radialGradient>
                <linearGradient id="paint14_linear_1073_1979" x1="13.1178" y1="93.6552" x2="5.20096" y2="66.7957" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8B2114" />
                    <stop offset="0.43" stop-color="#9E100A" />
                    <stop offset="0.99" stop-color="#B3100C" />
                    <stop offset="1" stop-color="#B3100C" />
                </linearGradient>
                <linearGradient id="paint15_linear_1073_1979" x1="73.8525" y1="4.64969" x2="91.6016" y2="12.4844" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B31000" />
                    <stop offset="0.44" stop-color="#910F08" />
                    <stop offset="0.99" stop-color="#791C12" />
                    <stop offset="1" stop-color="#791C12" />
                </linearGradient>
                <clipPath id="clip0_1073_1979">
                    <rect width="94.0324" height="93.7714" fill="white" transform="translate(0.459961 -0.000366211)" />
                </clipPath>
            </defs>
        </svg>

    )
}