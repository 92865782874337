import { FormattedMessage } from 'react-intl';
import { PreloadImage } from '../../../../components';
import { Inforgraphic } from '../Infographic';
import styles from './digicel.module.scss';
import img1 from './../../../../assets/images/clients/slider/digicel/digicel-improve-app-performance-and-boost-overall-customer-satisfaction-1.webp';
import img2 from './../../../../assets/images/clients/slider/digicel/digicel-improve-app-performance-and-boost-overall-customer-satisfaction-2.webp';
import img3 from './../../../../assets/images/clients/slider/digicel/digicel-improve-app-performance-and-boost-overall-customer-satisfaction-3.webp';
import img4 from './../../../../assets/images/clients/slider/digicel/digicel-improve-app-performance-and-boost-overall-customer-satisfaction-4.webp';

export const DigicelInfographic = () => {
    return (
        <Inforgraphic >
            <PreloadImage imagePath={img1}/>
            <PreloadImage imagePath={img2}/>
            <PreloadImage imagePath={img3}/>
            <div className={styles.block1}>
                <div className={styles.block1__inner}>
                    <div className={styles.title__container}>
                        <FormattedMessage id="clients.infographic-digicel-title" />
                        <svg
                            width="211"
                            height="73"
                            viewBox="0 0 211 73"
                            fill="none"
                            className={styles.arrowSvg__desktop}
                        >
                            <path
                                d="M0.818359 37.345H209.818M173.447 0.539062L209.818 36.5391L173.447 72.5391"
                                stroke="black"
                            />
                        </svg>
                        <svg
                            width="49"
                            height="141"
                            viewBox="0 0 49 141"
                            fill="none"
                            className={styles.arrowSvg__mobile}
                        >
                            <path
                                d="M24.0478 0.539061L24.0477 139.539M48.5264 115.349L24.5838 139.539L0.641194 115.349"
                                stroke="black"
                            />
                        </svg>
                    </div>
                </div>
                <img src={img1} alt='Digicel - services wakapi provided to Digicel' width={464} height={451} />

            </div>
            <div className={styles.block2}>
                <div className={styles.block2__inner1}>
                    <div className={styles.text}>
                        <FormattedMessage id="clients.infographic-digicel-1" />
                    </div>
                </div>
                <div className={styles.block2__inner2}>
                    <div className={styles.text}>
                        <FormattedMessage id="clients.infographic-digicel-2" />
                    </div>
                    <img src={img2} alt='Digicel - developed a microservice architecture for the payment application' width={292} height={451} />
                </div>

            </div>
            <div className={styles.block3}>
                <div className={styles.block3__inner1}>
                    <div className={styles.text}>
                        <FormattedMessage id="clients.infographic-digicel-3" />
                    </div>
                </div>
                <div className={styles.block3__inner2}>
                    <div className={styles.text}>
                        <FormattedMessage id="clients.infographic-digicel-4" />
                    </div>
                </div>
            </div>
            <div className={styles.block4}>
                <img src={img3} alt='Digicel - Implemented JUnit and automated integration test for better quality assurance' width={537} height={306} />
                <div className={styles.text}>
                    <FormattedMessage id="clients.infographic-digicel-5" />
                </div>
            </div>
            <div className={styles.block5}>
                <div className={styles.text}>
                    <FormattedMessage id="clients.infographic-digicel-6" />
                </div>
                <img src={img4} alt='Digicel - Facilitated faster implementation of new features' width={434} height={451} />
            </div>

        </Inforgraphic>
    )
}