import { Head, Contact, Footer, TopBar } from "../../components"

export const ContactPage = () => {
    return (
        <>
            <Head
                title="Wakapi - Contact"
                description="Wakapi delivers Software Development Services & Solutions to skyrocket your software engineering power. Get to know us."
                path="/contact"
                haveCarousel={false}
            />
            <TopBar />
            <div style={{ marginTop: 95 }}>
                <Contact isPageContact />
            </div>
            <Footer />
        </>
    )
}