import React, {useEffect} from 'react';
import { Routes, Route  } from "react-router-dom";
import Home from './pages/Home/Home';
import Nosotros from './pages/Nosotros/Nosotros'
import Services from './pages/Services/Services';
import Clients from './pages/Clients/Clients';
import Team from './pages/Team/Team';
import NotFound from './pages/404/404';
import PrivacyPolicy from './pages/Policy/privacy-policy';
import CookiePolicy from './pages/Policy/cookie-policy';
import Disclaimer from './pages/Policy/disclaimer';
import ContactConfirm from './pages/Contact-confirm/Contact-confirm';
import { ContactPage } from './pages/Contact/Contact';
import ReactGA from 'react-ga';
import { LandingOutsourcingServices } from './pages/Landing/Landing-outsourcing-services';
import { ClientsPinPointPage } from './pages/Clients/Pinpoint';
import { ClientsThomsonReutersPage } from './pages/Clients/ThomsomReuters';
import { ClientsWelfiPage } from './pages/Clients/Welfi';
import { ClientsAmazonPage } from './pages/Clients/Amazon';
import { ClientsErthPage } from './pages/Clients/Erth';
import { ClientsDollarGeneralPage } from './pages/Clients/DollarGeneral';
import { ClientsBudderflyPage } from './pages/Clients/Budderfly';
import { LandingStaffing } from './pages/Landing/Landing-staffing';
import { LandingDevelopment } from './pages/Landing/Landing-development';
import { AngularPage } from './pages/Technologies/TechnologiesAngular';
import { FlutterPage } from './pages/Technologies/TechnologiesFlutter';
import { NetPage } from './pages/Technologies/Technologies.Net';
import { PhpPage } from './pages/Technologies/TechnologiesPhp';
import { PWAPage } from './pages/Technologies/TechnologiesPWA';
import { PythonPage } from './pages/Technologies/TechnologiesPython';
import { ReactJsPage } from './pages/Technologies/TechnologiesReactJs';
import { ReactNativePage } from './pages/Technologies/TechnologiesReactNative';
import { ReactNodePage } from './pages/Technologies/TechnologiesReactNode';
import { RubyPage } from './pages/Technologies/TechnologiesRuby';
import { SaasPage } from './pages/Technologies/TechnologiesSaaS';
import { SqlPage } from './pages/Technologies/TechnologiesSql';
import { WebAppPage } from './pages/Technologies/TechnologiesWebApp';
import { CPage } from './pages/Technologies/TechnologiesC';
import { IoTPage } from './pages/Technologies/TechnologiesIoT';
import { JavaPage } from './pages/Technologies/TechnologiesJava';
import { HtmlPage } from './pages/Technologies/TechnologiesHtml';
import { MachineLearningPage } from './pages/Technologies/TechnologiesMachineLearning';
import { AIPage } from './pages/Technologies/TechnologiesAi';
import { ClientsDigicelPage } from './pages/Clients/Digicel';

export const AppRoutes = () => {
    useEffect(() => {
        ReactGA.initialize('AW-10927503275');
    }, []);
    return (
        <Routes>
            {/* Landings */}
            <Route path="/:lang?/software-outsourcing-services-in-latin-america" element={<LandingOutsourcingServices />} />
            <Route path="/:lang?/it-staffing-services-technical-recruitment" element={<LandingStaffing />} />
            <Route path="/:lang?/custom-made-software-development" element={<LandingDevelopment />} />

            {/* Nosotros */}
            <Route path="/:lang?/about-us" element={<Nosotros />} basename='nosotros' />

            {/* Servicios */}
            <Route path="/:lang?/services" element={<Services />} />
            <Route path="/:lang?/services/:item" element={<Services />} />

            {/* Clientes */}
            <Route path="/:lang?/clients" element={<Clients />} />
            <Route path="/:lang?/clients/pinpoint" element={<ClientsPinPointPage />} />            
            <Route path="/:lang?/clients/thomson-reuters" element={<ClientsThomsonReutersPage />} />
            <Route path="/:lang?/clients/welfi" element={<ClientsWelfiPage />} />
            <Route path="/:lang?/clients/amazon" element={<ClientsAmazonPage />} />            
            <Route path="/:lang?/clients/erth" element={<ClientsErthPage />} />                       
            <Route path="/:lang?/clients/dollar-general" element={<ClientsDollarGeneralPage />} />             
            <Route path="/:lang?/clients/budderfly" element={<ClientsBudderflyPage />} />
            <Route path="/:lang?/clients/digicel" element={<ClientsDigicelPage />} />

            {/* Equipo */}
            <Route path="/:lang?/team" element={<Team />} />
            <Route path="/:lang?/team/:position" element={<Team />} />

            {/* Tecnologias */}
            <Route path="/:lang?/technologies/angular" element={<AngularPage/>}/>
            <Route path="/:lang?/technologies/flutter" element={<FlutterPage/>}/>
            <Route path="/:lang?/technologies/net" element={<NetPage/>}/>
            <Route path="/:lang?/technologies/php" element={<PhpPage/>}/>
            <Route path="/:lang?/technologies/progressive-web-app" element={<PWAPage/>}/>
            <Route path="/:lang?/technologies/python" element={<PythonPage/>}/>
            <Route path="/:lang?/technologies/react-js" element={<ReactJsPage/>}/>
            <Route path="/:lang?/technologies/react-native" element={<ReactNativePage/>}/>
            <Route path="/:lang?/technologies/react-node" element={<ReactNodePage/>}/>
            <Route path="/:lang?/technologies/ruby" element={<RubyPage/>}/>
            <Route path="/:lang?/technologies/saas" element={<SaasPage/>}/>
            <Route path="/:lang?/technologies/sql" element={<SqlPage/>}/>
            <Route path="/:lang?/technologies/web-app" element={<WebAppPage/>}/>
            <Route path="/:lang?/technologies/ai" element={<AIPage/>}/>
            <Route path="/:lang?/technologies/c-c-sharp-c-plus-plus" element={<CPage/>}/>
            <Route path="/:lang?/technologies/html" element={<HtmlPage/>}/>
            <Route path="/:lang?/technologies/iot" element={<IoTPage/>}/>
            <Route path="/:lang?/technologies/java" element={<JavaPage/>}/>
            <Route path="/:lang?/technologies/machine-learning" element={<MachineLearningPage/>}/>

            {/* Politicas, privacidad y disclaimer */}
            <Route path="/:lang?/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/:lang?/cookie-policy" element={<CookiePolicy />} />
            <Route path="/:lang?/disclaimer" element={<Disclaimer />} />

            {/* Contacto */}
            <Route path="/:lang?/contact" element={<ContactPage />} />
            <Route path="/:lang?/contact-confirm" element={<ContactConfirm />} />        

            {/* Home */}
            <Route path="/:lang?/" element={<Home />} />

            {/* Not found */}
            <Route path="/:lang?/*" exact={true} element={<NotFound />} />
            <Route path="/*" element={<Home />} />
        </Routes>
    )
};