import { useEffect } from "react";
import { Helmet } from "react-helmet-async"
import { useLocation, useSearchParams } from "react-router-dom";

export const Head = ({ title, description, haveCarousel, noRobots, path }) => {

    //Validacion de idioma en url
    const location = useLocation();
    const lang = location.pathname.includes('/es') ? 'es' : 'en';

    return (
        <Helmet
            defer={false}
            prioritizeSeoTags={true}
            async={true}
            title={title}
            meta={[
                { name: "title", content: title },
                { name: "description", content: description },
                { property: "robots", content: noRobots ? "noindex" : "all" },
                { property: "og:site_name", content: "Wakapi" },

                { property: "og:image", content: "https://www.wakapi.com/graph.jpg" },
                { property: "og:image:width", content: "1200" },
                { property: "og:image:height", content: "630" },
                { property: "og:url", content: "https://www.wakapi.com/" },
                { property: "og:title", content: "Wakapi: software outsourcing done right!" },
                { property: "og:description", content: "Build development teams fast | Maximize your budget | Benefit from overlapping business hours." },
                { property: "og:type", content: "website" },
                { property: "og:site_name", content: "Wakapi" },

                { property: "twitter:card", content: "summary_large_image" },
                { property: "twitter:domain", content: "wakapi.com" },
                { property: "twitter:url", content: "https://www.wakapi.com/" },
                { property: "twitter:title", content: "Wakapi: software outsourcing done right!" },
                { property: "twitter:description", content: "Build development teams fast | Maximize your budget | Benefit from overlapping business hours." },
                { property: "twitter:image", content: "https://www.wakapi.com/graph.jpg" },

            ]}
            link={haveCarousel ?
                [
                    { rel: "canonical", href: `https://www.wakapi.com/${lang}${path}` },
                    { rel: "alternate", href: `https://www.wakapi.com/es${path}`, hreflang: "es" },
                    { rel: "alternate", href: `https://www.wakapi.com/en${path}`, hreflang: "en" },
                    { rel: "alternate", href: `https://www.wakapi.com/`, hreflang: "x-default" },
                    { rel: "preconnect", type: "text/css", href: "https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap' as='style' onload='this.onload=null';this.rel='stylesheet'" },
                    { rel: "apple-touch-icon", href: "%PUBLIC_URL%/favicon.png" },
                    { rel: "preconnect", type: "script", href: "https://app.termly.io/resource-blocker/60b99b72-0c81-4534-988d-b8e51f77fc2c?autoBlock=on", defer: true },
                    { rel: "preconnect", type: "script", href: "https://www.googletagmanager.com/gtag/js?id=AW-10927503275", defer: true },
                    { rel: "preload", type: "text/css", charset: "UTF-8", href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css" },
                    { rel: "preload", type: "text/css", href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css" },
                    { rel: "stylesheet", type: "text/css", charset: "UTF-8", href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css" },
                    { rel: "stylesheet", type: "text/css", href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css" },
                ]
                :
                [
                    { rel: "canonical", href: `https://www.wakapi.com/${lang}${path}` },
                    { rel: "alternate", href: `https://www.wakapi.com/es${path}`, hreflang: "es" },
                    { rel: "alternate", href: `https://www.wakapi.com/en${path}`, hreflang: "en" },
                    { rel: "alternate", href: `https://www.wakapi.com/`, hreflang: "x-default" },
                    { rel: "preconnect", type: "text/css", href: "https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap' as='style' onload='this.onload=null';this.rel='stylesheet'" },
                    { rel: "apple-touch-icon", href: "%PUBLIC_URL%/favicon.png" },
                    { rel: "preconnect", type: "script", href: "https://app.termly.io/resource-blocker/60b99b72-0c81-4534-988d-b8e51f77fc2c?autoBlock=on", defer: true },
                    { rel: "preconnect", type: "script", href: "https://www.googletagmanager.com/gtag/js?id=AW-10927503275", defer: true },
                ]
            }
        >
            <html lang={lang} />
            <script>
                {`
          !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');

        `}
            </script>
            <noscript>
                {`
                <img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=240971342282440&ev=PageView&noscript=1" />
                     `}
            </noscript>
            <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=AW-10927503275"
                defer={true}
            />
            <script >
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag() { dataLayer.push(arguments); }
                    gtag('js', new Date());
                
                    gtag('config', 'AW-10927503275');
                `}
            </script>
            <script >
                {`gtag('event', 'page_view', {
                'send_to': 'AW-10927503275',
                'value': 'replace with value',
                'items': [{
                'id': 'replace with value',
                'location_id': 'replace with value',
                'google_business_vertical': 'custom'
                }]
                });`}
            </script>

            <script
                async
                defer={true}
                src="https://app.termly.io/resource-blocker/60b99b72-0c81-4534-988d-b8e51f77fc2c?autoBlock=on"
            />

            <script async defer={true}>
                {`_linkedin_partner_id = "6535641";
                    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                    window._linkedin_data_partner_ids.push(_linkedin_partner_id);`
                }
            </script>

            <script async defer={true}>
                {`(function (l) {
                    if (!l) {
                        window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
                        window.lintrk.q = []
                    }
                    var s = document.getElementsByTagName("script")[0];
                    var b = document.createElement("script");
                    b.type = "text/javascript"; b.async = true;
                    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                    s.parentNode.insertBefore(b, s);
                    })(window.lintrk);`}
            </script>
            <script
                type="text/javascript"
                async
                defer
                src="//js.hs-scripts.com/43560897.js"
                id="hs-script-loader"
            />
        </Helmet>
    )
}

