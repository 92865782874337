import React, { useEffect } from 'react'
import NotFoundAnimation from "../../assets/animation/404_Not_Found.MP4";
import styles from './404.module.scss';
import parse from "html-react-parser";
import ReactGA from 'react-ga';
import NotFoundPoster from '../../assets/animation/404_Not_Found-poster.jpg'
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Head, Button } from '../../components';

export const NotFound = () => {
  const { formatMessage } = useIntl();
  useEffect(() => {
    ReactGA.pageview('/404NotFound');
  }, []);
  const lang = localStorage.getItem('lang')
  return (
    <div className={styles.background}>
      <Head
        title="Wakapi"
        description="Wakapi delivers Software Development Services & Solutions to skyrocket your software engineering power. Get to know us."
        path="/"
        noRobots={true}
      />

      <div className={styles.container}>
        <div className={styles.title__container}>
          <svg width="416" height="221" viewBox="0 0 416 221" fill="none" className={styles.number}>
            <path fillRule="evenodd" clipRule="evenodd" d="M197 44C164.967 44 139 69.9675 139 102V130C139 162.033 164.967 188 197 188H214C246.033 188 272 162.033 272 130V102C272 69.9675 246.033 44 214 44H197ZM205.5 91C203.015 91 201 93.0147 201 95.5V136.5C201 138.985 203.015 141 205.5 141C207.985 141 210 138.985 210 136.5V95.5C210 93.0147 207.985 91 205.5 91Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M133.5 122L137 169.5L123.5 170.5L125 190L61.5 194L60 175L3 179L0 132L51.5 54.5L113.5 50L119.5 123L133.5 122ZM55 106L56.5 127.5L39.5 129L55 106Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M405.273 148.373L404.615 195.998L391.079 195.816L390.872 215.372L327.265 213.817L327.429 194.758L270.297 193.77L271.41 146.687L329.475 73.9763L391.631 74.903L391.239 148.148L405.273 148.373ZM328.468 125.585L328.087 147.134L311.021 147.145L328.468 125.585Z" fill="black" />
            <path d="M197.441 29.9999L195.084 23.0645H183.479L181.122 29.9999H175.773L185.882 2.80227H192.863L202.971 29.9999H197.441ZM185.972 15.8572L184.839 19.2115H193.724L192.591 15.8572C191.639 13.0921 190.687 10.0097 189.871 7.28989H188.738C187.922 10.0097 186.924 13.0921 185.972 15.8572Z" fill="black" />
            <path d="M227.78 18.5769L225.423 29.9999H218.125L211.234 2.80227H216.765L219.484 15.1772C220.21 18.7582 220.89 22.0673 221.434 25.5577H222.657C223.247 21.9766 223.791 18.6676 224.561 15.2225L227.462 2.80227H234.035L236.891 15.2225C237.616 18.6222 238.251 21.9766 238.795 25.5577H239.928C240.517 22.1126 241.197 18.7582 241.922 15.1772L244.506 2.80227H249.81L243.01 29.9999H235.395L233.038 18.5769C232.358 15.2225 231.451 10.8256 230.907 7.1539H229.955C229.321 10.8256 228.46 15.2679 227.78 18.5769Z" fill="black" />
            <path d="M257.855 26.6456C261.254 26.6456 263.521 24.6511 263.521 21.9766V21.1154H257.991C255.543 21.1154 254.138 22.022 254.138 23.7898C254.138 25.331 255.09 26.6456 257.855 26.6456ZM263.748 29.9999V24.923H263.249C262.161 28.3681 259.713 30.4532 255.951 30.4532C252.143 30.4532 249.469 28.6401 249.469 24.4697C249.469 20.6621 251.781 17.897 257.175 17.897H263.521V16.4464C263.521 13.6813 262.388 12.0948 259.396 12.0948C256.903 12.0948 255.452 13.364 254.908 15.8572L250.285 14.8146C251.101 10.7803 254.138 8.19648 259.577 8.19648C265.742 8.19648 268.371 11.0976 268.371 16.4011V29.9999H263.748Z" fill="black" />
            <path d="M278.346 0.898438V17.4437H280.295L287.004 8.6951H292.579V9.19372L284.148 19.1662L293.168 29.5013V29.9999H287.593L280.295 21.07H278.346V29.9999H273.45V0.898438H278.346Z" fill="black" />
            <path d="M295.762 29.9999V2.80227H315.389V7.28989H300.929V13.9986H314.03V18.4409H300.929V25.5123H315.344V29.9999H295.762Z" fill="black" />
            <path d="M324.866 29.9999H320.016V8.6951H324.639V15.0866L325.047 15.1319C326.271 9.96432 328.266 8.46845 331.348 8.46845H332.119V13.9533H330.94C326.453 13.9533 324.866 15.5398 324.866 19.8915V29.9999Z" fill="black" />
            <path d="M340.271 29.9999H335.421V8.6951H340.044V15.0866L340.452 15.1319C341.676 9.96432 343.671 8.46845 346.753 8.46845H347.524V13.9533H346.345C341.858 13.9533 340.271 15.5398 340.271 19.8915V29.9999Z" fill="black" />
            <path d="M360.436 30.5892C353.591 30.5892 349.602 26.2829 349.602 19.3022C349.602 12.2761 353.817 8.15115 360.436 8.15115C366.963 8.15115 371.179 12.2761 371.179 19.3022C371.179 26.2829 367.19 30.5892 360.436 30.5892ZM360.39 12.2761C356.447 12.2761 354.497 14.8599 354.497 19.2569C354.497 23.6538 356.311 26.3736 360.39 26.3736C364.425 26.3736 366.328 23.6538 366.328 19.2569C366.328 14.8599 364.334 12.2761 360.39 12.2761Z" fill="black" />
            <path d="M379.89 29.9999H375.04V8.6951H379.663V15.0866L380.071 15.1319C381.295 9.96432 383.29 8.46845 386.372 8.46845H387.143V13.9533H385.964C381.477 13.9533 379.89 15.5398 379.89 19.8915V29.9999Z" fill="black" />
            <path d="M395.884 20.1634H390.943V2.80227H395.884V20.1634ZM390.581 24.4697H396.247V29.9999H390.581V24.4697Z" fill="black" />
          </svg>

        </div>


        <video autoPlay={true} className={styles.animation} loop muted controls={false} playsInline={true} disablePictureInPicture disableRemotePlayback poster={NotFoundPoster}>
          <source src={NotFoundAnimation} type="video/mp4" />
        </video>
        <div className={styles.text}>
          {parse(formatMessage({ id: "404.text" }))}
          <Link to={`/${lang}/`}>
            <Button primary>{formatMessage({ id: "404.button" })}</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}
export default NotFound;