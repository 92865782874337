import { useIntl } from "react-intl"
import { ArsysLogo, BudderflyLogo2, CompressusLogo, Contact, Footer, Head, TopBar } from "../../components"
import { Introduction, WhyChoose } from "../../modules"
import styles from './technologies.module.scss';
import parse from "html-react-parser";
import { SaasIcon } from "../../assets/icons/technologies";
import { ProductivityIcon, SettingsIcon, ScaleIcon, CheckIcon } from "../../assets/icons";
import { KeyFeatures } from "../../modules/Technologies/KeyFeatures/KeyFeatures";
import { DevelopmentProcess } from "../../modules/Technologies/DevelopmentProcess/DevelopmentProcess";
import { Expertise } from "../../modules/Technologies/Expertise/Expertise";
import { TechnologiesClient } from "../../modules/Technologies/Clients/Clients";
import { Suspense, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import ReactGA from 'react-ga';

export const SaasPage = () => {
    const { formatMessage } = useIntl();
    const renderLoader = () => <div className="spinner"><CircularProgress color="inherit" /></div>;

    useEffect(() => {
        ReactGA.pageview('/technologies/saas');
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            {/* Encabezados y meta descriptions */}
            <Head
                title="Saas | Wakapi"
                description="Adopt SaaS for scalable, cost-effective software. Discover the benefits of accessible and flexible solutions for your business. "
                path="/technologies/saas"
            />

            {/* Barra de navegación */}
            <TopBar />
            <main className={styles.container}>
                <Introduction
                    title={parse(formatMessage({ id: "technologies.Saas-title" }))}
                    description={parse(formatMessage({ id: "technologies.Saas-description" }))}
                    icon={<div className={styles.icon__saas}><SaasIcon size={{ width: "151", height: "86" }} /></div>}
                />
                {/* WhyChoose modulo */}
                <WhyChoose
                    list={
                        [
                            {
                                icon: <ScaleIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.Saas-why-choose-1-title" }),
                                description: formatMessage({ id: "technologies.Saas-why-choose-1-text" }),
                            },
                            {
                                icon: <CheckIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.Saas-why-choose-2-title" }),
                                description: formatMessage({ id: "technologies.Saas-why-choose-2-text" }),
                            },
                            {
                                icon: <ProductivityIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.Saas-why-choose-3-title" }),
                                description: formatMessage({ id: "technologies.Saas-why-choose-3-text" }),
                            },
                            {
                                icon: <SettingsIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.Saas-why-choose-4-title" }),
                                description: formatMessage({ id: "technologies.Saas-why-choose-4-text" }),
                            },
                        ]
                    }
                    title={parse(formatMessage({ id: "technologies.Saas-why-choose-title" }))}
                />
                <div className={styles.keyFeatures__background}>
                    <div className={styles.keyFeatures__container}>
                        <KeyFeatures
                            list={
                                [
                                    {
                                        title: formatMessage({ id: "technologies.Saas-key-aspects-1-title" }),
                                        text: formatMessage({ id: "technologies.Saas-key-aspects-1-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Saas-key-aspects-2-title" }),
                                        text: formatMessage({ id: "technologies.Saas-key-aspects-2-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Saas-key-aspects-3-title" }),
                                        text: formatMessage({ id: "technologies.Saas-key-aspects-3-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Saas-key-aspects-4-title" }),
                                        text: formatMessage({ id: "technologies.Saas-key-aspects-4-text" }),
                                    },
                                ]
                            }
                        />
                    </div>
                    <div className={styles.expertise__container}>
                        <Expertise
                            icon={<SaasIcon size={{ width: "125", height: "125" }} />}
                            title={formatMessage({ id: "technologies.Saas-expertise-title" })}
                            text={formatMessage({ id: "technologies.Saas-expertise-text" })}
                            list={
                                [
                                    {
                                        title: formatMessage({ id: "technologies.Saas-expertise-1-title" }),
                                        text: formatMessage({ id: "technologies.Saas-expertise-1-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Saas-expertise-2-title" }),
                                        text: formatMessage({ id: "technologies.Saas-expertise-2-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Saas-expertise-3-title" }),
                                        text: formatMessage({ id: "technologies.Saas-expertise-3-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Saas-expertise-4-title" }),
                                        text: formatMessage({ id: "technologies.Saas-expertise-4-text" }),
                                    },
                                ]
                            }
                        />
                    </div>
                </div>
                <div className={styles.process__background}>
                    <div className={styles.process__container}>
                        <DevelopmentProcess
                            title={formatMessage({ id: "technologies.development-process" })}
                            list={
                                [
                                    {
                                        title: formatMessage({ id: "technologies.Saas-development-process-1-title" }),
                                        text: formatMessage({ id: "technologies.Saas-development-process-1-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Saas-development-process-2-title" }),
                                        text: formatMessage({ id: "technologies.Saas-development-process-2-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Saas-development-process-3-title" }),
                                        text: formatMessage({ id: "technologies.Saas-development-process-3-text" }),
                                    },
                                    {
                                        title: formatMessage({ id: "technologies.Saas-development-process-4-title" }),
                                        text: formatMessage({ id: "technologies.Saas-development-process-4-text" }),
                                    },
                                ]
                            }
                        />
                    </div>
                </div>
                <TechnologiesClient
                    title={formatMessage({ id: "technologies.Saas-satisfied" })}
                    list={[
                        <BudderflyLogo2
                            size={
                                {
                                    width: '185',
                                    height: '90'
                                }}
                        />,        
                        <CompressusLogo size={{width:'167', height:'96'}}/>,
                        <ArsysLogo size={{width:'89', height:'96'}}/>
                    ]}
                />
                  <section className="contact__container">
                    <Suspense fallback={renderLoader()}>
                        <Contact />
                    </Suspense>
                </section>
                <Footer />
            </main>
        </>
    )
}