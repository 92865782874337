import styles from './topBar.module.scss';
import logoawa from "../../assets/images/logos/awa-logo.svg";
export const AwaVenturesButton = () => {
    return (
        <a
            href="https://www.awa-ventures.com/"
            style={{ textDecoration: "none" }}
            target="__blank"
        >
            <div className={styles["awa--desktop"]}>
                <img src={logoawa} alt="go to Awa Ventures" width={90} height={44} />
            </div>
            <div className={styles["awa--mobile"]}>
                awa ventures
            </div>
        </a>
    )
}