import logowakapi from './../../assets/images/logos/wakapi-logo-sm.svg';
import styles from './landing.module.scss';
import { Link } from "react-router-dom";
import { Button, Footer, Head, PreloadImage, ContactSmall, SubwayLogo2, DollarGeneralLogo2, AmazonLogo2, ThomsonReutersLogo2, BudderflyLogo2 } from "../../components";
import { useEffect, useState } from 'react';


export const LandingDevelopment = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            <Head
                title="Wakapi: software outsourcing services in Latin America"
                description="Explore Wakapi's AI, mobile app, API, and custom software development services. Tailored, innovative solutions to meet your business needs. "
                path="/custom-made-software-development"
                haveCarousel={false}
            />
            <PreloadImage imagePath={`${process.env.PUBLIC_URL}/images/landing-development.webp`} />
            <PreloadImage imagePath={`${process.env.PUBLIC_URL}/images/landing-development-mobile.webp`} />
            <div className={styles.background}>
                <div className={styles.img__development}>
                    <img src={`${process.env.PUBLIC_URL}/images/landing-development.webp`} alt="Software outsourcing services in latin america" fetchpriority="high" className={styles.img__desktop} width={1038} height={951}/>
                    <img src={`${process.env.PUBLIC_URL}/images/landing-development-mobile.webp`} alt="Software outsourcing services in latin america" fetchpriority="high" className={styles.img__mobile} width={769} height={831}/>
                </div>
                <div className={styles.img__gradient__development} />
                <div className={styles.container__development}>
                    <div className={styles.topBar}>
                        <Link to="/en/" style={{ textDecoration: "none" }}>
                            <div className={styles.logo}>
                                <img src={logowakapi} alt="logo wakapi" width={153} height={48} />
                            </div>
                        </Link>
                        <Link to="/en/clients">
                            <button className={styles.button} aria-label="Success stories">Success stories</button>
                        </Link>
                    </div>
                    <div className={styles.data__development}>
                        <h1>Wakapi's Development Services:
                            {windowWidth > 930 && (<br />)}
                            You name it, we do it!</h1>
                        <br />
                        <h2>AI Development </h2> {windowWidth > 930 && (<br />)}
                        <p>Leverage the power of artificial intelligence to drive innovation and efficiency. We offer cutting-edge AI services to help you stay ahead of the curve.</p>
                        <br /><br />
                        <h2>Mobile Development</h2> {windowWidth > 930 && (<br />)}
                        <p>Create seamless, user-friendly mobile applications for iOS and Android. Our experts ensure your mobile app is innovative, efficient, and made to your needs.</p>
                        <br /><br />
                        <h2>API Development</h2> {windowWidth > 930 && (<br />)}
                        <p>Design and implement robust APIs that enhance connectivity and integration. Our team provides secure and scalable API solutions for any industry.</p>
                        <br /><br />
                        <h2 className={styles.lastTitle}>Custom Software Development</h2> {windowWidth > 930 && (<br />)}
                        <p>Whether it's a CRM, ERP, or Inventory Management Software, we specialize in delivering bespoke solutions thought to address your unique business challenges.</p>
                        <br />
                        {windowWidth > 930 && (<br />)}
                        <Link to='https://meetings.hubspot.com/wakapi/meeting-with-wakapi?utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz--V7abN8cIRcLe7vAfQKMUdfarx3OhKkhpZ8SBYZ4Ks-gE-sO_ubxEZGu4v6ksDbVsDSsc2' target="__blank">
                            <Button primary>Let’s talk</Button>
                        </Link>
                    </div>
                    <div className={styles["data__development--mobile"]}>
                        <h1>Wakapi's Development Services:
                            {windowWidth > 930 && (<br />)}
                            You name it, we do it!</h1>
                        <p>
                            Drive innovation with our advanced <b>AI services,</b> create seamless <b>iOS and Android apps,</b> and integrate robust, secure <b>APIs.</b> We also deliver tailored <b>CRM, ERP,</b> and management software to meet your unique business needs.
                        </p>
                        {windowWidth > 930 && (<br />)}
                        <Link to='https://meetings.hubspot.com/wakapi/meeting-with-wakapi?utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz--V7abN8cIRcLe7vAfQKMUdfarx3OhKkhpZ8SBYZ4Ks-gE-sO_ubxEZGu4v6ksDbVsDSsc2' target="__blank">
                            <Button primary>Let’s talk</Button>
                        </Link>
                    </div>
                    <div className={styles.logos} style={{ marginTop: 14 }}>
                        <SubwayLogo2 size={{ width: 150, height: 90 }} />
                        <DollarGeneralLogo2 size={{ width: 208, height: 90 }} />
                        <div className={styles.onlyDesktop}>
                            <AmazonLogo2 size={{ width: 156, height: 90 }} />
                        </div>
                        <ThomsonReutersLogo2 size={{ width: 259, height: 90 }} color={(windowWidth > 692 && windowWidth < 1440) ? 'white' : 'black'} />
                        <div className={styles.onlyDesktop}>
                            <BudderflyLogo2 size={{ width: 186, height: 90 }} color='white' />
                        </div>
                    </div>
                </div>
                <ContactSmall />
            </div>
            <Footer />
        </>
    )
}