export const UnaxUnaLogo = ({ size: { width = '121', height = '96' } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 121 96" fill="none" xmlns="http://www.w3.org/2000/svg" aria-valuetext="Una x una">
            <path d="M95.0027 46.8923L95.647 51.6498L94.5351 51.7985L94.2714 49.842L92.0895 50.1393L92.3502 52.0928L91.2383 52.2415L90.5969 47.484L91.7089 47.3354L91.9636 49.2146L94.1455 48.9172L93.8907 47.038L95.0027 46.8923Z" fill="#DE9DC2" />
            <path d="M99.0546 47.8351C99.2379 47.9712 99.3887 48.1457 99.4964 48.3461C99.6041 48.5465 99.6661 48.7679 99.678 48.9947L99.9597 51.0761L98.9587 51.2099L98.8988 50.752C98.7429 51.1207 98.3892 51.3467 97.8408 51.4121C97.5878 51.4528 97.3289 51.4375 97.0825 51.3675C96.8781 51.3091 96.6949 51.1935 96.555 51.0345C96.4197 50.881 96.335 50.69 96.3123 50.4874C96.2855 50.3329 96.2968 50.1743 96.3454 50.0252C96.3939 49.876 96.4782 49.7408 96.591 49.631C96.9192 49.3616 97.3256 49.2033 97.7509 49.1791L98.6021 49.0661C98.5926 48.9652 98.5608 48.8675 98.509 48.7802C98.4572 48.6928 98.3867 48.6178 98.3023 48.5606C98.1019 48.4464 97.8662 48.4094 97.64 48.4565C97.4433 48.4829 97.2521 48.5402 97.0735 48.626C96.9063 48.6981 96.7531 48.7987 96.621 48.9234L96.1384 48.2335C96.3387 48.0545 96.5689 47.9116 96.8188 47.8113C97.0902 47.695 97.3767 47.6169 97.67 47.5794C98.1475 47.4873 98.6424 47.5787 99.0546 47.8351ZM98.4822 50.4814C98.6129 50.379 98.7023 50.2334 98.7339 50.0711L98.683 49.6964L97.9487 49.7916C97.5111 49.8511 97.3103 50.0235 97.3493 50.309C97.3556 50.3714 97.3776 50.4313 97.4132 50.4832C97.4488 50.5352 97.4969 50.5775 97.5531 50.6063C97.6953 50.6752 97.856 50.6971 98.0116 50.6688C98.1812 50.6543 98.3438 50.5947 98.4822 50.4963V50.4814Z" fill="#DE9DC2" />
            <path d="M101.264 50.853C101.009 50.8306 100.76 50.7662 100.526 50.6627L100.781 49.8539C100.981 49.9423 101.193 50.0014 101.41 50.0293C101.643 50.0619 101.879 50.0619 102.112 50.0293C102.564 49.9699 102.777 49.8271 102.744 49.6041C102.744 49.5001 102.66 49.4347 102.531 49.4049C102.334 49.3704 102.133 49.3565 101.932 49.3633C101.675 49.3632 101.419 49.3443 101.165 49.3068C100.961 49.2756 100.77 49.1915 100.61 49.063C100.434 48.9052 100.326 48.6855 100.311 48.4505C100.278 48.2291 100.32 48.0033 100.43 47.8082C100.556 47.5952 100.742 47.4228 100.964 47.3116C101.244 47.1663 101.549 47.0735 101.863 47.0381C102.125 47.0053 102.391 46.9994 102.654 47.0202C102.885 47.0344 103.112 47.0856 103.326 47.1719L103.071 47.9747C102.723 47.8341 102.343 47.7889 101.971 47.8439C101.793 47.8558 101.621 47.912 101.47 48.0074C101.425 48.0355 101.388 48.0751 101.363 48.1222C101.338 48.1692 101.327 48.222 101.33 48.275C101.338 48.3295 101.363 48.3798 101.403 48.4185C101.442 48.4571 101.493 48.4819 101.548 48.4891C101.746 48.5224 101.947 48.5393 102.148 48.5397C102.402 48.5446 102.657 48.5645 102.909 48.5991C103.108 48.6309 103.296 48.7151 103.451 48.8429C103.625 48.9954 103.732 49.2085 103.751 49.4376C103.786 49.6565 103.742 49.8804 103.628 50.071C103.497 50.2835 103.308 50.4546 103.083 50.5645C102.796 50.7082 102.485 50.7999 102.166 50.8351C101.867 50.8786 101.564 50.8846 101.264 50.853Z" fill="#DE9DC2" />
            <path d="M106.931 49.9818C106.824 50.0783 106.7 50.154 106.565 50.2048C106.413 50.2663 106.252 50.3073 106.089 50.3267C105.722 50.3999 105.341 50.3296 105.025 50.1305C104.881 50.018 104.763 49.8767 104.678 49.7158C104.594 49.5549 104.544 49.378 104.533 49.1968L104.329 47.6923L103.76 47.7666L103.652 46.9489L104.222 46.8746L104.102 45.9826L105.172 45.8398L105.292 46.7319L106.212 46.6099L106.32 47.4276L105.402 47.5496L105.603 49.0363C105.612 49.1669 105.672 49.2888 105.771 49.3752C105.821 49.4132 105.879 49.4402 105.94 49.4545C106.002 49.4689 106.066 49.4702 106.128 49.4585C106.283 49.4402 106.428 49.3724 106.541 49.2652L106.931 49.9818Z" fill="#DE9DC2" />
            <path d="M109.919 46.3929C110.102 46.5295 110.252 46.704 110.36 46.9043C110.468 47.1046 110.53 47.3258 110.543 47.5525L110.824 49.6339L109.823 49.7677L109.76 49.3128C109.607 49.6785 109.254 49.9075 108.705 49.9729C108.452 50.0106 108.194 49.9954 107.947 49.9283C107.742 49.8691 107.558 49.7536 107.417 49.5952C107.283 49.4397 107.2 49.2481 107.177 49.0452C107.149 48.8912 107.159 48.7326 107.208 48.5837C107.257 48.4348 107.342 48.3002 107.456 48.1918C107.782 47.9211 108.188 47.7626 108.612 47.7398L109.464 47.6268C109.455 47.5253 109.424 47.4269 109.372 47.339C109.32 47.251 109.249 47.1756 109.164 47.1184C108.963 47.0057 108.727 46.9698 108.502 47.0173C108.305 47.0437 108.114 47.1009 107.935 47.1868C107.767 47.2633 107.613 47.369 107.483 47.499L107 46.8062C107.201 46.6282 107.431 46.4854 107.68 46.384C107.953 46.2704 108.239 46.1924 108.532 46.152C109.008 46.0578 109.503 46.1437 109.919 46.3929ZM109.347 49.0392C109.478 48.937 109.567 48.7913 109.598 48.6289L109.548 48.2543L108.813 48.3524C108.376 48.4089 108.175 48.5813 108.214 48.8668C108.221 48.929 108.243 48.9886 108.279 49.0404C108.314 49.0922 108.362 49.1346 108.418 49.1641C108.56 49.2337 108.721 49.2546 108.876 49.2236C109.047 49.2063 109.21 49.1425 109.347 49.0392Z" fill="#DE9DC2" />
            <path d="M91.5679 55.3725L92.6408 55.2297L93.3212 60.2846L92.2512 60.4273L91.5679 55.3725Z" fill="#DE9DC2" />
            <path d="M93.636 55.099L94.703 54.9563L95.3833 60.0111L94.3133 60.1538L93.636 55.099Z" fill="#DE9DC2" />
            <path d="M99.7711 57.8672L96.9779 58.227C97.0535 58.4381 97.2065 58.6133 97.4064 58.7176C97.6328 58.8251 97.887 58.8614 98.1347 58.8217C98.3096 58.8004 98.4791 58.748 98.6352 58.6671C98.7827 58.5865 98.9113 58.4761 99.0129 58.343L99.6662 58.8812C99.4991 59.1107 99.2829 59.3008 99.0332 59.438C98.7836 59.5752 98.5064 59.6562 98.2216 59.675C97.8398 59.7342 97.4492 59.7015 97.0827 59.5799C96.7567 59.4722 96.4686 59.2741 96.2525 59.009C96.0363 58.738 95.9003 58.4127 95.8599 58.0694C95.8096 57.7293 95.8531 57.382 95.9858 57.0644C96.1153 56.7564 96.3292 56.4906 96.6032 56.2972C96.8887 56.0995 97.2173 55.9717 97.5621 55.9242C97.907 55.8766 98.2583 55.9107 98.5873 56.0237C98.897 56.1356 99.1676 56.3338 99.3665 56.5946C99.5801 56.8789 99.7135 57.2145 99.7532 57.5669C99.7532 57.5669 99.7621 57.6888 99.7711 57.8672ZM97.1007 57.0376C96.9548 57.2018 96.8748 57.4134 96.8759 57.6323L98.6922 57.3915C98.6372 57.1792 98.5034 56.9955 98.3176 56.8771C98.1352 56.7588 97.9159 56.7102 97.7002 56.7403C97.4692 56.7591 97.2544 56.8656 97.1007 57.0376Z" fill="#DE9DC2" />
            <path d="M104.027 55.1348L104.437 58.1855C104.496 58.4549 104.497 58.7334 104.441 59.0032C104.385 59.2731 104.272 59.5283 104.111 59.7525C103.725 60.1625 103.197 60.4113 102.633 60.4483C102.29 60.4943 101.942 60.4943 101.599 60.4483C101.302 60.4176 101.014 60.3265 100.754 60.1807L101.078 59.363C101.272 59.4757 101.485 59.5513 101.707 59.586C101.947 59.6304 102.193 59.6364 102.435 59.6038C102.734 59.5895 103.017 59.4617 103.224 59.247C103.304 59.1337 103.359 59.0053 103.387 58.8697C103.415 58.7342 103.414 58.5944 103.385 58.4591L103.364 58.3015C103.236 58.4775 103.071 58.6235 102.88 58.7293C102.689 58.8352 102.477 58.8982 102.259 58.914C101.935 58.9625 101.605 58.9309 101.296 58.8218C101.001 58.7152 100.741 58.5301 100.544 58.2866C100.344 58.0259 100.216 57.718 100.172 57.3931C100.129 57.0683 100.171 56.7377 100.295 56.4341C100.424 56.1464 100.632 55.9002 100.895 55.7235C101.161 55.5365 101.47 55.418 101.794 55.3786C102.023 55.3336 102.259 55.343 102.484 55.406C102.708 55.4691 102.915 55.584 103.086 55.7414L103.023 55.2715L104.027 55.1348ZM103.017 57.6771C103.092 57.5803 103.147 57.4698 103.179 57.3519C103.21 57.234 103.218 57.1111 103.202 56.9901C103.186 56.8691 103.146 56.7525 103.084 56.6469C103.023 56.5413 102.941 56.4488 102.843 56.3747C102.629 56.2302 102.366 56.1747 102.112 56.2201C101.854 56.2447 101.616 56.366 101.446 56.559C101.371 56.6558 101.316 56.7663 101.285 56.8842C101.253 57.0021 101.245 57.125 101.261 57.246C101.277 57.367 101.317 57.4836 101.379 57.5892C101.44 57.6948 101.522 57.7873 101.62 57.8614C101.836 58.0043 102.099 58.0597 102.354 58.016C102.611 57.9927 102.849 57.8712 103.017 57.6771Z" fill="#DE9DC2" />
            <path d="M107.89 54.9801C108.069 55.1199 108.215 55.2965 108.319 55.4977C108.422 55.6989 108.481 55.92 108.49 56.1457L108.771 58.2271L107.767 58.3579L107.707 57.903C107.552 58.2687 107.201 58.4977 106.649 58.5631C106.397 58.6006 106.14 58.5854 105.894 58.5185C105.689 58.4608 105.504 58.3451 105.364 58.1855C105.231 58.0294 105.146 57.8382 105.121 57.6354C105.094 57.4811 105.106 57.3227 105.155 57.1739C105.204 57.0251 105.289 56.8906 105.403 56.782C105.729 56.5114 106.135 56.3528 106.56 56.33L107.411 56.2171C107.401 56.1157 107.37 56.0175 107.318 55.9297C107.266 55.8418 107.196 55.7663 107.111 55.7086C106.909 55.596 106.672 55.5601 106.446 55.6075C106.249 55.6348 106.058 55.692 105.879 55.777C105.712 55.8535 105.559 55.9593 105.43 56.0892L104.944 55.3964C105.146 55.219 105.376 55.0763 105.624 54.9742C105.898 54.8606 106.185 54.7826 106.479 54.7422C106.963 54.6403 107.467 54.7254 107.89 54.9801ZM107.291 57.6324C107.423 57.5305 107.513 57.3848 107.546 57.2221L107.495 56.8474L106.76 56.9455C106.319 57.003 106.119 57.1755 106.161 57.4629C106.168 57.5252 106.19 57.5847 106.226 57.6365C106.261 57.6883 106.309 57.7308 106.365 57.7603C106.508 57.8296 106.67 57.8505 106.826 57.8197C106.996 57.8023 107.157 57.7373 107.291 57.6324Z" fill="#DE9DC2" />
            <path d="M110.809 54.3169C111.035 54.1917 111.284 54.1118 111.541 54.082L111.673 55.0633C111.553 55.0633 111.472 55.0633 111.433 55.0811C111.3 55.0903 111.171 55.1261 111.053 55.1864C110.935 55.2468 110.831 55.3303 110.746 55.432C110.667 55.5477 110.612 55.6782 110.584 55.8157C110.557 55.9532 110.558 56.0948 110.588 56.2318L110.821 57.9623L109.751 58.1021L109.257 54.4388L110.279 54.305L110.345 54.7867C110.452 54.5899 110.613 54.4271 110.809 54.3169Z" fill="#DE9DC2" />
            <path d="M95.7128 64.9114C95.895 65.0485 96.045 65.2232 96.1527 65.4234C96.2603 65.6236 96.3229 65.8446 96.3362 66.0711L96.6179 68.1525L95.6139 68.2863L95.5539 67.8283C95.3981 68.1971 95.0474 68.423 94.496 68.4885C94.244 68.529 93.986 68.5137 93.7407 68.4438C93.5358 68.3861 93.3518 68.2716 93.2102 68.1138C93.0777 67.9574 92.9935 67.7664 92.9674 67.5637C92.9405 67.409 92.9521 67.2501 93.0012 67.1009C93.0503 66.9516 93.1354 66.8165 93.2492 66.7074C93.5753 66.4384 93.9797 66.28 94.403 66.2554L95.2542 66.1424C95.2456 66.0413 95.2142 65.9433 95.1623 65.8559C95.1104 65.7684 95.0394 65.6936 94.9545 65.6369C94.7529 65.5231 94.5163 65.4861 94.2891 65.5329C94.0927 65.5602 93.9016 65.6173 93.7227 65.7024C93.5552 65.7789 93.4029 65.8846 93.2731 66.0146L92.7876 65.3247C92.9883 65.1463 93.2184 65.0035 93.4679 64.9025C93.7404 64.7862 94.0279 64.7081 94.3221 64.6706C94.7997 64.5747 95.2962 64.6606 95.7128 64.9114ZM95.1403 67.5578C95.2692 67.4536 95.358 67.3088 95.3921 67.1474L95.3411 66.7728L94.6068 66.8679C94.1673 66.9274 93.9675 67.0999 94.0074 67.3853C94.0146 67.4479 94.0373 67.5078 94.0733 67.5596C94.1094 67.6115 94.1578 67.6538 94.2142 67.6827C94.3567 67.7508 94.5175 67.7716 94.6728 67.7421C94.8424 67.723 95.0038 67.6594 95.1403 67.5578Z" fill="#DE9DC2" />
            <path d="M101.896 67.2841C101.79 67.3805 101.667 67.4562 101.533 67.5071C101.38 67.5678 101.22 67.6088 101.057 67.629C100.69 67.7028 100.308 67.6324 99.9929 67.4327C99.8492 67.3203 99.7312 67.179 99.6465 67.018C99.5617 66.8571 99.5123 66.6803 99.5013 66.4991L99.2975 64.9945L98.7281 65.0689L98.6172 64.2512L99.1866 64.1768L99.0668 63.2848L100.137 63.1421L100.257 64.0341L101.177 63.9122L101.288 64.7299L100.367 64.8518L100.568 66.3385C100.578 66.4697 100.638 66.5923 100.736 66.6804C100.786 66.7182 100.844 66.7451 100.906 66.7595C100.967 66.7738 101.031 66.7753 101.093 66.7637C101.248 66.7467 101.394 66.6787 101.506 66.5704L101.896 67.2841Z" fill="#DE9DC2" />
            <path d="M103.095 67.1147C102.776 67.0008 102.495 66.8023 102.283 66.5409C102.066 66.2699 101.93 65.9445 101.89 65.6013C101.839 65.2589 101.883 64.9092 102.019 64.5903C102.155 64.2822 102.375 64.0177 102.654 63.8261C102.959 63.6234 103.307 63.4927 103.67 63.4443C104.034 63.3958 104.404 63.4308 104.752 63.5466C105.07 63.6617 105.35 63.8612 105.561 64.1235C105.772 64.3973 105.908 64.721 105.954 65.0625C106 65.4041 105.956 65.7517 105.825 66.0711C105.692 66.3794 105.474 66.6443 105.196 66.8352C104.895 67.0438 104.547 67.1746 104.183 67.2158C103.817 67.2722 103.443 67.2374 103.095 67.1147ZM104.695 65.9789C104.839 65.7462 104.898 65.4714 104.862 65.2007C104.826 64.93 104.697 64.6798 104.497 64.4922C104.398 64.4132 104.284 64.3559 104.161 64.3241C104.038 64.2924 103.909 64.2868 103.784 64.3078C103.657 64.3202 103.534 64.3592 103.424 64.4222C103.313 64.4853 103.217 64.5709 103.143 64.6736C102.999 64.9062 102.94 65.181 102.976 65.4517C103.012 65.7225 103.141 65.9726 103.341 66.1603C103.44 66.2397 103.555 66.2971 103.679 66.3289C103.802 66.3607 103.931 66.3661 104.057 66.3446C104.181 66.3299 104.3 66.2897 104.407 66.2268C104.515 66.1638 104.608 66.0794 104.68 65.9789H104.695Z" fill="#DE9DC2" />
            <path d="M109.97 61.248L110.653 66.3029L109.628 66.4396L109.571 66.0174C109.452 66.197 109.292 66.3468 109.104 66.4551C108.916 66.5634 108.706 66.6273 108.49 66.6418C108.161 66.691 107.826 66.6551 107.515 66.5378C107.215 66.4212 106.954 66.2222 106.763 65.9639C106.563 65.677 106.435 65.347 106.388 65.0012C106.342 64.6553 106.379 64.3035 106.496 63.9747C106.61 63.6762 106.809 63.417 107.069 63.2284C107.333 63.0347 107.642 62.9118 107.968 62.8715C108.172 62.833 108.382 62.8397 108.583 62.8911C108.784 62.9425 108.971 63.0374 109.131 63.1689L108.891 61.3848L109.97 61.248ZM109.188 65.3811C109.335 65.1491 109.395 64.8733 109.359 64.6017C109.322 64.3301 109.19 64.0798 108.987 63.8944C108.783 63.7431 108.527 63.6773 108.274 63.7112C108.021 63.7451 107.792 63.876 107.635 64.0758C107.488 64.3078 107.428 64.5836 107.464 64.8552C107.501 65.1268 107.633 65.3771 107.836 65.5625C108.04 65.7146 108.297 65.7808 108.549 65.7469C108.802 65.7129 109.032 65.5815 109.188 65.3811Z" fill="#DE9DC2" />
            <path d="M114.001 62.485C114.184 62.6216 114.334 62.7961 114.442 62.9964C114.55 63.1967 114.612 63.4179 114.625 63.6446L114.906 65.726L113.905 65.8598L113.842 65.4049C113.689 65.7706 113.336 65.9996 112.787 66.065C112.534 66.1029 112.276 66.0867 112.029 66.0174C111.824 65.9601 111.64 65.8455 111.499 65.6874C111.365 65.5318 111.282 65.3402 111.259 65.1373C111.231 64.9828 111.242 64.8239 111.29 64.6746C111.339 64.5253 111.424 64.3901 111.538 64.2809C111.865 64.0127 112.27 63.8544 112.694 63.829L113.546 63.716C113.537 63.615 113.505 63.5172 113.453 63.4298C113.401 63.3423 113.331 63.2675 113.246 63.2105C113.045 63.0979 112.809 63.0619 112.584 63.1094C112.387 63.1345 112.195 63.1918 112.017 63.2789C111.849 63.3554 111.695 63.4611 111.565 63.5911L111.082 62.8983C111.283 62.7203 111.513 62.5775 111.762 62.4761C112.034 62.3613 112.321 62.2833 112.614 62.2442C113.09 62.1502 113.585 62.2361 114.001 62.485ZM113.429 65.1313C113.559 65.0284 113.648 64.883 113.681 64.721L113.63 64.3464L112.895 64.4445C112.458 64.501 112.257 64.6734 112.296 64.9589C112.303 65.0211 112.325 65.0807 112.361 65.1325C112.396 65.1843 112.444 65.2268 112.5 65.2562C112.642 65.3258 112.803 65.3467 112.958 65.3157C113.129 65.2985 113.292 65.2346 113.429 65.1313Z" fill="#DE9DC2" />
            <path d="M116.21 65.503C115.956 65.4799 115.707 65.4166 115.473 65.3157L115.727 64.5039C115.927 64.5938 116.139 64.6539 116.357 64.6823C116.59 64.7135 116.825 64.7135 117.058 64.6823C117.511 64.6228 117.72 64.4801 117.69 64.2601C117.69 64.156 117.607 64.0876 117.475 64.0579C117.277 64.0243 117.076 64.0114 116.875 64.0192C116.619 64.0177 116.362 63.9989 116.108 63.9627C115.905 63.9291 115.714 63.8441 115.554 63.716C115.376 63.5591 115.268 63.3389 115.254 63.1034C115.22 62.8821 115.262 62.6558 115.374 62.4612C115.501 62.2488 115.686 62.0766 115.907 61.9646C116.187 61.8189 116.492 61.7271 116.806 61.694C117.069 61.6583 117.334 61.6523 117.598 61.6762C117.828 61.6898 118.055 61.74 118.269 61.8248L118.014 62.6277C117.666 62.4889 117.286 62.4438 116.914 62.4968C116.735 62.5094 116.562 62.5655 116.411 62.6604C116.366 62.6888 116.329 62.7286 116.305 62.7756C116.281 62.8227 116.27 62.8753 116.273 62.928C116.28 62.9826 116.306 63.0333 116.346 63.072C116.385 63.1107 116.436 63.1354 116.492 63.1421C116.69 63.1768 116.89 63.1937 117.091 63.1926C117.346 63.197 117.6 63.2179 117.852 63.2551C118.052 63.2856 118.24 63.3699 118.395 63.4989C118.568 63.6514 118.676 63.8644 118.695 64.0936C118.728 64.3124 118.685 64.5362 118.572 64.7269C118.44 64.9378 118.251 65.1077 118.026 65.2175C117.74 65.3623 117.429 65.4539 117.109 65.4881C116.811 65.5319 116.509 65.5368 116.21 65.503Z" fill="#DE9DC2" />
            <path d="M119.432 64.9977C119.36 64.9438 119.3 64.8759 119.256 64.7981C119.212 64.7204 119.184 64.6345 119.174 64.5457C119.16 64.4586 119.164 64.3694 119.186 64.284C119.208 64.1985 119.248 64.1185 119.303 64.0491C119.417 63.9159 119.577 63.8292 119.752 63.8056C119.927 63.782 120.104 63.8232 120.25 63.9213C120.323 63.9739 120.383 64.0411 120.427 64.1185C120.472 64.1959 120.499 64.2816 120.508 64.3702C120.522 64.4583 120.517 64.5482 120.495 64.6345C120.473 64.7209 120.433 64.8019 120.379 64.8728C120.268 65.0116 120.108 65.1022 119.931 65.1255C119.754 65.1489 119.575 65.103 119.432 64.9977Z" fill="#DE9DC2" />
            <path d="M33.9485 35.748C34.1014 37.8919 34.3232 40.0506 34.3891 42.2152C34.4144 43.607 34.3322 44.9987 34.1433 46.378C33.9515 47.948 33.1783 48.7151 31.8656 48.8816C30.4689 49.06 29.354 48.501 28.8355 47.0292C28.073 44.9709 27.4551 42.8626 26.9863 40.7196C26.0504 36.2704 25.6889 31.7213 25.9103 27.1816C25.9628 26.1643 26.1742 25.1612 26.5367 24.2082C27.3279 22.1684 29.4109 21.3626 31.2511 22.5668C32.5616 23.4808 33.7101 24.6042 34.6499 25.8911C36.6759 28.5315 38.2254 31.4663 39.5531 34.514C39.619 34.6597 39.697 34.8114 39.7689 34.9452L39.9817 34.9036C39.682 32.3999 39.3013 29.9052 39.1545 27.3957C39.0957 25.7723 39.1961 24.1471 39.4542 22.543C39.664 21.0355 40.4732 20.3368 41.777 20.1643C43.1466 19.9978 44.2615 20.5479 44.7741 21.9484C45.2622 23.2572 45.6137 24.6122 45.823 25.9922C46.6652 31.6269 47.0219 37.2912 46.2576 42.9615C46.1684 43.5833 46.0044 44.1923 45.7691 44.7753C44.87 47.0827 42.0707 47.7874 40.3743 45.9647C38.9104 44.3262 37.6002 42.5586 36.4601 40.6839C35.48 39.1258 34.7757 37.4131 33.9485 35.748Z" fill="#222221" />
            <path d="M58.0811 63.7101C57.8233 61.4741 57.4607 59.25 57.3438 57.0021C57.2693 55.2088 57.3465 53.4126 57.5746 51.6321C57.8084 49.6548 59.1511 48.6795 60.8894 49.0304C62.319 49.3277 63.0623 50.3565 63.2871 51.6529C63.8175 54.7126 64.417 57.7841 64.6298 60.8705C64.8485 64.0461 64.6807 67.2515 64.5908 70.442C64.543 71.4399 64.3522 72.4261 64.0244 73.3708C63.1882 75.9101 60.302 76.6861 58.4767 74.7266C57.0134 73.0877 55.7089 71.3156 54.5805 69.434C53.6064 67.8818 52.9081 66.1632 52.1828 64.6765C52.3147 66.9006 52.5155 69.315 52.5784 71.7354C52.5894 72.9886 52.4891 74.2402 52.2787 75.476C52.0779 76.8051 51.3526 77.5098 50.2257 77.712C48.916 77.9439 47.7951 77.5811 47.2736 76.3353C46.4906 74.4708 45.864 72.5454 45.4004 70.5787C44.3391 65.9502 43.8858 61.2049 44.0517 56.4609C44.0778 55.5498 44.2055 54.6443 44.4323 53.7611C45.1816 50.8798 47.894 49.9997 50.2497 51.864C51.985 53.2377 53.2258 55.0426 54.3137 56.9188C55.5126 59.0002 56.5646 61.15 57.6825 63.2671C57.7724 63.4336 57.8743 63.5912 57.9672 63.7518L58.0811 63.7101Z" fill="#222221" />
            <path d="M81.9653 67.9443C79.8014 68.4973 77.8143 69.0296 75.8122 69.4934C75.6181 69.5094 75.4355 69.5916 75.2956 69.726C75.1558 69.8605 75.0672 70.0389 75.0449 70.2308C74.869 71.1545 74.6378 72.067 74.3526 72.9634C73.7712 74.6642 72.6503 75.4105 70.9089 75.3689C69.3115 75.3273 68.1666 74.5304 67.8489 72.7731C67.5443 71.006 67.4197 69.2129 67.4773 67.421C67.6079 62.1688 68.576 56.9704 70.3455 52.0186C71.2926 49.3604 73.0699 47.5585 76.04 47.2076C76.9261 47.0758 77.8311 47.1562 78.6794 47.4422C79.5278 47.7283 80.2947 48.2117 80.9163 48.8519C82.5976 50.5081 83.6526 52.5776 84.7556 54.6085C86.6377 58.0814 88.2322 61.6823 89.2062 65.515C89.5685 66.9767 89.7804 68.471 89.8386 69.9751C89.9046 71.4202 89.1553 72.3539 87.9894 72.7672C86.4429 73.2964 85.1751 73.0645 84.264 71.8514C83.4668 70.7839 82.8764 69.5678 82.199 68.4111C82.0822 68.2119 81.9832 67.977 81.9653 67.9443ZM79.7174 62.6486C78.7344 59.8447 77.7843 57.1359 76.8372 54.4241H76.7263C76.4926 57.463 76.2558 60.4988 76.016 63.6031L79.7174 62.6486Z" fill="#222221" />
            <path d="M64.2283 39.0545C62.0254 39.6046 59.9484 40.1101 57.8804 40.6631C57.7515 40.7207 57.6365 40.8048 57.5429 40.9099C57.4494 41.015 57.3795 41.1387 57.3379 41.2727C57.0712 42.1647 56.9273 43.1132 56.6306 44.0023C55.9682 45.9736 54.5027 46.7824 52.4646 46.381C51.1159 46.1164 50.2258 45.0876 49.965 43.4581C49.5867 40.7966 49.5655 38.097 49.9021 35.4299C50.3517 31.1958 51.1309 27.0241 52.6265 23.0189C53.5256 20.6401 55.0661 18.9096 57.7215 18.3863C60.0443 17.9254 62.0853 18.4992 63.5179 20.3487C64.831 22.08 66.0093 23.908 67.0425 25.8168C69.0559 29.344 70.5773 33.1261 71.5651 37.0593C71.8667 38.3646 72.0493 39.6941 72.1106 41.0318C72.1826 42.4323 71.4393 43.47 70.3124 43.8774C68.7778 44.4334 67.4681 44.2283 66.56 43.0419C65.7688 42.0071 65.1813 40.8177 64.516 39.6908C64.4077 39.4844 64.3116 39.272 64.2283 39.0545ZM59.0792 25.5581L58.9593 25.5879C58.7226 28.6208 58.4858 31.6537 58.246 34.7371L61.9445 33.741C60.9644 30.946 60.0233 28.2521 59.0792 25.5581Z" fill="#222221" />
            <path d="M19.9012 62.5832C19.8503 60.4334 19.5086 58.3371 20.0331 56.2349C20.5576 54.1327 22.4308 52.9374 24.4538 53.3804C24.954 53.4771 25.4195 53.7036 25.8028 54.0368C26.186 54.3701 26.4735 54.7981 26.6357 55.2775C27.325 57.2934 27.9454 59.3451 28.434 61.4176C28.9764 63.7517 29.2941 66.1364 29.8036 68.4765C30.0236 69.389 30.3685 70.2674 30.8286 71.0871C31.0204 71.4439 31.6079 71.8513 31.9465 71.8008C32.1843 71.7401 32.4041 71.6239 32.5876 71.4621C32.7711 71.3002 32.913 71.0973 33.0015 70.8701C33.3217 69.5762 33.5164 68.255 33.583 66.9243C33.6699 64.0163 33.583 61.1054 33.6429 58.1974C33.6861 56.9991 33.8244 55.806 34.0565 54.6293C34.3352 53.089 35.2853 52.2505 36.7539 52.0364C38.3334 51.8223 39.6341 52.3754 40.2275 53.8205C40.7688 55.1069 41.1415 56.457 41.3365 57.8376C41.9359 62.4999 42.0138 67.1682 41.0038 71.7978C40.6017 73.6487 39.751 75.3745 38.5252 76.8258C35.1355 80.8875 29.5878 81.0719 25.7905 77.26C24.0522 75.5146 22.9672 73.3588 22.2359 71.0693C21.3428 68.2772 20.6745 65.4168 19.9012 62.5832Z" fill="#222221" />
            <path d="M23.0723 30.0957C23.2761 34.2049 23.348 38.2637 22.4549 42.2689C21.7505 45.4356 20.195 48.0997 17.1889 49.6459C14.1829 51.1921 10.6972 50.5944 8.05379 48.2633C6.12366 46.5654 5.05669 44.3294 4.15756 42.0191C2.77258 38.3077 1.95236 34.4124 1.72391 30.4614C1.66481 29.3527 1.76574 28.2413 2.02362 27.1609C2.38027 25.579 3.69001 24.5889 5.28147 24.464C6.67812 24.357 7.90992 24.9992 8.46139 26.5722C9.19303 28.7572 9.80632 30.9796 10.2986 33.2297C10.7991 35.3675 11.1078 37.55 11.5904 39.6909C11.7792 40.4742 12.0939 41.2221 12.5225 41.9061C13.1219 42.8933 14.1229 42.816 14.5545 41.7396C14.875 40.97 15.0586 40.151 15.097 39.3192C15.1629 36.5391 15.067 33.7559 15.1479 30.9758C15.2002 29.227 15.3623 27.4832 15.6335 25.7545C15.8732 24.2113 16.8713 23.3936 18.3548 23.233C19.8893 23.0665 21.1661 23.6404 21.6846 25.0646C22.287 26.6822 22.6137 28.4097 23.0723 30.0957Z" fill="#222221" />
            <path d="M13.1819 63.8767C14.0601 65.1612 14.9802 66.4517 15.8373 67.7748C16.3967 68.5539 16.7342 69.468 16.8144 70.4212C16.8863 71.7652 15.7684 72.7524 14.4587 72.4074C13.8318 72.2387 13.2443 71.9498 12.7293 71.557C11.7643 70.8077 10.8801 69.9514 9.91507 69.098C9.84391 69.2772 9.76183 69.452 9.66931 69.6213C9.08487 70.5639 8.53041 71.5332 7.87105 72.4282C7.5617 72.8045 7.16884 73.1047 6.72316 73.3054C5.56928 73.8823 4.45735 73.2846 4.27453 72.012C4.12392 70.861 4.36213 69.693 4.95187 68.6907C5.53031 67.647 6.24662 66.6777 6.885 65.6994C5.89595 64.2424 4.78703 62.726 3.82496 61.1382C3.39808 60.4314 3.10742 59.652 2.96779 58.8397C2.69806 57.2519 3.94485 56.1636 5.49435 56.6215C6.19885 56.8437 6.85186 57.2026 7.41549 57.6771C8.31462 58.4205 9.10286 59.2887 9.94504 60.1123C10.2747 59.4374 10.5624 58.7237 10.9551 58.0726C11.3688 57.3791 11.8411 56.7216 12.3667 56.1071C12.6127 55.8008 12.9535 55.5833 13.3364 55.4882C13.7193 55.3931 14.1232 55.4257 14.4857 55.5808C15.3158 55.8782 15.6066 56.5621 15.5916 57.3649C15.5318 58.7507 15.1752 60.1079 14.5456 61.3463C14.141 62.2235 13.6285 63.0501 13.1819 63.8767Z" fill="#222221" />
            <path d="M32.7138 33.5178C32.8667 35.6587 33.0885 37.8204 33.1544 39.985C33.1785 41.3768 33.0963 42.7684 32.9087 44.1478C32.7138 45.7178 31.9436 46.4879 30.6309 46.6544C29.2342 46.8298 28.1193 46.2708 27.5978 44.799C26.8376 42.7399 26.2198 40.6318 25.7486 38.4894C24.8151 34.0399 24.4546 29.4909 24.6756 24.9514C24.7287 23.9342 24.94 22.9312 25.302 21.978C26.0933 19.9382 28.1762 19.1354 30.0165 20.3367C31.3263 21.2525 32.4747 22.3767 33.4152 23.6639C35.4382 26.3013 36.9907 29.2361 38.3184 32.2868C38.3844 32.4325 38.4623 32.5842 38.5342 32.718L38.744 32.6764C38.4443 30.1757 38.0667 27.678 37.9198 25.1685C37.8611 23.5461 37.9615 21.9219 38.2195 20.3188C38.4293 18.8113 39.2355 18.1096 40.5423 17.9401C41.9119 17.7736 43.0269 18.3266 43.5394 19.7241C44.0272 21.0341 44.3786 22.3901 44.5884 23.771C45.4305 29.4056 45.7872 35.07 45.0229 40.7403C44.9337 41.3611 44.7697 41.9691 44.5344 42.5511C43.6353 44.8585 40.836 45.5632 39.1396 43.7405C37.6733 42.1036 36.3611 40.3369 35.2194 38.4626C34.2424 36.8927 33.538 35.1651 32.7138 33.5178Z" fill="#DE9DC2" />
            <path d="M56.8465 61.4801C56.5887 59.244 56.2231 57.0199 56.1092 54.772C56.0353 52.9779 56.1115 51.1807 56.337 49.399C56.5737 47.4217 57.9134 46.4494 59.6548 46.8003C61.0844 47.0976 61.8277 48.1234 62.0524 49.4228C62.5859 52.4825 63.1853 55.5511 63.3981 58.6404C63.6169 61.819 63.4491 65.0244 63.3592 68.2149C63.3111 69.2118 63.1203 70.197 62.7927 71.1407C61.9535 73.6889 59.0703 74.4561 57.2391 72.4995C55.7755 70.8608 54.471 69.0887 53.3429 67.2069C52.3688 65.6518 51.6705 63.9361 50.9452 62.4494C51.0771 64.6705 51.2779 67.0879 51.3408 69.5083C51.3514 70.7614 51.2512 72.0131 51.0411 73.2489C50.8373 74.578 50.115 75.2797 48.9881 75.4819C47.6753 75.7168 46.5544 75.354 46.0359 74.1052C45.2453 72.2381 44.6137 70.3085 44.1478 68.3368C43.0914 63.7085 42.6411 58.9645 42.8081 54.2219C42.8355 53.3109 42.9631 52.4056 43.1887 51.5221C43.941 48.6408 46.6533 47.7577 49.0061 49.622C50.7444 50.9987 51.9852 52.8006 53.0731 54.6769C54.272 56.7583 55.324 58.9051 56.4419 61.0251C56.5288 61.1886 56.6307 61.3462 56.7266 61.5098L56.8465 61.4801Z" fill="#DE9DC2" />
            <path d="M80.7303 65.7142C78.5664 66.2643 76.5793 66.7966 74.5772 67.2604C74.3826 67.2765 74.1998 67.3591 74.0598 67.4941C73.9198 67.6292 73.8315 67.8083 73.81 68.0008C73.6337 68.9244 73.4025 69.8368 73.1176 70.7334C72.5362 72.4342 71.4153 73.1805 69.674 73.1359C68.0765 73.0972 66.9316 72.3004 66.6139 70.5401C66.309 68.773 66.1844 66.9798 66.2423 65.1879C66.3727 59.9348 67.3407 54.7353 69.1105 49.7826C70.0576 47.1244 71.8319 45.3225 74.805 44.9716C75.6937 44.8404 76.6012 44.9225 77.4512 45.2112C78.3013 45.4999 79.0691 45.9868 79.6903 46.6308C81.3716 48.284 82.4266 50.3535 83.5266 52.3874C85.4117 55.8484 87.0062 59.4522 87.9803 63.282C88.3412 64.7439 88.5531 66.2381 88.6126 67.7421C88.6786 69.1842 87.9263 70.1208 86.7634 70.5312C85.2169 71.0634 83.9492 70.8285 83.038 69.6183C82.2408 68.5509 81.6474 67.3318 80.973 66.1781C80.8442 65.9789 80.7483 65.7469 80.7303 65.7142ZM78.4675 60.4186C77.4874 57.6117 76.5373 54.9029 75.5872 52.1941H75.4793C75.2456 55.23 75.0088 58.2688 74.769 61.373L78.4675 60.4186Z" fill="#DE9DC2" />
            <path d="M62.9935 36.8184C60.7906 37.3685 58.7106 37.874 56.6456 38.427C56.5167 38.4846 56.4017 38.5687 56.3082 38.6738C56.2146 38.7789 56.1447 38.9026 56.1031 39.0366C55.8334 39.9286 55.6925 40.8742 55.3958 41.7632C54.7334 43.7346 53.2649 44.5434 51.2298 44.142C49.8811 43.8773 48.991 42.8515 48.7273 41.2221C48.3517 38.5602 48.3315 35.8609 48.6673 33.1938C49.1169 28.9597 49.8961 24.788 51.3887 20.7828C52.2878 18.404 53.8313 16.6735 56.4837 16.1502C58.8065 15.6923 60.8475 16.2632 62.2802 18.1156C63.5944 19.8407 64.7756 21.6616 65.8137 23.5629C67.8271 27.0891 69.3485 30.8701 70.3364 34.8024C70.6365 36.109 70.819 37.4394 70.8818 38.7779C70.9424 39.3825 70.7986 39.9899 70.473 40.5045C70.1475 41.0191 69.6587 41.4117 69.0836 41.6205C67.5521 42.1765 66.2393 41.9743 65.3342 40.7879C64.543 39.7532 63.9555 38.5638 63.2902 37.4369C63.1775 37.2375 63.0784 37.0309 62.9935 36.8184ZM57.8384 23.328L57.7156 23.3548C57.4818 26.3906 57.245 29.4235 57.0052 32.504L60.7007 31.5079L57.8384 23.328Z" fill="#DE9DC2" />
            <path d="M18.7893 60.4572C18.4446 57.8674 18.2738 56.1041 18.7893 54.0049C19.3048 51.9056 21.187 50.7074 23.207 51.1474C23.7075 51.2447 24.173 51.4717 24.5563 51.8055C24.9396 52.1392 25.2268 52.5677 25.3889 53.0474C26.0927 55.0626 26.6929 57.112 27.1872 59.1876C27.7327 61.5187 28.0503 63.9034 28.5599 66.2465C28.779 67.1593 29.1239 68.0377 29.5849 68.8571C29.7767 69.2139 30.3611 69.6213 30.7028 69.5678C30.9423 69.5083 31.1638 69.3924 31.3485 69.2298C31.5332 69.0673 31.6757 68.8629 31.7638 68.6341C32.0869 67.3398 32.2816 66.0173 32.3452 64.6854C32.4351 61.7774 32.3452 58.8664 32.4081 55.9584C32.4519 54.7603 32.5892 53.5674 32.8187 52.3903C33.1005 50.8531 34.0506 50.0116 35.5161 49.7975C37.0956 49.5834 38.3963 50.1394 38.9868 51.5816C39.529 52.8689 39.9027 54.22 40.0987 55.6016C40.6981 60.261 40.776 64.9322 39.763 69.5588C39.3614 71.4103 38.5117 73.1369 37.2874 74.5899C33.8977 78.6486 28.3501 78.8359 24.5527 75.024C22.8144 73.2756 21.7295 71.1199 20.9982 68.8333C20.105 66.0472 19.2059 63.5793 18.7893 60.4572Z" fill="#DE9DC2" />
            <path d="M22.4129 27.9934C22.6227 32.2067 22.6946 36.3695 21.7805 40.4818C21.0552 43.7288 19.4608 46.4613 16.3857 48.0491C13.3107 49.6369 9.72018 49.0095 7.0108 46.6308C5.02972 44.8884 3.94477 42.5929 3.03065 40.2112C1.60873 36.4047 0.767262 32.409 0.53406 28.3562C0.470516 27.2188 0.571443 26.0784 0.83377 24.9694C1.19942 23.3489 2.54213 22.332 4.17554 22.2071C5.60816 22.0941 6.87293 22.7542 7.43938 24.3658C8.18883 26.6107 8.81711 28.8935 9.32156 31.2047C9.83407 33.3991 10.1518 35.6381 10.6463 37.8354C10.8413 38.6373 11.164 39.4032 11.6024 40.1041C12.2228 41.1181 13.2448 41.0408 13.7003 39.9347C14.0283 39.1451 14.2159 38.305 14.2548 37.4519C14.3207 34.6004 14.2248 31.7459 14.3057 28.8973C14.3606 27.1048 14.5268 25.3174 14.8033 23.5452C15.049 21.9603 16.074 21.1218 17.5966 20.9583C19.17 20.7888 20.4798 21.3746 21.0133 22.8375C21.6187 24.4937 21.9573 26.2659 22.4129 27.9934Z" fill="#DE9DC2" />
            <path d="M12.3067 62.6011C13.2059 63.9183 14.141 65.2444 15.0311 66.6003C15.6034 67.4002 15.9491 68.3376 16.0321 69.315C16.1071 70.6917 14.9592 71.6938 13.6165 71.3489C12.9736 71.1772 12.3708 70.8823 11.8422 70.4806C10.8531 69.7105 9.94802 68.8333 8.95598 67.9562C8.88343 68.1406 8.79935 68.3204 8.70423 68.4944C8.10481 69.4608 7.53536 70.4539 6.867 71.3756C6.55097 71.7599 6.14861 72.0654 5.69215 72.2676C4.50829 72.8623 3.36939 72.2498 3.17757 70.9445C3.02504 69.7637 3.26945 68.5659 3.8729 67.5369C4.47232 66.4665 5.20062 65.4555 5.85698 64.4713C4.84396 62.9846 3.72005 61.4206 2.71902 59.7882C2.27942 59.0648 1.98054 58.266 1.83787 57.4333C1.56214 55.8038 2.8419 54.6858 4.43036 55.1586C5.15316 55.3849 5.82278 55.7529 6.39946 56.2409C7.31957 57.0021 8.13178 57.8941 8.99495 58.7386C9.33362 58.0458 9.63033 57.3143 10.0319 56.6572C10.4567 55.9462 10.941 55.2718 11.4795 54.6412C11.7322 54.3277 12.0818 54.1052 12.4743 54.0079C12.8669 53.9107 13.2809 53.9441 13.6524 54.103C14.5036 54.4182 14.8033 55.108 14.7883 55.9495C14.722 57.3631 14.3565 58.747 13.7154 60.0112C13.2928 60.9032 12.7683 61.7655 12.3067 62.6011Z" fill="#DE9DC2" />
        </svg>


    )
}