import React  from "react";
import { FormattedMessage } from "react-intl";
import styles from "./thomson-reuters-infographic.module.scss";
import img1 from "./../../../../assets/images/clients/slider/thomson-reuters/staff-augmentation-latam-thomson-reuters1.webp";
import img2 from "./../../../../assets/images/clients/slider/thomson-reuters/staff-augmentation-latam-thomson-reuters3.webp";
import img3 from "./../../../../assets/images/clients/slider/thomson-reuters/staff-augmentation-latam-thomson-reuters4.webp";
import img4 from "./../../../../assets/images/clients/slider/thomson-reuters/staff-augmentation-latam-thomson-reuters2.webp";
import { Inforgraphic } from "../Infographic";
import { PreloadImage } from "../../../../components";

export const ThomsonReutersInfographic = () => {
  return (
    <Inforgraphic height={550}>
      <PreloadImage imagePath={img1}/>
      <PreloadImage imagePath={img2}/>
      <PreloadImage imagePath={img3}/>
      <div className={styles.block1}>
          <div className={styles.title__container}>
            <FormattedMessage id="clients.infographic-thomson-reuters-title" />
            <svg
              width="211"
              height="73"
              viewBox="0 0 211 73"
              fill="none"
              className={styles.arrowSvg__desktop}
            >
              <path
                d="M0.818359 37.345H209.818M173.447 0.539062L209.818 36.5391L173.447 72.5391"
                stroke="black"
              />
            </svg>
            <svg
              width="49"
              height="141"
              viewBox="0 0 49 141"
              fill="none"
              className={styles.arrowSvg__mobile}
            >
              <path
                d="M24.0478 0.539061L24.0477 139.539M48.5264 115.349L24.5838 139.539L0.641194 115.349"
                stroke="black"
              />
            </svg>
          </div>
          <img src={img1} alt="Thomson Reuters" className={styles.img} width={534} height={298} loading="lazy"/>
          <div className={styles.text1}>
            <FormattedMessage id="clients.infographic-thomson-reuters-1" />
          </div>
        </div>
        <div className={styles.block2} />
        <div className={styles.block3}>
          <div className={styles.text2}>
            <FormattedMessage id="clients.infographic-thomson-reuters-2" />
          </div>
          <img src={img2} alt="Thomson Reuters" className={styles.img} width={449} height={303} loading="lazy"/>
        </div>
        <div className={styles.block4}>
            <img src={img3} alt="Thomson Reuters" className={styles.img} width={450} height={552} loading="lazy" />
            <div className={styles.text3}>
                <FormattedMessage id="clients.infographic-thomson-reuters-3" />
            </div>
        </div>
        <div className={styles.block5}>
          <div className={styles.block6}>
            <FormattedMessage id="clients.infographic-thomson-reuters-4" />
          </div>
          <img src={img4} alt="Thomson Reuters" className={styles.img} width={538} height={454} loading="lazy"/>
          <div className={styles.text4}>
                <FormattedMessage id="clients.infographic-thomson-reuters-5" />
            </div>
        </div>
    </Inforgraphic>
  );
};
export default ThomsonReutersInfographic;
