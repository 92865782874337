import { CircleBackgroundSvg } from './CircleBackground';
import styles from './clientsPageTitle.module.scss';

export const ClientsPageTitle = ({ logo, text }) => {
    return (
        <>
            {/* Fondo con forma circular */}
           <CircleBackgroundSvg/>
            {/* Logo de cliente y descripcion */}
            <div className={styles.background__title}>
                {logo}
                <p>{text}</p>
            </div>
        </>
    )
}