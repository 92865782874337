import styles from './clients-slider.module.scss';
import { FormattedMessage } from 'react-intl';
import { AmazonLogo2, AppapachoLogo, BudderflyLogo2, DollarGeneralLogo2, ErthLogo2, PinPointLogo2, ThomsonReutersLogo2, UnaxUnaLogo, WelfiLogo2, HowlyLogo, DigicelLogo, NavisLogo, TangoeLogo, SubwayLogo2, QuincyLogo, DamsuLogo, IpcLogo, EarnedCardLogo, GisLogo, RecoveryPlannerLogo, VestaCareLogo, NetstepLogo, PaydiantLogo, CuidoBienLogo, CompressusLogo, ArsysLogo } from '../../../components';
import { useEffect, useRef, useState } from 'react';

const LogosSlider = ({ children, direction = 'left' }) => {
    const sliderRef = useRef(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
      const slider = sliderRef.current;
      let scrollAmount = 0;
      let speed = windowWidth > 640 ? 1 : 0.5;
  
      const scrollSlider = () => {
        if (slider) {
          scrollAmount = direction === 'left' ? scrollAmount + speed : scrollAmount - speed;
          if (scrollAmount >= slider.scrollWidth / 2 || scrollAmount <= 0) {
            // reinicia scroll cuando alcanza la mitad del contenido (izquierda) o cuando llega al inicio del contenido (derecha)
            scrollAmount = direction === 'left' ? 0 : slider.scrollWidth / 2;
          }
          slider.scrollLeft = scrollAmount;
          requestAnimationFrame(scrollSlider);
        }
      };
  
      // duplica el contenido
      const sliderContent = slider.innerHTML;
      slider.innerHTML += sliderContent;
  
      scrollSlider();
  
      return () => {
        // Limpiar la animación si el componente se desmonta
        slider.innerHTML = sliderContent;
      };
    }, [direction, windowWidth]);
  
    return (
      <div
        ref={sliderRef}
        style={{
          display: 'flex',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
       {children}
      </div>
    );
  };

export const ClientsSlider = () => {
    return (
        <>
            <div className={styles.logos__container}>
                <h2><FormattedMessage id="clients.section" /></h2>
                <h3><FormattedMessage id="clients.logos-title" /></h3>
            </div>
            <div className={styles.container}>
                <div className={styles.gallery__container}>
                    <LogosSlider direction='left'>
                        <div className={styles.slide__amazon} >
                            <AmazonLogo2 size={{width:'182', height:'94'}}/>
                        </div>
                        <div className={styles.slide} >
                            <ArsysLogo size={{width:'89', height:'96'}}/>
                        </div>
                        <div className={styles.slide} >
                            <BudderflyLogo2 size={{width:'191', height:'96'}}/>
                        </div>
                        <div className={styles.slide} >
                            <CompressusLogo size={{width:'167', height:'96'}}/>
                        </div>
                        <div className={styles.slide} >
                            <CuidoBienLogo size={{width:'137', height:'96'}}/> 
                        </div>
                        <div className={styles.slide} >
                            <DamsuLogo size={{width:'161', height:'96'}}/>
                        </div>
                        <div className={styles.slide} >
                            <DigicelLogo size={{width:'127', height:'96'}}/>
                        </div>
                        <div className={styles.slide}>
                            <DollarGeneralLogo2 size={{width:'245', height:'96'}}/>
                        </div>
                        <div className={styles.slide} >
                            <IpcLogo size={{width:'62', height:'96'}}/>
                        </div>
                        <div className={styles.slide} >
                            <EarnedCardLogo size={{width:'127', height:'96'}}/>
                        </div>
                        <div className={styles.slide} >
                            <ErthLogo2 size={{width:'84',height:'96'}}/>
                        </div>                      
                        <div className={styles.slide} >
                            <NavisLogo size={{width:'145',height:'96'}}/>
                        </div>
                        <div className={styles.slide} >
                            <GisLogo size={{width:'73',height:'96'}}/>
                        </div>
                        <div className={styles.slide} >
                            <UnaxUnaLogo size={{width:'121', height:'96'}}/>
                        </div>
                    </LogosSlider>
                </div>
                <div className={styles.gallery__container}>
                    <LogosSlider  direction='right'>
                        <div className={styles.slide} >
                            <QuincyLogo size={{width:'110', height:'96'}}/> 
                        </div>
                        <div className={styles.slide} >
                            <RecoveryPlannerLogo size={{width:'163', height:'96'}}/>
                        </div>
                        <div className={styles.slide} >
                            <SubwayLogo2 size={{width:'159', height:'96'}}/>
                        </div>
                        <div className={styles.slide} >
                            <TangoeLogo size={{width:'127', height:'96'}}/>
                        </div>
                        <div className={styles.slide} >
                            <ThomsonReutersLogo2 size={{width:'253', height:'96'}}/>
                        </div>                        
                        <div className={styles.slide} >
                            <VestaCareLogo  size={{width:'176', height:'96'}}/>
                        </div>
                        <div className={styles.slide} >
                            <WelfiLogo2 size={{width:'150', height:'96'}}/>
                        </div>
                        <div className={styles.slide} >
                            <AppapachoLogo size={{width:'175', height:'96'}}/>
                        </div>                     
                        <div className={styles.slide} >
                            <NetstepLogo  size={{width:'170', height:'86'}}/>
                        </div>
                        <div className={styles.slide} >
                            <HowlyLogo size={{width:'146', height:'90'}}/>
                        </div>   
                        <div className={styles.slide} >
                            <PinPointLogo2 size={{width:'160', height:'96'}}/>
                        </div>                  
                        <div className={styles.slide} >
                            <PaydiantLogo  size={{width:'150', height:'96'}}/>
                        </div>
                      
                    </LogosSlider>
                </div>
            </div>
        </>
        
    )
}