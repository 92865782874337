import { Link } from 'react-router-dom';
import styles from './servicesList.module.scss';

export const ServiceListItem = ({ icon, title, text, small, link }) => {
    return (
        <Link to={link} className={small ? styles["item--small"] : styles.item}>
                {icon}
                <h4>{title}</h4>
                <p>{text}</p>
        </Link>
    )
}