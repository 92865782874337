import { useContext, useEffect, useState } from 'react';
import { Chevron } from '../../assets/icons/generic';
import styles from './topBar.module.scss';
import { langContext } from '../../context/langContext';
import { useNavigate } from 'react-router-dom';

export const LangButton = ({ path, languageSelected, setLanguageSelected }) => {
    const language = useContext(langContext);
    const navigate = useNavigate();
    const [isSelectExtended, setIsSelectExtended] = useState(false);

    useEffect(() => {
        const haveLangPath = path.includes('/es') || path.includes('/en');
        if (haveLangPath) {
            if (path.includes('/es')) {
                setLanguageSelected('es');
            } else {
                setLanguageSelected('en');
            }
        }
    }, [languageSelected])

    const changeLanguage = (lang) => {
        const currentLang = lang === 'es' ? 'en' : 'es';
        if (lang === 'es') {
            setLanguageSelected(lang);
            language.changeLanguage(lang);
        } else {
            setLanguageSelected(lang);
            language.changeLanguage(lang);
        }
        setIsSelectExtended(!isSelectExtended);
        const newPath = path.split(`/${currentLang}`)[1];
        if (newPath) {
            navigate('/' + lang + newPath)
        } else {
            navigate('/' + lang + '/')
        }

    };
    return (
        <div className={styles["menu__item--language"]}>
            <div >
                {languageSelected === 'es' ? (
                    <>
                        ESP
                        <div
                            className={styles.language__content}
                            onClick={() => {
                                changeLanguage('en');
                            }}
                        >
                            ENG
                        </div>
                    </>
                ) : (
                    <>
                        ENG
                        <div
                            className={styles.language__content}
                            onClick={() => {
                                changeLanguage('es');
                            }}
                        >
                            ESP
                        </div>
                    </>
                )}
                <Chevron direction="down" size={{ width: 18, height: 18 }} />
            </div>
        </div>
    )
}