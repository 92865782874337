import React from "react";
import styles from "./case-studies.module.scss";
import parse from "html-react-parser";
import { FormattedMessage, useIntl } from "react-intl";
import { WhatDoTheyDoIcon, HowDidWeHelpIcon, HowDidTheyBenefitIcon, ToolboxIcon, WhatDidWeAchieveIcon, WhatWasTheChallengeIcon, WhoUsesTheAppIcon } from "../../../assets/icons";

export const ClientsDescription = ({ client, img, marginImg = '-60' }) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles[`container__${client}`]}>

      {/* Primer columna */}
      <div>
        {/* What do they do? */}
        <>
          <div className={styles.title}>
            <WhatDoTheyDoIcon className={styles.title__icon} size={{ width: '100%', height: '100%' }} />
            <FormattedMessage id="clients.whatDoTheyDo-title" />
          </div>
          <div className={styles.text}>
            {parse(formatMessage({ id: `clients.whatDoTheyDo-${client}` }))}
          </div>
        </>

        {/* How did we help? */}
        <>
          <div className={styles.title}>
            <HowDidWeHelpIcon className={styles.title__icon} />
            <FormattedMessage id="clients.howDidWeHelp-title" />
          </div>
          <div className={styles.text}>
            {parse(formatMessage({ id: `clients.howDidWeHelp-${client}` }))}
          </div>
        </>

        {/* What was the challenge? */}
        <>
          <div className={styles.title}>
            <WhatWasTheChallengeIcon className={styles.title__icon} />
            <FormattedMessage id="clients.whatWasTheChallenge-title" />
          </div>
          <div className={styles.text}>
            {parse(formatMessage({ id: `clients.whatWasTheChallenge-${client}` }))}
          </div>
        </>

        {/* What did we achieve? */}
        <>
          <div className={styles.title}>
            <WhatDidWeAchieveIcon className={styles.title__icon} />
            <FormattedMessage id="clients.whatDidWeAchieve-title" />
          </div>
          <div className={styles.text}>
            {parse(formatMessage({ id: `clients.whatDidWeAchieve-${client}` }))}
          </div>
        </>

        {/* Toolbox */}
        {(client !== 'erth') && (
          <>
            <div className={styles.title}>
              <ToolboxIcon className={styles.title__icon} />
              <FormattedMessage id="clients.toolbox-title" />
            </div>
            <div className={styles.text} style={{ borderBottom: "0px" }}>
              {parse(formatMessage({ id: `clients.toolbox-${client}` }))}
            </div>
          </>
        )}

        {/* Who uses the App?  */}
        {client === 'budderfly' && (
          <>
            <div className={styles.title}>
              <WhoUsesTheAppIcon className={styles.title__icon} />
              <FormattedMessage id="clients.whoUseTheApp-title" />
            </div>
            <div className={styles.text}>
              {parse(formatMessage({ id: `clients.whoUseTheApp-${client}` }))}
            </div>
          </>
        )}
      </div>

      {/* Segunda columna */}
      <div className={styles.column2} style={{ marginTop: marginImg }}>
        {img}


        <div>
          {/* How did they benefit? */}
          <>
            <div className={styles.title}>
              <HowDidTheyBenefitIcon className={styles.title__icon} />
              <FormattedMessage id="clients.howDidTheyBenefit-title" />
            </div>
            <div className={styles.text}>
              {parse(formatMessage({ id: `clients.howDidTheyBenefit-${client}` }))}
            </div>
          </>

          {/* Who uses the App?  */}
          {client !== 'budderfly' && (
            <>
              <div className={styles.title}>
                <WhoUsesTheAppIcon className={styles.title__icon} />
                <FormattedMessage id="clients.whoUseTheApp-title" />
              </div>
              <div className={styles.text}>
                {parse(formatMessage({ id: `clients.whoUseTheApp-${client}` }))}
              </div>
            </>
          )}


          {/* Toolbox */}
          {(client === 'erth') && (
            <>
              <div className={styles.title}>
                <ToolboxIcon className={styles.title__icon} />
                <FormattedMessage id="clients.toolbox-title" />
              </div>
              <div className={styles.text} style={{ borderBottom: "0px" }}>
                {parse(formatMessage({ id: `clients.toolbox-${client}` }))}
              </div>
            </>
          )}

        </div>
      </div>
    </div>
  );
};
