import { CircularProgress } from "@mui/material";
import { Suspense, useEffect } from "react";
import styles from './clients.module.scss';
import { Contact, DigicelLogo, Footer, Head, TopBar } from "../../components";
import { FormattedMessage } from "react-intl";
import { ClientsComments, ClientsDescription, ClientsIcons, ClientsPageTitle } from "../../modules";
import ReactGA from 'react-ga';
import digicel from './../../assets/images/clients/digicel-illustration.webp';
import { DigicelInfographic } from "../../modules/Clients/Infographics/Digicel/Digicel-infographic";
import { CaseStudiesSummaryClients } from "../../modules/Clients/Case-studies/CaseStudiesSummaryClients";
export const ClientsDigicelPage = () => {
    useEffect(() => {
        ReactGA.pageview('/clients/digicel');
        window.scrollTo(0, 0);
    }, [])
    const renderLoader = () => <div className="spinner"><CircularProgress color="inherit" /></div>;
    return (

        <main>
            <Head
                title='Digicel Case Study | Wakapi'
                description="Discover how Wakapi transformed Digicel’s app with a microservice architecture, improving scalability, payment integration, and user experience."
                path='/clients/digicel'
                haveCarousel={true}
            />
            {/* Barra de navegación */}
            <TopBar />

            {/* Titulos */}
            <section className={styles.title__container}>
                <div className={styles.title}>
                    <h1>
                        <FormattedMessage id="clients.section" />
                    </h1>
                    <h3>
                        <FormattedMessage id="clients.title" />
                    </h3>
                </div>
            </section>

            {/* digicel */}
            <section>
                <div className={styles.background}>
                    <ClientsPageTitle
                        logo={<DigicelLogo size={{ width: '256', height: '200' }} />}
                        text={<FormattedMessage id="clients.caseStudies-summary-digicel" />}
                    />
                    <div className={styles.infographic}>
                        <DigicelInfographic />
                    </div>
                    <ClientsDescription
                        client='digicel'
                        img={<img src={digicel} alt="Digicel Client Software Outsourcing Success" width={319} height={319} />}
                        marginImg="0"
                    />
                </div>
            </section>
            {/* Generico clientes */}
            <section className={styles.clients__section}>
                {/* Casos de éxito */}
                <CaseStudiesSummaryClients context='digicel' />

                {/* Logos de clientes */}
                <ClientsIcons />

                {/* Opinines */}
                <ClientsComments />
            </section>

            {/* Contacto */}
            <section className="contact__container">
                <Suspense fallback={renderLoader()}>
                    <Contact />
                </Suspense>
            </section>

            {/* Footer */}
            <Footer />
        </main >
    )
}