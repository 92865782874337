import { useState } from 'react';
import styles from './bannerImageAnimated.module.scss';

export const BannerImageAnimated = ({ imgBackground, imgFront, altBackground, altFront, isVisible, highPriority,frontStatic }) => {
    const [isLoadedFront, setIsLoadFront] = useState(false);
    if (frontStatic) {
        return (
            <div className={styles.banner_img}>
                <div className={styles["banner__gradient--mobile"]} />
                <img
                    fetchpriority={highPriority ? "high" : "low"}
                    src={imgFront} alt={altFront}
                    className={styles["banner__imgBackground--animation"]}
                    width={877} height={533}
                />

            </div>
        )
    }
    return (
        <div className={styles.banner_img} style={{ visibility: isLoadedFront ? 'visible' : 'hidden' }}>
            <div className={styles["banner__gradient--mobile"]} />
            <img
                fetchpriority={highPriority ? "high" : "low"}
                src={imgBackground} alt={altBackground}
                className={isVisible ? styles["banner__imgBackground--animation"] : styles["banner__imgBackground"]}
                width={877} height={533}

            />
            <img
                fetchpriority={highPriority ? "high" : "low"}
                src={imgFront} alt={altFront}
                className={isVisible ? styles["banner__imgFront--animation"] : styles["banner__imgFront"]}
                width={877} height={533}
                onLoad={() => setIsLoadFront(true)}
            />

        </div>
    )
}