export const ErthLogo2 = ({size:{width='241',height='200'}}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 241 200" fill="none" xmlns="http://www.w3.org/2000/svg" aria-valuetext="Erth">
            <g clipPath="url(#clip0_566_277)">
                <path d="M43.4 23V26.36H21.43V45.47H42.76V48.91H21.43V68.45H44.04V71.81H17.71V23H43.4Z" fill="black" />
                <path d="M69.7999 23H82.1099C91.8399 23 96.9899 27.94 96.9899 35.67C96.9899 42.11 93.1999 46.26 86.7599 47.62C90.0499 49.05 91.6299 50.55 94.8499 55.56L105.23 71.81H100.72L91.3399 56.85C87.1899 50.34 84.3299 48.76 79.4599 48.76H73.5199V71.8H69.7999V23ZM79.1699 45.33C87.4699 45.33 93.1199 43.4 93.1199 35.67C93.1199 28.51 88.1799 26.37 81.1699 26.37H73.5099V45.33H79.1599H79.1699Z" fill="black" />
                <path d="M158.75 23V26.36H140.93V71.8H137.21V26.36H119.53V23H158.75Z" fill="black" />
                <path d="M186.58 23V45.4H218.43V23H222.15V71.81H218.43V48.91H186.58V71.81H182.86V23H186.58Z" fill="black" />
                <path d="M34.04 157.07C32.34 155.07 29.89 153.93 27.31 153.93C22.2 153.93 18.37 158.03 18.37 163.08C18.37 169 23.3 172.08 27.34 172.08C29.34 172.08 32 171.27 34.04 169.06V170.79C32.01 172.58 29.56 173.27 27.4 173.27C21.42 173.27 17 168.58 17 163.02C17 157.46 21.6 152.74 27.34 152.74C31.14 152.74 33.44 154.77 34.04 155.34V157.07Z" fill="black" />
                <path d="M58.97 163.01C58.97 168.75 54.46 173.26 48.63 173.26C42.8 173.26 38.29 168.75 38.29 163.01C38.29 157.27 42.8 152.73 48.63 152.73C54.46 152.73 58.97 157.27 58.97 163.01ZM57.6 163.01C57.6 157.9 53.77 153.92 48.63 153.92C43.49 153.92 39.66 157.9 39.66 163.01C39.66 168.12 43.49 172.07 48.63 172.07C53.77 172.07 57.6 168.09 57.6 163.01Z" fill="black" />
                <path d="M73.3 172.94L66.84 163.52H66.24V172.94H64.92V153H67.79C69.31 153 70.69 153.12 72 154.02C73.55 155.1 74.06 156.74 74.06 158.29C74.06 160.26 73.19 162.3 70.71 163.13C69.66 163.49 68.71 163.52 68.26 163.52L74.78 172.94H73.29H73.3ZM66.24 162.33H68.15C70.99 162.33 72.69 160.83 72.69 158.26C72.69 156.26 71.7 155.21 70.93 154.79C70.09 154.31 68.66 154.19 67.88 154.19H66.24V162.32V162.33Z" fill="black" />
                <path d="M81.8801 163.46V172.94H80.5601V153H84.4201C87.6801 153 90.2501 154.79 90.2501 158.2C90.2501 161.61 87.8901 163.46 84.4501 163.46H81.8801ZM81.8801 162.27H84.5101C87.1101 162.27 88.8701 160.75 88.8701 158.23C88.8701 155.71 87.2001 154.19 84.3001 154.19H81.8801V162.26V162.27Z" fill="black" />
                <path d="M115.15 163.01C115.15 168.75 110.64 173.26 104.81 173.26C98.98 173.26 94.47 168.75 94.47 163.01C94.47 157.27 98.98 152.73 104.81 152.73C110.64 152.73 115.15 157.27 115.15 163.01ZM113.78 163.01C113.78 157.9 109.95 153.92 104.81 153.92C99.67 153.92 95.84 157.9 95.84 163.01C95.84 168.12 99.67 172.07 104.81 172.07C109.95 172.07 113.78 168.09 113.78 163.01Z" fill="black" />
                <path d="M129.48 172.94L123.02 163.52H122.42V172.94H121.1V153H123.97C125.49 153 126.87 153.12 128.18 154.02C129.73 155.1 130.24 156.74 130.24 158.29C130.24 160.26 129.37 162.3 126.89 163.13C125.84 163.49 124.89 163.52 124.44 163.52L130.96 172.94H129.47H129.48ZM122.42 162.33H124.33C127.17 162.33 128.87 160.83 128.87 158.26C128.87 156.26 127.88 155.21 127.11 154.79C126.27 154.31 124.84 154.19 124.06 154.19H122.42V162.32V162.33Z" fill="black" />
                <path d="M146.13 166.39H137.64L134.98 172.94H133.55L141.95 152.4L150.23 172.94H148.79L146.13 166.39ZM145.65 165.2L141.91 155.75L138.11 165.2H145.64H145.65Z" fill="black" />
                <path d="M157.64 154.2V172.94H156.32V154.2H151.15V153H162.81V154.2H157.64Z" fill="black" />
                <path d="M168.89 153V172.94H167.58V153H168.89Z" fill="black" />
                <path d="M195.52 163.01C195.52 168.75 191.01 173.26 185.18 173.26C179.35 173.26 174.84 168.75 174.84 163.01C174.84 157.27 179.35 152.73 185.18 152.73C191.01 152.73 195.52 157.27 195.52 163.01ZM194.15 163.01C194.15 157.9 190.32 153.92 185.18 153.92C180.04 153.92 176.21 157.9 176.21 163.01C176.21 168.12 180.04 172.07 185.18 172.07C190.32 172.07 194.15 168.09 194.15 163.01Z" fill="black" />
                <path d="M201.48 172.94V152.79L218.22 170.31V153H219.53V173.45L202.79 155.93V172.94H201.47H201.48Z" fill="black" />
                <path d="M58.3 94.4102H17.3V135.41H58.3V94.4102Z" fill="#66B661" />
                <path d="M113.3 94.4102H72.3V135.41H113.3V94.4102Z" fill="#B2B7BB" />
                <path d="M168.3 94.4102H127.3V135.41H168.3V94.4102Z" fill="#0069AD" />
                <path d="M226.3 94.4102H185.3V135.41H226.3V94.4102Z" fill="#B32018" />
            </g>
            <defs>
                <clipPath id="clip0_566_277">
                    <rect width="209.3" height="150.45" fill="white" transform="translate(17 23)" />
                </clipPath>
            </defs>
        </svg>

    )
}