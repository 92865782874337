import React from 'react';
import styles from './team.module.scss';

import img1 from "./../../assets/images/team/team/expert-software-engineers-latam.webp";
import img2 from "./../../assets/images/team/team/coders-outsourcing-latinamerica.webp";
import img3 from "./../../assets/images/team/team/programmers-outsourcing-company-latam.webp";
import img4 from "./../../assets/images/team/team/qa-testing-services-argentina.webp";
import img5 from "./../../assets/images/team/team/uxui-services-nearshore.webp";
import img6 from "./../../assets/images/team/team/software-development-outsourcing-team.webp";
import img7 from "./../../assets/images/team/team/outsourcing-software-factory-south-america.webp";


export const TeamGallery = () => {
  return (
    <div className={styles.gallery__container}>
    <div className={styles.slider}>
      <div className={styles.slideTrack}>
        <div className={styles.slide}>
          <img src={img1} height="360" width="460" alt="Expert Software Engineers in Latam" loading='lazy'/>
        </div>
        <div className={styles.slide}>
          <img src={img2} height="360" width="460" alt="Coders Outsourcing Latin America" loading='lazy'/>
        </div>
        <div className={styles.slide}>
          <img src={img3} height="360" width="460" alt="Programmers Outsourcing Company Latam" loading='lazy'/>
        </div>
        <div className={styles.slide}>
          <img src={img4} height="360" width="460" alt="QA Testing Services Argentina" loading='lazy'/>
        </div>
        <div className={styles.slide}>
          <img src={img5} height="360" width="460" alt="UXUI Services Nearshore" loading='lazy'/>
        </div>
        <div className={styles.slide}>
          <img src={img6} height="360" width="460" alt="Software Development Outsourcing Team" loading='lazy' />
        </div>
        <div className={styles.slide}>
          <img src={img7} height="360" width="460" alt="Outsourcing Software Factory South America " loading='lazy'/>
        </div>
        <div className={styles.slide}>
          <img src={img1} height="360" width="460" alt="Expert Software Engineers in Latam" loading='lazy'/>
        </div>
        <div className={styles.slide}>
          <img src={img2} height="360" width="460" alt="Coders Outsourcing Latin America" loading='lazy'/>
        </div>
        <div className={styles.slide}>
          <img src={img3} height="360" width="460" alt="Programmers Outsourcing Company Latam" loading='lazy'/>
        </div>
        <div className={styles.slide}>
          <img src={img4} height="360" width="460" alt="QA Testing Services Argentina" loading='lazy'/>
        </div>
        <div className={styles.slide}>
          <img src={img5} height="360" width="460" alt="UXUI Services Nearshore" loading='lazy'/>
        </div>
        <div className={styles.slide}>
          <img src={img6} height="360" width="460" alt="Software Development Outsourcing Team" loading='lazy'/>
        </div>
        <div className={styles.slide}>
          <img src={img7} height="360" width="460" alt="Outsourcing Software Factory South America " loading='lazy'/>
        </div>
      </div>
    </div>
  </div>
  )
};
export default TeamGallery;
