import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./Welfi-infographic.module.scss";
import img1 from "./../../../../assets/images/clients/slider/welfi/welfi-uxui-outsourcing-services-argentina1.webp";
import img2 from "./../../../../assets/images/clients/slider/welfi/welfi-uxui-outsourcing-services-argentina2.webp";
import img3 from "./../../../../assets/images/clients/slider/welfi/welfi-uxui-outsourcing-services-argentina3.webp";
import img4 from "./../../../../assets/images/clients/slider/welfi/welfi-uxui-outsourcing-services-argentina4.webp";
import { Inforgraphic } from "../Infographic";
import { PreloadImage } from "../../../../components";

export const WelfiInfographic = () => {
  return (
    <Inforgraphic height={550}>
      <PreloadImage imagePath={img1}/>
      <PreloadImage imagePath={img2}/>
      <PreloadImage imagePath={img3}/>
      <div className={styles.block1}>
          <div className={styles.title__container}>
            <FormattedMessage id="clients.infographic-welfi-title" />
            <svg
              width="211"
              height="73"
              viewBox="0 0 211 73"
              fill="none"
              className={styles.arrowSvg__desktop}
            >
              <path
                d="M0.818359 37.345H209.818M173.447 0.539062L209.818 36.5391L173.447 72.5391"
                stroke="black"
              />
            </svg>
            <svg
              width="49"
              height="141"
              viewBox="0 0 49 141"
              fill="none"
              className={styles.arrowSvg__mobile}
            >
              <path
                d="M24.0478 0.539061L24.0477 139.539M48.5264 115.349L24.5838 139.539L0.641194 115.349"
                stroke="black"
              />
            </svg>
          </div>
          <img src={img1} alt="Welfi UXUI Outsourcing Services Argentina 1" className={styles.img} width={1441} height={901} loading="lazy"/>
          <div className={styles.text1}>
            <FormattedMessage id="clients.infographic-welfi-1" />
          </div>
        </div>
        <div className={styles.block2}>
            <img src={img2} alt="Welfi UXUI Outsourcing Services Argentina 2" className={styles.img} width={685} height={623} loading="lazy"/>
            <div className={styles.text2}>
                <FormattedMessage id="clients.infographic-welfi-2" />
            </div>
        </div>
        <div className={styles.block3}>
            <img src={img3} alt="Welfi UXUI Outsourcing Services Argentina 3" className={styles.img} width={1440} height={900} loading="lazy"/>
        </div>
        <div className={styles.block4}>
            <img src={img4} alt="Welfi UXUI Outsourcing Services Argentina 4" className={styles.img} width={1032} height={768} loading="lazy"/>
            <div className={styles.text3}>
                <FormattedMessage id="clients.infographic-welfi-3" />
            </div>
        </div>
  </Inforgraphic>
  );
};
export default WelfiInfographic;
