import { FormattedMessage } from 'react-intl';
import styles from './certifications.module.scss';
import awsSolutionsArchitech from "../../assets/images/certifications/certifications-aws-solutionsArchitect.webp";
import awsSysOps from "../../assets/images/certifications/certifications-aws-sysOps.webp";
import awsTechnicalEssentials from "../../assets/images/certifications/certifications-aws-technicalEssentials.webp";
import awsCloudPractitioner from "../../assets/images/certifications/certifications-aws-cloudPractitioner.webp";
import awsEngineeringOperations from "../../assets/images/certifications/certifications-aws-operations.webp";
import microsoftAzure from "../../assets/images/certifications/certifications-microsoft-azure.webp";
import microsoftMcsa from "../../assets/images/certifications/certifications-microsoft-mcsa.webp";

import scrumCsm from "../../assets/images/certifications/certifications-ScrumAlliance-csm.webp";
import scrumCspo from "../../assets/images/certifications/certifications-ScrumAlliance-cspo.webp";
import scrumCal from "../../assets/images/certifications/certifications-ScrumAlliance-cal.webp";
import scrumRep from "../../assets/images/certifications/certifications-ScrumAlliance-rep.webp";
import scrumCst from "../../assets/images/certifications/certifications-ScrumAlliance-cst.webp";
import ciscoCcna from "../../assets/images/certifications/certifications-Cisco-ccna.webp";
import hipaa from "../../assets/images/certifications/certifications-hipaa.webp";
import istqb from "../../assets/images/certifications/certifications-istqb.webp";

import accelerance from "../../assets/images/certifications/certifications-accelerance.webp";
import juniorAchievement from "../../assets/images/certifications/certifications-juniorAchivement.webp";
import corpora from "../../assets/images/certifications/certifications-corpora.webp";
import hubspot from "../../assets/images/certifications/certifications-hubspot.webp";
import arcap from "../../assets/images/certifications/certifications-arcap.webp";
import idits from "../../assets/images/certifications/certifications-idits.webp";
import poloTic from "../../assets/images/certifications/certifications-poloTic.webp";


export const Certifications = () => {

    const data = [
        {
            id: 1,
            image: awsSolutionsArchitech,
            alt: "aws certified - Solutions Architect",
            width: 206,
            height: 206
        },
        {
            id: 2,
            image: awsSysOps,
            alt: "aws certified - SysOps Administrator",
            width: 206,
            height: 206
        },
        {
            id: 3,
            image: awsTechnicalEssentials,
            alt: "aws certified - AWS Technical Essentials",
            width: 206,
            height: 206
        },
        {
            id: 4,
            image: awsCloudPractitioner,
            alt: "aws certified - Cloud Practitioner",
            width: 206,
            height: 206
        },
        {
            id: 5,
            image: awsEngineeringOperations,
            alt: "aws academy - Engineering Operations Techinicians",
            width: 206,
            height: 206
        },
        {
            id: 6,
            image: microsoftAzure,
            alt: "Microsoft Certified - Azure fundamentals",
            width: 206,
            height: 206
        },
        {
            id: 7,
            image: microsoftMcsa,
            alt: "Microsoft Certified Professional - MCSA",
            width: 206,
            height: 206
        },
        {
            id: 8,
            image: scrumCsm,
            alt: "Scrum Alliance - CSM Certified",
            width: 206,
            height: 206
        },
        {
            id: 9,
            image: scrumCspo,
            alt: "Scrum Alliance - CSPO Certified",
            width: 206,
            height: 206
        },
        {
            id: 10,
            image: scrumCal,
            alt: "Scrum Alliance - CAL Credential I",
            width: 206,
            height: 206
        },
        {
            id: 11,
            image: scrumRep,
            alt: "Scrum Alliance - REP Registered",
            width: 206,
            height: 206
        },
        {
            id: 12,
            image: scrumCst,
            alt: "Scrum Alliance - CST Certified",
            width: 206,
            height: 206
        },
        {
            id: 13,
            image: ciscoCcna,
            alt: "Scrum Alliance - CST Certified",
            width: 206,
            height: 206
        },
        {
            id: 14,
            image: hipaa,
            alt: "HIPAA",
            width: 262,
            height: 206
        },
        {
            id: 15,
            image: istqb,
            alt: "ISTQB - Certified Tester Foundation Level",
            width: 262,
            height: 206
        },
        {
            id: 16,
            image: accelerance,
            alt: "Accelerance Certified",
            width: 210,
            height: 206
        },
        {
            id: 17,
            image: juniorAchievement,
            alt: "Junior Achievement Argentina",
            width: 315,
            height: 206
        },
        {
            id: 18,
            image: corpora,
            alt: "Corpora",
            width: 253,
            height: 206
        },
        {
            id: 19,
            image: hubspot,
            alt: "HubSpot - Approved partner",
            width: 206,
            height: 206
        },
        {
            id: 20,
            image: arcap,
            alt: "Arcap",
            width: 275,
            height: 206
        },
        {
            id: 21,
            image: idits,
            alt: "IDITS",
            width: 286,
            height: 206
        },
        {
            id: 22,
            image: poloTic,
            alt: "Polo TIC Mendoza",
            width: 246,
            height: 206
        },
    ]
    return (
        <div className={styles.container}>
            <div className={styles.data}>
                <h3><FormattedMessage id="home.certifications-title" /></h3>
                <div className={styles.row}>
                    {data.map((icon, index) => {
                        if (icon.id < 8) return (
                            <img
                                key={icon.id + index}
                                src={icon.image}
                                alt={icon.alt}
                                width={icon.width}
                                height={icon.height}
                            />
                        )
                    })}
                </div>
                <div className={styles.row}>
                    {data.map((icon, index) => {
                        if (icon.id >= 8 && icon.id < 16) return (
                            <img
                                key={icon.id + index}
                                src={icon.image}
                                alt={icon.alt}
                                width={icon.width}
                                height={icon.height}
                            />
                        )
                    })}
                </div>
                <div className={styles.row}>
                    {data.map((icon, index) => {
                        if (icon.id >= 16) return (
                            <img
                                key={icon.id + index}
                                src={icon.image}
                                alt={icon.alt}
                                width={icon.width}
                                height={icon.height}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}