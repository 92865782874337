export const DamsuLogo = ({ size: { width = '168', height = '96' } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 168 96" fill="none" xmlns="http://www.w3.org/2000/svg" aria-valuetext="Damsu">
            <g clipPath="url(#clip0_838_434)">
                <path d="M49.9971 37.063H56.5156C63.0654 37.063 68.2854 41.1145 68.2854 47.917C68.2854 55.3242 63.7926 59.151 56.5156 59.151H49.9971V37.063ZM53.1655 56.3679H56.4845C61.9279 56.3679 64.9301 53.74 64.9301 48.0454C64.9301 42.886 61.4189 39.8514 56.8637 39.8514H53.1603V56.3679H53.1655Z" fill="black" />
                <path d="M80.0191 37.063H83.3381L92.6719 59.151H89.223L86.9791 53.5795H76.1599L73.9108 59.151H70.5918L80.0191 37.063ZM81.5721 40.0387L77.1727 50.9891H85.9663L81.5721 40.0387Z" fill="black" />
                <path d="M95.9023 37.063H101.122L108.051 55.4152L114.85 37.063H120.07V59.151H116.907V40.0387H116.845L109.537 59.151H106.436L99.1279 40.0387H99.0655V59.151H95.9023V37.063Z" fill="black" />
                <path d="M135.762 40.3867C134.146 39.7551 133.263 39.4714 131.617 39.4714C129.846 39.4714 127.758 40.103 127.758 42.4151C127.758 46.6539 137.247 45.6745 137.247 52.9533C137.247 57.6685 133.611 59.5363 129.181 59.5363C127.628 59.5363 126.111 59.0921 124.657 58.6211L124.974 55.6775C126.049 56.1217 127.945 56.7533 129.56 56.7533C131.362 56.7533 133.892 55.7417 133.892 53.146C133.892 48.2114 124.402 49.5708 124.402 42.7041C124.402 38.781 127.503 36.6937 131.487 36.6937C132.941 36.6937 134.681 36.9453 136.198 37.4858L135.762 40.3867Z" fill="black" />
                <path d="M158.725 50.0096C158.725 56.2127 155.593 59.5363 150.15 59.5363C144.706 59.5363 141.574 56.2127 141.574 50.0096V37.063H144.737V49.5922C144.737 53.831 146.067 56.7425 150.144 56.7425C154.227 56.7425 155.557 53.831 155.557 49.5922V37.063H158.72V50.0096H158.725Z" fill="black" />
                <path fillRule="evenodd" clipRule="evenodd" d="M35.682 36.7258C41.7227 42.9503 41.7227 53.0443 35.682 59.2687L35.6768 59.2741C40.7098 54.0826 40.7098 45.6638 35.6716 40.4776C30.6333 35.2861 22.463 35.2861 17.4247 40.4776C12.3865 45.6691 12.3865 54.0879 17.4247 59.2848C22.3799 64.3907 30.3736 64.4763 35.4274 59.531C29.3711 65.4932 19.7621 65.4076 13.8045 59.2687C7.76375 53.0443 7.76375 42.9503 13.8045 36.7258C19.8452 30.5014 29.6412 30.5014 35.682 36.7258Z" fill="#478CBD" />
                <path fillRule="evenodd" clipRule="evenodd" d="M17.4662 57.4544C12.9214 52.7767 12.9214 45.1821 17.4662 40.5044C22.0059 35.8267 29.3711 35.8267 33.9159 40.5044C38.4555 45.1821 38.4555 52.7767 33.9159 57.4544C29.3711 62.1374 22.0059 62.1374 17.4662 57.4544ZM17.4974 53.7347C21.0346 57.3794 26.7688 57.3794 30.306 53.7347C33.8432 50.0899 33.8432 44.1812 30.306 40.5365C26.7688 36.8917 21.0346 36.8917 17.4974 40.5365C13.9654 44.1812 13.9654 50.0899 17.4974 53.7347Z" fill="#478CBD" />
                <path fillRule="evenodd" clipRule="evenodd" d="M21.1335 49.8331C18.2455 49.8331 15.9082 47.4193 15.9082 44.4489C15.9082 41.4731 18.2507 39.0647 21.1335 39.0647C24.0214 39.0647 26.3587 41.4785 26.3587 44.4489C26.3587 47.4193 24.0214 49.8331 21.1335 49.8331Z" fill="#478CBD" />
            </g>
            <defs>
                <clipPath id="clip0_838_434">
                    <rect width="150" height="32" fill="white" transform="translate(9 32)" />
                </clipPath>
            </defs>
        </svg>

    )
}