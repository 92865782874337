import React from 'react';
import { Helmet } from 'react-helmet-async';

export const PreloadImage = ({ imagePath }) => {
  return (
    <>
      <Helmet
        link={[
          { rel: "preload", href: imagePath },
          { rel: "prefetch", href: imagePath },
        ]}
      />
    </>
  );
};

export default PreloadImage;