import { FormattedMessage } from 'react-intl';
import styles from './erth-infographic.module.scss';

//Imagenes
import img1 from './../../../../assets/images/clients/slider/erth/erth-enhacing-database-performance-and-maintainability-1.webp';
import img2 from './../../../../assets/images/clients/slider/erth/erth-enhacing-database-performance-and-maintainability-2.webp';
import img3 from './../../../../assets/images/clients/slider/erth/erth-enhacing-database-performance-and-maintainability-3.webp';
import img4 from './../../../../assets/images/clients/slider/erth/erth-enhacing-database-performance-and-maintainability-4.webp';
import { Inforgraphic } from '../Infographic';
import { PreloadImage } from '../../../../components';

export const ErthInfographic = () => {
    return (
        <Inforgraphic>
            <PreloadImage imagePath={img1}/>
            <PreloadImage imagePath={img2}/>
            <div className={styles.block1}>
                    <div className={styles.block1__inner}>
                        <div className={styles.title__container}>
                            <FormattedMessage id="clients.infographic-erth-title" />
                            <svg
                                width="211"
                                height="73"
                                viewBox="0 0 211 73"
                                fill="none"
                                className={styles.arrowSvg__desktop}
                            >
                                <path
                                    d="M0.818359 37.345H209.818M173.447 0.539062L209.818 36.5391L173.447 72.5391"
                                    stroke="black"
                                />
                            </svg>
                            <svg
                                width="49"
                                height="141"
                                viewBox="0 0 49 141"
                                fill="none"
                                className={styles.arrowSvg__mobile}
                            >
                                <path
                                    d="M24.0478 0.539061L24.0477 139.539M48.5264 115.349L24.5838 139.539L0.641194 115.349"
                                    stroke="black"
                                />
                            </svg>
                        </div>
                        <div className={styles.text}>
                            <FormattedMessage id="clients.infographic-erth-1" />
                        </div>
                    </div>
                    <img src={img1} alt='Erth-enhacing database performance and maintainability' width={644} height={280} />
                </div>
                <div className={styles.block2}>
                    <div className={styles.block2__green}>
                        <div className={styles.text}>
                            <FormattedMessage id="clients.infographic-erth-2" />
                        </div>
                    </div>
                    <div className={styles.block2__gray}>
                        <div className={styles.text}>
                            <FormattedMessage id="clients.infographic-erth-3" />
                        </div>
                    </div>
                </div>
                <div className={styles.block3}>
                    <img src={img2} alt='Erth-enhacing database performance and maintainability' width={551} height={320} />
                    <div className={styles.text}>
                        <FormattedMessage id="clients.infographic-erth-4" />
                    </div>
                </div>
                <div className={styles.block4}>
                    <div className={styles.text}>
                        <FormattedMessage id="clients.infographic-erth-5" />
                    </div>
                </div>
                <div className={styles.block5}>
                    <img src={img3} alt='Erth-enhacing database performance and maintainability' width={266} height={289} />
                    <div className={styles.text}>
                        <FormattedMessage id="clients.infographic-erth-6" />
                    </div>
                </div>
                <div className={styles.block6}>
                    <img src={img4} alt='Erth-enhacing database performance and maintainability' width={625} height={452} />
                    <div className={styles.text}>
                        <FormattedMessage id="clients.infographic-erth-7" />
                    </div>
                </div>
        </Inforgraphic>
    );
}