import { FormattedMessage } from "react-intl";
import { Footer, Head, TopBar, Contact, BudderflyLogo2 } from "../../components"
import { Suspense, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import styles from './clients.module.scss';
import { ClientsPageTitle, ClientsIcons, ClientsComments, ClientsDescription, BudderflyInfographic } from "../../modules";
import budderfly from './../../assets/images/clients/budderfy-illustration.webp';
import ReactGA from 'react-ga';
import { CaseStudiesSummaryClients } from "../../modules/Clients/Case-studies/CaseStudiesSummaryClients";

export const ClientsBudderflyPage = () => {
    useEffect(() => {
        ReactGA.pageview('/clients/budderfly');
        window.scrollTo(0, 0);
    }, [])
    const renderLoader = () => <div className="spinner"><CircularProgress color="inherit" /></div>;
    return (

        <main>
            <Head
                title='Budderfly Case Study | Wakapi'
                description="A scalable SaaS tool built from scratch, integrating IoT technology and streamlined billing to enhance energy monitoring and management across over 3,000 customer sites."
                path='/clients/budderfly'
                haveCarousel={true}
            />
            {/* Barra de navegación */}
            <TopBar />

            {/* Titulos */}
            <section className={styles.title__container}>
                <div className={styles.title}>
                    <h1>
                        <FormattedMessage id="clients.section" />
                    </h1>
                    <h3>
                        <FormattedMessage id="clients.title" />
                    </h3>
                </div>
            </section>

            {/* Budderfly */}
            <section>
                <div className={styles.background}>
                    <ClientsPageTitle
                        logo={<BudderflyLogo2 size={{width:'414',height:'200'}}/>}
                        text={<FormattedMessage id="clients.caseStudies-summary-budderfly" />}
                    />
                    <div className={styles.infographic}>
                        <BudderflyInfographic />
                    </div>
                    <ClientsDescription
                        client='budderfly'
                        img={<img src={budderfly} alt="Budderfly Client Software Outsourcing Success" width={485} height={272} />}
                        marginImg="0"
                    />
                </div>
            </section>
            {/* Generico clientes */}
            <section className={styles.clients__section}>
                {/* Casos de éxito */}
                <CaseStudiesSummaryClients context='budderlfy' />

                {/* Logos de clientes */}
                <ClientsIcons />

                {/* Opinines */}
                <ClientsComments />
            </section>

            {/* Contacto */}
            <section className="contact__container">
                <Suspense fallback={renderLoader()}>
                    <Contact />
                </Suspense>
            </section>

            {/* Footer */}
            <Footer />
        </main >
    )
}