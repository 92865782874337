export const PinPointLogo2 = ({size:{width='317',height='200'}}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 317 200" fill="none" xmlns="http://www.w3.org/2000/svg" aria-valuetext="Pinpoint">
            <path d="M104.093 95.893C103.143 99.9093 102.147 103.858 101.197 107.829C99.975 112.833 98.7303 117.836 97.5762 122.862C97.5083 124.006 96.5128 124.859 95.3587 124.792C95.3134 124.792 95.2456 124.792 95.2003 124.792C90.8328 124.747 86.4427 124.747 82.0752 124.792C80.5139 124.792 80.1746 124.164 80.5365 122.795C81.2606 120.102 81.8943 117.432 82.6184 114.74C82.7088 113.798 83.5463 113.102 84.4966 113.192C84.5418 113.192 84.6097 113.192 84.6549 113.192C86.1939 113.371 86.6237 112.541 86.9406 111.24C89.1355 102.355 91.3533 93.4922 93.6161 84.6299C93.9783 83.2836 94.0009 82.3862 92.1905 82.4759C90.9912 82.5432 90.8328 81.7802 91.1043 80.8381C91.8284 78.0108 92.5527 75.2064 93.2768 72.3794C93.4578 71.4146 94.4081 70.7639 95.3813 70.921H109.547C111.244 70.921 111.539 71.9082 111.81 73.1871C113.824 82.3862 115.838 91.5852 117.852 100.784C117.852 101.031 117.988 101.3 118.214 102.131C119.232 97.9573 120.138 94.2999 121.02 90.6205C122.378 84.8317 123.826 79.0429 125.071 73.1871C125.139 71.8408 126.293 70.7863 127.673 70.8536C127.786 70.8536 127.899 70.8536 128.012 70.8984C132.176 71.0107 136.363 70.9881 140.526 70.8984C142.269 70.8984 142.654 71.5043 142.201 73.1197C141.499 75.5205 140.934 77.9661 140.368 80.4116C140.051 81.7579 139.531 82.7003 137.924 82.5206C136.838 82.4085 136.317 83.0145 136.091 84.0016C133.783 93.2007 131.452 102.4 129.121 111.599C128.827 112.721 129.121 113.282 130.366 113.192C131.611 113.102 131.995 113.82 131.724 115.009C131 117.702 130.298 120.372 129.687 123.064C129.393 124.41 128.623 124.769 127.356 124.747C122.83 124.747 118.327 124.657 113.824 124.747C111.991 124.747 111.312 124.051 110.928 122.391C109.049 114.201 107.103 106.057 105.157 97.8676L104.659 95.9154H104.116L104.093 95.893Z" fill="#6D556C" />
            <path d="M44.941 72.2899H57.7039C58.6542 72.2672 59.6047 72.2899 60.5324 72.402C66.3481 73.1423 68.5433 76.0591 67.5475 81.7805C66.7782 86.2006 65.6919 90.531 64.2889 94.794C62.1166 101.413 57.9075 104.397 50.87 104.509H41.1847C39.9627 104.285 38.8086 105.092 38.5824 106.304C38.5824 106.439 38.5371 106.551 38.5371 106.685C38.5371 107.762 38.469 108.839 38.0619 109.827C36.9756 112.519 36.0479 114.426 38.4464 114.269C39.9175 114.179 39.827 114.965 39.6005 115.997C38.8991 118.711 38.2202 121.449 37.564 124.164C37.4509 125.286 36.4551 126.116 35.3238 126.004C35.3012 126.004 35.2785 126.004 35.2559 126.004C29.0328 126.004 23.0135 126.004 16.8583 126.004C15.5458 126.004 14.8217 124.994 15.1838 123.76C15.9079 121.314 16.5189 118.824 17.0846 116.333C17.4014 114.987 17.9898 114.269 19.4381 114.448C20.8863 114.628 21.3615 113.91 21.6557 112.676C23.896 103.701 26.1363 94.7266 28.3992 85.7743C28.716 84.5401 28.6255 83.7998 27.0414 83.8671C25.4574 83.9345 25.3216 83.1268 25.6837 81.8928C26.3399 79.3573 27.0414 76.7994 27.6298 74.2418C27.7429 73.0302 28.8291 72.1328 30.0511 72.2673C30.0964 72.2673 30.1416 72.2673 30.1869 72.2673C35.0747 72.3796 40.0306 72.3122 44.8957 72.3122L44.941 72.2899ZM48.2903 83.0144H45.6653C45.0543 83.0144 44.3526 83.1268 44.2395 83.8224C43.4475 86.7839 42.7008 89.768 41.9541 92.7297C41.8183 93.2456 41.9541 93.7392 42.5651 93.7392H48.6975C49.3763 93.7392 49.512 92.9765 49.6706 92.4379C50.1683 90.531 50.6662 88.6461 51.1188 86.7391C52.2504 82.2517 52.137 83.0818 48.2903 83.0144Z" fill="#6D556C" />
            <path d="M150.098 120.933C149.465 123.378 148.921 125.555 148.378 127.686C148.084 128.741 148.469 129.235 149.578 129.19C150.935 129.19 151.275 129.773 150.935 131.007C150.347 133.183 149.781 135.36 149.238 137.536C149.08 138.793 147.858 139.645 146.568 139.309L136.204 138.568C130.977 139.533 128.623 140.094 129.37 137.469C129.913 135.539 130.501 133.61 130.818 131.635C131.09 129.997 131.61 128.988 133.262 129.167C134.756 129.324 135.073 128.584 135.367 127.417C137.584 118.577 139.825 109.737 142.088 100.897C142.472 99.4383 142.45 98.4735 140.549 98.5632C139.485 98.5632 139.304 97.9126 139.553 97.0375C140.164 94.6143 140.798 92.1688 141.409 89.7233C141.544 88.8929 142.314 88.3096 143.174 88.377H156.525C157.724 88.377 158.154 88.8706 157.815 90.0598C157.453 90.7778 157.385 91.5855 157.588 92.3706C159.037 91.6529 160.372 90.7552 161.571 89.6783C163.97 87.9508 170.781 87.861 173.157 89.5886C174.515 90.4636 175.217 92.0567 174.945 93.6272C173.429 101.929 172.071 110.253 168.971 118.173C167.07 123.064 164.49 124.725 159.263 124.725C154.036 124.725 153.923 124.769 150.143 120.91L150.098 120.933ZM153.538 114.202C155.054 114.112 156.367 114.426 156.774 112.856C157.905 108.48 159.037 104.128 160.168 99.7301C160.281 99.2812 160.395 98.81 159.874 98.4735C158.109 97.6882 156.027 98.4735 155.235 100.224C155.235 100.268 155.19 100.313 155.167 100.358C154.171 104.285 153.221 108.211 152.157 112.138C151.682 113.865 152.271 114.404 153.515 114.202H153.538Z" fill="#9A8A9B" />
            <path d="M83.4105 62.1257C82.9581 66.0746 82.5054 70.0234 82.0527 73.995C80.695 85.7743 79.4504 97.531 78.1153 109.288C77.0519 118.779 75.943 128.247 74.8567 137.716C74.7888 138.523 74.4043 139.286 73.7933 139.847C68.9053 144.491 64.0401 149.136 59.1974 153.825C58.134 154.88 57.5682 154.7 57.0024 153.399C54.6263 148.036 52.2052 142.719 49.7838 137.446C49.4216 136.638 49.4895 135.696 49.9421 134.933L74.9472 79.7164L82.4828 63.0234C82.6411 62.6642 82.8223 62.3277 83.0033 61.9912L83.4333 62.1257H83.4105Z" fill="url(#paint0_radial_640_86)" />
            <path d="M252.903 92.9324C254.26 91.9676 255.369 91.0928 256.546 90.2401C259.375 88.1087 265.687 87.7048 268.722 89.5221C270.463 90.4869 271.322 92.5062 270.803 94.4134C269.83 100.225 268.086 105.856 266.66 111.6C266.299 113.103 265.644 114.606 268.246 114.562C269.037 114.562 269.217 115.212 269.06 115.908C268.427 118.421 267.814 120.911 267.158 123.402C267.001 124.165 266.299 124.703 265.506 124.636H251.975C250.142 124.636 250.504 123.469 250.776 122.369C252.518 115.526 254.238 108.706 255.958 101.862C256.116 101.279 256.252 100.673 256.387 100.067C256.546 99.6859 256.455 99.2373 256.184 98.9232C255.03 97.7564 251.002 98.8335 250.594 100.471C248.92 107.045 247.29 113.619 245.616 120.171C244.258 125.331 245.005 124.546 239.868 124.613C237.696 124.613 235.501 124.546 233.306 124.613C231.563 124.681 231.179 124.052 231.609 122.437C233.442 115.414 235.184 108.391 237.017 101.346C237.356 100 238.216 98.5193 235.659 98.4296C234.754 98.4296 234.89 97.4422 235.048 96.7916C235.659 94.4134 236.315 92.0576 236.904 89.7015C237.175 88.6246 237.922 88.3555 238.895 88.3555H251.613C253.061 88.3555 253.604 88.9164 253.219 90.3748C252.812 91.1599 252.699 92.0349 252.857 92.8877L252.903 92.9324Z" fill="#9A8A9B" />
            <path d="M188.885 124.612L181.893 123.266C176.982 124.208 174.991 122.212 175.308 117.41C175.67 112.115 177.276 107.044 178.453 101.906C178.928 99.4382 179.63 97.0374 180.558 94.7039C182.028 90.755 185.853 88.1524 190.107 88.2198C194.678 88.1077 199.272 88.0627 203.843 88.2198C209.659 88.4219 211.695 90.9795 210.79 96.6335C209.726 102.983 208.301 109.243 206.513 115.413C204.658 121.965 201.308 124.321 194.339 124.612H188.862H188.885ZM190.786 114.202C192.325 114.202 193.795 114.493 194.226 112.564C195.153 108.346 196.194 104.127 197.167 99.9318C197.28 99.4158 197.461 98.8772 196.85 98.4734C195.04 97.6209 192.868 98.406 192.008 100.201C191.985 100.246 191.962 100.291 191.94 100.358C191.012 104.217 190.175 108.121 189.224 112.003C188.817 113.618 188.976 114.561 190.786 114.179V114.202Z" fill="#9A8A9B" />
            <path d="M281.46 124.613H276.55C273.2 124.523 272.046 123.154 272.839 119.923C274.196 114.135 275.757 108.369 277.228 102.58C277.409 101.862 277.592 101.144 277.818 100.426C278.133 99.349 278.11 98.5412 276.618 98.6086C275.124 98.6759 274.989 97.913 275.261 96.7464C275.848 94.4576 276.415 92.1468 276.934 89.8357C277.048 88.9383 277.864 88.31 278.746 88.4224C278.792 88.4224 278.812 88.4224 278.858 88.4224C280.444 88.5568 281.008 87.6817 281.303 86.3131C281.755 84.1367 282.388 81.9829 282.84 79.784C282.955 78.595 284.017 77.6975 285.217 77.7873C288.363 77.6302 291.507 77.3834 294.631 77.0916C296.306 76.9346 296.712 77.6076 296.306 79.1109C295.65 81.5341 295.106 83.9796 294.473 86.403C294.155 87.592 294.178 88.4223 295.739 88.3997C297.007 88.3997 297.437 88.9607 297.096 90.2172C296.463 92.5057 295.899 94.7944 295.355 97.1052C295.241 98.07 294.359 98.7433 293.385 98.631C293.342 98.631 293.296 98.631 293.251 98.631C291.893 98.4963 291.169 99.1245 290.851 100.493C289.924 104.442 288.927 108.391 287.888 112.317C287.502 113.664 287.616 114.584 289.357 114.494C290.717 114.494 290.989 115.145 290.717 116.334C290.127 118.555 289.563 120.799 289.042 123.042C288.905 124.074 287.954 124.815 286.914 124.68C286.914 124.68 286.869 124.68 286.846 124.68C285.105 124.59 283.293 124.635 281.506 124.635L281.46 124.613Z" fill="#9A8A9B" />
            <path d="M215.293 124.612C213.528 124.612 211.74 124.612 209.975 124.612C208.618 124.612 208.052 124.119 208.459 122.661C209.07 120.507 209.59 118.33 210.088 116.154C210.315 115.099 210.812 114.426 211.989 114.426C213.166 114.426 213.687 113.798 213.935 112.743C214.954 108.615 215.995 104.464 217.081 100.313C217.398 99.1242 217.171 98.496 215.723 98.5633C214.275 98.6307 214.229 97.9127 214.524 96.8358C215.09 94.5921 215.633 92.3483 216.13 90.1272C216.244 88.9156 217.488 88.0629 218.733 88.4892L227.173 89.1398C233.51 87.4796 233.238 88.8483 232.763 90.6882C230.952 97.7782 229.187 104.891 227.355 111.981C227.038 113.237 226.427 114.538 228.712 114.673C229.64 114.673 229.527 115.615 229.368 116.288C228.803 118.577 228.237 120.888 227.694 123.199C227.535 124.186 226.608 124.859 225.635 124.702C222.263 124.59 218.778 124.657 215.316 124.635L215.293 124.612Z" fill="#9A8A9B" />
            <path d="M84.6548 57.2573C85.5375 50.2569 86.3521 43.6157 87.1893 36.9969C87.7777 32.3749 88.4339 27.7529 89.0675 23.1309C89.2714 21.7174 92.824 17.5441 94.0912 17.2973C94.2722 17.2525 94.4758 17.2525 94.6796 17.2973C95.743 17.7236 97.6665 21.5828 97.2594 22.727C93.2993 34.0352 89.3166 45.3433 85.311 56.629C85.2658 56.7861 85.1301 56.8534 84.6774 57.2573H84.6548Z" fill="url(#paint1_radial_640_86)" />
            <path d="M81.5775 57.7959C76.9159 55.1033 72.2767 52.4334 67.6378 49.7411C62.5688 46.8017 57.568 43.7505 52.4311 40.9458C49.5571 39.3751 49.444 36.4584 48.3806 33.9904C48.222 33.6763 48.3806 33.3621 48.6973 33.1154C49.7609 32.2403 53.8115 32.173 54.8525 33.1154C62.0033 39.5098 69.1315 45.9269 76.2368 52.3437C78.2737 53.9144 80.0838 55.754 81.5775 57.8183V57.7959Z" fill="url(#paint2_radial_640_86)" />
            <path d="M87.1894 58.379C89.1355 57.3245 91.0363 56.2476 93.0051 55.2154C101.536 50.6832 110.113 46.1508 118.712 41.641C120.952 40.8559 123.441 41.3271 125.252 42.8526C125.75 43.4135 125.433 43.8174 125.139 44.2886C123.373 47.0709 121.45 49.2696 117.852 50.1894C107.94 52.7025 98.1419 55.7537 88.2983 58.581C87.9816 58.6481 87.6646 58.7155 87.348 58.7378L87.1667 58.379H87.1894Z" fill="url(#paint3_radial_640_86)" />
            <path d="M228.735 72.6705C231.926 72.6258 234.551 75.1387 234.618 78.3022C234.686 81.4657 232.129 84.0686 228.938 84.1357C228.78 84.1357 228.622 84.1357 228.463 84.1357C225.408 84.1583 222.897 81.7351 222.874 78.6837C222.874 78.594 222.874 78.4816 222.874 78.3919C222.806 75.2957 225.272 72.7379 228.395 72.6705C228.508 72.6705 228.622 72.6705 228.735 72.6705Z" fill="#9A8A9B" />
            <defs>
                <radialGradient id="paint0_radial_640_86" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(83.1577 57.9055) rotate(105.303) scale(98.0632 98.0632)">
                    <stop offset="0.1" stopColor="#FBE94F" />
                    <stop offset="0.355461" stopColor="#EA3223" />
                    <stop offset="1" stopColor="#6D556C" />
                </radialGradient>
                <radialGradient id="paint1_radial_640_86" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(83.7184 60.7781) rotate(-75.6589) scale(43.8163 43.8163)">
                    <stop offset="0.1" stopColor="#FBE94F" />
                    <stop offset="0.511416" stopColor="#EA3223" />
                    <stop offset="1" stopColor="#6D556C" />
                </radialGradient>
                <radialGradient id="paint2_radial_640_86" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(83.3844 58.3111) rotate(-144.073) scale(40.9251 25.8638)">
                    <stop offset="0.1" stopColor="#FBE94F" />
                    <stop offset="0.53" stopColor="#EA3223" />
                    <stop offset="1" stopColor="#6D556C" />
                </radialGradient>
                <radialGradient id="paint3_radial_640_86" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(83.7121 60.2755) rotate(-21.4385) scale(45.5002 48.1685)">
                    <stop offset="0.1" stopColor="#FBE94F" />
                    <stop offset="0.475" stopColor="#EA3223" />
                    <stop offset="1" stopColor="#6D556C" />
                </radialGradient>
            </defs>
        </svg>

    )
}