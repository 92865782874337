export const NetIcon = ({ size: { width = "125", height = "125" } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1073_2002)">
                <path d="M124.312 0.885376H0.3125V124.885H124.312V0.885376Z" fill="#512BD4" />
                <path d="M22.4132 80.1075C21.5365 80.1075 20.7912 79.8155 20.1775 79.2311C19.5637 78.6317 19.2568 77.9201 19.2568 77.0962C19.2568 76.257 19.5637 75.538 20.1775 74.9387C20.7912 74.3393 21.5365 74.0397 22.4132 74.0397C23.3046 74.0397 24.0572 74.3393 24.6709 74.9387C25.2993 75.538 25.6134 76.257 25.6134 77.0962C25.6134 77.9201 25.2993 78.6317 24.6709 79.2311C24.0572 79.8155 23.3046 80.1075 22.4132 80.1075Z" fill="white" />
                <path d="M57.4636 79.6131H51.7645L36.7499 55.9256C36.37 55.3263 36.0556 54.7044 35.8074 54.0602H35.6758C35.7927 54.7495 35.8511 56.2253 35.8511 58.4875V79.6131H30.8096V47.3854H36.8812L51.3919 70.5111C52.0057 71.4702 52.4003 72.1294 52.5756 72.4889H52.6632C52.5172 71.635 52.444 70.1891 52.444 68.1513V47.3854H57.4636V79.6131Z" fill="white" />
                <path d="M82.0131 79.6131H64.3682V47.3854H81.3118V51.9252H69.5851V61.0496H80.3914V65.5669H69.5851V75.0958H82.0131V79.6131Z" fill="white" />
                <path d="M107.091 51.9252H98.0597V79.6131H92.8427V51.9252H83.834V47.3854H107.091V51.9252Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_1073_2002">
                    <rect width="124" height="124" fill="white" transform="translate(0.3125 0.885376)" />
                </clipPath>
            </defs>
        </svg>


    )
}