import React, { useEffect, useRef, lazy, Suspense } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./team.module.scss";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import ReactGA from 'react-ga';
import AnchorLink from "react-anchor-link-smooth-scroll";
import { CircularProgress } from "@mui/material";
import { OpenPositions } from '../../modules/Team/OpenPositions';
import { Head, Button, TopBar, Footer } from "../../components";
import ReactPlayer from 'react-player';

const Contact = lazy(() => import('../../components/Contact/Contact'));
const TeamCardFlipper = lazy(() => import('../../modules/Team/TeamCardFlipper'));
const TeamGallery = lazy(() => import('../../modules/Team/TeamGallery'));

export const Team = () => {
  const renderLoader = () => <div className="spinner"><CircularProgress color="inherit" /></div>;
  const { formatMessage } = useIntl();
  const { position } = useParams();
  const jobsRef = useRef(null);
  const video = localStorage.getItem("lang") === 'en' ? "https://www.youtube.com/embed/qak827APcGQ" : "https://www.youtube.com/embed/twoBmKJGyq0";

  useEffect(() => {
    ReactGA.pageview('/team');
    if (position === 'jobs') {
      setTimeout(() => {
        if (jobsRef && jobsRef.current) {
          window.scrollTo({
            top: jobsRef.current.offsetTop
          });
        }
      }, [100])

    }
    else {
      window.scrollTo(0, 0);
    }

  }, [position])

  return (
    <main>
      <Head
        title={position === 'jobs' ? "Careers | Wakapi" : "Join the Team| Wakapi"}
        description={position === 'jobs' ? "Jobs at Wakapi - Search through our open positions and submit your resume. Join our team! " : "Join Wakapi and skyrocket your career! Find our open positions and be part of our knowledge-sharing and collaboration culture."}
        path={position === 'jobs' ? "/team/jobs" : "/team"}
        haveCarousel={true}
      />
      <TopBar />
      <section className={styles.background__white}>
        <div className={styles.presentacion__container}>
          <div className={styles.title}>
            <h1>
              <FormattedMessage id="team.title" />
            </h1>
            <b>
              <FormattedMessage id="team.subtitle" />
            </b>
            <p>{parse(formatMessage({ id: "team.text" }))}</p>
            <AnchorLink href="#openPositions" style={{ zIndex: 2, position: 'relative' }}>
              <Button secondary>
                <FormattedMessage id="team.button" />
              </Button>
            </AnchorLink>

          </div>
          <div className={styles.video__container}>
            <ReactPlayer url={video}
              width='100%'
              height='100%'
              className='video'
              config={{
                youtube: {
                  playerVars: {
                    showinfo: 0,
                    modestbranding: 1,
                    controls: 0,
                    rel: 0,
                    fs: 0,
                    iv_load_policy: 3,
                    disablekb: 1,
                    color: 'white'
                  },
                  embedOptions: {
                    controls: 0,
                    rel: 0
                  }
                }
              }
              }
            />
          </div>
        </div>
      </section>
      <section className={styles.cardFlipper__container}>
        <Suspense fallback={renderLoader()}>
          <TeamCardFlipper />
        </Suspense>
      </section>
      <section className={styles.gallery__container}>
        <Suspense fallback={renderLoader()}>
          <TeamGallery />
        </Suspense>
      </section>
      <section ref={jobsRef} id="openPositions" className={styles.openPositions__container}>
        <OpenPositions />
        {position === 'jobs' && <div style={{height:2}}></div>}
      </section>
      <section className="contact__container">
        <Suspense fallback={renderLoader()}>
          <Contact />
        </Suspense>
      </section>
      <Footer />
    </main>
  );
};
export default Team;
