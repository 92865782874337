import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import parse from "html-react-parser";
import styles from "./team.module.scss";
import { useState } from "react";
import { getJobs } from "../../services/api";
import { CircularProgress } from "@mui/material";
import Slider from "react-slick";
import { useSearchParams } from "react-router-dom";

export const OpenPositions = () => {
  const { formatMessage } = useIntl();
  const [width, setWidth] = useState();
  const [selected, setSelected] = useState(width >= 640 ? 2 : 0);
  const [jobs, setJobs] = useState([]);
  const [pointerDown, setPointerDown] = useState(0);

  const [searchParams] = useSearchParams();
  const section = searchParams.get('section');

  useEffect(() => {
    setWidth(window.innerWidth);
    if(section === "metapixel"){
      localStorage.setItem('section','metapixel')
    }
  }, []);
  useEffect(() => {
    async function fetchData() {
      const response = await getJobs();
      setJobs(response);
    }
    fetchData();
  }, []);

  const removeTags = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  };

  const setText = (str) => {
    let text = removeTags(str).replace("The Role:", "");
    const end = text.search("Responsibilities:"); // Busca el final del texto a mostrar
    const splitText = text.slice(0, end); // Corta el texto en el final
    if(splitText.length > 310){ // Analiza si el texto cortado posee mas de 310 caracteres
      const lastSpace = splitText.indexOf(' ',300); // Busca en las ultimas 10 posiciones un espacio en blanco para cortar el texto
      return splitText.slice(0,lastSpace) + '...'; // Retorna el texto cortado justo antes del espacio en blanco y agrega puntos suspensivos.
    }else{
      return splitText; // Retorna el texto completo si no supera los 310 caracteres.
    }
  };
  const applyStyle = (position) => {
    if (position === selected) {
      return "openPosition__card--selected";
    }
    if (position === selected - 1) {
      return "openPosition__cardLeft-level1";
    }
    if (position === selected + 1) {
      return "openPosition__cardRight-level1";
    }
    if (position === selected - 2) {
      return "openPosition__cardLeft-level2";
    }
    if (position === selected + 2) {
      return "openPosition__cardRight-level2";
    }
  };

  const applyMarginLeft = () => {
    if (selected === 0) {
      if (width > 1440) {
        return 395;
      }
      if (width > 1025) {
        return 315;
      }
      return 190;
    }
    if (selected === 1) {
      if (width > 1440) {
        return 170;
      }
      if (width > 1025) {
        return 140;
      }
      return 0;
    }
  };
  const applyMarginRight = () => {
    if (selected === jobs.length - 1) {
      if (width > 1440) {
        return 395;
      }
      if (width > 1025) {
        return 335;
      }
      return 190;
    }
    if (selected === jobs.length - 2) {
      if (width > 1440) {
        return 170;
      }
      if (width > 1025) {
        return 140;
      }
      return 0;
    }
  };
  const moveLeft = () => {
    if (selected > 0) setSelected(selected - 1);
  };

  const moveRight = () => {
    if (selected < jobs.length) setSelected(selected + 1);
  };

  const onPointerDown = (e) => {
    if (e.pointerType === 'touch' && e.type === 'pointerdown') {
      setPointerDown(e.clientX);
    }
  }
  const onPointerUp = (e) => {
    if (e.pointerType === 'touch' && e.type === 'pointerup') {
      let pointerUp = e.clientX;
      if (pointerDown > pointerUp) {
        moveRight();
        return;
      }
      if (pointerDown < pointerUp) {
        moveLeft();
        return;
      }
    }
  }

  const metaPixelEvent = () =>{
   const isMetaPixel =  localStorage.getItem('section');
   if(isMetaPixel){    
      window.fbq('init', '240971342282440');
      window.fbq('track', 'PageView');
      localStorage.removeItem("section");
   }
  }
  const renderCardText = (item) => {
    return (
      <div style={{ minWidth: '250px', width: '100%', display: 'flex', flexDirection: 'column' }}>
        <div className={styles.title}>{item.attributes.title}</div>
        <p>{setText(item.attributes.body)}</p>
        <div className={styles.ubication}>
          Mendoza, Argentina Remote
        </div>
        <a className={styles.apply} href={item.links['careersite-job-apply-url']} target="__blank">
          Apply for the job
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            className={styles.arrowApply}
          >
            <path
              d="M1.5 15.5L16 1M16 1H1.5M16 1V13.5"
              stroke="#00D264"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      </div>
    )
  }
  var settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    autoplay: false,
    slidesToScroll: 1,
    arrows: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    pauseOnDotsHover: false,
  };
  return (
    <div className={styles.openPositions__container} id="jobs">
      <h3>
        {" "}
        <FormattedMessage id="team.openPositions-title" />
      </h3>
      <p>{parse(formatMessage({ id: "team.openPositions-text" }))}</p>

      {jobs.length === 0 ?
        <CircularProgress />
        :
        <>
          <div className={styles.openPositions__cardContainer}>
            {selected !== 0 && (
              <div className={styles.arrowLeft} onClick={moveLeft}>
                <svg width="21" height="38" viewBox="0 0 21 38" fill="none">
                  <path
                    d="M19.7852 36.059L1.78515 18.559L19.7852 1.05896"
                    stroke="#6E6E6E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}

            <div
              style={{
                display: "flex",
                alignSelf: "center",
                paddingLeft: applyMarginLeft(),
                paddingRight: applyMarginRight(),
              }}
              onPointerDown={onPointerDown} onPointerUp={onPointerUp}
            >
              {jobs.length > 0 &&
                jobs.map((item, index) => {
                  if (index >= selected - 2 && index <= selected + 2) {
                    if(index === selected){
                      return(
                        <a className={styles[applyStyle(index)]} key={item.id} style={{ transition: 'width 1s' }} href={item.links["careersite-job-url"]} target="__blank" onClick={metaPixelEvent}>
                        {renderCardText(item)}
                       </a>
                      )
                    }
                    return (
                      <div className={styles[applyStyle(index)]} key={item.id} style={{ transition: 'width 1s' }}>
                       {renderCardText(item)}
                      </div>
                    );
                  }
                  return <></>;
                })}
            </div>
            {selected !== jobs.length - 1 && (
              <div className={styles.arrowRight} onClick={moveRight}>
                <svg width="21" height="40" viewBox="0 0 21 40" fill="none">
                  <path
                    d="M1.78516 1.6095L19.7852 20.1095L1.78516 38.6095"
                    stroke="#6E6E6E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </div>

          <div className={styles["openPositions__cardContainer--mobile"]}>
             <Slider {...settings}  className="openPosition-slider" afterChange={(index) => setSelected(index)} >
              {jobs.length > 0 &&
                jobs.map((item, index) => {
                  if (index >= selected - 2 && index <= selected + 2) {
                    return (
                      <div style={{display:'flex'}}>
                       <div className={styles[applyStyle(index)]} key={item.id} style={{ transition: 'width 1s' }}>
                        <div style={{ minWidth: '250px', width: '100%', display: 'flex', flexDirection: 'column' }}>
                          <div className={styles.title}>{item.attributes.title}</div>
                          <p>{setText(item.attributes.body)}</p>
                          <div className={styles.ubication}>
                            Mendoza, Argentina Remote
                          </div>
                          <a className={styles.apply} href={item.links['careersite-job-apply-url']}>
                            Apply for the job
                            <svg
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                              className={styles.arrowApply}
                            >
                              <path
                                d="M1.5 15.5L16 1M16 1H1.5M16 1V13.5"
                                stroke="#00D264"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                      </div>
                    );
                  }
                  return <></>;
                })}
                </Slider>
          </div>
        </>
      }

    </div>
  );
};
export default OpenPositions;
