import { FormattedMessage } from "react-intl";
import { Footer, Head, TopBar, Contact, ErthLogo2 } from "../../components"
import { Suspense, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import styles from './clients.module.scss';
import { ClientsPageTitle, ClientsIcons, ClientsComments, ClientsDescription, ErthInfographic } from "../../modules";
import erthIllustration from './../../assets/images/clients/erth-illustration.webp'
import ReactGA from 'react-ga';
import { CaseStudiesSummaryClients } from "../../modules/Clients/Case-studies/CaseStudiesSummaryClients";

export const ClientsErthPage = () => {
    useEffect(() => {
        ReactGA.pageview('/clients/erth');
        window.scrollTo(0, 0);
      }, [])
    const renderLoader = () => <div className="spinner"><CircularProgress color="inherit" /></div>;
    return (

        <main>
            <Head
                title='Erth Case Study | Wakapi'
                description="An upgraded application solution that enhanced database performance and maintainability, allowing rapid feature integration and improved operational efficiency."
                path='/clients/erth'
                haveCarousel={true}
            />
            {/* Barra de navegación */}
            <TopBar />

            {/* Titulos */}
            <section className={styles.title__container}>
                <div className={styles.title}>
                    <h1>
                        <FormattedMessage id="clients.section" />
                    </h1>
                    <h3>
                        <FormattedMessage id="clients.title" />
                    </h3>
                </div>
            </section>

            {/* Erth */}
            <section>
                <div className={styles.background}>
                    <ClientsPageTitle
                        logo={<ErthLogo2 size={{width:'241',height:'200'}}/>}
                        text={<FormattedMessage id="clients.caseStudies-summary-erth" />}
                    />
                    <div className={styles.infographic}>
                        <ErthInfographic />
                    </div>
                    <ClientsDescription
                        client='erth'
                        img={<img src={erthIllustration} alt="Erth Client Software Outsourcing Success" width={259} height={215}/>}
                        marginImg="0"
                    />
                </div>
            </section>
            {/* Generico clientes */}
            <section className={styles.clients__section}>
                {/* Casos de éxito */}
                <CaseStudiesSummaryClients context='erth' />

                {/* Logos de clientes */}
                <ClientsIcons />

                {/* Opinines */}
                <ClientsComments />
            </section>

            {/* Contacto */}
            <section className="contact__container">
                <Suspense fallback={renderLoader()}>
                    <Contact />
                </Suspense>
            </section>

            {/* Footer */}
            <Footer />
        </main>
    )
}