import styles from './keyFeatures.module.scss';

export const KeyAspects = ({title, list}) => {
    return (
        <div className={styles.ai__keyAspects}>
            <h2>{title}</h2>
            <div className={styles.ai__list}>
                {list.map((item,index)=>{
                    return(
                        <div key={index}>
                            <h4>{item.title}</h4>
                            <p>{item.text}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}