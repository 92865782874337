import styles from './clients.module.scss';

export const TechnologiesClient = ({ title, list }) => {
    return (
        <div className={styles.clients}>
            <div className={styles.clients__container}>
                <h2>{title}</h2>
                <div className={styles.clients__list}>
                    {
                        list.map((item, index) => {
                            return <div key={index}>{item}</div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}