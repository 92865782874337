export const DigicelLogo = ({ size: { width = '121', height = '96' } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 121 96" fill="none" xmlns="http://www.w3.org/2000/svg" aria-valuetext="Digicel">
            <g clipPath="url(#clip0_835_269)">
                <path d="M10.9376 30.3729H0.347168V57.6398H10.9376C15.2123 57.6398 18.8455 56.3474 21.4439 53.9038C24.0468 51.4575 25.4214 48.0344 25.4214 44.0063C25.4214 39.9783 24.0468 36.5561 21.4439 34.108C18.8455 31.6643 15.2123 30.3729 10.9376 30.3729ZM6.29083 35.8353H10.8793C16.1824 35.8353 19.2232 38.8134 19.2232 44.0063C19.2232 49.1224 16.1037 52.1774 10.8793 52.1774H6.29083V35.8353Z" fill="#ED1B2F" />
                <path d="M30.9213 29.3391C28.9636 29.3391 27.4307 30.8539 27.4307 32.7883C27.4307 34.7011 28.9636 36.1987 30.9213 36.1987C32.8902 36.1987 34.4324 34.7011 34.4324 32.7883C34.4324 30.8539 32.8902 29.3391 30.9213 29.3391Z" fill="#ED1B2F" />
                <path d="M28.1553 57.6405H33.7463V38.6243H28.1553V57.6405Z" fill="#ED1B2F" />
                <path d="M51.2191 40.3068C49.8121 38.877 47.8765 38.1263 45.579 38.1263C43.2029 38.1263 40.9739 39.075 39.3031 40.7978C37.561 42.5921 36.6011 45.0918 36.6011 47.8357C36.6011 50.5705 37.561 53.0639 39.3031 54.8563C40.9748 56.5774 43.2038 57.5251 45.579 57.5251C47.8978 57.5251 49.7797 56.8098 51.1802 55.3972V56.0863C51.1802 59.021 49.2474 60.7727 46.0095 60.7727C43.8481 60.7727 41.782 60.1478 39.8714 58.9161L39.6354 58.7641L37.6212 63.2688L37.7804 63.3782C39.8511 64.8081 43.0085 65.6609 46.2261 65.6609C52.6946 65.6609 56.712 61.9114 56.712 55.8756V38.6237H51.2191V40.3068ZM46.8722 52.4643C44.2405 52.4643 42.33 50.5181 42.33 47.8357C42.33 45.1416 44.2405 43.1863 46.8722 43.1863C49.5353 43.1863 51.395 45.0981 51.395 47.8357C51.395 50.5181 49.4937 52.4643 46.8722 52.4643Z" fill="#ED1B2F" />
                <path d="M63.3842 29.3391C61.4264 29.3391 59.8926 30.8539 59.8926 32.7883C59.8926 34.7011 61.4264 36.1987 63.3842 36.1987C65.353 36.1987 66.8952 34.7011 66.8952 32.7883C66.8952 30.8539 65.353 29.3391 63.3842 29.3391Z" fill="#ED1B2F" />
                <path d="M60.6182 57.6405H66.2091V38.6243H60.6182V57.6405Z" fill="#ED1B2F" />
                <path d="M83.3209 51.2001C82.2157 52.3776 80.8466 53.0007 79.3619 53.0007C76.7524 53.0007 74.8585 50.9496 74.8585 48.1225C74.8585 45.2954 76.7608 43.2434 79.3813 43.2434C80.8837 43.2434 82.0815 43.7942 83.2635 45.0268L83.4172 45.1878L87.4494 41.8986L87.3114 41.7213C85.5055 39.4025 82.7387 38.1255 79.5183 38.1255C73.4247 38.1255 69.1694 42.2368 69.1694 48.1225C69.1694 54.0082 73.4247 58.1185 79.5183 58.1185C82.7211 58.1185 85.4944 56.8488 87.329 54.5444L87.4697 54.369L83.4764 51.0346L83.3209 51.2001Z" fill="#ED1B2F" />
                <path d="M120.653 57.4064C120.653 57.4064 119.375 57.6398 117.674 57.6398C112.4 57.6398 110.646 54.9881 110.646 50.4455V31.3315H116.205V49.8802C116.205 51.9703 116.822 52.6938 118.578 52.8303C119.624 52.9117 120.653 52.7516 120.653 52.7516V57.4064Z" fill="#ED1B2F" />
                <path d="M103.692 51.7262C102.388 52.7762 100.68 53.4029 99.1079 53.4029C96.6956 53.4029 95.0794 52.2046 94.5259 50.0205H107.898L107.926 49.8188C107.986 49.3893 108.027 48.6992 108.027 48.1033V48.1023C108.016 45.2047 107.106 42.7159 105.391 40.9053C103.671 39.0875 101.308 38.1262 98.559 38.1262C95.7006 38.1262 93.2448 39.0921 91.4555 40.9189C89.6653 42.7457 88.7192 45.2572 88.7192 48.1792C88.7192 51.0949 89.693 53.5874 91.5351 55.3871C93.3633 57.1741 95.8811 58.1183 98.8136 58.1183C101.529 58.1183 103.882 57.5051 105.787 56.2272C106.563 55.7063 107.177 55.0497 107.385 54.8101L103.692 51.7262ZM98.5201 42.8995C100.499 42.8995 101.853 44.0498 102.359 46.1471H94.5426C94.9128 44.6042 95.9116 42.8995 98.5201 42.8995Z" fill="#ED1B2F" />
            </g>
            <defs>
                <clipPath id="clip0_835_269">
                    <rect width="121" height="37" fill="white" transform="translate(0 29)" />
                </clipPath>
            </defs>
        </svg>

    )
}