export const ZippyYumLogo = ({ size: { width = '129', height = '72' } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 129 72" fill="none" xmlns="http://www.w3.org/2000/svg" aria-valuetext="Zippy Yum">
            <g clip-path="url(#clip0_1104_1082)">
                <path d="M32.9929 41.1902V45.2255H30.7621C30.7621 45.0238 30.7621 44.8485 30.7621 44.6731C30.7621 41.4357 30.7557 39.9222 30.7642 36.6848C30.7717 34.1181 32.1565 31.9722 34.3819 31.0462C38.2405 29.4395 42.4665 32.529 42.2288 36.7823C41.9782 41.2801 37.3099 43.8183 33.5273 41.5113C33.3806 41.4226 33.2338 41.3349 32.9918 41.1902H32.9929ZM36.5013 40.091C38.4665 40.0965 40.0269 38.5084 40.029 36.5007C40.0312 34.5126 38.443 32.8873 36.5056 32.895C34.5929 32.9027 33.0164 34.5115 33.0068 36.4667C32.9972 38.4767 34.5479 40.0866 36.5013 40.0921V40.091Z" fill="black" />
                <path d="M45.6345 41.1902V45.2255H43.4037C43.4037 45.0238 43.4037 44.8485 43.4037 44.6731C43.4037 41.4357 43.3973 39.9222 43.4058 36.6848C43.4133 34.1181 44.7981 31.9722 47.0235 31.0462C50.8821 29.4395 55.1081 32.529 54.8704 36.7823C54.6198 41.2801 49.9515 43.8183 46.1689 41.5113C46.0222 41.4226 45.8754 41.3349 45.6334 41.1902H45.6345ZM49.1418 40.091C51.107 40.0965 52.6674 38.5084 52.6696 36.5007C52.6717 34.5126 51.0835 32.8873 49.1461 32.895C47.2334 32.9027 45.657 34.5115 45.6473 36.4667C45.6377 38.4767 47.1884 40.0866 49.1418 40.0921V40.091Z" fill="black" />
                <path d="M55.8962 30.7109H58.1077C58.1077 30.9499 58.1077 31.1647 58.1077 31.3795C58.1088 33.11 58.0799 34.8416 58.1206 36.571C58.1634 38.3628 59.3928 39.7832 61.0571 40.0572C62.7192 40.3311 64.3653 39.3481 64.9243 37.6943C65.0678 37.268 65.1128 36.7901 65.1182 36.3353C65.1396 34.4854 65.1267 32.6354 65.1267 30.7361H67.3297C67.3393 30.9049 67.3554 31.0605 67.3565 31.2162C67.3565 34.5172 67.3725 37.817 67.3511 41.118C67.334 43.7735 65.6965 46.0081 63.2601 46.7676C62.9431 46.8663 62.6132 46.932 62.2855 46.9846C62.0949 47.0153 61.8967 46.9901 61.6515 46.9901V44.7577C62.8413 44.7018 63.8737 44.2042 64.5195 43.1258C64.8461 42.5811 65.0014 41.9301 65.222 41.3602C64.1886 41.6989 63.2697 42.1175 62.309 42.2918C59.5513 42.7926 56.6491 40.7487 56.0633 37.9299C55.9144 37.2121 55.9133 36.4559 55.9026 35.7161C55.8801 34.0689 55.8962 32.4206 55.8962 30.712V30.7109Z" fill="black" />
                <path d="M28.8515 42.3728H26.6689V30.7295H28.8515V42.3728Z" fill="black" />
                <path d="M27.8252 27.8462H27.7074C27.142 27.8462 26.6836 28.3153 26.6836 28.8939C26.6836 29.4726 27.142 29.9416 27.7074 29.9416H27.8252C28.3907 29.9416 28.8491 29.4726 28.8491 28.8939C28.8491 28.3153 28.3907 27.8462 27.8252 27.8462Z" fill="black" />
                <path d="M106.069 41.9937H103.967C103.955 41.7898 103.933 41.586 103.932 41.3821C103.929 39.7667 103.926 38.1513 103.932 36.5359C103.943 33.9976 105.324 31.9077 107.544 31.0572C109.754 30.2101 112.057 30.8413 113.728 32.7581C113.791 32.8294 113.816 32.9324 113.883 33.0694C114.745 31.991 115.686 31.26 116.877 30.9224C120.224 29.9755 123.581 32.22 123.908 35.784C124.095 37.8247 123.942 39.8982 123.942 41.9926H121.786C121.786 41.7997 121.786 41.5958 121.786 41.3931C121.786 39.7196 121.793 38.045 121.783 36.3715C121.772 34.4558 120.323 32.939 118.494 32.9116C116.601 32.8831 115.083 34.322 115.036 36.2597C114.994 37.9518 115.021 39.6461 115.018 41.3394C115.018 41.5476 115.018 41.7569 115.018 41.9969H112.879C112.879 41.8008 112.879 41.598 112.879 41.3942C112.879 39.7207 112.895 38.0461 112.875 36.3726C112.853 34.6267 111.596 33.1658 109.959 32.9324C108.337 32.7022 106.757 33.6853 106.262 35.2865C106.121 35.7402 106.085 36.2421 106.078 36.7222C106.055 38.2598 106.069 39.7974 106.069 41.335C106.069 41.5443 106.069 41.7536 106.069 41.9948V41.9937Z" fill="black" />
                <path d="M91.3818 30.7417H93.528C93.528 31.0212 93.528 31.2688 93.528 31.5165C93.528 33.1714 93.5173 34.8263 93.5344 36.4812C93.5515 38.136 94.8281 39.5728 96.4378 39.7843C98.0688 39.9991 99.6763 38.9777 100.13 37.4094C100.231 37.0609 100.28 36.6861 100.284 36.3223C100.3 34.6871 100.291 33.052 100.291 31.4157C100.291 31.2053 100.291 30.996 100.291 30.7472H102.444C102.444 30.9949 102.444 31.2404 102.444 31.4847C102.444 33.1013 102.451 34.7167 102.442 36.3332C102.425 39.0807 100.382 41.5269 97.921 41.9291C94.6996 42.4563 92.1957 40.3323 91.5232 37.7777C91.4546 37.5157 91.3893 37.2428 91.3872 36.9754C91.3764 34.914 91.3807 32.8536 91.3807 30.7417H91.3818Z" fill="black" />
                <path d="M80.6359 25C75.2255 25 70.8389 29.489 70.8389 35.0257C70.8389 40.5624 75.2255 45.0514 80.6359 45.0514C81.5023 45.0514 82.3409 44.9352 83.1409 44.7193C83.3048 44.533 83.46 44.3314 83.5961 44.0979C83.957 43.4776 84.4282 41.6014 84.4282 40.3082C83.1645 41.1707 82.2113 41.6551 81.1468 41.8545C78.0914 42.4255 74.8774 40.0955 74.2284 36.8811C74.0635 36.0625 74.0624 35.2 74.0507 34.3561C74.026 32.4776 74.0432 30.5981 74.0432 28.6506H76.4935C76.4935 28.9224 76.4935 29.1679 76.4935 29.4134C76.4946 31.3872 76.4624 33.361 76.5074 35.3337C76.5545 37.3776 77.9168 38.9952 79.761 39.3087C81.6019 39.621 83.4258 38.4999 84.0448 36.6148C84.2044 36.1293 84.2536 35.5835 84.26 35.0652C84.2836 32.9566 84.2697 30.8469 84.2697 28.6802H86.7093C86.72 28.872 86.7382 29.0495 86.7382 29.2271C86.7382 32.9905 86.7564 38.0472 86.7318 41.8107C86.7297 42.1987 86.6965 42.5779 86.6376 42.9472C88.9455 41.1126 90.4319 38.2478 90.4319 35.0268C90.4319 29.4901 86.0453 25.0011 80.6349 25.0011L80.6359 25Z" fill="#95C83C" />
                <path d="M17.4464 39.2296H24.5125C24.988 38.0307 25.2515 36.7221 25.2515 35.3489C25.2515 33.4836 24.7663 31.7345 23.9203 30.2231L17.4453 39.2296H17.4464Z" fill="#CB5416" />
                <path d="M11.7502 42.248L19.1366 31.6118H11.4621V28.45H22.6707C20.8169 26.3327 18.1245 25 15.1259 25C9.53334 25 5 29.6337 5 35.349C5 41.0644 9.53334 45.698 15.1259 45.698C18.1245 45.698 20.8169 44.3653 22.6718 42.248H11.7502Z" fill="#ED6721" />
                <path d="M76.4942 28.6494L84.2061 35.8816C84.2061 35.8816 83.7692 39.359 80.3496 39.359C76.9301 39.359 76.4492 36.8142 76.4492 35.4114C76.4492 33.7675 76.4931 28.6494 76.4931 28.6494H76.4942Z" fill="#7DAB2D" />
                <path d="M76.2306 40.3388L81.2823 45.0294C81.2823 45.0294 82.0994 44.9889 83.1404 44.7193C84.0143 43.7625 84.3388 42.0002 84.4276 40.3081C82.2954 41.8326 81.0027 41.9378 80.3495 41.9378C79.6962 41.9378 77.8755 41.8468 76.2295 40.3388H76.2306Z" fill="#7DAB2D" />
                <path d="M11.4619 31.6118L16.0799 36.0142L19.1374 31.6118H11.4619Z" fill="#CB5416" />
                <path d="M11.75 42.248L15.403 45.698C15.403 45.698 19.4191 45.8811 22.6705 42.248C22.6523 42.2184 11.75 42.248 11.75 42.248Z" fill="#CB5416" />
            </g>
            <defs>
                <clipPath id="clip0_1104_1082">
                    <rect width="119" height="22" fill="white" transform="translate(5 25)" />
                </clipPath>
            </defs>
        </svg>




    )
}