import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { string, object } from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { CircularProgress } from "@mui/material";
import { Formik } from "formik";
import styles from './contactsmall.module.scss';
import Button from "../../Button/Button";

export const ContactSmall = () => {

    const recaptchaRef = useRef();
    const form = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const submitForm = async (values, e) => {
        await recaptchaRef.current.execute().then(token => {
            values.recaptcha = token;
        })
        setIsLoading(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_MAIL}`,
            headers: { "content-type": "application/json" },
            data: values
        })
            .then(result => {
                if (result.status === 200) {
                    setError(false)
                    navigate('/en/contact-confirm')
                } else {
                    setError(true)
                }
                setIsLoading(false);
            })
            .catch(error => {
                setError(error.message)
                setIsLoading(false);
            });
    };

    const validationSchema = () =>
        object().shape({
            name: string().required('Full Name is a required field.'),
            email: string()
                .email("Enter a valid E-mail.")
                .required('E-mail is a required field. '),
            message: string().required('Your message is a required field.'),
        });

    const renderForm = ({
        values,
        handleBlur,
        handleSubmit,
        handleChange,
        errors,
        touched,
    }) => {
        return (
            <form
                action="#"
                ref={form}
                onSubmit={handleSubmit}
                id="contacto"
                className={styles.form__container}
            >
                <div className={styles.form}>
                    <div className={styles.data__field}>
                        <label htmlFor="name">
                            Full Name
                        </label>
                        <input
                            id="name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.name && touched.name && "input--error"}
                        />
                        {errors.name && touched.name && (
                            <div className="form--error">{errors.name}</div>
                        )}
                    </div>
                    <div className={styles.data__field}>
                        <label htmlFor="email">
                            Email
                        </label>
                        <input
                            name="email"
                            id="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="email"
                            className={errors.email && touched.email && "input--error"}
                        />
                        {errors.email && touched.email && (
                            <div className="form--error">{errors.email}</div>
                        )}
                    </div>
                    <div className={styles["data__field--message"]}>
                        <label htmlFor="message">
                            Your message
                        </label>
                        <textarea
                            name="message"
                            id="message"
                            value={values.message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                                errors.message && touched.message && "input--error"
                            }
                        />

                        {errors.message && touched.message && (
                            <div className="form--error">{errors.message}</div>
                        )}
                    </div>
                    <div className={styles.button__container}>
                        <ReCAPTCHA
                            className={styles.captcha}
                            size="invisible"
                            badge="inline"
                            ref={recaptchaRef}
                            sitekey="6Lf9uA8mAAAAALE6ss4VXVTcEtUYMvmSDm9Won3O"
                        />
                        <Button secondary type="submit" disabled={isLoading}>
                            {isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : (
                                <>Send</>
                            )}
                        </Button>

                    </div>
                </div>
                <div className={styles.error__container}>
                    {error && <div className={styles.error}>
                        Oops! Something failed when sending your message. Please try again!
                    </div>}
                </div>
            </form>
        );
    };


    return (
        <div className={styles.contact__background}>
            <div className={styles.contact}>
                <h3>Let's talk...</h3>
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: "",
                        email: "",
                        message: "",
                        recaptcha: "",
                        mailSent: false,
                        error: null
                    }}
                    onSubmit={(values, { resetForm }) => {
                        submitForm(values, resetForm);
                        resetForm({
                            name: "",
                            email: "",
                            message: "",
                            recaptcha: "",
                            mailSent: false,
                            error: null
                        });
                    }}
                    validationSchema={validationSchema}
                >
                    {renderForm}
                </Formik>

            </div>

        </div>
    )
}