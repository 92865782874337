import { FormattedMessage } from 'react-intl';
import styles from './whyChoose.module.scss';
import { ModularIcon, ScaleIcon, CommentIcon, SettingsIcon, ProductivityIcon } from '../../../assets/icons';

export const WhyChoose = ({title,list}) => {

   
    
    return (
        <div className={styles.boxcontainer}>
            <h2>{title}</h2>
            <div className={styles.whyboxcontainer}>

                {list.map((item,index)=>{
                    return(
                        <div className={styles.whyboxcolumn} key={index}>
                        <div className={styles.background}>
                            {item.icon}
                        </div>
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                    </div>
                    )
                })}
            </div>
        </div>
    )
}