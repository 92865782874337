import styles from './budderfly-infographic.module.scss'
import { FormattedMessage } from "react-intl";
import { Inforgraphic } from '../Infographic';

//Imagenes
import img1 from './../../../../assets/images/clients/slider/budderfly/budderfly-improving-energy-monitoring-and-management-1.webp';
import img2 from './../../../../assets/images/clients/slider/budderfly/budderfly-improving-energy-monitoring-and-management-2.webp';
import img3 from './../../../../assets/images/clients/slider/budderfly/budderfly-improving-energy-monitoring-and-management-3.webp';
import img4 from './../../../../assets/images/clients/slider/budderfly/budderfly-improving-energy-monitoring-and-management-4.webp';
import { PreloadImage } from '../../../../components';

export const BudderflyInfographic = () => {
    return (
        <Inforgraphic>
            <PreloadImage imagePath={img1}/>
            <PreloadImage imagePath={img2}/>
             <div className={styles.block1}>
                    <div className={styles.block1__inner}>
                        <div className={styles.title__container}>
                            <FormattedMessage id="clients.infographic-budderfly-title" />
                            <svg
                                width="211"
                                height="73"
                                viewBox="0 0 211 73"
                                fill="none"
                                className={styles.arrowSvg__desktop}
                            >
                                <path
                                    d="M0.818359 37.345H209.818M173.447 0.539062L209.818 36.5391L173.447 72.5391"
                                    stroke="black"
                                />
                            </svg>
                            <svg
                                width="49"
                                height="141"
                                viewBox="0 0 49 141"
                                fill="none"
                                className={styles.arrowSvg__mobile}
                            >
                                <path
                                    d="M24.0478 0.539061L24.0477 139.539M48.5264 115.349L24.5838 139.539L0.641194 115.349"
                                    stroke="black"
                                />
                            </svg>
                        </div>
                        <div className={styles.text}>
                            <FormattedMessage id="clients.infographic-budderfly-1" />
                        </div>
                    </div>
                    <div className={styles.green}>
                        <div className={styles.text}>
                            <FormattedMessage id="clients.infographic-budderfly-2" />
                        </div>
                        <img src={img1} width={339} height={253} alt='Budderfly- Improving energy monitoring and management' />
                    </div>
                </div>
                <div className={styles.block2}>
                    <div className={styles.text}>
                        <FormattedMessage id="clients.infographic-budderfly-3" />
                    </div>
                    <img src={img2} width={339} height={253} alt='Budderfly- Improving energy monitoring and management' />
                    <div className={styles.text}>
                        <FormattedMessage id="clients.infographic-budderfly-4" />
                    </div>
                </div>
                <div className={styles.block3}>
                    <div className={styles.green}>
                        <img src={img3} width={327} height={327} alt='Budderfly- Improving energy monitoring and management' />
                        <div className={styles.text}>
                            <FormattedMessage id="clients.infographic-budderfly-5" />
                        </div>
                    </div>
                    <div className={styles.text}>
                        <FormattedMessage id="clients.infographic-budderfly-6" />
                    </div>
                </div>
                <div className={styles.block4}>
                    <img src={img4} width={678} height={452} alt='Budderfly- Improving energy monitoring and management' />
                    <div className={styles.text}>
                        <div>
                            <FormattedMessage id="clients.infographic-budderfly-7" />
                        </div>
                        <div>
                            <FormattedMessage id="clients.infographic-budderfly-8" />
                        </div>
                    </div>
                </div>
        </Inforgraphic>
    );
}