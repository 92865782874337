export const NetstepLogo = ({ size: { width = '176', height = '96' } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 176 96" fill="none" xmlns="http://www.w3.org/2000/svg" aria-valuetext="Net steps">
            <g clipPath="url(#clip0_845_749)">
                <path d="M59.6465 55.9692C58.4076 55.3888 56.8886 55.0969 55.0929 55.0969C51.726 55.0969 49.5005 56.0109 48.4182 57.8388V55.4045H47.2664V67.9078H48.4182V60.8864C48.4182 59.2305 49.1334 57.9569 50.5637 57.069C51.7851 56.3184 53.3024 55.9414 55.1172 55.9414C56.6519 55.9414 57.9273 56.1985 58.9383 56.7111C60.2468 57.3609 60.901 58.3513 60.901 59.6823V67.9078H62.0529V59.4269C62.0529 57.8735 61.2507 56.7198 59.6447 55.9674L59.6465 55.9692Z" fill="black" />
                <path d="M79.3276 56.4296C77.7912 55.5417 75.8632 55.0969 73.5438 55.0969C71.2244 55.0969 69.2425 55.6095 67.6557 56.6346C65.8234 57.8301 64.9082 59.5137 64.9082 61.6822C64.9082 63.8506 65.7538 65.6455 67.4469 66.7558C68.9119 67.7132 70.8834 68.191 73.3611 68.191C75.1411 68.191 76.7019 67.9425 78.0452 67.4473C79.773 66.8166 80.9597 65.7393 81.6052 64.219H80.4012C79.8426 65.3119 78.8473 66.132 77.4171 66.6793C76.2652 67.1241 74.9393 67.3448 73.4394 67.3448C68.6231 67.3448 66.1453 65.543 66.0061 61.9393L82.1551 61.9133V61.1957C82.1551 59.095 81.212 57.5051 79.3293 56.4296H79.3276ZM66.0061 61.0932C66.1975 59.3678 67.0693 58.0438 68.6231 57.1211C69.9664 56.3358 71.6072 55.9431 73.5438 55.9431C75.4804 55.9431 77.0778 56.3271 78.3862 57.0968C79.9575 58.0021 80.7683 59.3348 80.8205 61.0932H66.0079H66.0061Z" fill="black" />
                <path d="M88.1216 52.1761H86.9958V56.0213H83.4897V56.8918H86.9958L87.0011 67.9095H88.1216V56.8918H91.7078V56.0213H88.1216V52.1761Z" fill="black" />
                <path d="M103.145 61.0167L99.4019 60.5563C97.1677 60.2661 96.0524 59.684 96.0524 58.8135C96.0524 58.0786 96.6196 57.5156 97.7541 57.1229C98.6432 56.8154 99.6646 56.6625 100.816 56.6625C102.771 56.6625 104.183 56.9266 105.057 57.4565C105.876 57.9517 106.287 58.7545 106.287 59.8647H108.302C108.302 56.6712 105.746 55.0726 100.634 55.0726C99.1165 55.0726 97.7558 55.3124 96.5517 55.7902C94.8935 56.4574 94.0653 57.4652 94.0653 58.8135C94.0653 59.9064 94.6499 60.7439 95.8192 61.3243C96.6735 61.7517 97.9211 62.0679 99.5619 62.273C100.696 62.3929 101.831 62.5127 102.964 62.6326C105.267 63.0079 106.418 63.6404 106.418 64.5283C106.418 65.9461 104.698 66.655 101.262 66.655C99.1339 66.655 97.6062 66.3562 96.6822 65.7585C95.7566 65.1607 95.2955 64.2034 95.2955 62.8881H93.3066C93.3066 66.4587 95.9497 68.2431 101.238 68.2431C102.965 68.2431 104.413 68.0555 105.582 67.6802C107.467 67.0477 108.408 65.9982 108.408 64.5283C108.408 62.6153 106.656 61.4459 103.148 61.0185L103.145 61.0167Z" fill="black" />
                <path d="M115.393 52.1761H113.482V55.8406H109.976V57.4044H113.482V67.9095H115.393V57.4044H118.979V55.8406H115.393V52.1761Z" fill="black" />
                <path d="M134.656 56.4053C133.067 55.5331 131.079 55.0987 128.688 55.0987C126.297 55.0987 124.273 55.5956 122.615 56.5843C120.644 57.7797 119.657 59.4808 119.657 61.6839C119.657 63.8871 120.564 65.6559 122.379 66.7836C123.915 67.7236 125.956 68.1927 128.503 68.1927C130.336 68.1927 131.942 67.953 133.32 67.4751C135.152 66.8427 136.495 65.5882 137.35 63.7082H135.309C134.244 65.6733 132.001 66.655 128.582 66.655C124.202 66.655 121.864 65.2042 121.568 62.299L137.691 62.273V61.2218C137.691 59.0863 136.678 57.4809 134.654 56.4053H134.656ZM121.622 60.7613C121.883 59.3956 122.721 58.3444 124.134 57.6094C125.391 56.9613 126.91 56.6364 128.688 56.6364C130.363 56.6364 131.802 56.9266 133.007 57.5069C134.541 58.2245 135.389 59.3087 135.545 60.7613H121.622Z" fill="black" />
                <path d="M154.468 56.5843C152.916 55.5939 150.89 55.0987 148.395 55.0987C145.324 55.0987 143.022 55.8771 141.486 57.4305V55.3802H139.549V72.2151H141.486V66.0921C143.038 67.6628 145.359 68.4499 148.447 68.4499C150.943 68.4499 152.966 67.946 154.52 66.9382C156.352 65.7602 157.267 64.0261 157.267 61.7361C157.267 59.446 156.335 57.7814 154.466 56.586L154.468 56.5843ZM153.368 65.6542C152.147 66.4743 150.551 66.8844 148.578 66.8844C146.448 66.8844 144.774 66.5004 143.553 65.7307C142.175 64.8428 141.484 63.4927 141.484 61.6822C141.484 60.0594 142.208 58.7962 143.657 57.8909C144.983 57.0708 146.633 56.6607 148.604 56.6607C150.471 56.6607 152.014 57.0447 153.236 57.8127C154.666 58.718 155.381 60.0333 155.381 61.7587C155.381 63.484 154.71 64.749 153.366 65.6542H153.368Z" fill="black" />
                <path d="M168.915 61.0167L165.172 60.5563C162.938 60.2661 161.823 59.684 161.823 58.8135C161.823 58.0786 162.39 57.5156 163.524 57.1229C164.415 56.8154 165.435 56.6625 166.587 56.6625C168.541 56.6625 169.954 56.9266 170.827 57.4565C171.647 57.9517 172.057 58.7545 172.057 59.8647H174.072C174.072 56.6712 171.516 55.0726 166.404 55.0726C164.887 55.0726 163.524 55.3124 162.32 55.7902C160.662 56.4574 159.834 57.4652 159.834 58.8135C159.834 59.9064 160.418 60.7439 161.588 61.3243C162.442 61.7517 163.69 62.0679 165.33 62.273C166.465 62.3929 167.599 62.5127 168.732 62.6326C171.036 63.0079 172.186 63.6404 172.186 64.5283C172.186 65.9461 170.467 66.655 167.03 66.655C164.901 66.655 163.375 66.3562 162.451 65.7585C161.527 65.1607 161.064 64.2034 161.064 62.8881H159.075C159.075 66.4587 161.718 68.2431 167.006 68.2431C168.734 68.2431 170.182 68.0555 171.351 67.6802C173.235 67.0477 174.177 65.9982 174.177 64.5283C174.177 62.6153 172.424 61.4459 168.917 61.0185L168.915 61.0167Z" fill="black" />
                <path d="M38.0013 46.1516C43.1147 41.0455 43.1147 32.767 38.0013 27.6609C32.888 22.5548 24.5977 22.5548 19.4843 27.6609C14.371 32.767 14.371 41.0455 19.4843 46.1516C24.5977 51.2577 32.888 51.2577 38.0013 46.1516Z" fill="#4898B1" />
                <path d="M65.3032 12.6733C55.3417 12.6733 47.2681 20.6208 47.2681 30.424V48.1746H81.1128C91.0743 48.1746 99.1479 40.2271 99.1479 30.424V12.6733H65.3032Z" fill="#75E6DA" />
                <path d="M14.0289 55.4062C6.37815 55.4062 0.175049 61.5102 0.175049 69.0389V82.6733H26.1724C33.8232 82.6733 40.0263 76.5694 40.0263 69.0389V55.4045H14.0289V55.4062Z" fill="#05445E" />
            </g>
            <defs>
                <clipPath id="clip0_845_749">
                    <rect width="174" height="70" fill="white" transform="translate(0.175049 12.6733)" />
                </clipPath>
            </defs>
        </svg>


    )
}