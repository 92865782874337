import React, { useState, useRef } from "react";
import styles from "./contact.module.scss";
import Button from "../Button/Button";
import arrowSocial from '../../assets/images/iconos/arrow-right.svg'
import { Formik } from "formik";
import { string, object } from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import ReCAPTCHA from "react-google-recaptcha";
import Slogan from "../Slogan/Slogan";
import { useNavigate } from "react-router";

export const Contact = ({ isPageContact }) => {

  //Hooks
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const recaptchaRef = useRef();
  const form = useRef();
  const { formatMessage } = useIntl();

  // Renderizacion de formulario
  const renderForm = ({
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    errors,
    touched,
  }) => {
    return (
      <form
        action="#"
        ref={form}
        onSubmit={handleSubmit}
        id="contacto"
        className={styles["container__data--form"]}
      >
        {/* Nombre */}
        <div className={styles.data__field}>
          <label htmlFor="name">
            <FormattedMessage id="contact-form-name" />
          </label>
          <br />
          <input
            id="name"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.name && touched.name && "input--error"}
          />
          {errors.name && touched.name && (
            <div className="form--error">{errors.name}</div>
          )}
        </div>

        {/* Apellido */}
        <div className={styles.data__field}>
          <label htmlFor="lastName">
            <FormattedMessage id="contact-form-lastName" />
          </label>
          <br />
          <input
            id="lastName"
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.lastName && touched.lastName && "input--error"}
          />
          {errors.lastName && touched.lastName && (
            <div className="form--error">{errors.lastName}</div>
          )}
        </div>

        {/* Telefono */}
        <div className={styles.data__field}>
          <label htmlFor="phone">
            <FormattedMessage id="contact-form-cell" />
          </label>
          <br />
          <input
            name="phone"
            id="phone"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            className={errors.phone && touched.phone && "input--error"}
          />
          {errors.phone && touched.phone && (
            <div className="form--error">{errors.phone}</div>
          )}
        </div>

        {/* Email */}
        <div className={styles.data__field}>
          <label htmlFor="email">
            <FormattedMessage id="contact-form-email" />
          </label>
          <br />
          <input
            name="email"
            id="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            type="email"
            className={errors.email && touched.email && "input--error"}
          />
          {errors.email && touched.email && (
            <div className="form--error">{errors.email}</div>
          )}
        </div>

        {/* Mensaje */}
        <div className={styles.data__field}>
          <label htmlFor="message">
            <FormattedMessage id="contact-form-message" />
          </label>
          <br />
          <textarea
            name="message"
            id="message"
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors.message && touched.message && "input--error"
            }
          ></textarea>
          {errors.message && touched.message && (
            <div className="form--error">{errors.message}</div>
          )}
        </div>

        {/* Compañia */}
        <div className={styles.data__field}>
          <label htmlFor="company">
            <FormattedMessage id="contact-form-company" />
          </label>
          <br />
          <input
            name="company"
            id="company"
            value={values.company}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.company && touched.company && "input--error"}
          />
          {errors.company && touched.company && (
            <div className="form--error">{errors.company}</div>
          )}
        </div>

        {/* Error al enviar mobile */}
        <div className={styles.feedback__mobile}>
          {error && <Alert variant="outlined" severity="error">
            <FormattedMessage id="contact-form-message-error" />
          </Alert>}
        </div>

        {/* Boton submit */}
        <div className={styles.button__container}>
          <ReCAPTCHA
            className={styles.captcha}
            size="invisible"
            badge="inline"
            ref={recaptchaRef}
            sitekey="6Lf9uA8mAAAAALE6ss4VXVTcEtUYMvmSDm9Won3O"
          />
          <Button secondary type="submit" disabled={isLoading}>
            {isLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <FormattedMessage id="contact-form-button" />
            )}
          </Button>

        </div>

        {/* Error al enviar desktop */}
        <div className={styles.feedback__desktop}>
          {error && <Alert variant="outlined" severity="error">
            <FormattedMessage id="contact-form-message-error" />
          </Alert>}
        </div>

      </form>
    );
  };


  const submitForm = async (values, e) => {
    await recaptchaRef.current.execute().then(token => {
      values.recaptcha = token;
    })
    setIsLoading(true);
    const lang = localStorage.getItem('lang')
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_MAIL}`,
      headers: { "content-type": "application/json" },
      data: values
    })
      .then(result => {
        if (result.status === 200) {
          setError(false)
          navigate('/' + lang + '/contact-confirm')
        } else {
          setError(true)
        }
        setIsLoading(false);
      })
      .catch(error => {
        setError(error.message)
        setIsLoading(false);
      });
  };


  const validationSchema = () =>
    object().shape({
      name: string().required(formatMessage({ id: "contact-form-name-required" })),
      lastName: string().required(formatMessage({ id: "contact-form-lastName-required" })),
      phone: string()
        .matches(/^[0-9]+$/, "Solo números.")
        .min(6, "Debe ingresar al menos 6 números"),
      email: string()
        .email("Ingresa un email válido.")
        .required(formatMessage({ id: "contact-form-email-required" })),
      message: string().required(formatMessage({ id: "contact-form-message-required" })),
    });

  return (
    <>
      <div className={styles.container} id="contacto">

        <div className={styles.container__top}>

          {/* Titulos */}
          <div className={styles.title}>
            {isPageContact ?
              <h1>
                <FormattedMessage id="contact-section" />
              </h1>
              :
              <h2>
                <FormattedMessage id="contact-section" />
              </h2>
            }

            <h3>
              <FormattedMessage id="contact-title" />
            </h3>
            <p>
              <FormattedMessage id="contact-subtitle" />
            </p>
          </div>

          {/* Formulario */}
          <Formik
            enableReinitialize
            initialValues={{
              name: "",
              lastName: "",
              phone: "",
              email: "",
              message: "",
              company: "",
              recaptcha: "",
              mailSent: false,
              error: null
            }}
            onSubmit={(values, { resetForm }) => {
              submitForm(values, resetForm);
              resetForm({
                name: "",
                lastName: "",
                phone: "",
                email: "",
                message: "",
                company: "",
                recaptcha: "",
                mailSent: false,
                error: null
              });
            }}
            validationSchema={validationSchema}
          >
            {renderForm}
          </Formik>
        </div>

        {/* Pie de formulario, redes sociales */}
        <div className={styles.footer}>
          <div className={styles.title}>
            <h3>
              <FormattedMessage id="contact-social-title" />&nbsp;
              <div className={styles.arrow__container}>
                <img src={arrowSocial} alt='arrow' className={styles.arrow} />
              </div>
            </h3>
          </div>
          <div className={styles.container__data}>
            <div className={styles.row}>
              <FormattedMessage id="contact-social-email" />
              <br />
              <div className={styles.link__group}>
                <div className={styles.link}>
                  <a href={"mailto:hello@wakapi.com"}>hello@wakapi.com</a>
                  <div className={styles.selected}></div>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <FormattedMessage id="contact-social-phone" />
              <br />
              <div className={styles.link__group}>
                <div className={styles.link__phone}>
                  <a href="tel:+1 (415) 800-4778">+1&nbsp;(415)&nbsp;223-2613</a>
                  <div className={styles.selected}></div>
                </div>
                <div className={styles.link__phone}>
                  <a href="tel:+1 (415) 800-4778">+54&nbsp;(9)&nbsp;261&nbsp;458-2181</a>
                  <div className={styles.selected}></div>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <FormattedMessage id="contact-social-social" />
              <br />
              <div className={styles.link__group}>
                <div className={styles.link}>
                  <a href="https://www.instagram.com/be.wakapi" target="_blank" rel="noopener">Instagram</a>
                  <div className={styles.selected}></div>
                </div>
                <div className={styles.link}>
                  <a href="https://www.linkedin.com/company/wakapi" target="_blank" rel="noopener">LinkedIn</a>
                  <div className={styles.selected}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
      <Slogan />
    </>
  );
};

export default Contact;
