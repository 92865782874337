import React, { useEffect } from "react";
import ReactGA from 'react-ga';
import { Head, TopBar, Footer } from "../../components";

export const CookiePolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview('/cookies');
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <>
      <Head
        title="Cookie Policy | Wakapi"
        description="Learn more about our cookie policy information."
        path='/cookie-policy'
      />
      <TopBar />
      <div className="policy__container" style={{ minHeight: '100vh' }}>
        <div className="policy__text">
          <div className="policy__title"><h1>Cookie policy</h1></div>
          <div
            name="termly-embed"
            data-id="78976490-da7e-4fbc-8ac9-903d671619c3"
            data-type="iframe"
          ></div>
        </div>

      </div>
      <Footer />
    </>

  );
};
export default CookiePolicy;