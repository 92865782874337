export const HtmlIcon = ({ size: { width = "149", height = "148" } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 149 148" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1073_2078)">
                <path d="M31.543 0.385376H38.1914V6.93147H44.3195V0.385376H50.968V20.1959H44.3195V13.5924H38.2492V20.1959H31.543M59.7555 6.98889H53.8875V0.385376H72.3008V6.98889H66.4039V20.1959H59.7555M75.2203 0.385376H82.1867L86.4648 7.36213L90.743 0.385376H97.7094V20.1959H91.0609V10.3768L86.407 17.4971L81.7531 10.3768V20.1959H75.2203M101.005 0.385376H107.653V13.6498H117.077V20.1959H101.005" fill="#747474" />
                <path d="M31.3125 135.614L21.7734 29.2686H126.645L117.106 135.556L74.1227 147.385" fill="#E44D26" />
                <path d="M74.209 138.341V37.9967H117.077L108.896 128.723" fill="#F16529" />
                <path d="M41.2559 51.0027H74.209V64.0375H55.6512L56.8652 77.3881H74.209V90.3942H44.8402M45.4184 96.9402H58.5996L59.5246 107.362L74.209 111.267V124.876L47.2684 117.411" fill="#EBEBEB" />
                <path d="M107.047 51.0027H74.1514V64.0375H105.833M104.647 77.3881H74.1514V90.4229H90.3389L88.8068 107.362L74.1514 111.267V124.819L101.034 117.411" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_1073_2078">
                    <rect width="148" height="147" fill="white" transform="translate(0.208984 0.385376)" />
                </clipPath>
            </defs>
        </svg>


    )
}