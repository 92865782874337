import AnchorLink from "react-anchor-link-smooth-scroll";
import { FormattedMessage } from "react-intl";
import styles from './topBar.module.scss';
import { Link } from "react-router-dom";

export const ContactButton = ({path, lang, toggleMenu}) => {
    if (path.includes("privacy-policy") || path.includes("cookie-policy") || path.includes("disclaimer")) {
        return (
            <Link to={`/${lang}/contact`} style={{ textDecoration: "none" }}>
                <>
                    <div className={styles.button} >
                        <FormattedMessage id="topBar.talk" />
                    </div>
                    <div className={styles["letsTalk--mobile"]} >
                        <FormattedMessage id="topBar.talk" />
                    </div>
                </>
            </Link>
        )
    }
    return (
        <>
            <AnchorLink href="#contacto" className={styles.button}>
                <FormattedMessage id="topBar.talk" />
            </AnchorLink>
            <AnchorLink href="#contacto" className={styles["letsTalk--mobile"]} onClick={toggleMenu}>
                <FormattedMessage id="topBar.talk" />
            </AnchorLink>

        </>
    )
}