import styles from './servicesDescription.module.scss';
import customSoftwareImg from './../../../assets/images/services/services-customer-software-development.webp';
import staffAugmentationRecruitmentImg from './../../../assets/images/services/services-staff-augmentation-recruitment.webp';
import productDesignAndUserExpercienteImg from './../../../assets/images/services/services-product-design-user-experience.webp';
import appDevelopmentImg from './../../../assets/images/services/services-app-development.webp';
import consultancyInnovationImg from './../../../assets/images/services/services-consultancy-innovation.webp';
import qaTesting from './../../../assets/images/services/services-qa-testing.webp';
import { FormattedMessage, useIntl } from 'react-intl';
import parse from "html-react-parser";
import Button from '../../../components/Button/Button';
import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

export const ServicesDescription = () => {
    const { formatMessage } = useIntl();
    const customSoftwareRef = useRef(null);
    const staffAugmentationRef = useRef(null);
    const productDesignRef = useRef(null);
    const appDevelopmentRef = useRef(null);
    const consultancyInnovationRef = useRef(null);
    const qaTesingRef = useRef(null);
    const { item } = useParams();

    const [width, setWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        const topBarHeight = width > 426 ? 130 : 70
        if (item) {
            let offsetTop = 0;
            switch (item) {
                case 'software-design-development':
                    offsetTop = customSoftwareRef.current.offsetTop;
                    break;
                case 'staff-augmentation-recruitment':
                    offsetTop = staffAugmentationRef.current.offsetTop;
                    break;
                case 'product-design-ux':
                    offsetTop = productDesignRef.current.offsetTop;
                    break;
                case 'app-development':
                    offsetTop = appDevelopmentRef.current.offsetTop;
                    break;
                case 'consultancy-innovation':
                    offsetTop = consultancyInnovationRef.current.offsetTop;
                    break;
                case 'qa-testing':
                    offsetTop = qaTesingRef.current.offsetTop;
                    break;
                default:
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    return;
            }
            window.scrollTo({ top: offsetTop - topBarHeight, behavior: 'smooth' });
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    
    }, [item])
    const lang = localStorage.getItem('lang');
return (
    <section>
        <div className={styles.background1} ref={customSoftwareRef}>
            <div className={styles.container}>
                <img src={customSoftwareImg}
                    alt={formatMessage({ id: "services-custom-software-development-title" })}
                    width={416}
                    height={390}
                    className={styles.img__w}
                />
                <div className={styles.text__container}>
                    <h3><FormattedMessage id="services-custom-software-development-title" /></h3>
                    {parse(formatMessage({ id: "services-custom-software-development-text-2" }))}
                    <br />
                    <Link to={`/${lang}/clients/pinpoint`} style={{ textDecoration: "none" }}>
                        <Button secondaryDark>
                            <FormattedMessage id="case-studies" />
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
        <div className={styles.background2} ref={staffAugmentationRef}>
            <div className={styles.container} style={{ flexDirection: 'row-reverse' }}>
                <img src={staffAugmentationRecruitmentImg}
                    alt={formatMessage({ id: "services-staff-augmentation-recruitment-title" })}
                    width={337}
                    height={363}
                    className={styles.img__a}
                />
                <div className={styles.text__container}>
                    <h3><FormattedMessage id="services-staff-augmentation-recruitment-title" /></h3>
                    {parse(formatMessage({ id: "services-staff-augmentation-recruitment-text-2" }))}
                    <br />
                    <Link to={`/${lang}/clients/pinpoint`} style={{ textDecoration: "none" }}>
                        <Button secondary>
                            <FormattedMessage id="case-studies" />
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
        <div className={styles.background1} ref={productDesignRef}>
            <div className={styles.container}>
                <img src={productDesignAndUserExpercienteImg}
                    alt={formatMessage({ id: "services-product-design-user-experience-title" })}
                    width={351}
                    height={422}
                    className={styles.img__k}
                />
                <div className={styles.text__container}>
                    <h3><FormattedMessage id="services-product-design-user-experience-title" /></h3>
                    {parse(formatMessage({ id: "services-product-design-user-experience-text-2" }))}
                    <br />
                    <Link to={`/${lang}/clients/welfi`} style={{ textDecoration: "none" }}>
                        <Button secondaryDark>
                            <FormattedMessage id="case-studies" />
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
        <div className={styles.background2} ref={appDevelopmentRef}>
            <div className={styles.container} style={{ flexDirection: 'row-reverse' }}>
                <img src={appDevelopmentImg}
                    alt={formatMessage({ id: "services-app-development-title" })}
                    width={447}
                    height={308}
                    className={styles.img__2a}
                />
                <div className={styles.text__container}>
                    <h3><FormattedMessage id="services-app-development-title" /></h3>
                    {parse(formatMessage({ id: "services-app-development-text-2" }))}
                    <br />
                    <Link to={`/${lang}/clients/welfi`} style={{ textDecoration: "none" }}>
                        <Button secondary>
                            <FormattedMessage id="case-studies" />
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
        <div className={styles.background1} ref={consultancyInnovationRef}>
            <div className={styles.container}>
                <img src={consultancyInnovationImg}
                    alt={formatMessage({ id: "services-consultancy-innovation-title" })}
                    width={331}
                    height={390}
                    className={styles.img__p}
                />
                <div className={styles.text__container}>
                    <h3><FormattedMessage id="services-consultancy-innovation-title" /></h3>
                    {parse(formatMessage({ id: "services-consultancy-innovation-text-2" }))}
                    <br />
                    <Link to={`/${lang}/clients/amazon`} style={{ textDecoration: "none" }}>
                        <Button secondaryDark>
                            <FormattedMessage id="case-studies" />
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
        <div className={styles.background2} ref={qaTesingRef}>
            <div className={styles.container} style={{ flexDirection: 'row-reverse' }}>
                <img src={qaTesting}
                    alt={formatMessage({ id: "services-qa-testing-title" })}
                    width={300}
                    height={333}
                    className={styles.img__i}
                />
                <div className={styles.text__container}>
                    <h3><FormattedMessage id="services-qa-testing-title" /></h3>
                    {parse(formatMessage({ id: "services-qa-testing-text-2" }))}
                    <br />
                    <Link to={`/${lang}/clients/thomson-reuters`} style={{ textDecoration: "none" }}>
                        <Button secondary>
                            <FormattedMessage id="case-studies" />
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    </section>
)
}