import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import franciscoTeot from './../../assets/images/team/francisco-teot-wakapi-coo-engineer.webp';
import mauricioBarzola from './../../assets/images/team/mauricio-barzola-wakapi-ceo-engineer.webp';
import styles from './founders.module.scss';
import parse from "html-react-parser";

export const Founders = () => {
    const {formatMessage} = useIntl();
  return (
   <div className={styles.founders__container}>
    <div className={styles.founders__titleContainer}>
        <h2><FormattedMessage id="aboutUs.founders-section" /></h2>
        <h3><FormattedMessage id="aboutUs.founders-title" /></h3>
        <p>{parse(formatMessage({ id: "aboutUs.founders-text" }))}</p>
    </div>
    <div className={styles.founders__imageContainer}>
        <div className={styles.founders__item}>
            <img src={franciscoTeot} alt="Francisco Teot at Wakapi" className={styles.founders__img} width={485} height={520} loading="lazy"/>
            <div className={styles.founders__name}>
                Francisco Teot - COO
                <br/>
                <span><FormattedMessage id="aboutUs.founders-Francisco-text" /></span>
            </div>
        </div>
        <div className={styles.spacing}/>
        <div className={styles.founders__item}>
            <img src={mauricioBarzola} alt="Mauricio Barzola at Wakapi"  className={styles.founders__img}  width={485} height={520} loading="lazy"/>
            <div className={styles.founders__name}>
                Mauricio Barzola - CEO
                <br/>
                <span><FormattedMessage id="aboutUs.founders-Mauricio-text" /></span>
            </div>
        </div>
        
    </div>
   </div>
  )
}
export default Founders;