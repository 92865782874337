export const PhpIcon = ({ size: { width = "167", height = "92" } }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 167 92" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1073_2009)">
                <mask id="mask0_1073_2009"  maskUnits="userSpaceOnUse" x="2" y="2" width="162" height="88">
                    <path d="M2.64648 45.8853C2.64648 69.7039 38.7626 89.0131 83.3123 89.0131C127.862 89.0131 163.978 69.7039 163.978 45.8853C163.978 22.0671 127.862 2.75763 83.3123 2.75763C38.7626 2.75763 2.64648 22.0671 2.64648 45.8853Z" fill="white" />
                </mask>
                <g mask="url(#mask0_1073_2009)">
                    <path d="M2.64648 45.8853C2.64648 69.7039 38.7626 89.0131 83.3123 89.0131C127.862 89.0131 163.978 69.7039 163.978 45.8853C163.978 22.0671 127.862 2.75763 83.3123 2.75763C38.7626 2.75763 2.64648 22.0671 2.64648 45.8853Z" fill="url(#paint0_radial_1073_2009)" />
                </g>
                <mask id="mask1_1073_2009"  maskUnits="userSpaceOnUse" x="-1" y="-3" width="169" height="97">
                    <path d="M-0.714844 -2.15332H167.339V93.924H-0.714844V-2.15332Z" fill="white" />
                </mask>
                <g mask="url(#mask1_1073_2009)">
                    <path d="M83.3116 85.8109C126.121 85.8109 160.826 67.9355 160.826 45.8852C160.826 23.8351 126.121 5.95943 83.3116 5.95943C40.502 5.95943 5.79688 23.8351 5.79688 45.8852C5.79688 67.9355 40.502 85.8109 83.3116 85.8109Z" fill="#777BB3" />
                </g>
                <mask id="mask2_1073_2009"  maskUnits="userSpaceOnUse" x="-1" y="-3" width="169" height="97">
                    <path d="M-0.714844 -2.15332H167.339V93.924H-0.714844V-2.15332Z" fill="white" />
                </mask>
                <g mask="url(#mask2_1073_2009)">
                    <path d="M46.4725 50.8355C49.9926 50.8355 52.6205 50.1757 54.2832 48.8751C55.9282 47.5878 57.0643 45.3576 57.6598 42.2467C58.215 39.3388 58.0035 37.3085 57.0313 36.2128C56.0373 35.0936 53.8882 34.5261 50.6438 34.5261H45.0184L41.8998 50.8355H46.4725ZM28.0733 70.9257C27.8122 70.9257 27.5642 70.8071 27.3982 70.6019C27.2318 70.397 27.1644 70.1272 27.2143 69.8665L35.4793 26.6463C35.5593 26.2284 35.9196 25.9266 36.3385 25.9266H54.1517C59.7499 25.9266 63.9169 27.4715 66.536 30.519C69.1691 33.5825 69.9823 37.8648 68.9526 43.2466C68.5337 45.4392 67.813 47.4755 66.8108 49.2985C65.8075 51.1237 64.4814 52.8125 62.8689 54.3189C60.9386 56.155 58.7539 57.4865 56.3813 58.2699C54.0463 59.043 51.0476 59.435 47.4686 59.435H40.2557L38.1959 70.2057C38.116 70.6238 37.7559 70.9257 37.337 70.9257H28.0733Z" fill="black" />
                    <path d="M45.7403 35.4155H50.6442C54.5599 35.4155 55.9201 36.2888 56.3822 36.8093C57.1489 37.6734 57.2937 39.4953 56.8009 42.0778C56.2471 44.969 55.2207 47.019 53.7497 48.1701C52.2436 49.3485 49.7951 49.9457 46.4729 49.9457H42.9618L45.7403 35.4155ZM54.152 25.0368H36.3386C35.5007 25.0368 34.7803 25.6406 34.6204 26.4765L26.3554 69.6967C26.2557 70.2183 26.3905 70.7577 26.7228 71.1679C27.0554 71.578 27.5508 71.8152 28.0736 71.8152H37.337C38.1752 71.8152 38.8956 71.2114 39.0555 70.3755L40.9776 60.3245H47.4689C51.1399 60.3245 54.2302 59.9176 56.6532 59.1155C59.1452 58.2926 61.4378 56.8974 63.467 54.9688C65.1452 53.3995 66.5276 51.6381 67.575 49.7328C68.6227 47.827 69.3754 45.7017 69.8122 43.4161C70.8961 37.7511 70.0156 33.2147 67.1954 29.9338C64.4027 26.6844 60.014 25.0368 54.152 25.0368ZM40.8381 51.725H46.4729C50.2077 51.725 52.9896 51.01 54.8175 49.5798C56.6457 48.1493 57.8786 45.7619 58.5193 42.4164C59.1323 39.2044 58.8531 36.9377 57.6817 35.6172C56.5088 34.2967 54.1634 33.6363 50.6442 33.6363H44.2972L40.8381 51.725ZM54.152 26.816C59.5107 26.816 63.42 28.2453 65.8772 31.1042C68.3341 33.9631 69.0741 37.9536 68.0937 43.0768C67.6902 45.1873 67.0078 47.1168 66.047 48.8649C65.0851 50.6141 63.8291 52.2136 62.2769 53.664C60.4272 55.4233 58.3723 56.6771 56.1112 57.4238C53.85 58.1725 50.9701 58.5453 47.4689 58.5453H39.5342L37.337 70.036H28.0736L36.3386 26.816H54.152Z" fill="white" />
                    <path d="M90.1925 59.435C89.9311 59.435 89.6834 59.3164 89.5173 59.1112C89.3507 58.9063 89.2834 58.6367 89.3332 58.3758L92.9893 39.2525C93.3371 37.4339 93.2513 36.1285 92.748 35.577C92.4399 35.2398 91.5144 34.6743 88.778 34.6743H82.1545L77.5578 58.715C77.4779 59.1331 77.1175 59.435 76.6986 59.435H67.5081C67.2467 59.435 66.999 59.3164 66.8327 59.1112C66.6664 58.9063 66.599 58.6364 66.6489 58.3758L74.9139 15.1555C74.9938 14.7377 75.3542 14.4358 75.7731 14.4358H84.9636C85.225 14.4358 85.4727 14.5545 85.639 14.7594C85.8053 14.9646 85.8727 15.2341 85.8228 15.4951L83.8283 25.9266H90.9537C96.3819 25.9266 100.063 26.8992 102.207 28.9002C104.392 30.9416 105.074 34.2055 104.233 38.6025L100.388 58.715C100.308 59.1331 99.9481 59.435 99.5288 59.435H90.1925Z" fill="black" />
                    <path d="M84.9639 13.5461H75.7734C74.9352 13.5461 74.2149 14.1499 74.055 14.9858L65.79 58.206C65.6902 58.7276 65.825 59.267 66.1576 59.6771C66.4902 60.0872 66.9856 60.3245 67.5085 60.3245H76.6989C77.5368 60.3245 78.2575 59.7207 78.4174 58.8848L82.8763 35.5638H88.7784C91.5101 35.5638 92.0834 36.1569 92.1065 36.1824C92.2722 36.3636 92.4901 37.2028 92.1304 39.0828L88.4743 58.206C88.3746 58.7276 88.5094 59.267 88.842 59.6771C89.1746 60.0872 89.67 60.3245 90.1928 60.3245H99.5291C100.367 60.3245 101.088 59.7207 101.248 58.8848L105.093 38.7723C105.995 34.0512 105.223 30.5097 102.799 28.2453C100.486 26.0863 96.6115 25.0368 90.954 25.0368H84.8901L86.6824 15.6648C86.7818 15.1432 86.6473 14.6036 86.3147 14.1937C85.9821 13.7836 85.4867 13.5461 84.9639 13.5461ZM84.9639 15.3253L82.7666 26.816H90.954C96.1056 26.816 99.659 27.73 101.615 29.5554C103.57 31.3821 104.156 34.3418 103.375 38.4331L99.5291 58.5453H90.1928L93.8488 39.4223C94.2649 37.2469 94.112 35.7631 93.3896 34.9719C92.6669 34.1808 91.1296 33.7846 88.7784 33.7846H81.433L76.6989 58.5453H67.5085L75.7734 15.3253H84.9639Z" fill="white" />
                    <path d="M118.775 50.8355C122.295 50.8355 124.923 50.1757 126.586 48.8751C128.231 47.5881 129.367 45.3579 129.963 42.2467C130.518 39.3388 130.306 37.3085 129.334 36.2128C128.34 35.0936 126.191 34.5261 122.947 34.5261H117.321L114.202 50.8355H118.775ZM100.376 70.9257C100.115 70.9257 99.8672 70.8071 99.7011 70.6019C99.5348 70.397 99.4671 70.1272 99.517 69.8665L107.782 26.6463C107.862 26.2284 108.222 25.9266 108.642 25.9266H126.454C132.053 25.9266 136.22 27.4715 138.839 30.519C141.472 33.5822 142.285 37.8645 141.256 43.2466C140.836 45.4392 140.116 47.4755 139.114 49.2985C138.11 51.1237 136.784 52.8125 135.172 54.3189C133.241 56.155 131.057 57.4865 128.684 58.2699C126.349 59.043 123.35 59.435 119.771 59.435H112.558L110.499 70.2057C110.419 70.6238 110.059 70.9257 109.64 70.9257H100.376Z" fill="black" />
                    <path d="M118.043 35.4155H122.947C126.862 35.4155 128.222 36.2888 128.685 36.8093C129.452 37.6734 129.596 39.4953 129.103 42.0775C128.55 44.969 127.523 47.019 126.052 48.1701C124.546 49.3485 122.098 49.9457 118.775 49.9457H115.265L118.043 35.4155ZM126.454 25.0368H108.641C107.803 25.0368 107.083 25.6406 106.923 26.4765L98.6582 69.6967C98.5584 70.2183 98.6932 70.7577 99.0255 71.1679C99.3581 71.578 99.8535 71.8152 100.376 71.8152H109.64C110.478 71.8152 111.198 71.2114 111.358 70.3755L113.28 60.3245H119.771C123.442 60.3245 126.533 59.9176 128.956 59.1155C131.448 58.2926 133.741 56.8971 135.77 54.9682C137.448 53.3992 138.83 51.6381 139.877 49.7328C140.925 47.827 141.678 45.7017 142.115 43.4161C143.199 37.7508 142.318 33.2144 139.498 29.9335C136.705 26.6844 132.317 25.0368 126.454 25.0368ZM113.141 51.725H118.775C122.51 51.725 125.292 51.01 127.12 49.5798C128.948 48.1493 130.181 45.7619 130.822 42.4164C131.435 39.2044 131.156 36.9377 129.984 35.6172C128.811 34.2967 126.466 33.6363 122.947 33.6363H116.6L113.141 51.725ZM126.454 26.816C131.813 26.816 135.722 28.2453 138.18 31.1042C140.637 33.9631 141.376 37.9536 140.396 43.0768C139.993 45.1873 139.31 47.1168 138.349 48.8649C137.388 50.6141 136.132 52.2136 134.579 53.664C132.73 55.4233 130.675 56.6771 128.414 57.4238C126.152 58.1725 123.272 58.5453 119.771 58.5453H111.837L109.64 70.036H100.376L108.641 26.816H126.454Z" fill="white" />
                </g>
            </g>
            <defs>
                <radialGradient id="paint0_radial_1073_2009" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(51.0786 17.92) scale(105.926 107.659)">
                    <stop stop-color="#AEB2D5" />
                    <stop offset="0.3" stop-color="#AEB2D5" />
                    <stop offset="0.75" stop-color="#484C89" />
                    <stop offset="1" stop-color="#484C89" />
                </radialGradient>
                <clipPath id="clip0_1073_2009">
                    <rect width="166" height="91" fill="white" transform="translate(0.3125 0.385376)" />
                </clipPath>
            </defs>
        </svg>


    )
}