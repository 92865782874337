import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl'
import spanishMessages from './../assets/lang/esp.json'
import englishMessages from './../assets/lang/eng.json'
const langContext = React.createContext();

const LangProvider = ({ children }) => {
    let localDefault;
    let messagesDefault = spanishMessages;

    const lang = localStorage.getItem('lang') === null ? 'es' : localStorage.getItem('lang');
    const path = window.location.pathname;
    useEffect(() => {
        if (lang) {
            if (lang === 'es') {
                localDefault = 'es'
                messagesDefault = spanishMessages;
            } else if (lang === 'en') {
                localDefault = 'en'
                messagesDefault = englishMessages;
            } else {
                localDefault = 'es';
                messagesDefault = spanishMessages;
            }
        }
    }, [lang])

    useEffect(() => {
        const haveLangPath = path.includes('/es') || path.includes('/en');
        var navigatorLang = navigator.language || navigator.userLanguage;
        let newPathBase = '';
        let origin = '';
        let lang = '';
        if (!haveLangPath) {
            if (path.startsWith('/wakapi.com/testweb/')) {
                newPathBase = path.split('/wakapi.com/testweb')[1];
                origin = window.location.origin + '/wakapi.com/testweb'
            } else {
                if (!path.startsWith('/es') && !path.startsWith('/en')) {
                    newPathBase = path;
                    origin = window.location.origin
                }
            }
            if (navigatorLang.startsWith('es')) {
                lang = '/es';
            } else {
                lang = '/en';
            }
            window.location.replace(origin + lang + newPathBase)
        } else {
            if (path.includes('/es')) {
                localStorage.setItem('lang', 'es')
                setLocale('es');
                setMessages(spanishMessages);
            } else {
                localStorage.setItem('lang', 'en')
                setLocale('en');
                setMessages(englishMessages);
            }
        }

    }, [])

    const [locale, setLocale] = useState('es')
    const [messages, setMessages] = useState(messagesDefault)
    const changeLanguage = (language) => {
        switch (language) {
            case 'es':
                setLocale('es');
                setMessages(spanishMessages);
                localStorage.setItem('lang', 'es')
                break;
            case 'en':
                setLocale('en');
                setMessages(englishMessages);
                localStorage.setItem('lang', 'en')
                break;
            default:
                setLocale('es');
                setMessages(spanishMessages);
                localStorage.setItem('lang', 'es')
                break;
        }
    }
    return (
        <langContext.Provider value={{ changeLanguage: changeLanguage, lang: localDefault }}>
            <IntlProvider messages={messages} locale={locale} defaultLocale='es'>
                {children}
            </IntlProvider>
        </langContext.Provider>
    )
}
export { LangProvider, langContext };