import { useIntl } from "react-intl"
import { BudderflyLogo2, Contact, Footer, Head, ShootheLogo, TopBar, ZippyYumLogo } from "../../components"
import { Introduction, WhyChoose } from "../../modules"
import styles from './technologies.module.scss';
import parse from "html-react-parser";
import { AiIcon } from "../../assets/icons/technologies/aiIcon";
import { ScaleIcon, DobleArrowIcon, CheckIcon, SecurityIcon } from "../../assets/icons";
import { KeyAspects } from "../../modules/Technologies/KeyFeatures/KeyAspects";
import { DevelopmentProcessAi } from "../../modules/Technologies/DevelopmentProcess/DevelopmentProcessAi";
import { TechnologiesClient } from "../../modules/Technologies/Clients/Clients";
import { Suspense, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import ReactGA from 'react-ga';

export const AIPage = () => {
    const { formatMessage } = useIntl();
    const renderLoader = () => <div className="spinner"><CircularProgress color="inherit" /></div>;


    useEffect(() => {
        ReactGA.pageview('/technologies/ai');
        window.scrollTo(0, 0);
    }, [])


    return (
        <>
            {/* Encabezados y meta descriptions */}
            <Head
                title='AI Development | Wakapi'
                description="Transform your business with AI development. Explore how artificial intelligence boosts efficiency and drives innovation. "
                path="/technologies/ai"
            />

            {/* Barra de navegación */}
            <TopBar />
            <main className={styles.container}>
                <Introduction
                    title={parse(formatMessage({ id: "technologies.AIDevelopment-title" }))}
                    description={parse(formatMessage({ id: "technologies.AIDevelopment-description" }))}
                    icon={<div className={styles.icon__ai}> <AiIcon size={{ width: "119", height: "120" }} /></div>}
                />
                {/* WhyChoose modulo */}
                <WhyChoose
                    list={
                        [
                            {
                                icon: <DobleArrowIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.AIDevelopment-why-choose-1-title" }),
                                description: formatMessage({ id: "technologies.AIDevelopment-why-choose-1-text" }),
                            },
                            {
                                icon: <CheckIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.AIDevelopment-why-choose-2-title" }),
                                description: formatMessage({ id: "technologies.AIDevelopment-why-choose-2-text" }),
                            },
                            {
                                icon: <ScaleIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.AIDevelopment-why-choose-3-title" }),
                                description: formatMessage({ id: "technologies.AIDevelopment-why-choose-3-text" }),
                            },
                            {
                                icon: <SecurityIcon size={{ width: "56", height: "56" }} color='#0ACF83' />,
                                title: formatMessage({ id: "technologies.AIDevelopment-why-choose-4-title" }),
                                description: formatMessage({ id: "technologies.AIDevelopment-why-choose-4-text" }),
                            },
                        ]
                    }
                    title={parse(formatMessage({ id: "technologies.AIDevelopment-why-choose-title" }))}
                />

                <div className={styles.ai__container}>
                    <KeyAspects
                        title={formatMessage({ id: "technologies.AIDevelopment-key-aspects-title" })}
                        list={
                            [
                                {
                                    title: formatMessage({ id: "technologies.AIDevelopment-key-aspects-1-title" }),
                                    text: formatMessage({ id: "technologies.AIDevelopment-key-aspects-1-text" }),
                                },
                                {
                                    title: formatMessage({ id: "technologies.AIDevelopment-key-aspects-2-title" }),
                                    text: formatMessage({ id: "technologies.AIDevelopment-key-aspects-2-text" }),
                                },
                                {
                                    title: formatMessage({ id: "technologies.AIDevelopment-key-aspects-3-title" }),
                                    text: formatMessage({ id: "technologies.AIDevelopment-key-aspects-3-text" }),
                                },
                                {
                                    title: formatMessage({ id: "technologies.AIDevelopment-key-aspects-4-title" }),
                                    text: formatMessage({ id: "technologies.AIDevelopment-key-aspects-4-text" }),
                                },
                                {
                                    title: formatMessage({ id: "technologies.AIDevelopment-key-aspects-5-title" }),
                                    text: formatMessage({ id: "technologies.AIDevelopment-key-aspects-5-text" }),
                                },
                            ]
                        }
                    />
                    <DevelopmentProcessAi
                        title={formatMessage({ id: "technologies.development-process" })}
                        list={
                            [
                                {
                                    title: formatMessage({ id: "technologies.AIDevelopment-development-process-1-title" }),
                                    text: formatMessage({ id: "technologies.AIDevelopment-development-process-1-text" }),
                                },
                                {
                                    title: formatMessage({ id: "technologies.AIDevelopment-development-process-2-title" }),
                                    text: formatMessage({ id: "technologies.AIDevelopment-development-process-2-text" }),
                                },
                                {
                                    title: formatMessage({ id: "technologies.AIDevelopment-development-process-3-title" }),
                                    text: formatMessage({ id: "technologies.AIDevelopment-development-process-3-text" }),
                                },
                                {
                                    title: formatMessage({ id: "technologies.AIDevelopment-development-process-4-title" }),
                                    text: formatMessage({ id: "technologies.AIDevelopment-development-process-4-text" }),
                                },
                                {
                                    title: formatMessage({ id: "technologies.AIDevelopment-development-process-5-title" }),
                                    text: formatMessage({ id: "technologies.AIDevelopment-development-process-5-text" }),
                                },
                            ]
                        }
                    />
                </div>
                <TechnologiesClient
                    title={formatMessage({ id: "technologies.AIDevelopment-satisfied" })}
                    list={[
                        <BudderflyLogo2
                            size={
                                {
                                    width: '185',
                                    height: '90'
                                }}
                        />,

                        <ZippyYumLogo
                            size={{
                                width: '141',
                                height: '78'
                            }}
                        />,
                        <ShootheLogo
                            size={
                                {
                                    width: '91',
                                    height: '78'
                                }}
                        />,
                    ]}
                />
                <section className="contact__container">
                    <Suspense fallback={renderLoader()}>
                        <Contact />
                    </Suspense>
                </section>
                <Footer />
            </main>
        </>
    )
}